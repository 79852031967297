import axios from 'axios';

var roydanToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/, '$1');

class HttpAgent {
	constructor() {

		let version = document.cookie.replace(/(?:(?:^|.*;\s*)version\s*\=\s*([^;]*).*$)|^.*$/, '$1');
		if (!version) {
			const urlParams = new URLSearchParams(window.location.search);
			version = urlParams.get('version');
		}

		axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
		axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		axios.defaults.headers.common['Authorization'] = `Bearer ${roydanToken}`;
		axios.defaults.headers.common['Expected-API-Version'] = version;
	}

	async getTwilioToken() {
		const response = await axios.get('api/twiliotoken');
		return response;
	}

	async pauseRecording(callId) {
		const response = await axios.post('api/phonecall/pauserecording', { CallId: callId });
		return response;
	}

	async resumeRecording(callId) {
		const response = await axios.post('api/phonecall/resumerecording', { CallId: callId });
		return response;
	}

	async placeOnHold(callId) {
		const response = await axios.post('api/phonecall/placeonhold', { CallId: callId });
		return response;
	}

	async takeOffHold(callId) {
		const response = await axios.post('api/phonecall/takeoffhold', { CallId: callId });
		return response;
	}

	async getPhoneCallEvents(callId) {
		const response = await axios.get(`api/phonecall/getPhoneCallEvents/${callId}`);
		return response;
	}

	async voicemailPlayed(id) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post('api/voicemail/played', { id: id});
		return response;
	}

	async getTasks() {
		const response = await axios.get('api/worktask');
		return response;
	}

	async getVoicemails() {
		const response = await axios.get('api/voicemail');
		return response;
	}

	async archiveVoicemail(id) {
		const response = await axios.post('api/voicemail/archive', {id : id});
		return response;
	}

	async deleteVoicemail(id) {
		const response = await axios.delete('api/voicemail/' + id);
		return response;
	}

	async attachVoicemail(data) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/voicemail/attach`, data);
		return response;
	}

	async endWorkTask(activePhoneCallId, agentId, taskId, force, reject) {
		const response = await axios.post('api/worktask/end', { activePhoneCallId: activePhoneCallId, agentId : agentId, id : taskId, force: force, reject: reject });
		return response;
	}

	async dropIn(dropIn) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/dropin`, dropIn);
		return response;
	}

	async coach(coach) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/coach`, coach);
		return response;
	}

	async uncoach(uncoach) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/uncoach`, uncoach);
		return response;
	}

	async barge(barge) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/barge`, barge);
		return response;
	}

	async transferCall(transfer) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/transfer`, transfer);
		return response;
	}

	async cancelTransfer(transfer) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/canceltransfer`, transfer);
		return response;
	}

	async placeAgentToAgentCall(agent) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/callagent`, agent);
		return response;
	}

	async placeManualCall(call) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.post(`api/${entity}/phonecall/makecall`, call);
		return response;
	}

	async getDialingCampaigns() {
		const response = await axios.get('api/dialingCampaign');
		return response;
	}

	async getOutboundStatistics() {
		const response = await axios.get('api/statistics/outbound');
		return response;
	}

	async getInboundStatistics() {
		const response = await axios.get('api/statistics/inbound');
		return response;
	}

	async pauseDialingCampaign(dialer) {
		const response = await axios.post('api/dialingCampaign/pause', dialer);
		return response;
	}

	async resumeDialingCampaign(dialer) {
		const response = await axios.post('api/dialingCampaign/resume', dialer);
		return response;
	}

	async stopDialingCampaign(dialer) {
		const response = await axios.post('api/dialingCampaign/stop', dialer);
		return response;
	}

	async getAgents() {
		const response = await axios.get('api/agent');
		return response;
	}

	async getAgent(agentId) {
		const response = await axios.get(`api/agent/${agentId}`);
		return response;
	}

	async getAgentPhoneNumbers(agentId) {
		const response = await axios.get(`api/agent/${agentId}/availablephonenumbers`);
		return response;
	}

	async updateAgent(agent) {
		return axios.put(`api/agent/${agent.id}`, agent);
	}

	async createTag(tag) {
		const response = await axios.post(`api/tag`, tag);
		return response;
	}

	async getTags() {
		const response = await axios.get('api/tag');
		return response;
	}

	async updateTag(tag) {
		const response = await axios.put(`api/tag/${tag.id}`, tag);
		return response;
	}

	async loginDialer(dialerRequest) {
		try {
			const response = await axios.post('api/agent/dialerlogin', dialerRequest);
			return response;
		} catch (error) {
			return {
				error: error,
				data: null
			};
		}
	}

	async logoutDialer(dialerRequest) {
		const response = await axios.post('api/agent/dialerlogout', dialerRequest);
		return response;
	}

	async checkUserLicense() {
		const response = await axios.get('api/agent/connection');
		return response;
	}

	async getConsumerConsent(consumerId) {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.get(`api/${entity}/consumer/${consumerId}/getconsent?active=true`);
		return response;
	}

	async addConsumerConsent(consumerConsent, isNewAltPhone) {	
		const entity = await window.bloodhound.getEntity();
		const consumerId = consumerConsent.debtorId;
		const response = !isNewAltPhone ?
			await axios.post(`api/${entity}/consumer/${consumerId}/addconsent`, consumerConsent) :
			await axios.post(`api/${entity}/consumer/${consumerId}/addphonewithconsent`, consumerConsent);
		return response;
	}

	async getConsumerPhoneNumbers(entity, consumerId) {
		const response = await axios.get(`api/${entity}/consumer/${consumerId}/phone`);
		return response;
	}

	async getConsentTypes() {
		const entity = await window.bloodhound.getEntity();
		const response = await axios.get(`api/${entity}/consenttypes`);
		return response;
	}

	async getCurrentComplianceRules() {
		const response = await axios.get(`api/compliancerule`);			
		return response;
	}

	async getCurrentComplianceRule(id) {
		const response = await axios.get(`api/compliancerule/${id}`);
		return response;
	}

	async updateComplianceRules(model) {
		const response = await axios.post(`api/compliancerule`, model);
		return response;
	}

	async testComplianceRules(model) {
		const response = await axios.post(`api/compliancerule/test`, model);
		return response;
	}

	async getConsumerIdByNumber(entity, consumerNumber) {
		const response = await axios.get(`api/${entity}/consumer/${consumerNumber}/id`);
		return response;
	}

	async consumerLookup(entity) {
		const response = await axios.get(`api/${entity}/lookup/consumer`);
		return response;
	}

	async rightPartyContact(commId, consumerId, enable) {

		console.log('right party contact', commId, consumerId);
		const response = await axios.post(`api/phonecall/rpc`,
            {
				commId: commId,
				consumerId: consumerId,
				enable : enable
            });
        return response;
	}

	async logOutAgentSession(agent) {
		console.log('Logging out of agent session...', agent);
		const response = await axios.post(`api/agent/kill`, agent);
		return response;
	}
}

const httpAgent = new HttpAgent();
export default httpAgent;
