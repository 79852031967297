import React from 'react';
import { connect } from 'react-redux';
import RoundButton from './core/RoundButton/RoundButton';
import HttpAgent from '../httpagent.js';
import styled, { css } from 'styled-components';
import { actionCreators as notificationActionCreators } from '../store/Notifications';
import { actionCreators as tabActionCreators } from '../store/Tabs';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConnectionIndicator from './core/ConnectionIndicator/ConnectionIndicator';
import NotificationDefinitions from './notifications/NotificationDefinitions';
import Tag from './core/Tag/Tag';
import AgentTaskTimer from './utils/AgentTaskTimer';
import PhoneNumber from './core/PhoneNumber/PhoneNumber';

const AttributeSection = styled.section`
	text-align: center;
	margin-bottom: ${props => props.theme.margin.full};
`;
const AttributeContainer = styled.div`
	font-size: 1.3em;
`;

const ActiveIndicator = styled(ConnectionIndicator)`

	font-size: 2em;
	position: absolute;
	top: 0;
	left: calc(50% - 1.1em);
	transform: rotate(-45deg);

`;

const BoundIndicator = styled(FontAwesomeIcon)`

	font-size: 2em;
	position: absolute;
	top: 0;
	left: calc(50% - 1.1em);
	transform: rotate(-45deg);

`;

const PhoneIcon = styled(FontAwesomeIcon)`

	font-size: 4em;
	margin-bottom: ${props => props.theme.margin.double};

`;

const DialerIconContainer = styled.div`

	color: ${props => props.theme.colors.brand};	

`;

const TaskHeader = styled.h3`

	margin-bottom: ${props => props.theme.margin.full};

`;

const AgentTimerContainer = styled.div`

	color: ${props => props.theme.colors.brand};	
	margin-top: 15px;
`;

const ButtonContainer = styled.div`
    margin-top: 15px;
`;

const ActionButton = styled(RoundButton)`
    min-width: 175px;
`;

const CallStatusContainer = styled.div`
    margin-bottom: 5px;
	padding: 0;
	display: inline-block;
`;

class TaskDetail extends React.Component {
	constructor(props) {
		super(props);
		this.finishTaskClicked = this.finishTaskClicked.bind(this);
		this.rightPartyContactClicked = this.rightPartyContactClicked.bind(this);
		this.state = {
			finishTaskEnabled: true,
			rightPartyContactInFlight: false,
			rightPartyContact:false
		};
	}

	async componentDidMount() {
	}

	async finishTask() {
		var task = this.props.activeTask;
		var result = await HttpAgent.endWorkTask(null, null, this.props.activeTask.id, false, false);
		this.setState({
			finishTaskEnabled: true
		});

		if (!result.data[0] || !result.data[0].ended) {
			this.props.createNotification(
				{
					type: NotificationDefinitions.types.plainText,
					body: result.data[0].message,
					title: 'unable to finish task',
					icon: "clipboard-check",
					warning: true
				});
		} else if (task && (task.workTaskType === 9 || task.workTaskType === 10)) {
			this.props.tabDeleted(this.props.tabKey);

			var tab = this.props.tabs.find(t => t.type === 'dialPad');

			if (tab) {
				this.props.tabSelectionChanged({ tabId: tab.key });
			}
		}
	}

	async rightPartyContact() {
		var activeTask = this.props.activeTask;
		if (!activeTask) {
			this.setState({
                rightPartyContactInFlight: false
			});
			return;
		}

		var commId = activeTask.activePhoneCallId ? activeTask.activePhoneCallId : activeTask.lastPhoneCallId;
		var callHasRightPartyContact = this.state.lastRpcCallId && this.state.lastRpcCallId === commId && this.state.rightPartyContact;
		var newRpc = !callHasRightPartyContact;
		await HttpAgent.rightPartyContact(commId, activeTask.consumerId, newRpc);
		this.setState({
			rightPartyContactInFlight: false,
			lastRpcCallId: commId,
			rightPartyContact : newRpc
		});
	}

	async finishTaskClicked() {
		if (!this.state.finishTaskEnabled) {
			return;
		}

		this.setState({
			finishTaskEnabled: false
		}, () => this.finishTask());
	}

	async rightPartyContactClicked() {
        var activeTask = this.props.activeTask;
        if (!activeTask) {
            return;
        }
        var commId = activeTask.activePhoneCallId ? activeTask.activePhoneCallId : activeTask.lastPhoneCallId;
		var enabled = commId != null && !this.state.rightPartyContactInFlight;
		if (!enabled) {
			return;
		}

		this.setState({
            rightPartyContactInFlight: true
		}, () => this.rightPartyContact());
	}

	translateCallStatus(eventNumber) {
		switch (eventNumber) {
			case 0:
				return "Initiating Provider";
			case 1:
				return "Ringing";
			case 4:
				return "In Progress";
			case 8:
				return "Completed";
			case 9:
				return "Busy";
			case 18:
				return "Initializing";
			case 10:
				return "Failed";
			case 11:
				return "No Answer";
			case 12:
				return "Cancelled";
			default:
				return "";
		}
	}

	renderAttributes() {
		if (this.props.activeTask.attributes) {
			var attrs = this.props.activeTask.attributes.map(attr => {
				return <Tag key={attr.key}>{attr.name}</Tag>;
			});
			return <AttributeSection>
				<AttributeContainer>
					{attrs}
				</AttributeContainer>
			</AttributeSection>;
		}
		return;
	}

	renderTaskControl() {
		var activeTask = this.props.activeTask;
		if (!activeTask) {
			return;
		}
		if (activeTask.lastPhoneCallTimeEnd) {
			return <React.Fragment>
				<ButtonContainer><ActionButton icon={"flag-checkered"} onClick={this.finishTaskClicked} enabled={this.state.finishTaskEnabled}>Finish Task</ActionButton></ButtonContainer>
				<AgentTimerContainer><AgentTaskTimer {...this.props} icon={"hourglass-start"} /></AgentTimerContainer>
			</React.Fragment>;
		}
	}

	renderRpc() {
		var activeTask = this.props.activeTask;
		if (!activeTask || !activeTask.consumerId) {
			return;
		}
		var commId = activeTask.activePhoneCallId ? activeTask.activePhoneCallId : activeTask.lastPhoneCallId;
		var enabled = commId != null && !this.state.rightPartyContactInFlight;
        var callHasRightPartyContact = this.state.lastRpcCallId && this.state.lastRpcCallId === commId && this.state.rightPartyContact;
		return <ButtonContainer><ActionButton icon={"check"} onClick={this.rightPartyContactClicked} positive={callHasRightPartyContact} enabled={enabled}>Right Party Contact</ActionButton></ButtonContainer>;
	}

	renderPhoneCallStatus() {
		let activeTask = this.props.activeTask;
		let event = this.props.phoneCallEvent;

		if (!activeTask || !event
			|| (activeTask && activeTask.workTaskType !== 1 && activeTask.workTaskType !== 10)) {
			return;
		}

		var callId = activeTask.activePhoneCallId ? activeTask.activePhoneCallId : activeTask.lastPhoneCallId;

		if (event.phoneCallId !== callId) {
			return;
		}

		return <CallStatusContainer>{this.translateCallStatus(event.phoneCallEventType)}</CallStatusContainer>;
	}

	render() {
		var task = this.props.activeTask;
		let taskDisplayText = 'Unknown Caller';

		if (!task) {
			return <React.Fragment>
				<DialerIconContainer>
					<PhoneIcon icon="phone" fixedWidth />
					<ActiveIndicator />
				</DialerIconContainer>
				<TaskHeader>
					Waiting for calls
					{this.props.queueCode &&
						<span> on queue <code>{this.props.queueCode}</code></span>
					}
				</TaskHeader>
				<AgentTimerContainer style={{ marginTop: 0 }}><AgentTaskTimer {...this.props} icon={"hourglass-start"} /></AgentTimerContainer>
			</React.Fragment>;
		} else {
			if (task.consumer) {
				taskDisplayText = task.consumer.firstName + ' ' + task.consumer.lastName;
			} else if (task.inbound) {
				taskDisplayText = "Inbound Caller";
			} else if (task.workTaskType === 10 || (task.workTaskType === 6 && task.phoneNumber !== '')) {
				taskDisplayText = <PhoneNumber>{task.phoneNumber}</PhoneNumber>;
			} else if (task.workTaskType === 9) {
				taskDisplayText = `AGENT: ${task.phoneNumber}`;
			}
		}

		var directionArrow = task.inbound ? "arrow-alt-down" : "arrow-alt-up";
		return <React.Fragment>
			<DialerIconContainer active>
				<PhoneIcon icon="phone" fixedWidth />
				<BoundIndicator icon={directionArrow} fixedWidth />
			</DialerIconContainer>
			{this.renderPhoneCallStatus()}
			<TaskHeader>
				<span>{taskDisplayText}</span>
			</TaskHeader>
			{this.renderAttributes()}
			<span>
				{this.renderRpc()}
				{this.renderTaskControl()}
			</span>
		</React.Fragment>;
	}
}

export default connect(state => { return { ...state.agent, ...state.notifications, ...state.tabs, ...state.phone } },
	dispatch => bindActionCreators({
		...tabActionCreators,
		...notificationActionCreators
	}, dispatch))(TaskDetail);