import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HexToRgb from '../../utils/HexToRgb.js';

const StatusContainer = styled.div`
	display: inline-block;
	${props => {
		const theme = props.theme;

		if (props.showCode) {
			return css`
			
				padding: ${theme.padding.half} ${theme.padding.double} 0 ${theme.padding.full};
				font-family: ${theme.fonts.code};
				font-size: calc(1.25em * .9);
				font-weight: 300;
				background: ${HexToRgb(props.color, .15 * props.opacity)};
				border-radius: 999px;
				color: ${theme.colors.text};
			`;
		}
	}}`;

const IconContainer = styled.span`
	${props => {
		return css`		
			font-size: .8em;
			color: ${props.color};
			opacity: ${props.opacity};
			margin-right: ${props.showCode ? props.theme.margin.quarter : "0"};
		
		`;
	}}`;

/**
 * Shows the status of a given agent, represented by an icon.
 * 
 * *TODO: Add a boolean property to show/hide the person code*
 * @property {boolean}			showCode				-	Show the person code
 * @property {object}			agent					-	Agent object from Redux. Now
 * 	required for all usages of StatusIndicator
 */
class StatusIndicator extends React.Component {

	render() {
		const theme = this.props.theme;
		let status;
		let state;
		let online;
		let personCode;
		let currentTask;
		let onCall;

		// NOTE: When multiple tasks per agent become a thing (call + chat + email) then using currentTask
		//       to indicate "wrap up" time will break. Be sure to fix that when it becomes relevant

		// Make sure that we have the full agent info
		const agent = this.props.agent;
		if (!agent) {
			// eslint-disable-next-line no-console
			console.error("StatusIndicator component now requires full agent object.");
		}

		if (agent) {
			state = agent.state;
			online = agent.online && agent.phoneConnected && agent.phoneAudioConnected;
			personCode = agent.personCode;
			currentTask = agent.currentWorkTasks && agent.currentWorkTasks.length > 0 ? agent.currentWorkTasks[0] : null;
		}

		if (currentTask && !currentTask.lastPhoneCallTimeEnd) {
			onCall = true;
		}

		if (state === undefined || !online) {
			state = 2;
		}

		if (this.props.fixedWidth) {
			personCode = personCode.padStart(4, '\xa0');
		}

		let style = "fas";
		let color = theme.colors.positive;
		let opacity = 1;
		const icon = onCall ? "phone" : currentTask ? "flag-checkered" : "circle";
		switch (state) {
			case 0:
				status = "Available";
				break;
			case 1:
				status = "Standby";
				color = theme.colors.warning;
				break;
			case 2:
				style = icon === "circle" ? "far" : style;
				status = "Away";
				color = theme.colors.text;
				opacity = 0.5;
				break;
		}

		return <StatusContainer className={this.props.className} color={color}
			showCode={this.props.showCode} status={status}  
			opacity={opacity}>
			<IconContainer status={status} showCode={this.props.showCode}
				color={color} opacity={opacity}>
				<FontAwesomeIcon fixedWidth icon={[style, icon]} />
			</IconContainer>
			{this.props.showCode && <span>{personCode}</span>}
		</StatusContainer>;
	}

}

export default withTheme(StatusIndicator);
