import React from 'react';
import styled, { css } from 'styled-components';
import RoundButton from '../core/RoundButton/RoundButton';
import PhoneNumber from '../core/PhoneNumber/PhoneNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Transfers';

const StepHeading = styled.h3`

	font-size: 1.3em;
	margin-bottom: ${props => props.theme.margin.half};

`;

const TransferIcon = styled(FontAwesomeIcon)`

	color: ${props => props.theme.colors.positive};
	margin-right: ${props => props.theme.margin.half};
`;

class TransferSteps extends React.Component {

	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	async handleClose(evt) {
		if (this.props.transferRejected) {
			this.props.resetTransfer(false);
		}

		this.props.onClose(evt);
	}

	async handleCancel(evt) {
		this.props.resetTransfer(false);
		this.props.setTransferring(false);
		this.props.onCancel(this.props.details);
	}

	render() {
		return <React.Fragment>
			{
				(!this.props.transferRejected && this.props.transferring) &&
				<StepHeading><TransferIcon icon="exchange-alt" />Connected to <PhoneNumber>{this.props.details && this.props.details.toDescription}</PhoneNumber></StepHeading> 
			}

			{
				(this.props.transferRejected && !this.props.transferring) && 
				<StepHeading><TransferIcon icon="exclamation" />Transfer Rejected by <PhoneNumber>{this.props.details && this.props.details.toDescription}</PhoneNumber></StepHeading>
			}

			<RoundButton icon={["far", "times"]} primary onClick={this.handleClose}>Close Window</RoundButton>
			<RoundButton icon="phone-slash" disabled={!this.props.transferring} onClick={this.handleCancel}>Cancel Transfer</RoundButton>
		</React.Fragment>;
	}
}

export default connect(state => {
	return {
		...state.transfers
	};
}
	, dispatch => bindActionCreators(Object.assign({}, actionCreators), dispatch)
)(TransferSteps);