import React from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';
import {css, ThemeProvider, withTheme} from "styled-components";
import {LowerLayer} from './core/Themes.js';
import PhoneControl from './PhoneControl';
import OnlineIndicator from './OnlineIndicator';
import AgentStatus from './AgentStatus';
import CallAcceptMode from './CallAcceptMode';
import TabController from './home/TabController';
import TabContainer from './home/TabContainer';
import DialogDisplay from './DialogDisplay';
import VoicemailNotification from './VoicemailNotification'
const Grid = styled.div`
	height: 100vh;
	width: 100vw;
	margin: 0;	
	display: grid;
	grid-template-rows: auto auto 1fr auto;
	grid-template-columns: auto;
	grid-row-gap: 0;
	grid-template-areas: 
		"tabs"
		"buttons"
		"content"
		"control";
`;

const TabRow = styled.div`
	${props => props.theme && css`		
		background: ${props => props.theme.colors.background};
		border-bottom: 2px solid ${props => props.theme.colors.border};
		color: ${props => props.theme.colors.text};
	`}
	grid-area: tabs;
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr auto auto auto;
	grid-template-areas: 
		"spacer indicator callaccept status"
		"tab-set tab-set tab-set tab-set";
	align-items: center;
`;

const IndicatorWrapper = styled(OnlineIndicator)`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: indicator;
			padding: ${theme.padding.full};
		
		`;
	}}	
`;

const StatusWrapper = styled(AgentStatus)`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: status;
			padding: ${theme.padding.full};
		
		`;
	}}	
`;

const CallAcceptModeWrapper = styled(CallAcceptMode)`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: callaccept;
			padding: ${theme.padding.full};
		
		`;
	}}	
`;

const ContentRow = styled.div`
	${props => props.theme && css`		
		background: ${props => props.theme.colors.background};
		color: ${props => props.theme.colors.text};
	`}
	grid-area: content;
	overflow-y:auto;
`;

const ControlRow = styled.div`
	grid-area: control;
`;

/**
 * Encore Hub root component
 */
class Layout extends React.Component {

	componentDidMount() {
		if (window.bloodhound && window.bloodhound.clearTasks) {
			console.log('Invoking clearTasks back to bloodhound');
			window.bloodhound.clearTasks();
		}
		if (window.bloodhound && window.bloodhound.clearSessions) {
			console.log('Invoking clearSessions back to bloodhound');
			window.bloodhound.clearSessions();
		}
	}

	render() {
		return <Grid>
			<ThemeProvider theme={LowerLayer}>
				<TabRow>
					<TabController />
					<VoicemailNotification />
					<IndicatorWrapper />
					<CallAcceptModeWrapper/>
					<StatusWrapper/>
				</TabRow>
			</ThemeProvider>
			<ContentRow>			
				<TabContainer />
			</ContentRow>
			<ControlRow>
				<PhoneControl />
			</ControlRow>
			<DialogDisplay />
		</Grid>;
	}
}

export default connect()(withTheme(Layout));