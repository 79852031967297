import React from 'react';
import ListComplianceRules from "../list/ListComplianceRules";
import { Typography, Box, Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";

class ComplianceHome extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			rules: [],
			groups: [],
			rulesLoaded: false
		}

		this.handleTestRuleClick = this.handleTestRuleClick.bind(this);
		this.handleCreateNewRuleClick = this.handleCreateNewRuleClick.bind(this);
		this.handleRulesLoaded = this.handleRulesLoaded.bind(this);
	}

	handleTestRuleClick() {
		this.props.history.push({
			pathname: '/test',
			rules: this.state.rules
		});
	}

	handleRulesLoaded(rules, groups) {
		this.setState({
			rules: rules,
			groups: groups,
			rulesLoaded: true
		})
	}

	handleCreateNewRuleClick() {
		this.props.history.push({
			pathname: '/edit',
			groups: this.state.groups
		});
	}

	render() {
		return <Box pt={1}>
			<Box mb={1} display="flex">
				<Box flexGrow={1}>
					<Typography variant="h1">Compliance Rule Builder</Typography>
				</Box>
				<Box mr={1}><Button variant="contained" color="primary" onClick={this.handleTestRuleClick} disabled={!this.state.rulesLoaded}>Test</Button></Box>
				<Box><Button variant="contained" color="primary" onClick={this.handleCreateNewRuleClick} disabled={!this.state.rulesLoaded}>Create a New Rule</Button></Box>
			</Box>
			<ListComplianceRules onRulesLoaded={this.handleRulesLoaded} />
		</Box >;
	}
}

export default withRouter(ComplianceHome);