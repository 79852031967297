import React from 'react';
import styled, { css } from "styled-components";
import RoundButton from '../core/RoundButton/RoundButton';

const CloseButton = styled(RoundButton)`

	${props => {
		const theme = props.theme;
		return css`
		
			font-size: .8em;
			padding: ${theme.padding.full};
			padding-left: ${theme.padding.full};
			padding-right: 0;
			margin-left: ${theme.padding.full};
			display: inline-flex;
			align-items: center;
			background: none;
			color: ${theme.baseColors.background.mix(theme.baseColors.text, 0.5).hex()};
			box-shadow: inset 0 0 0 2px ${theme.colors.border};
		
		`;
	}}	

`;

const TabListItem = styled.li`
	${props => {
		const theme = props.theme;
		var bg = theme.tab.background,
			bottomBorder = theme.colors.border,
			marginBottom = "0px";
		if (props.selected) {
			bg = theme.tab.selected.background;
			bottomBorder = theme.tab.selected.background;
			marginBottom = "-2px";
		}
		return css`

			background: ${bg};

			padding: ${theme.padding.full} ${theme.margin.half};
			white-space: nowrap;
			text-overflow: ellipsis; 
			overflow: hidden; 

			display: block;
			font-family: ${theme.fonts.heading};
			font-weight: 300;
			font-size: .9em;
			border-right: 2px solid ${theme.colors.border};
			border-top: 2px solid ${theme.colors.border};
			border-bottom: 2px solid ${bottomBorder};
			margin-bottom: ${marginBottom};
	
			transition: background-color ease-in-out .1s;

			&:last-of-type {
				border-top-right-radius: ${theme.padding.full};
			}

			&:hover {
				background: ${theme.tab.hover.background};
			}

			strong {
				font-weight: 500;
			}
		
		`;
	}}	
`;

export default class TabHeader extends React.Component {

	render() {
		var closeButton = <CloseButton
			disabled={this.props.disableClose}
			onClick={e => this.props.onClose(e)}
			icon={["far", "times"]} />;

		return <TabListItem {...this.props}>
			{this.props.children}
			{closeButton}
		</TabListItem>;
	}
}

