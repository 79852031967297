import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Agent';

class CallAcceptMode extends React.Component {
	constructor(props) {
		super(props);

		this.agentCallAcceptStateChanged = this.agentCallAcceptStateChanged.bind(this);
	}

	async componentWillUnmount() {
	}

	async agentCallAcceptStateChanged(e) {
		//do not use target value directly past await.  causes issues with synthetic eventing.
		var newValue = e.target.value;
		this.props.updateMyCallAcceptState(newValue);
	}

	render() {
		var loggedInToCampaign = (this.props.currentCampaigns && this.props.currentCampaigns.length > 0);
		var loggedInToOutBound = (loggedInToCampaign ? this.props.currentCampaigns[0].outbound : false);
		var AutoAcceptText = "Auto Accept Inbound" + (loggedInToOutBound ? "/Outbound" : "");

		const renderSection = <select value={this.props.callAcceptState} onChange={this.agentCallAcceptStateChanged} disabled={this.props.callAcceptMode !== 2 || loggedInToOutBound }>
			<option value="0">{AutoAcceptText}</option>
			<option value="1">Manual Accept Inbound</option>
		</select>;

		return <div className={this.props.className}>
			{renderSection}
		</div>;
	}
}

export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(CallAcceptMode);
