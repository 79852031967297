import React from 'react';
import styled, { css } from 'styled-components';

const PrimaryText = styled.span``;

const SecondaryText = styled.span`

	opacity: 0.5;

	${props => {
		if (props.rightMargin) {
			return css`margin-right: ${props.theme.margin.quarter};`;
		}
	}}

`;

const ExtensionText = styled.span`

	font-size: 0.7em;
	text-transform: uppercase;
	margin: 0 ${props => props.theme.margin.quarter} 0 ${props => props.theme.margin.half};

`;

// Regex from https://stackoverflow.com/a/16702965
const NorthAmericanPhoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

/**
 * Nicely formats a given phone number. If it does not match the NA phone format,
 * should just print the given string.
 */
export default class PhoneNumber extends React.Component {

	render() {
		if (!this.props.children || !this.props.children.match) {
			return <span className={this.props.className}>
			</span>;
		}
		var matches = this.props.children.match(NorthAmericanPhoneNumberRegex);
		if (matches == null) {
			return <span className={this.props.className}>
				{this.props.children}
			</span>;
		}
		
		return <span className={this.props.className}>
			<SecondaryText>(</SecondaryText>
			<PrimaryText>{matches[2]}</PrimaryText>
			<SecondaryText rightMargin>)</SecondaryText>
			<PrimaryText>{matches[3]}</PrimaryText>
			<SecondaryText>-</SecondaryText>
			<PrimaryText>{matches[4]}</PrimaryText>
			{
				matches[5] != null && 
				<span>
					<ExtensionText>Ext</ExtensionText>
					<PrimaryText>{matches[5]}</PrimaryText>
				</span>
			}
		</span>;
	}
	
}