import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Phone';
import RoundButton from '../core/RoundButton/RoundButton';
import connection from '../../signalragent.js';
import styled from "styled-components";

const Container = styled.div`

	display: inline-block;

`;

const SelectorContainer = styled.div`

	select {
		border: 2px solid ${props => props.theme.colors.border};
	}

`;

const TestButton = styled(RoundButton)`

	margin-top: ${props => props.theme.margin.half};

`;

const LevelContainer = styled.div`

	width: calc(100% - 4px);
	height: 8px;
	padding: 2px;
	border-radius: 6px;
	background: ${props => props.theme.colors.secondary};
	margin-bottom: ${props => props.theme.margin.half};

`;

const LevelIndicator = styled.div`

	background: ${props => props.theme.colors.positive};
	width: calc(100% * ${props => props.level});
	height: 8px;
	border-radius: 4px;

`;

const NLevelIndicator = styled.div.attrs(props => ({
	level: props.level,
}))`
	background: ${props => props.theme.colors.positive};
	height: 8px;
	border-radius: 4px;
	width: calc(100% * ${props => props.level});
`;

// const Component = styled.div.attrs({
// 	style: ({ background }) => ({
// 	  background,
// 	}),
//  })`width: 100%;`

class DeviceSelector extends React.Component {
	constructor(props) {
		super(props);

		var selected;
		if (this.props.input) {
			selected = this.props.device.audio.inputDevice;
		}
		else {
			var speakerDevices = this.props.device.audio.speakerDevices.get();
			speakerDevices.forEach((spkDev) => { selected = spkDev; });
		}

		this.state = {
			selectedDevice: selected ? selected.deviceId : '',
			inputVolume: 0
		};
		if (this.props.input) {
			this.deviceInputVolumeChanged = this.deviceInputVolumeChanged.bind(this);
		}
		this.deviceChanged = this.deviceChanged.bind(this);
		this.outputDeviceTestClicked = this.outputDeviceTestClicked.bind(this);
		this.deviceAudioChange = this.deviceAudioChange.bind(this);
	}

	async componentDidMount() {
		this.props.device.audio.on('deviceChange', this.deviceAudioChange)
		if (this.props.input) {
			this.props.device.audio.on('inputVolume', this.deviceInputVolumeChanged);
		}
	}

	deviceAudioChange() {
		this.forceUpdate();
	}

	async componentWillUnmount() {		
		this.props.device.audio.removeListener('deviceChange', this.deviceAudioChange);
		if (this.props.input) {
			this.props.device.audio.removeListener('inputVolume', this.deviceInputVolumeChanged);
		}
	}

	deviceInputVolumeChanged(volume) {
		this.setState({
			inputVolume: volume
		});
	}

	async deviceChanged(event) {
		const target = event.target.value;
		try {
			if (this.props.input && target) {
				this.props.device.audio.setInputDevice(target);
				var selectedInputDevice = this.props.device.audio.availableInputDevices.get(target);
				localStorage.setItem('audioDeviceInput', event.target.value);
				localStorage.setItem('audioDeviceInputLabel', selectedInputDevice.label);
			}
			if (this.props.output && target) {
				this.props.device.audio.speakerDevices.set(target);
				var selectedOutputDevice = this.props.device.audio.availableOutputDevices.get(target);
				localStorage.setItem('audioDeviceOutput', target);
				localStorage.setItem('audioDeviceOutputLabel', selectedOutputDevice.label);
			}

			await connection.invoke('phoneAudioConnected');
			this.setState({
				selectedDevice: target
			});
		}
		catch (ex) {
			console.error(ex);
		}
	}

	outputDeviceTestClicked(event) {
		this.props.device.audio.speakerDevices.test();
	}



	render() {
		const availableDevices = this.props.input
			? this.props.device.audio.availableInputDevices
			: this.props.device.audio.availableOutputDevices;
		var devices;
		if (this.props.ready) {
			const spread = [...availableDevices];
			devices = spread.map(val => {
				var prefix = val[0] === 'communications' || val[0] === 'default' ? val[0] : '';
				return <option key={val[0]} value={val[0]}> {`${prefix} ${val[1].label}`} </option>;
			});
		}
		return <Container className={this.props.className}>
			{this.props.input && 
				<div>
					<LevelContainer>
						<NLevelIndicator level={this.state.inputVolume} />
					</LevelContainer>
				</div>
			}
			{/* <div>{this.props.input && this.state.inputVolume}</div> */}
			<SelectorContainer>
				<select value={this.state.selectedDevice} onChange={this.deviceChanged}>
					<option key='none' value=''></option>
					{devices}
				</select>
			</SelectorContainer>
			{this.props.output &&
				<div>
					<TestButton icon="volume-up" onClick={e => this.outputDeviceTestClicked(e)} >Test Audio</TestButton>
				</div>
			}
		</Container>;
	}
}


export default connect(state => state.phone,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DeviceSelector);
