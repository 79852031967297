import React from 'react';
import HttpAgent from '../../../httpagent.js';
import { Typography, Box, Button, Grid, TextField, Snackbar, Switch } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import AutoCompleteTextField from '../../_shared-components/core/AutoCompleteTextField';
import ValidationService from '../../_utilities/ValidationService';
import { Options as SelectOptions } from '../_utilities/SelectOptionHelpers';
import LoadingDataSpinner from '../../_shared-components/core/LoadingDataSpinner';
import TestRuleList from "./TestRuleList";
import Stepper from '../../_shared-components/core/FixedBottomStepper';
import SelectField from '../../_shared-components/core/SelectField';
import moment from 'moment';

class TestComplianceRule extends React.Component {
	constructor(props) {
		super(props);

		this.validationService = new ValidationService({
			ruleConsumerNumber: [
				{ label: "Rule consumer number is required", isInvalid: (value) => { return !value || value.trim().length === 0 } },
			],
			ruleOperations: [
				{ label: "Rule operation is required.", isInvalid: (value) => { return !value || value.length === 0 } }
			],
			ruleContactPoint: [
				{ label: "Rule contact point is required", isInvalid: (value) => { return !value || value.trim().length === 0 } },
			],
			ruleEntity: [
				{ label: "Rule entity is required", isInvalid: (value) => { return !value || value.trim().length === 0 } },
			],
			ruleDateTime: [
				{ label: "Rule date and time are required", isInvalid: (value) => { return !value || value.trim().length === 0 } },
			],
		});

		this.state = {
			activeStep: 1,
			currentRuleList: [],
			consumerId: 0,
			contactPoints: [],
			ruleTestResults: undefined,
			ruleConsumerNumber: '',
			ruleContactPoint: '',
			ruleOperations: '',
			ruleEntity: '',
			ruleDateTime: this.getCurrentGMTDateTime(),
			showSnackbar: false,
			snackbarSeverity: 'error',
			snackbarMessage: '',
			clientErrors: [],
			serverErrors: undefined,
			isLoaded: true,
			isRunningTest: false,
			isDirty: false,
			showDisabledRules: false,
		};

		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleNextClick = this.handleNextClick.bind(this);
		this.handleConfirmClick = this.handleConfirmClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleTableRowClick = this.handleTableRowClick.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleStartTest = this.handleStartTest.bind(this);
		this.handleRuleFieldChange = this.handleRuleFieldChange.bind(this);
		this.handleRuleFieldOnBlur = this.handleRuleFieldOnBlur.bind(this);
		this.handleRuleAutoCompleteChange = this.handleRuleAutoCompleteChange.bind(this);
		this.handleRuleAutoCompleteInputChange = this.handleRuleAutoCompleteInputChange.bind(this);
		this.handleRuleMultiSelectOnBlur = this.handleRuleMultiSelectOnBlur.bind(this);
		this.handleRuleAutoCompleteChange = this.handleRuleAutoCompleteChange.bind(this);
		this.handleRuleAutoCompleteInputChange = this.handleRuleAutoCompleteInputChange.bind(this);
		this.resetTestFields = this.resetTestFields.bind(this);
		this.closeSnackbar = this.closeSnackbar.bind(this);
		this.toggleVisibleResults = this.toggleVisibleResults.bind(this);
	}

	async componentDidMount() {
		if (this.props.match.params.consumerNumber) {
			this.setState({
				consumerNumber: this.props.match.params.consumerNumber,
				activeStep: 2
			});
		}

		if (this.state.currentRuleList.length === 0 && this.props.location.rules) {
			this.setState({
				currentRuleList: this.props.location.rules
			});
		} else {
			await this.getCurrentRules();
		}
	}

	getCurrentGMTDateTime() {
		var dt = new Date();
		return moment(dt).format().slice(0, 16);
	}

	resetTestFields() {
		this.setState({
			consumerId: 0,
			contactPoints: [],
			ruleTestResults: undefined,
			ruleConsumerNumber: '',
			ruleContactPoint: '',
			ruleOperations: '',
			ruleEntity: '',
			ruleDateTime: this.getCurrentGMTDateTime(),
			clientErrors: [],
			serverErrors: undefined,
			ruleConsumerNumberValidation: undefined,
			ruleContactPointValidation: undefined,
			ruleOperationsValidation: undefined,
			ruleEntityValidation: undefined,
			ruleDateTimeValidation: undefined,
			showDisabledRules: false,
		});
	}

	closeSnackbar() {
		this.setState({
			showSnackbar: false
		});
	}

	handleCancelClick() {
		this.props.history.push('/');
	}

	handleBackClick() {
		if (this.state.activeStep !== 1) {
			this.setState({
				activeStep: this.state.activeStep - 1,
			});
		} else {
			this.props.history.push('/');
		}
	}

	handleNextClick() {
		if (this.state.activeStep === 1) {
			this.handleStartTest();
		}
	}

	handleConfirmClick() {
		this.props.history.push('/');
	}

	handleTableRowClick(consumerNumber) {
		this.setState({
			ruleConsumerNumber: consumerNumber,
			activeStep: 2
		});
	}

	async handleStartTest() {
		if (this.validateRuleConfiguration()) {
			var rule = this.setTestRuleObject();
			var testOk = await this.testRule(rule);

			if (testOk) {
				this.setState({
					activeStep: 2
				});
			}
		} else {
			this.setState({
				showSnackbar: true,
				snackbarSeverity: 'error',
				snackbarMessage:
					`There are outstanding validation issues that must be resolved before the rule can be tested.`,
			});
		}
	}

	handleSearchChange(e) {
		this.setState({ searchText: e.target.value });
		this.getConsumerInformation(e.target.value);
	}

	handleRuleFieldChange(e) {
		const { name, value, type, checked } = e.target;
		const combinedValue = type === "checkbox" ? checked : value;
		const validation = this.validationService.validate(name, combinedValue);
		this.setState({ [name]: combinedValue, [`${name}Validation`]: validation, isDirty: true });
	}

	handleRuleFieldOnBlur(e) {
		const { name, value, type, checked } = e.target;
		const combinedValue = type === "checkbox" ? checked : value;
		const validation = this.validationService.validate(name, combinedValue);
		this.setState({ [`${name}Validation`]: validation });
		this.handleLookupFields(name);
	}

	handleRuleAutoCompleteChange(e, value, fieldName) {
		const validation = this.validationService.validate(fieldName, value);
		var rawValues = Array.isArray(value) ? value.map(x => x.value) : value;
		this.setState({ [fieldName]: rawValues, [`${fieldName}Validation`]: validation, isDirty: true });
	}

	handleRuleAutoCompleteInputChange(e, values, reason, fieldName) {
		const validation = this.validationService.validate(fieldName, values);
		var rawValues = Array.isArray(values) ? values.map(x => x.value) : values;
		this.setState({ [fieldName]: rawValues, [`${fieldName}Validation`]: validation, isDirty: (reason !== 'reset') });
	}

	handleRuleMultiSelectOnBlur(e) {
		var name = e.target.name;
		const validation = this.validationService.validate(name, this.state[name]);
		this.setState({ [`${name}Validation`]: validation });
	}

	async getCurrentRules() {
		try {
			this.setState({ isLoaded: false });
			var response = await HttpAgent.getCurrentComplianceRules();
			var ruleList = response.data;

			this.setState({
				currentRuleList: ruleList,
				isLoaded: true
			});
		}
		catch (ex) {
			this.setState({
				showSnackbar: true,
				snackbarMessage: "There was an error trying to retrieve the compliance rules from the system.",
				isLoaded: true
			});
		}
	}

	setTestRuleObject() {
		var testRule = {
			consumerId: this.state.consumerId,
			contactPoint: this.state.ruleContactPoint,
			operation: this.state.ruleOperations,
			entity: this.state.ruleEntity.toLocaleUpperCase(),
			timestamp: this.state.ruleDateTime
		}

		return testRule;
	}

	async testRule(testRuleModel) {
		try {
			this.setState({ isRunningTest: true });
			testRuleModel.includeDisabledRules = this.state.showDisabledRules;
			var response = await HttpAgent.testComplianceRules(testRuleModel);

			this.setState({
				isRunningTest: false,
				ruleTestResults: response.data
			});
			return true;
		}
		catch (error) {
			if (error.response.status === 400) {
				this.setState({
					serverErrors: error.response.data,
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage: `There are outstanding validation issues that must be resolved before the rule(s) can be tested.`,
					isRunningTest: false,
					ruleTestResults: undefined
				});

				this.handleServerErrors(error.response.data);
			} else {
				this.setState({
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage: `There was an unexpected error that occurred while trying to test the rule(s).`,
					isRunningTest: false,
					ruleTestResults: undefined
				});
			}
		}

		return false;
	}

	handleServerErrors(errors) {
		var mappedFields = this.buildFieldMapping();

		if (errors && errors.length) {
			Object.entries(mappedFields).forEach(([key, value]) => {
				var matchingItem = errors.filter(x => x.MemberNames.some(z => z === mappedFields[key].postFieldName));

				if (matchingItem.length > 0) {
					this.setState({
						[`${mappedFields[key].testFieldName}Validation`]: { isInvalid: true, messages: [matchingItem[0].ErrorMessage] }
					});
				}
			});
		}
	}

	buildFieldMapping() {
		return [
			{ testFieldName: "ruleEntity", postFieldName: 'EntityCode' },
			{ testFieldName: "ruleConsumerNumber", postFieldName: 'ConsumerNumber' },
		];
	}

	async handleLookupFields(name) {
		switch (name.toLocaleLowerCase()) {
			case "ruleconsumernumber":
			case "ruleentity":
				if (this.state.ruleConsumerNumber !== '' && this.state.ruleEntity !== '') {
					try {
						var response1 = await HttpAgent.getConsumerIdByNumber(this.state.ruleEntity, this.state.ruleConsumerNumber);

						this.setState({
							consumerId: response1.data,
						});

						try {
							var response2 = await HttpAgent.getConsumerPhoneNumbers(this.state.ruleEntity, response1.data);

							this.setState({
								contactPoints: response2.data.map(p => p.digits)
							});
						}
						catch (ex) {
							this.setState({
								contactPoints: [],
								ruleContactPointValidation: { isInvalid: true, messages: ["Unable to retrieve consumer contact points."] }
							});
						}
					}
					catch (ex) {
						this.setState({
							consumerId: 0,
							ruleConsumerNumberValidation: { isInvalid: true, messages: ["Unable to verify consumer number."] },
							ruleContactPointValidation: { isInvalid: true, messages: ["Unable to retrieve consumer contact points."] }
						});
					}
				} else {
					this.setState({
						consumerId: 0,
					});
				}
				break;
			default:
				break;
		}
	}

	validateRuleConfiguration() {
		var errors = [];

		Object.entries(this.validationService.config).forEach(([key, value]) => {
			const validation = this.validationService.validate(key, this.state[key]);

			if (validation.isInvalid && validation.messages.length > 0) {
				for (var i = 0; i < validation.messages.length; i++) {
					errors.push(validation.messages[i]);
					this.setState({ [`${key}Validation`]: validation });
				}
			}
		});

		this.setState({ clientErrors: errors });
		return (errors.length === 0);
	}

	getPageHeader(activeStep) {
		switch (activeStep) {
			case 0:
				return 'Select a consumer to test';
			case 1:
				return 'Test Simulation';
			case 2:
				return 'Rule Evaluation Summary';
			case 3:
				return 'Rule Testing Details';
			default:
				return '';
		}
	}

	formatAsDisplayDateTime(dateTimeOffset, displayNowNever = false) {
		var dt = new Date(dateTimeOffset);

		if (displayNowNever) {
			var dtNow = new Date();

			if (dt <= dtNow) {
				return 'Now';
			}

			if (dt.getFullYear() === 9999) {
				return 'Never';
			}
		}

		return moment(dt).format('MM/DD/YYYY h:mm:ss A');
	}

	toggleVisibleResults(e) {
		this.setState({
			showDisabledRules: e.target.checked
		});
	}

	render() {
		const totalSteps = 2;
		let pageHeader = this.getPageHeader(this.state.activeStep);

		return <Box pt={1}>
			<Box mb={2} display="flex">
				<Box mr={1}><Button variant="contained" color="primary" onClick={this.handleCancelClick}>Cancel</Button></Box>
				<Box flexGrow={1}>
					<Typography variant="h1">{pageHeader}</Typography>
				</Box>
			</Box>

			{(this.state.activeStep === 2) &&
				<Box mb={1} display="flex" flexDirection="column" alignContent="center" justifyContent="center">
					{(this.state.ruleConsumerNumber && this.state.ruleConsumerNumber !== null) &&
						<Typography variant="h3">Consumer Number: {this.state.ruleConsumerNumber.toLocaleUpperCase()}</Typography>
					}
					{this.state.ruleTestResults && this.state.ruleTestResults.estimatedNextContactTime &&
						<Typography variant="h3">Estimated Next Contact Time: {this.formatAsDisplayDateTime(this.state.ruleTestResults.estimatedNextContactTime, true)}</Typography>
					}
					{this.state.ruleTestResults &&
						<Typography variant="h3">Would The Operation be Allowed: <span style={{ color: this.state.ruleTestResults.restricted ? 'red' : 'green' }}>{this.state.ruleTestResults.restricted ? "No" : "Yes"}</span></Typography>
					}
					{this.state.ruleTestResults && this.state.ruleTestResults.restrictedActionCode &&
						<Typography variant="h3">Run Action On Prevent: {this.state.ruleTestResults.restrictedActionCode}</Typography>
					}
					<Typography variant="h3">Showing: {this.state.showDisabledRules ? 'All' : 'Enabled Only'}</Typography>
				</Box>
			}

			{this.state.isRunningTest && <LoadingDataSpinner message="Testing rule..." gridProps={{ style: { marginTop: '20%', fontStyle: 'italic' } }} />}
			{!this.state.isLoaded && <LoadingDataSpinner message="Loading Data..." gridProps={{ style: { marginTop: '20%', fontStyle: 'italic' } }} />}
			{(this.state.isLoaded && !this.state.isRunningTest) && <Box>

				{(this.state.activeStep === 1) && <Box>
					<Box display="flex" flexDirection="vertical" justifyContent="center" mb={2}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<SelectField
									id="ruleOperations"
									name="ruleOperations"
									label="Operations"
									formProps={{
										fullWidth: true,
										variant: "outlined",
										error: this.state.ruleOperationsValidation ? this.state.ruleOperationsValidation.isInvalid : false,
										helperText: this.state.ruleOperationsValidation ? this.state.ruleOperationsValidation.messages[0] : '',
									}}
									value={this.state.ruleOperations}
									options={SelectOptions.operations}
									onBlur={this.handleRuleFieldOnBlur}
									onChange={this.handleRuleFieldChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									type="datetime-local"
									InputLabelProps={{ shrink: true }}
									fullWidth
									name="ruleDateTime"
									label="Date/Time"
									error={this.state.ruleDateTimeValidation ? this.state.ruleDateTimeValidation.isInvalid : false}
									helperText={this.state.ruleDateTimeValidation ? this.state.ruleDateTimeValidation.messages[0] : ''}
									value={this.state.ruleDateTime}
									onBlur={this.handleRuleFieldOnBlur}
									onChange={this.handleRuleFieldChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									error={this.state.ruleConsumerNumberValidation ? this.state.ruleConsumerNumberValidation.isInvalid : false}
									helperText={this.state.ruleConsumerNumberValidation ? this.state.ruleConsumerNumberValidation.messages[0] : ''}
									name="ruleConsumerNumber"
									label="Consumer Number"
									value={this.state.ruleConsumerNumber}
									onBlur={this.handleRuleFieldOnBlur}
									onChange={this.handleRuleFieldChange}
									inputProps={{ style: { textTransform: "uppercase" } }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label="Entity"
									name="ruleEntity"
									error={this.state.ruleEntityValidation ? this.state.ruleEntityValidation.isInvalid : false}
									helperText={this.state.ruleEntityValidation ? this.state.ruleEntityValidation.messages[0] : ''}
									value={this.state.ruleEntity}
									onChange={this.handleRuleFieldChange}
									onBlur={this.handleRuleFieldOnBlur}
									inputProps={{ style: { textTransform: "uppercase" } }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<AutoCompleteTextField
									options={this.state.contactPoints.length > 0 ? this.state.contactPoints : []}
									fullWidth
									name="ruleContactPoint"
									label="Contact Point"
									textFieldProps={{
										variant: "outlined",
										onBlur: this.handleRuleFieldOnBlur,
										error: this.state.ruleContactPointValidation ? this.state.ruleContactPointValidation.isInvalid : false,
										helperText: this.state.ruleContactPointValidation ? this.state.ruleContactPointValidation.messages[0] : ''
									}}
									value={this.state.ruleContactPoint}
									onInputChange={this.handleRuleAutoCompleteInputChange}
									onChange={this.handleRuleAutoCompleteChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Switch
									checked={this.state.showDisabledRules}
									onClick={this.toggleVisibleResults}
								/> {this.state.showDisabledRules ? 'Include' : 'Exclude'} Disabled Rules In Test
							</Grid>
						</Grid>
					</Box>
					<Box display="flex" flexDirection="horizontal" justifyContent="center" mb={3}><Button variant="contained" color="primary" onClick={this.resetTestFields}>Reset Fields</Button></Box>
				</Box>}

				{(this.state.activeStep === 2) && <Box style={{ margin: '0', borderTop: '1px solid #fff' }}>
					<TestRuleList results={this.state.ruleTestResults} style={{ marginBottom: "50px" }} showDisabledRules={ this.state.showDisabledRules } />
				</Box>}

				<Box>
					<Stepper
						steps={totalSteps}
						onBackClick={this.handleBackClick}
						onNextClick={this.handleNextClick}
						onConfirmClick={this.handleConfirmClick}
						activeStep={this.state.activeStep}
						nextButtonText="Start Test"
						nextButtonDisabled={this.state.activeStep === totalSteps}
						backButtonText="Back"
						showCustomBackButtonTextOnLastStepOnly={true}
						confirmButtonText="Done"
						nextButtonDisabled={this.state.consumerId === 0}
					/>
				</Box>

				<Snackbar open={this.state.showSnackbar} autoHideDuration={6000} onClose={this.closeSnackbar}>
					<MuiAlert elevation={6} variant="filled" onClose={this.closeSnackbar} severity={this.state.snackbarSeverity}>
						{this.state.snackbarMessage}
					</MuiAlert>
				</Snackbar>
			</Box>}
		</Box>;
	}
}

export default withRouter(TestComplianceRule);