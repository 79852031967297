import React from 'react';
import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`
`;

const Container = styled.div`	
	${props => {
		const theme = props.theme;
		return css`
			font-size: 5em;
			color: ${theme.colors.brandHighlight};
			display: flex;
			justify-content: center;
			align-items: center;
		`;
	}} 
`;

/**
 * A giant icon for indicating the state of a dialer.
 */
export default class ConnectionIndicator extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			frame: "wifi"
		};
		this.nextFrame = this.nextFrame.bind(this);
	}

	async componentDidMount() {
		const intId = window.setInterval(this.nextFrame, 300);
		this.setState({
			intId: intId
		});
	}

	async componentWillUnmount() {
		window.clearInterval(this.state.intId);
	}

	async nextFrame() {
		this.setState(prev => {
			const newFrame = prev.frame == "wifi" ? "wifi-1"
				: prev.frame == "wifi-1" ? "wifi-2"
					: "wifi";
			return {
				frame: newFrame
			};
		});
	}

	render() {

		return <Container className={this.props.className}>
			<Icon icon={this.state.frame} fixedWidth />
		</Container>;
	}

}