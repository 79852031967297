import React from 'react';
import { MemoryRouter, Route, Switch, withRouter } from "react-router-dom";

import EditComplianceRule from "./edit/EditComplianceRule";
import Home from "./home/ComplianceHome";
import TestComplianceRule from "./test/TestComplianceRule";

class ComplianceRouter extends React.Component {
	render() {
		return <MemoryRouter>
			<Switch>
				{/* Add Rules Screen. */}
				<Route path="/edit/:id" component={EditComplianceRule} />
				<Route path="/edit" component={EditComplianceRule} />

				{/* Test Rules Screen. */}
				<Route path="/test/:consumerNumber" component={TestComplianceRule} />
				<Route path="/test" component={TestComplianceRule} />

				{/* The Compliance rules manager. */}
				<Route path="/" component={Home} />
			</Switch>
		</MemoryRouter>;
	}
}

export default withRouter(ComplianceRouter);