import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Phone';
import styled, { css, ThemeProvider, withTheme } from 'styled-components';
import { LowerLayer, DarkTheme } from './core/Themes.js';
import ToggleLabel from './core/ToggleLabel/ToggleLabel';
import PhoneNumber from './core/PhoneNumber/PhoneNumber';
import RoundButton from './core/RoundButton/RoundButton';
import TypeIndicator from './core/TypeIndicator/TypeIndicator';
import PhoneTimer from './PhoneTimer';
import PhoneDialPad from './PhoneDialPad';
import PhoneTransfer from './PhoneTransfer';
import PermissionWrapper from './utils/PermissionWrapper';
import HttpAgent from '../httpagent.js';
import connection from '../signalragent.js';
import { withRouter } from 'react-router-dom';

import { actionCreators as transferActionCreators } from '../store/Transfers';

//#region Styles

const Grid = styled.div`
	${props => props.theme && css`		
		background: ${props => props.theme.colors.background};
		border-top: 2px solid ${props => props.theme.colors.border};
		color: ${props => props.theme.colors.text};
	`}	
	display: grid;
	height: 78px;
	grid-template-rows: 28px 40px;
	grid-row-gap: 10px;
	padding: 10px 10px 10px;
	grid-template-areas: 
		"top-row"
		"bottom-row"
	;
`;

const TopRow = styled.div`
	grid-area: top-row;
	display: grid;
	grid-column-gap: 10px;
	grid-template-areas: "contact actions";
	grid-template-columns: 1fr auto;
`;

const ContactLabel = styled.div`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: contact;
			font-size: 1.5em;
			color: ${theme.colors.brand};
			letter-spacing: -2px;
			font-family: ${theme.fonts.numeric};
			display: grid;
			grid-template-areas: "name phone-number";
			grid-template-columns: auto 1fr;

			h2 {
				grid-area: name;
				font-size: 1.15em;
				letter-spacing: 0;
				font-family: ${theme.fonts.heading};
				color: ${theme.colors.text};
				text-overflow: ellipsis; 
				overflow: hidden; 
				white-space: nowrap;
				margin-right: 10px;
			}
		`;
	}}
`;

const ContactPhoneNumber = styled(PhoneNumber)`
	display: block;
	grid-area: phone-number;
	line-height: 1.6em;
`;

const ModalActions = styled.div`
	grid-area: actions;
`;

const ModalActionButton = styled(RoundButton)`
	&:not(:last-child) {
		margin-right: 10px;
	}
`;

const BottomRow = styled.div`
	grid-area: bottom-row;
	display: grid;
	grid-column-gap: 10px;
	grid-template-areas: "indicators actions";
	grid-template-columns: 1fr auto;
`;

const Indicators = styled.div`
	${props => {
		const theme = props.theme;
		const backgroundColor = theme.baseColors.background.alpha(0.65).hsl().string();
		const shadowColor = theme.baseColors.background.alpha(0.2).hsl().string();
		const textShadowColor = theme.baseColors.positiveLight.alpha(0.4).hsl().string();
		return css`

			grid-area: indicators;
			background: ${backgroundColor};
			box-shadow: inset 0 1px 4px ${shadowColor};
			border-radius: 999px;
			color: ${theme.colors.positiveLight};
			text-shadow: 0 1px 6px ${textShadowColor};
			font-family: ${theme.fonts.heading};
			font-size: 0.835em;
			font-weight: 500;
			letter-spacing: 1px;
			text-transform: uppercase;
			padding: 0px ${theme.margin.full};
			
			align-items: center;
			display: grid;			

			grid-column-gap: 20px;
			grid-template-areas: "duration type states";
			grid-template-columns: auto 1fr auto;
		`;
	}}	
`;

const PhoneTypeIndicator = styled(TypeIndicator)`
	grid-area: type;
	display: flex;
	justify-content: center;
	text-align: center;
`;

const DurationIndicator = styled.div`

	${props => {
		const theme = props.theme;
		const textColor = theme.colors.text;
		const textShadowColor = theme.baseColors.text.alpha(0.25).hsl().string();
		return css`

			grid-area: duration;
			font-family: ${theme.fonts.numeric};
			font-size: 1.75em;
			margin-bottom: -.1em;

			color: ${textColor};
			text-shadow: 0 1px 8px ${textShadowColor};

		`;
	}}	
`;

const Actions = styled.div`

	${props => {

		const gridAreas = props.answer
			? css`grid-template-areas: "hang-up answer";`
			: css`grid-template-areas: "hang-up";`;

		return css`

			grid-area: actions;
			display: grid;
			${gridAreas}
			grid-auto-columns: 1fr;
			grid-column-gap: 10px;

		`;
	}}

`;

const ActionButton = styled(RoundButton)`

	grid-area: ${props => props.area};
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
	padding: 0 0.75em 0 1em;

	&:not(:last-child) {
		margin-right: 0;
	}

`;

const StateToggles = styled.div`
	grid-area: states;
	font-size: 1em;
	margin-right: -6px;

	a:not(:last-child) {		
		margin-right: ${props => props.theme.margin.quarter};
	}
`;
//#endregion

/**
 * Controls for all always-present call functionality for Encore
 */
class PhoneControl extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			recordingPaused: false,
			phoneMuted: false,
			phoneOnHold: false,
			transferTo: '',
			transferWindowOpen: false,
			keypadWindowOpen: false,
			ringing: false,
			inCall: false,
			monitoring: true,
			coaching: false,
			barging: false,
			endCallButtonEnabled: true,
			bargeButtonEnabled: true,
			coachButtonEnabled: true
		};
		this.setupDevice();
		this.registerDeviceEventHandlers();
		this.handleTransferDismissal = this.handleTransferDismissal.bind(this);
		this.handleKeypadDismissal = this.handleKeypadDismissal.bind(this);
		this.handleToggleHold = this.handleToggleHold.bind(this);
		this.handleToggleMute = this.handleToggleMute.bind(this);
		this.handleCoachClicked = this.handleCoachClicked.bind(this);
		this.handleBargeClicked = this.handleBargeClicked.bind(this);
		this.handleTogglePauseRecording = this.handleTogglePauseRecording.bind(this);
	}

	async componentDidMount() {
		connection.invoke('EncoreHubConnected');
		this.props.device.on('ready', device => this.deviceReady(device));
	}

	//#region Functions

	getActiveConnectionId() {
		if (this.props.device) {
			const activeConnection = this.props.device.activeConnection();
			if (activeConnection) {
				return activeConnection.parameters.CallSid;
			}
		}
		return null;
	}

	getActiveTask() {
		if (this.props.currentWorkTasks) {
			return this.props.currentWorkTasks.find(x => x.blocking && x.activePhoneCallId != null);
		}
		return null;
	}

	async setupDevice() {
		console.info('requesting twilio token and setting up phone device');
		const token = await HttpAgent.getTwilioToken();
		await this.props.device.setup(token.data);
		await connection.invoke('phoneConnected');
	}

	deviceRetry() {
		var self = this;
		setTimeout(function () {
			self.setupDevice();
		}, 1000);
	}

	registerDeviceEventHandlers() {
		this.props.device.on('offline', device => this.deviceOffline(device));
		this.props.device.on('connect', connection => this.deviceConnected(connection));
		this.props.device.on('disconnect', connection => this.deviceDisconnected(connection));
		this.props.device.on('incoming', connection => this.deviceIncoming(connection));
		this.props.device.on('error', error => this.deviceError(error));
		this.props.device.on('ringing', connection => this.deviceRinging(connection));

	}

	//During a restart the device ID's will change.  We will use the lables to re-find the devices and return the new device ids if neccessary.
	findOutputDevice(deviceId, label) {
		if (deviceId === 'default' || deviceId === 'communications') return deviceId;
		var selectedId = '';
		this.props.device.audio.availableOutputDevices.forEach(d => {
			if (label === d.label) {
				selectedId = d.deviceId;
			}
		});
		return selectedId;
	}

	//During a restart the device ID's will change.  We will use the lables to re-find the devices and return the new device ids if neccessary.
	findInputDevice(deviceId, label) {
		if (deviceId === 'default' || deviceId === 'communications') return deviceId;
		var selectedId = '';
		this.props.device.audio.availableInputDevices.forEach(d => {
			if (label === d.label) {
				selectedId = d.deviceId;
			}
			
		});
		return selectedId;
	}

	async setupAudio() {
		var errorMessage = '';
		var audioInput = localStorage.getItem('audioDeviceInput');
		var audioInputLabel = localStorage.getItem('audioDeviceInputLabel');
		if (audioInput && audioInputLabel) {
			try {
				var id = this.findInputDevice(audioInput, audioInputLabel);
				await this.props.device.audio.setInputDevice(id);
				//reset the local storage device
				if (id) {
					localStorage.setItem('audioDeviceInput', id);
				}
			}
			catch (error) {
				console.error('unable to set input device', error);
				errorMessage += 'Unable to setup the previously selected input device.\n' + audioInputLabel.replace('- ', '') + '\n';
			}
		}
		else {
			try {
				await this.props.device.audio.setInputDevice('default');
			}
			catch (error) {
				console.error('unable to set input device', error);
				errorMessage += 'Unable to use the default input device.' + '\n';
			}
		}

		var audioOutput = localStorage.getItem('audioDeviceOutput');
		var audioOutputLabel = localStorage.getItem('audioDeviceOutputLabel');
		if (audioOutput && audioOutputLabel) {
			try {
				var id = this.findOutputDevice(audioOutput, audioOutputLabel);
				await this.props.device.audio.speakerDevices.set(id);
				//reset the local storage device
				if (id) {
					localStorage.setItem('audioDeviceOutput', id);
				}
			}
			catch (error) {
				console.error('unable to set output device', error);
				errorMessage += 'Unable to setup the previously selected output device.\n' + audioOutputLabel.replace('- ', '') + '\n';
			}
		}
		else {
			try {
				await this.props.device.audio.speakerDevices.set('default');
			}
			catch (error) {
				console.error('unable to set output device', error);
				errorMessage += 'Unable to use the default output device.' + '\n';
			}
		}

		this.props.updateDeviceReady(true);
		if (errorMessage === '') {
			await connection.invoke('phoneAudioConnected');
		}
		else {
			await connection.invoke('phoneAudioDisconnected', errorMessage);
		}
	}

	async deviceOffline(device) {
		try {
			console.log('device offline', device);
			this.deviceDisconnected();
			this.props.updateDeviceReady(false);
			await connection.invoke('phoneDisconnected', 'The phone has become offline.');
			this.deviceRetry();
		} catch (ex) {
			console.error('an error occurred while processing device offline', ex);
		}
	}

	deviceError(error) {
		console.warn('device error', error);
	}

	async deviceAudioChange(lostActiveDevices) {
		console.warn('devices lost connection', lostActiveDevices);
		if (lostActiveDevices.length > 0) {
			var message = 'The selected audio device has lost connection:\n'
			for (var x = 0; x < lostActiveDevices.length; x++) {
				var direction = lostActiveDevices[x].kind === 'audioinput' ? "Input Device" : "Output Device";
				message += direction + " " + lostActiveDevices[x].label;
			}
			await connection.invoke('phoneAudioDisconnected', message);
		}
		else {
			this.setupAudio(false);
		}
	}

	async deviceReady() {
		try {
			this.props.device.audio.on('deviceChange', lostActiveDevices => this.deviceAudioChange(lostActiveDevices))
			await this.setupAudio(true);
		} catch (ex) {
			console.error('an error occurred while processing device ready', ex);
		}
	}

	async startCall(task, connection) {
		this.props.setTransferring(false);
		this.props.setTransferResponse(null);

		if (task != null) {
			if (window.bloodhound.callStarted) {
				console.log('Invoking callStarted back to bloodhound', task.activePhoneCallId);
				window.bloodhound.callStarted(task.activePhoneCallId);
			}
		}
		else {
			console.warn('starting call without task', task, connection);
		}
		if (connection) {
			connection.accept();
		}
	}

	async answerClicked() {
		var activeConnection = this.props.device.activeConnection();
		var activeTask = this.getActiveTask();		
		await this.startCall(activeTask, activeConnection);
	}

	async deviceIncoming(connection) {
		console.debug('device incoming', connection);
		this.setState({
			ringing: true,
			inCall: false
		});
		var activeTask = this.getActiveTask();
		await this.attemptToAnswerCall(activeTask, connection);
	}

	async attemptToAnswerCall(activeTask, connection) {
		if (this.autoAnswer(activeTask, connection)) {
			console.debug('task set to auto answer.  starting call.', activeTask, connection)
			await this.startCall(activeTask, connection);
		}
		else {
			console.debug('task not set to auto answer.', activeTask, connection)
		}
	}

	async deviceRinging(connection) {
		console.debug('device ringing', connection);
	}

	autoAnswer(activeTask) {
		return activeTask && activeTask.autoAccept === true;
	}

	//Call Disonnected
	async deviceDisconnected(connection) {
		this.endCall();
		this.setState({
			phoneMuted: false,
			phoneOnHold: false,
			transferWindowOpen: false,
			keypadWindowOpen: false,
			monitoring: true,
			coaching: false,
			barging: false
		});
		console.log('phone call disconnected.', connection);
	}

	//Call Connected
	async deviceConnected(connection) {
		this.setState({
			ringing: false,
			inCall: true
		});
		console.log('phone call connected', connection);
	}

	async wait(ms) {
		await new Promise(resolve => {
			setTimeout(resolve, ms);
		});
	}

	handleToggleHold() {
		var activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			var task = this.getActiveTask();
			var phoneOnHold = !this.state.phoneOnHold;
			this.setState({
				phoneOnHold: phoneOnHold
			});
			if (phoneOnHold) {
				HttpAgent.placeOnHold(task.activePhoneCallId);
			} else {
				HttpAgent.takeOffHold(task.activePhoneCallId);
			}
		}
	}

	async handleCoach() {
		const monitoring = false;
		const coaching = !this.state.coaching;
		const barging = false;
		var task = this.getActiveTask();
		this.setState({
			monitoring: monitoring,
			coaching: coaching,
			barging: barging
		});
		var req = { taskId: task.id };
		if (coaching) {
			await HttpAgent.coach(req);
		}
		else {
			const monitoring = !this.state.monitoring;
			const coaching = !this.state.coaching;
			this.setState({
				monitoring: monitoring,
				coaching: coaching,
				barging: barging
			});
			await HttpAgent.uncoach(req);
		}

		this.setState({
			coachButtonEnabled: true
		});		
	}

	handleCoachClicked() {
		if (!this.state.coachButtonEnabled) {
			return;
		}

		this.setState({
			coachButtonEnabled: false
		}, async () => await this.handleCoach());
	}

	async handleBarge() {
		const monitoring = false;
		const coaching = false;
		const barging = !this.state.barging;
		var task = this.getActiveTask();
		this.setState({
			monitoring: monitoring,
			coaching: coaching,
			barging: barging
		});
		if (barging) {
			var req = { taskId: task.id };
			await HttpAgent.barge(req);
		}

		this.setState({
			bargeButtonEnabled: true
		});
	}

	handleBargeClicked() {
		if (!this.state.bargeButtonEnabled) {
			return;
		}

		this.setState({
			bargeButtonEnabled: false
		}, async () => await this.handleBarge());
	}

	handleToggleMute() {
		const activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			const newState = !this.state.phoneMuted;
			this.setState({
				phoneMuted: !this.state.phoneMuted
			});
			activeConnection.mute(newState);
		}
	}

	handleTogglePauseRecording() {
		var activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			const recordingPaused = !this.state.recordingPaused;
			var task = this.getActiveTask();
			this.setState({
				recordingPaused: recordingPaused
			});

			if (recordingPaused) {
				HttpAgent.pauseRecording(task.activePhoneCallId);
			} else {
				HttpAgent.resumeRecording(task.activePhoneCallId);
			}
		}
	}


	endCall() {
		if (window.bloodhound.callEnded) {
			console.log('Invoking callEnded back to bloodhound');
			window.bloodhound.callEnded();
		}
		this.setState({
			recordingPaused: false,
			ringing: false,
			inCall: false
		});
	}

	async hangup() {
		const monitoring = true;
		const coaching = false;
		const barging = false;
		this.setState({
			monitoring: monitoring,
			coaching: coaching,
			barging: barging
		});
		this.endCall();
		const activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			await activeConnection.disconnect();
		}

		this.setState({
			endCallButtonEnabled: true
		});
	}

	hangupClicked() {
		if (!this.state.endCallButtonEnabled) {
			return;
		}

		this.setState({
			endCallButtonEnabled: false
		}, async () => await this.hangup());		
	}

	async ignoreClicked() {
		this.endCall();
		var task = this.getActiveTask();
		if (task) {
			await HttpAgent.endWorkTask(task.activePhoneCallId, this.props.agentId, null, true, false);
		}
		const activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			activeConnection.ignore();
		}
	}

	async rejectClicked() {
		this.endCall();
		var task = this.getActiveTask();
		if (task) {
			await HttpAgent.endWorkTask(task.activePhoneCallId, this.props.agentId, null, true, true);
		}
		const activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			activeConnection.reject();
		}
	}

	handleTransferDismissal() {
		this.setState({
			transferWindowOpen: false
		});
	}

	handleKeypadDismissal() {
		this.setState({
			keypadWindowOpen: false
		});
	}

	//#endregion

	showPhone() {
		return this.props.device.activeConnection() != null;
	}


	renderTopRow(task) {
		var phoneNumber = null, name = null;
		var showFullPhoneControl = false;
		if ((this.state.inCall && task && task.workTaskType !== 6) || (this.state.inCall && task && task.workTaskType === 6 && this.state.barging)) {
			showFullPhoneControl = true;
		}
		// Transferred calls do not have task until answered
		if (task) {
			phoneNumber = task.phoneNumber;
			if (task.displayName && task.displayName !== '') {
				name = task.displayName;
			}
			else if (task.consumer) {
				name = task.consumer.firstName + ' ' + task.consumer.lastName;
			}
		}

		return <TopRow>
			<ContactLabel>
				{name && <h2>{name}</h2>}
				{phoneNumber && <ContactPhoneNumber>{phoneNumber}</ContactPhoneNumber>}
			</ContactLabel>
			<ModalActions>
				{showFullPhoneControl &&
					<React.Fragment>
						<ModalActionButton icon="exchange-alt" onClick={() => this.setState({
							transferWindowOpen: !this.state.transferWindowOpen
						})}>Transfer</ModalActionButton>
						<ModalActionButton icon="th" onClick={() => this.setState({
							keypadWindowOpen: !this.state.keypadWindowOpen
						})}>Keypad</ModalActionButton>
					</React.Fragment>
				}
				{
					(!showFullPhoneControl && this.state.inCall && task) &&
					<ModalActionButton icon="user-plus" disabled={!this.state.bargeButtonEnabled} onClick={this.handleBargeClicked}>Barge</ModalActionButton>									
				}  
			</ModalActions>
		</TopRow>;
	}

	renderToggles(task) {
		if (!this.state.inCall) return;
		var indicatorColor = this.props.theme.baseColors.positiveLight;
		var destructiveIndicatorColor = this.props.theme.baseColors.warningLight;

		if (task && task.workTaskType === 6 && !this.state.barging) {
			return <StateToggles>
				<ToggleLabel toggledColor={indicatorColor} icon="whistle" checked={this.state.coaching} onChange={this.handleCoachClicked} disabled={!this.state.coachButtonEnabled}>Coach</ToggleLabel>
			</StateToggles>;
		}
		else {
			return <StateToggles>
				<ToggleLabel toggledColor={destructiveIndicatorColor} icon="pause" checked={this.state.phoneOnHold} onChange={this.handleToggleHold}>Hold</ToggleLabel>
				<ToggleLabel toggledColor={destructiveIndicatorColor} icon="microphone-alt-slash" checked={this.state.phoneMuted} onChange={this.handleToggleMute}>Mute</ToggleLabel>
				<PermissionWrapper permission="canPauseRecording">
					<ToggleLabel toggledColor={indicatorColor} icon="circle" checked={!this.state.recordingPaused} onChange={this.handleTogglePauseRecording}>Recording</ToggleLabel>
				</PermissionWrapper>
			</StateToggles>;
		}
	}

	renderBottomRow(task) {
		return <BottomRow>
			{task &&
				<ThemeProvider theme={DarkTheme}>
					<Indicators>
						<DurationIndicator><PhoneTimer /></DurationIndicator>
						<PhoneTypeIndicator phone workTaskType={task.workTaskType}></PhoneTypeIndicator>
						{this.renderToggles(task)}
					</Indicators>
				</ThemeProvider>
			}

			<Actions answer={this.state.ringing && !this.state.inCall}>
				{
					(this.state.ringing || this.state.inCall) &&
					<ActionButton destructive disabled={!this.state.endCallButtonEnabled} area="hang-up" icon={!((this.state.inCall && task && task.workTaskType !== 6) || (this.state.inCall && task && task.workTaskType == 6 && this.state.barging)) ? "sign-out" : "phone-slash"} onClick={() => this.state.ringing ? this.rejectClicked() : this.hangupClicked()} />
				}
				{
					this.state.ringing && <ActionButton positive area="answer" icon="phone" onClick={() => this.answerClicked()} />
				}
			</Actions>
		</BottomRow>;
	}

	renderGrid(task) {
		return <Grid>

			{this.showPhone() && this.state.transferWindowOpen &&
				<PhoneTransfer dismissRequested={this.handleTransferDismissal} task={task}></PhoneTransfer>
			}
			{this.showPhone() && this.state.keypadWindowOpen &&
				<PhoneDialPad onClickOutside={this.handleKeypadDismissal}></PhoneDialPad>
			}

			{this.showPhone() && this.renderTopRow(task)}
			{this.showPhone() && this.renderBottomRow(task)}
		</Grid>;
	}

	render() {				
		var task = this.getActiveTask();
		var activeConnection = this.props.device.activeConnection()
		/* Handle the case where the phone call was delivered before the task and we need to auto accept it. */

		if (task && activeConnection && activeConnection.status() === 'pending') {
			this.attemptToAnswerCall(task, activeConnection);
		}

		return <ThemeProvider theme={LowerLayer}>
			{this.renderGrid(task)}
		</ThemeProvider>;
	}

}

export default withTheme(withRouter(connect(state => ({ ...state.agent, ...state.phone, ...state.transfers }),
	dispatch => bindActionCreators(Object.assign({}, actionCreators, transferActionCreators), dispatch)
)(PhoneControl)));
