import React from 'react';
import styled, {css} from 'styled-components';
import Field from '../Field/Field.jsx';
import Tag from '../Tag/Tag.jsx';
import posed, { PoseGroup } from 'react-pose';
import LoadingIndicator from '../../core/LoadingIndicator/LoadingIndicator.jsx';

const Container = styled.ul`	
	${props => {
		const theme = props.theme;
		return css`
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				margin: 0;

				&:not(:last-child) {
					margin-right: ${theme.margin.half};
				}
			}
		`;
	}} 
`;

const TagItem = posed.li({	
	enter: {
		y: 0,
		opacity: 1,
		delay: 300,
		transition: {
			y: { type: 'spring', stiffness: 1000, damping: 15 },
			default: { duration: 300 }
		}
	},
	exit: {
		y: 50,
		opacity: 0,
		transition: { duration: 150 }
	}
});

const Spinner = styled(LoadingIndicator)`

	color: ${props => props.theme.colors.border};
	font-size: .5em;

`;

/**
 * A kind of specialized input-add component for attributes. Click a tag to
 * select it, and click again to remove.
 * 
 * 
 * @property {string}	label						- Text to describe field to user
 * @property {boolean}	[loading]				- Set to true to replace content with 
 * 	spinning cog to indicate loading
 * @property {string}	name						- HTML form input name
 * @property {function}	onClick					- Callback when a tag is clicked
 * @property {Array(object)}	availableTags	- List of available attributes
 * @property {Array(object)}	selectedTags	- List of selected attributes
 */
export default class TagSelector extends React.Component {

	constructor(props) {
		super(props);
		this.onTagClick = this.onTagClick.bind(this);
	}

	async onTagClick(id) {
		if (this.props.onTagClick) {
			this.props.onTagClick(id);
		}
	}

	render() {
		
		var tagComponents;
		if (this.props.availableTags != null && this.props.availableTags.length > 0) {
			// Sort alphabetically
			var tags = this.props.availableTags.sort((a, b) => a.name ? a.name.localeCompare(b.name) : "");
			// Scrub selected status
			tags.forEach(t => t.selected = false);
			// Then by what's selected
			if (this.props.selectedTags != null) {
				var indexCounter = 0;
				this.props.selectedTags.forEach(sTag => {
					var matchIndex = tags.findIndex(aTag => aTag.id == sTag.id);
					tags.splice(indexCounter, 0, tags.splice(matchIndex, 1)[0]);
					tags[indexCounter].selected = true;
					indexCounter += 1;
				});
			}
			tagComponents = tags.map(t => 
				<TagItem key={t.id}>
					<Tag selected={t.selected} key={t.id} 
						onClick={(e) => this.onTagClick(t.id)}>
						{t.name || t.key}
					</Tag>
				</TagItem>
			);
		}

		return <Field className={this.props.className} 
			name={this.props.name} label={this.props.label}>
			<Container>
				{
					!this.props.loading &&
					<PoseGroup>
						{tagComponents}
					</PoseGroup>				
				}
				{
					this.props.loading && <Spinner />
				}
			</Container>
		</Field>;
	}

}