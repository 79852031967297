import React from 'react';
import NewTabView from '../views/NewTabView';
import SettingsView from '../views/SettingsView';
import DialerView from '../views/DialerView';
import ConsumerView from '../views/ConsumerView';
import MonitoringView from '../views/MonitoringView';
import VoicemailView from '../views/VoicemailView';
import DialPadView from '../views/DialPadView';

const tabDefs = {
	newTab: {
		init: props => {
			return {			
				label: "New Tab",
				...props
			};
		},
		render: (tab) => {
			return <NewTabView {...tab} tabKey={tab.key}>New Tab Page</NewTabView>;
		}
	},
	settings: {
		init: props => {
			return {			
				label: "Settings",
				...props
			};
		},
		render: (tab) => {
			return <SettingsView {...tab} tabKey={tab.key} />;
		}
	},
	dialerSession : {
		init: props => {
			return {
				label: "Encore Voice",
				...props
			};
		},
		render: (tab) => {
			return <DialerView {...tab} tabKey={tab.key} />;
		}
	},
	consumerSession: {
		init: props => {
			return {
				label: "Consumer",
				...props
			};
		},
		render: (tab) => {
			return <ConsumerView {...tab} tabKey={tab.key} />;
		}
	},
	monitoring: {
		init: props => {
			return {
				label: "Monitoring",
				containerStyle: true,
				...props
			};
		},
		render: (tab) => {
			return <MonitoringView {...tab} tabKey={tab.key} />;
		}
	},
	voicemail: {
		init: props => {
			return {
				label: "Voicemail",
				containerStyle: true,
				...props
			};
		},
		render: (tab) => {
			return <VoicemailView {...tab} tabKey={tab.key} />;
		}
	},
	dialPad: {
		init: props => {
			return {
				label: "Dial Pad",
				singleInstance: true,
				...props
			};
		},
		render: (tab) => {
			return <DialPadView {...tab} tabKey={tab.key} />;
		}
	}
};

var types = {}
Object.keys(tabDefs).forEach(key => {
	types[key] = key;
});


function generateUUID() { // Public Domain/MIT
	var d = new Date().getTime();//Timestamp
	var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16;//random number between 0 and 16
		if (d > 0) {//Use timestamp until depleted
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {//Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
}

function InitializeTab(tabType, tabKey,  props) {
	var def = tabDefs[tabType];
	if (!tabKey) {
		tabKey = generateUUID();
	}
	var newProps = {
		...props,
		key: tabKey,
		type: tabType
	};
	return def.init(newProps);
}

function RenderTab(tab) {
	var def = tabDefs[tab.type];
	return def.render(tab);	
}

export default {
	types,
	InitializeTab,
	RenderTab
};