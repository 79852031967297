import React from 'react';
import Toggle from '../Toggle/Toggle.jsx';
import Field from '../Field/Field.jsx';

/**
 * `Toggle` wrapped in a `Field` element.
 * 
 * @property {string}		label					-	User-facing name for the field
 * @property {string}		name					-	HTML name for the form field. Used for 
 * 	the `for` attribute on the label as well.
 * @property {object}		[validation]		-	Validations to apply to field. Either
 * 	a validation object, or an array of validation objects, from the component's
 * 	validation state.
 * 
 * **All properties passed to the `Toggle` component.** See `Toggle` component for more details.
 */
export default class FormToggle extends React.Component {

	render() {

		return <Field name={this.props.name} label={this.props.label} validation={this.props.validation}>
			<Toggle {...this.props} />
		</Field>;
	}
}