
function DateDiffInDays(a, b) {	
	var duration = (b - a);
	return MillisecondsToDuration(duration);
}

function MillisecondsToDuration(milliseconds, restrictToFiveCharacters = false) {	

	const minutesFromMs = 1000 * 60;
	const secondsFromMs = 1000;

	var minutes = Math.floor(milliseconds / minutesFromMs);
	var seconds = Math.floor((milliseconds % minutesFromMs) / secondsFromMs);
	var difference;

	if (!restrictToFiveCharacters) {
		const hoursFromMinutes = 60;
		var hours = Math.floor(minutes / hoursFromMinutes);
		var revisedMinutes = Math.floor(minutes % hoursFromMinutes);
		difference = {
			hours: (minutes < 60) ? 0 : hours,
			minutes: (minutes < 0) ? 0 : revisedMinutes,
			seconds: (seconds < 0) ? 0 : seconds
		};	
		return difference.minutes >= 60
			? `${difference.hours}:${("0" + difference.minutes).slice(-2)}:${("0" + difference.seconds).slice(-2)}`
			: `${difference.minutes}:${("0" + difference.seconds).slice(-2)}`;
	}
	else {
		difference = {
			minutes: (minutes < 0) ? 0 : minutes,
			seconds: (seconds < 0) ? 0 : seconds
		};			
		return difference.minutes >= 60
			? "> 1h"
			: `${difference.minutes}:${("0" + difference.seconds).slice(-2)}`;
	}
}

export {
	DateDiffInDays,
	MillisecondsToDuration
}