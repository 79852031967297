import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

const SwitchPane = styled.div`
	${props => {
		const theme = props.theme;
		var backgroundColor = theme.button.background;		
		if (props.toggled) {
			if (props.customColors && props.customColors.pane) {
				switch (props.customColors.pane) {
					case "destructive":
						backgroundColor = theme.button.destructive.background;
						break;
					default:
						backgroundColor = props.customColors.pane;
				}
			}
			backgroundColor = theme.colors.positiveDark;
		}
		return css`
		
			display: inline-flex;
			background-color: ${theme.button.background};
			background-color: ${backgroundColor};
			padding: 2px;
			height: 1.2em;
			width: 30px;
			margin-right: .35em;
			border-radius: 999px;
			position: relative;
			top: 1px;
			
			transition: background-color ease-in-out ${props => props.toggled ? "0.05s" : ".1s"};

			flex-direction: ${props => props.toggled ? "row-reverse" : "row"};

		`;
	}}
`;

const SwitchPeg = styled.div`
	${props => {
		const theme = props.theme;
		var backgroundColor = theme.colors.text;		
		if (props.toggled) {
			if (props.customColors && props.customColors.peg) {
				backgroundColor = props.customColors.peg;
			}
			backgroundColor = theme.button.primary.text || theme.colors.text;
		}
		return css`

			display: block;
			background-color: ${backgroundColor};
			height: calc(1.2em - 4px);
			width: calc(1.2em - 4px);
			margin: 2px;
			border-radius: 999px;

		`;
	}}
`;

const ToggleContainer = styled.div`
	${props => {
		const theme = props.theme;
		var hoverBackgroundColor = null;
		if (props.canInteract && !props.toggled) {
			hoverBackgroundColor = css`		
					&:hover ${SwitchPane} {
						background-color: ${theme.button.hover.background || theme.button.active.background || theme.button.background};
					}
				`;
		}
		return css`		
			display: block;
			${hoverBackgroundColor}		
		`;
	}}
`;

/**
 * A toggle control that functions like a checkbox.
 * 
 * @property {boolean}	toggled					- True if checked/toggled
 * @property {object}	customColors			- Set special colors for the toggle control
 * @property {object}	customColors.peg		- Set the toggled color of the peg
 * @property {object}	customColors.pane		- Set the toggled color of the well that the 
 * 	peg sits in
 * @property {function}	onChange					- Callback when the toggle state is changed
 */
export default class Toggle extends React.Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(evt) {
		if (this.props.onChange) {
			this.props.onChange(evt);
		}
	}

	render() {
		var sharedProps = {
			toggled: this.props.checked,
			customColors: this.props.customColors
		}

		return <ToggleContainer {...sharedProps} className={this.props.className}
			onClick={this.handleChange} canInteract={this.props.onChange != undefined}>
			<SwitchPane {...sharedProps}>
				<SwitchPeg {...sharedProps}></SwitchPeg>
			</SwitchPane>
			{this.props.children}
		</ToggleContainer>;
	}
}