import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Phone';
import InputBar from './core/InputBar/InputBar';
import { ThemeProvider } from 'styled-components';
import { LightTheme } from './core/Themes.js';
import DialButton from './core/DialButton/DialButton';
import styled, { css } from 'styled-components';

const Container = styled.div`

	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: calc(25vh - 120px);

`;

const NumberPad = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			display: grid;
			grid-row-gap: ${theme.margin.half};
			grid-column-gap: ${theme.margin.half};
			grid-template-columns: 11vh 11vh 11vh;
			grid-template-rows: 11vh 11vh 11vh 11vh;
		`;
	}};

`;

const DigitMap = [
	{
		digit: "1"
	},
	{
		digit: "2",
		aliases: "abc"
	},
	{
		digit: "3",
		aliases: "def"
	},
	{
		digit: "4",
		aliases: "ghi"
	},
	{
		digit: "5",
		aliases: "jkl"
	},
	{
		digit: "6",
		aliases: "mno"
	},
	{
		digit: "7",
		aliases: "pqrs"
	},
	{
		digit: "8",
		aliases: "tuv"
	},
	{
		digit: "9",
		aliases: "wxyz"
	},
	{
		digit: "*"
	},
	{
		digit: "0",
		aliases: "+"
	},
	{
		digit: "#"
	},
];

class PhoneDialPad extends React.Component {
	constructor(props) {
		super(props);

		DigitMap.forEach(d => {
			d.el = React.createRef();
		});

		this.state = {
			digits: DigitMap,
			digitDisplay: ""
		};

		this.handleButtonDown = this.handleButtonDown.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.inputKeyPressed = this.inputKeyPressed.bind(this);
		this.sendDigit = this.sendDigit.bind(this);
	}

	async componentDidMount() {

	}

	inputKeyPressed(event) {
		event.preventDefault();
		this.sendDigit(event.key);
	}

	handleButtonDown(digit) {
		this.sendDigit(digit);
	}

	handleKeyDown(event) {
		// Escape key does not trigger keyPressed.
		if ((event.key === 'Escape' || event.key === 'Enter') && this.props.onClickOutside) {
			this.props.onClickOutside(event);
		}
	}

	sendDigit(inputDigit) {
		const activeConnection = this.props.device.activeConnection();
		if (activeConnection) {
			// Translate key
			const digit = this.state.digits.find(f => {
				if (f.digit === inputDigit) return true;
				if (f.aliases && f.aliases.includes(inputDigit)) return true;
				return false;
			});
			if (digit) {
				digit.el.current.showPressed();
				this.setState(ps => {
					return {
						digitDisplay: ps.digitDisplay + digit.digit
					};
				});
				activeConnection.sendDigits(digit.digit);
			}
		}
	}
	
	render() {

		const buttons = this.state.digits.map(d => 
			<DialButton key={d.digit} 
				letters={d.aliases}
				pressed={d.pressed}
				onClick={() => this.handleButtonDown(d.digit)}
				ref={d.el}>
				{d.digit}
			</DialButton>
		);

		return <ThemeProvider theme={LightTheme}>
			<InputBar onKeyPress={this.inputKeyPressed} placeholder="#" type='text'
				onClickOutside={this.props.onClickOutside} onKeyDown={this.handleKeyDown}
				value={this.state.digitDisplay} readOnly numeric>
				<Container>
					<NumberPad>
						{buttons}
					</NumberPad>
				</Container>
			</InputBar>
		</ThemeProvider>;
	}
}


export default connect(state => state.phone,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(PhoneDialPad);
