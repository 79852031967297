import React from 'react';
import DialingCampaign from './DialingCampaign';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/DialingCampaigns';
import HttpAgent from '../../httpagent';

import posed, { PoseGroup } from 'react-pose';
import styled, { css } from 'styled-components';

const CardContainer = posed.li({});

const Container = styled.ul`

	${props => {
		const theme = props.theme;
		return css`
			width: 100%;
			list-style: none;
			padding: 0;
			margin-left: -${theme.margin.full};
			margin-right: -${theme.margin.full};
		`;
	}}

`;

const DialerList = styled.section`


`;

const DialerListContainer = styled.div`

	margin: ${props => props.theme.margin.full} 0;

`;

const DialerListHeader = styled.h3`

	${props => {
		const theme = props.theme;
		return css`
			font-family: ${theme.fonts.heading};
			font-weight: 500;
			text-transform: uppercase;
			margin-left: ${theme.margin.full};
		`;
	}};
`;

const NoDialersMessage = styled.li`

	/* text-align: center; */
	margin-left: ${props => props.theme.margin.full};
	color: ${props => props.theme.colors.secondary};

`;

class MessageDialerList extends React.Component {

	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		var res = await HttpAgent.getDialingCampaigns();
		this.props.loadDialerCampaigns(res.data);
	}
	render() {

		var dialers = this.props.campaigns || [];
		var pdCampaigns = dialers.filter(dialer => dialer.campaignType === "PredictiveDialing")
			.map(dialer => <DialingCampaign predictive dialer={dialer} key={dialer.id}></DialingCampaign>);
		var msgDialCampaigns = dialers.filter(dialer => dialer.campaignType === "MessageDialing")
			.map(dialer => <DialingCampaign message dialer={dialer} key={dialer.id}></DialingCampaign>);

		var pdCards = pdCampaigns.map(a => {
			return <CardContainer key={a.key}>{a}</CardContainer>;
		});
		var mdCards = msgDialCampaigns.map(a => {
			return <CardContainer key={a.key}>{a}</CardContainer>;
		});

		return <Container>	
			<DialerList>
				<DialerListHeader>Message Dialing</DialerListHeader>
				{msgDialCampaigns.length == 0 && 
					<NoDialersMessage>No Active Message Dialer Campaigns</NoDialersMessage>
				}		
				<DialerListContainer>
					<PoseGroup animateOnMount={true}>			
						{mdCards}
					</PoseGroup>
				</DialerListContainer>
			</DialerList>		
			<DialerList>
				<DialerListHeader>Outbound Dialing</DialerListHeader>
				{pdCampaigns.length == 0 && 
					<NoDialersMessage>No Active Outbound Dialer Campaigns</NoDialersMessage>
				}		
				<DialerListContainer>
					<PoseGroup animateOnMount={true}>			
						{pdCards}
					</PoseGroup>
				</DialerListContainer>
			</DialerList>	
		</Container>;
	}

}

export default connect(state => state.dialingCampaigns,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(MessageDialerList);