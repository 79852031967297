import React from 'react';
import HttpAgent from '../../httpagent.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Phone';
import { ThemeProvider } from 'styled-components';
import { CardLayer } from '../core/Themes.js';
import DialButton from '../core/DialButton/DialButton';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInput from "../core/TextInput/TextInput.jsx";
import StatusIndicator from '../core/StatusIndicator/StatusIndicator.jsx';
import InputLine from '../core/InputBar/InputLine.jsx';
import { withRouter } from "react-router-dom";

const DialPadContainer = styled.div`
	${props => {
		const theme = props.theme;
	
		let bg = "#fff";
		let pointerEvents = "auto";
		let opacity = 1;

		if (props.disabled) {
			bg = "#CCC";
			pointerEvents = "none";
			opacity = 0.5;
		} 

		return css`
			pointer-events: ${pointerEvents};
			background-color: ${bg};
			opacity: ${opacity};
			padding: 20px 40px;
			border-radius: 50px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			width:55vw;
			box-shadow: ${theme.card.shadow};
		`;
	}};
`;

const AgentListContainer = styled.div`
	background-color: #fff;
	margin-right: 20px;
	vertical-align: top;
	border-right: 1px #e1e1e1 solid;
	top: 0px;
	padding-left:5px;
	display: table;
	height:100%;
`;

const AgentListSubContainer = styled.div`
	border: none; 
	border-radius: 0px;
	height:100%;
	overflow:auto;
`;

const CallButton = styled.div`
	${props => {
		const theme = props.theme;
		let bg = "#8cbbe9";
		let hoverCursor = "pointer";
		let hoverColor = "#25d366";
		

		if (props.disabled) {
			bg = "#CCC";
			hoverCursor = "not-allowed";
			hoverColor = bg;
		}

		return css`
			border-radius: 9999px;
			background-color: ${bg};
			border: 2px solid ${theme.colors.border};
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			cursor: default;
			user-select: none;
			outline: none;

			&:hover {
				border: 2px solid ${theme.colors.primary};
				cursor: ${hoverCursor};
				background-color: ${hoverColor};
				opacity:0.7;
			}

			transition: background-color ease-in-out .15s,
				border-color ease-in-out .05s;

		`;
	}}
`;

const Container = styled.div`

`;

const NumberPad = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			display: grid;
			grid-row-gap: ${theme.margin.half};
			grid-column-gap: ${theme.margin.half};
			grid-template-columns: 8vh 8vh 8vh;
			grid-template-rows: 8vh 8vh 8vh 8vh 8vh;
			justify-content: center;
		`;
	}};

`;

const DigitMap = [
	{
		digit: "1"
	},
	{
		digit: "2",
		aliases: "abc"
	},
	{
		digit: "3",
		aliases: "def"
	},
	{
		digit: "4",
		aliases: "ghi"
	},
	{
		digit: "5",
		aliases: "jkl"
	},
	{
		digit: "6",
		aliases: "mno"
	},
	{
		digit: "7",
		aliases: "pqrs"
	},
	{
		digit: "8",
		aliases: "tuv"
	},
	{
		digit: "9",
		aliases: "wxyz"
	},
	{
		digit: "*"
	},
	{
		digit: "0",
		aliases: "+"
	},
	{
		digit: "#"
	},
];

const Field = styled(TextInput)`

	grid-area: field;
	font-size: 1.15em;

`;


const AgentCallOption = styled(InputLine)`
	${props => {
		const theme = props.theme;
		const status = props.status;
		const color = status === "Online" ? theme.colors.positiveText : theme.colors.brand;
		const canCallAgent = props.canCallAgent;
		const cursor = canCallAgent ? "pointer" : "not-allowed";

		return css`	
			div {
				color: ${color};
				margin: 0px;
			}

            cursor: ${cursor};
			font-size: small;			
		`;
	}}
`;

const InputField = React.forwardRef((props, ref) => (
	<Field {...props} innerRef={ref} />
));

const InfoMessage = styled.div`
	color:red;
	text-align: center;
`;

class DialPadView extends React.Component {
	constructor(props) {
		super(props);

		DigitMap.forEach(d => {
			d.el = React.createRef();
		});

		this.digitDisplay = React.createRef();

		this.state = {
			digits: DigitMap,
			digitDisplay: "",
			shiftKeyDown: false,	
			infoMessage: ""
		};

		this.handleButtonDown = this.handleButtonDown.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.inputKeyPressed = this.inputKeyPressed.bind(this);
		this.sendDigit = this.sendDigit.bind(this);
		this.queryAgentList = this.queryAgentList.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
	}

	async componentDidMount() {				
	}

	handleChange(event) {	
		this.setState({ infoMessage: "" });

		if (!this.isAgentAvailable(this.props.agent)) {
			return;
		}

		this.setState({
			digitDisplay: event.target.value
		});
	}

	handleOnBlur(event) {
		if (this.digitDisplay.current) {
			this.digitDisplay.current.focus();
		}
	}

	inputKeyPressed(event) {	
		const dialPadAccessPermission = this.props.agent ? this.props.agent.dialPadAccessPermission : 0;

		// prevent non-numeric character entry if agent only has external dialing permissions
		if (dialPadAccessPermission === 2 && (event.which < 48 || event.which > 57)) {
			event.preventDefault();
			return;
		}
		
		this.sendDigit(event.key, false);
	}

	handleButtonDown(digit) {
		this.sendDigit(digit, true);
	}

	handleKeyDown(event) {
		const activeConnection = this.props.phone.device.activeConnection();

		if (!activeConnection) {
			// These do not trigger keyPressed.  Handle accordingly
			if (event.key === 'Enter') {
				this.initManualCall(this.state.digitDisplay);
			}

			if (event.key === 'Shift') {
				this.setState({ shiftKeyDown: true})
			}
		}
	}

	handleKeyUp(event) {
		if (event.key === 'Shift') {
			this.setState({ shiftKeyDown: false })
		}
	}

	sendDigit(inputDigit, isButtonPress) {
		if (!this.isAgentAvailable(this.props.agent)) {
			return;
		}

		// Translate key
		const digit = this.state.digits.find(f => {
			if (f.digit === inputDigit) return true;
			if (f.aliases && f.aliases.includes(inputDigit)) return true;
			return false;
		});

		if (digit && digit.el.current) {
			digit.el.current.showPressed();

			if (isButtonPress) {
				this.setState(ps => {
					return {
						digitDisplay: ps.digitDisplay + inputDigit
					};
				});
			}

			const activeConnection = this.props.phone.device.activeConnection();

			if (activeConnection) {
				activeConnection.sendDigits(digit.digit);
			}
		}
	}

	queryAgentList() {
		//when the query is empty return an empty list.  This will be an additive filter.
		if (this.state.digitDisplay === '') {
			return [];
		}

		const startsWithSearch = (a, b) => {
			return a && b && a.trim().toUpperCase().startsWith(b.trim().toUpperCase());
		};		

		const agents = this.props.agents.filter(agent => {
			if (!agent) {
				return false;
			}

			if (!agent.encoreEnabled) {
				return false;
			}

			return (startsWithSearch(agent.personCode, this.state.digitDisplay) ||
				startsWithSearch(agent.name, this.state.digitDisplay)) &&
				// Exclude self
				agent.personCode.toUpperCase() !== this.props.agent.personCode.toUpperCase();
		}).sort(function (a, b) {
			const aName = a.name || a.personCode;
			const bName = b.name || b.personCode;
			const onlineSort = (a.online === b.online) ? 0 : a.online ? -1 : 1;
			const readyForTaskSort = (a.readyForTask === b.readyForTask) ? 0 : a.readyForTask ? -1 : 1;
			const availableSort = (a.state === b.state) ? 0 : a.state === 0 ? -1 : 1;
			const nameSort = aName.localeCompare(bName);

			return onlineSort || readyForTaskSort || availableSort || nameSort;
		});		

		return agents;
	}

	async initManualCall(phoneNumber) {
		this.setState({ infoMessage: "" });
		const dialPadAccessPermission = this.props.agent ? this.props.agent.dialPadAccessPermission : 0;

		if (dialPadAccessPermission === 2 || dialPadAccessPermission === 3) {
			if (phoneNumber && this.props.agent.online && phoneNumber.length >= 3) {
				if (this.isAgentAvailable(this.props.agent)) {
					await this.placeManualCall(phoneNumber);
				} else {
					console.log('You cannot place a manual call if you\'re unavailable');
				}
			} else {
				console.log('Unable to place manual call: ', this.props, phoneNumber);
			}
		} else {
			console.log('You do not have permission to make external calls.', this.props.agent);
		}
	}

	async initAgentToAgentCall(userId) {
		this.setState({ infoMessage: "" });
		const dialPadAccessPermission = this.props.agent ? this.props.agent.dialPadAccessPermission : 0;

		if (dialPadAccessPermission === 1 || dialPadAccessPermission === 3) {
			if (userId && this.props.agent.online) {
				if (this.isAgentAvailable(this.props.agent)) {
					await this.placeAgentToAgentCall(userId);
				} else {
					console.log('You cannot place an agent call if you\'re unavailable');
				}
			} else {
				console.log('Unable to place call to agent: ', this.props, userId);
			}
		} else {
			console.log('You do not have permission to make an agent to agent call.', this.props.agent);
		}
	}

	async placeAgentToAgentCall(userId) {
		console.log('Placing agent-to-agent call: ', userId);

		const req = {
			ToAgentId: userId,
			OriginId: this.generateUUID()
		};

		try {
			const response = await HttpAgent.placeAgentToAgentCall(req);

			if (response.status === 200 && response.data) {
				console.log('Agent to agent call successful')
			} else {
				console.log('A server error occurred while attempting to place an agent call: ', response)
				this.setState({ infoMessage: 'A server error occurred while attempting to place an agent call' });
			}
		} catch (error) {
			console.log('Error placing agent to agent call: ', error)
			this.setState({ infoMessage: 'Error placing agent to agent call' });
		}
	}

	async placeManualCall(phoneNumber) {
		phoneNumber = this.normalizePhoneNumber(phoneNumber);

		if (this.isNumeric(phoneNumber)) {
			console.log('Placing manual call to: ', phoneNumber)

			const req = {
				numberToDial: phoneNumber,
				originId: this.generateUUID()
			};

			try {				
				const response = await HttpAgent.placeManualCall(req);

				if (response.status === 200 && response.data) {
					console.log('Manual call successful')
				} else {
					console.log('A server error occurred while attempting to place a manual call: ', response)
					this.setState({ infoMessage: 'A server error occurred while attempting to place a manual call' });
				}
			} catch (error) {
				console.log('Error placing manual call: ', error)
				this.setState({ infoMessage: 'Error placing manual call' });
			}
		} else {
			console.log('Invalid phone #: ', phoneNumber)
		}	
	}

	normalizePhoneNumber(ph) {
		return ph.replace(/\(/g, "").replace(/\)/g, "").replace(/\s/g, "").replace(/\-/g, "").replace(/\./g, "").replace(/#/g, "").trim();
	}

	isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	generateUUID() { // Public Domain/MIT
		var d = new Date().getTime();//Timestamp
		var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16;//random number between 0 and 16
			if (d > 0) {//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

	isAgentAvailable(agent) {
		let isAvailable = false;

		if (agent) {
			let state = agent.state;
			let online = false;
			let currentTask = null;
			let onCall = false;

			state = agent.state;
			online = agent.online && agent.phoneConnected && agent.phoneAudioConnected;
			currentTask = agent.currentWorkTasks && agent.currentWorkTasks.length > 0 ? agent.currentWorkTasks[0] : null;

			onCall = (currentTask && !currentTask.completedTimestamp);
			//isAvailable = (state === 0 && online && !onCall);
			isAvailable = (online && !onCall);
		}

		return isAvailable;
	}

	render() {
		const dialPadAccessPermission = this.props.agent ? this.props.agent.dialPadAccessPermission : 0;

		const buttons = this.state.digits.map(d =>
			<DialButton key={d.digit}
				letters={d.aliases}
				pressed={d.pressed}
				onClick={() => this.handleButtonDown(d.digit)}
				ref={d.el}>
				{d.digit}
			</DialButton>
		);

		const searchResults = this.queryAgentList();
		const agentList = searchResults.map(agent => {
			let canCallAgent = false;

			if (agent) {
				let state = agent.state;
				let online = false;
				let currentTask = null;
				let onCall = false;

				state = agent.state;
				online = agent.online && agent.phoneConnected && agent.phoneAudioConnected;
				currentTask = agent.currentWorkTasks && agent.currentWorkTasks.length > 0 ? agent.currentWorkTasks[0] : null;

				onCall = (currentTask && !currentTask.completedTimestamp);
				canCallAgent = (state !== 2 && online && !onCall);
			}

			return <AgentCallOption key={agent.bloodhoundUserId} status={agent.status} canCallAgent={canCallAgent}
				onClick={(e) => this.initAgentToAgentCall((canCallAgent ? agent.bloodhoundUserId : null), e)}
			>
				<StatusIndicator agent={agent} showCode />
				{agent.name && <span>{agent.name}</span>}
			</AgentCallOption>;
		});

		let containerStyle = "";
		let agentContainerStyle = "";
		let callPlaceholder = "";
		let enableCallButton = this.normalizePhoneNumber(this.state.digitDisplay).length >= 3 &&
			this.isAgentAvailable(this.props.agent) && 
			this.isNumeric(this.normalizePhoneNumber(this.state.digitDisplay));

		if (dialPadAccessPermission !== 3) {
			containerStyle = {
				marginTop: '10px'
			}

			if (dialPadAccessPermission === 1) {
				callPlaceholder = "Enter agent name";

				agentContainerStyle = {
					height: '40vh',
					width: '98%',
					border: 'none'
				}
			} else {
				callPlaceholder = "Enter phone number";
			}			
		} else {		
			containerStyle = {
				display: 'grid',
				gridTemplateColumns: 'auto auto',
				gridTemplateRows: 'auto',
				marginTop: '10px'
			}

			agentContainerStyle = {
				display: 'table'
			}

			callPlaceholder = "Enter phone number or agent name";
		}

		return <ThemeProvider theme={CardLayer}>
			{dialPadAccessPermission !== 0 ?
			<DialPadContainer disabled={!this.isAgentAvailable(this.props.agent)}>
				<InputField {...this.props}
					name="digitDisplay"
					ref={this.digitDisplay}
					width={"100%"}
					autoFocus
					placeholder={callPlaceholder}
					onKeyPress={this.inputKeyPressed}
					onKeyDown={this.handleKeyDown}
					onKeyUp={this.handleKeyUp}
					onChange={this.handleChange}
					value={this.state.digitDisplay}
					onBlur={this.handleOnBlur}
				>
				</InputField>

				{this.state.infoMessage !== "" ? <InfoMessage>{this.state.infoMessage}</InfoMessage> : ""}

				<Container style={containerStyle}>
					{dialPadAccessPermission === 1 || dialPadAccessPermission === 3 ?
					<AgentListContainer style={agentContainerStyle}>
						<span style={{ borderBottom: "1px solid #e1e1e1", fontWeight: "bold" }}>ENCORE AGENTS</span>
						<AgentListSubContainer>
							{agentList}
						</AgentListSubContainer>
					</AgentListContainer>
					: ""}
					{dialPadAccessPermission === 2 || dialPadAccessPermission === 3 ?
					<NumberPad>						
						{buttons}
						<div></div>
						<CallButton onClick={() => this.initManualCall(this.state.digitDisplay)} disabled={!enableCallButton}>
							<FontAwesomeIcon size='lg' fixedWidth icon={['far', 'phone-alt']} style={{color: 'white'}} />
						</CallButton>
					</NumberPad>
					: ""}
				</Container>
			</DialPadContainer>
			: <span>You do not have permission to access this feature.</span>
		}
		</ThemeProvider>;
	}
}

export default withRouter(connect(state => ({ agent: { ...state.agent }, ...state.agents, phone: { ...state.phone } }),
	dispatch => bindActionCreators(actionCreators, dispatch))(DialPadView));
