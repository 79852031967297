import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/DialingCampaigns';
import HttpAgent from '../../httpagent';

import DataTable from '../core/DataTable/DataTable.jsx';
import DataTableRow from '../core/DataTable/DataTableRow.jsx';
import { CompactColumn } from './CompactColumn.jsx';
import styled, { css, withTheme } from 'styled-components';

import RoundButton from '../core/RoundButton/RoundButton.jsx';
import Badge from '../core/Badge/Badge.jsx';

import { MillisecondsToDuration } from '../utils/DateHelpers';

const CompactButton = styled(RoundButton)`

	margin: 0;
	
	${props => {
		const theme = props.theme;
		return css`
		
			padding: ${theme.margin.quarter} ${theme.margin.half};
		
		`;
	}}

`;
const CompactBadge = styled(Badge)`

	margin: 0;
	
	${props => {
		const theme = props.theme;
		return css`
		
			padding: ${theme.margin.quarter} ${theme.margin.half};
		
		`;
	}}

`;

class OutboundGrid extends React.Component {

	constructor(props) {
		super(props);
		this.onControlChange = this.onControlChange.bind(this);
	}

	async componentDidMount() {
		var res = await HttpAgent.getDialingCampaigns();
		this.props.loadDialerCampaigns(res.data);
	}

	async onControlChange(campaign, status) {
		switch (status) {
			case 'run':
				break;
			case 'stop':
				await HttpAgent.stopDialingCampaign(campaign);
				break;
			case 'pause':
				await HttpAgent.pauseDialingCampaign(campaign);
				break;
			case 'resume':
				await HttpAgent.resumeDialingCampaign(campaign);
				break;
		}
	}

	getDialerState(current) {
		const theme = this.props.theme;

		const paused = current.paused;
		const running = current.running;
		const stopping = current.stopping;
		const empty = current.endOfQueue && (paused || running || stopping);

		if (!running) {
			return {
				text: 'Not Running'
			};
		}
		
		if (stopping) {
			return {
				text: 'Stopping',
				color: theme.colors.negative
			};
		}

		if (paused) {
			return {
				text: 'Paused',
				color: theme.colors.negative
			};
		}

		if (empty) {
			return {
				text: 'Empty',				
				color: theme.colors.black,
				background: theme.colors.warningLight
			};
		}

		return {
			text: 'Running',
			background: theme.button.positive.background,
			color: theme.button.positive.text,
			running: true
		};
	}

	calculateRow(key) {
		const current = this.props.campaigns.find(x => x.queue === key.queue && x.entity === key.entity && x.campaignType === key.campaignType);
		const daily = this.props.stats.find(x => x.queueCode === key.queue && x.entityCode === key.entity && (x.campaignType === 1 ? 'PredictiveDialing' : 'MessageDialing') === key.campaignType);
		let avgDuration = 0;
		if (daily != null && daily.talkingTime != null && daily.numberOfConnectedCalls > 0) {
			avgDuration = Math.round(daily.talkingTime / daily.numberOfConnectedCalls);
		} 
		let ds;
		if (current) {
			ds = this.getDialerState(current);
		}

		return <DataTableRow key={key.queue + key.campaignType} running={ds ? ds.running : null}>
			<CompactColumn code key="queue-name">
				{key.queue}
			</CompactColumn>
			<CompactColumn key="actions">
				{
					current != null && current.running && !current.paused && <CompactButton icon="pause" onClick={() => { this.onControlChange(current, 'pause') }} >Pause</CompactButton>
				}
				{
					current != null && current.paused && <CompactButton icon="pause" onClick={() => { this.onControlChange(current, 'resume') }}>Resume</CompactButton>
				}
				{
					current != null && current.running && current.campaignType === "MessageDialing" && <CompactButton icon="stop" onClick={() => { this.onControlChange(current, 'stop') }}>Stop</CompactButton>
				}
			</CompactColumn>
			<CompactColumn key="state">
				{
					ds && <CompactBadge background={ds.background} color={ds.color}>{ds.text}</CompactBadge>
				}
			</CompactColumn>
			<CompactColumn key="type">
				{
					<span>{key.campaignType == "PredictiveDialing" ? "Outbound" : "Message"}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="cpc">
				{
					<span>{current != null && current.callsPerAgent ? current.callsPerAgent.toFixed(2) : '-'}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="agents">
				{
					<span>{current != null && current.loggedInAgents ? current.loggedInAgents : 0}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="calls">
				{
					<span>{current != null ? current.currentOutboundCalls : 0}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="daily">
				{
					<span>{daily != null ? daily.totalCalls : 0}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="avg-duration">
				{
					<span>{MillisecondsToDuration(avgDuration * 1000)}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="failed">
				{
					<span>{daily != null ? daily.numberOfFailedCalls : 0}</span>
				}
			</CompactColumn>
			<CompactColumn numeric key="hang-ups">
				{
					<span>{daily != null ? daily.numberOfAbandonedCalls : 0}</span>
				}
			</CompactColumn>
		</DataTableRow>;
	}

	getUniqueRows() {

		var campaigns = this.props.campaigns.filter(x => x.entity && x.queue).map(x => {
			return { queue: x.queue, entity: x.entity, campaignType: x.campaignType };
		});
		var stats = this.props.stats.filter(x => x.entityCode && x.queueCode).map(x => {
			return {
				queue: x.queueCode,
				entity: x.entityCode,
				campaignType: x.campaignType === 1 ? 'PredictiveDialing' : 'MessageDialing'
			};
		});
		var uniqueRows = [...campaigns, ...stats].reduce(
			(accumulator, current) => accumulator.some(x => x.queue === current.queue && x.entity === current.entity && x.campaignType === current.campaignType)
				? accumulator
				: [...accumulator, current],
			[]
		);
		return uniqueRows;
	}

	render() {
		var rows = [];
		if (this.props.stats) {
			rows = this.getUniqueRows().map(x => this.calculateRow(x));
			rows = rows.sort(function (a, b) {
				if (a.props.running != b.props.running) {
					return (a.props.running && !b.props.running);
				}
				var nameSort = a.key.localeCompare(b.key);
				return nameSort;
			});
		}
		return <DataTable columns={[
			{
				key: "queue-name",
				title: "Queue",
				width: [
					{
						width: "10%"
					}
				]
			},
			{
				key: "actions",
				headerVisible: false,
				width: [
					{
						width: "23%"
					}
				]
			},
			{
				key: "state",
				title: "State",
				width: [
					{
						width: "15%"
					}
				]
			},
			{
				key: "type",
				title: "Type",
				width: [
					{
						width: "9%"
					}
				]
			},
			{
				key: "cpc",
				title: "CPC",
				align: "right",
				width: [
					{
						width: "7%"
					}
				]
			},
			{
				key: "agents",
				title: "Agents",
				align: "right",
				width: [
					{
						width: "9%"
					}
				]
			},
			{
				key: "calls",
				title: "Calls",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "daily",
				title: "Daily",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "avg-duration",
				title: "Avg. Duration",
				align: "right",
				width: [
					{
						width: "15%"
					}
				]
			},
			{
				key: "failed",
				title: "Failed",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "hang-ups",
				title: "Hang Ups",
				align: "right",
				width: [
					{
						width: "12%"
					}
				]
			},
		]}>
			{rows}
		</DataTable>;

	}
}

export default connect(state => state.dialingCampaigns, dispatch => bindActionCreators(actionCreators, dispatch)
)(withTheme(OutboundGrid));