import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { LowerLayer } from '../Themes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './Sidebar.jsx';

const TabBar = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			padding: ${theme.margin.half} ${theme.margin.half} 0;
			display: grid;
			grid-auto-columns: 1fr;		
			grid-column-gap: ${theme.margin.half};		
		`;
	}} 

`;

const Tab = styled.div`
	${props => {
		const theme = props.theme;
		var deSelected;
		if (!props.selected) {
			deSelected = css`
				color: ${theme.colors.secondary};
				&:hover {
					border-bottom-color: ${theme.colors.brand};
				}
			`;
		}
		return css`
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			grid-column: ${props.column + 1};
			border-bottom: 5px solid ${props.selected ? theme.colors.brand : theme.colors.border};	
			transition: border-bottom-color ease-in-out .05s, 
				color ease-in-out .05s, 
				font-size ease-in-out .05s;	
			font-size: ${props.selected ? "1.3em" : "0.9em"};
			${deSelected}		
		`;
	}} 
`;

const TabIcon = styled(FontAwesomeIcon)`
`;

/**
 * **INCOMPLETE**
 * 
 * Sidebar element containing collapsible sections, accordion-style.
 * Intended for the filter list on the monitoring view.
 */
class SidebarCurtain extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedTabId: null
		};
		this.onTabClick = this.onTabClick.bind(this);
	}

	componentDidMount() {
		var kids = React.Children.map(this.props.children, child => child.props.tabId);
		if (Array.isArray(kids) && kids.length > 0) {
			this.setState({
				selectedTabId: kids[0]
			});
		}
	}

	async onTabClick(e) {
		this.setState({
			selectedTabId: e
		});
	}

	render() {

		var selectedComponent = null;
		var tabs = [];
		// Create tabs (marking the selected one)
		React.Children.forEach(this.props.children, (child, i) => {
			var selected = child.props.tabId == this.state.selectedTabId;
			tabs.push(<Tab key={child.props.tabId} selected={selected} 
				column={i} onClick={(e) => this.onTabClick(child.props.tabId)}>
				<TabIcon fixedWidth icon={child.props.icon}></TabIcon>
			</Tab>)
			if (this.state.selectedTabId != null && this.state.selectedTabId == child.props.tabId) {
				selectedComponent = child;
			}
		});
		var showTabs = tabs.length > 1;

		return <Sidebar className={this.props.className} title={this.props.title}>
			{showTabs && <TabBar>
				{tabs}
			</TabBar>}
			{selectedComponent}
		</Sidebar>;
	}
}

export default SidebarCurtain;
