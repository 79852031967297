import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.tr`

	${props => {
		const theme = props.theme;
		let background = theme.colors.background;
		let border = `2px solid ${theme.colors.divider}`;
		if (props.toggled || props.hovering) {
			background = theme.colors.divider;
			if (props.hovering && props.toggled) {
				background = "none";
			}
			if (!props.expanded) {
				border = "none";
			}
		}
		return css`
			background: ${background};a
			border-bottom: ${border};
		`;
	}}

`;

const ExpandedColumn = styled.td`

`;

/**
 * Row for a DataTable.
 * @property {Array(DataTableColumn)}	children			-	Set of columns representing the table cells
 * @property {string}						key				-	Row key
 * @property {React.Component}			expandedView	-	Expanded view if you click on the row
 */
class DataTableRow extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hovering: false
		};
		this.isHovering = this.isHovering.bind(this);
		this.isNotHovering = this.isNotHovering.bind(this);
		this.toggleExpandedView = this.toggleExpandedView.bind(this);
	}

	isHovering() {
		if (this.props.expandedView && this.props.onRowClick) {
			this.setState({hovering: true});
		}
	}

	isNotHovering() {
		if (this.props.expandedView && this.props.onRowClick) {
			this.setState({hovering: false});
		}
	}

	toggleExpandedView() {
		if (this.props.expandedView && this.props.onRowClick) {
			this.props.onRowClick(this.props.rowKey);
		}
	}

	render() {
		// Setting column width on columns
		let kids = [];
		if (Array.isArray(this.props.children)) {
			kids = this.props.children;
		} else {
			kids = [this.props.children];
		}
		const columns = kids.map(c => {
			if (!c) return;
			const matchingColumn = this.props.columns.find(l => l.key === c.key);
			return React.cloneElement(c, {
				...c.props,
				width: matchingColumn.width,
				align: matchingColumn.align
			});
		});		

		return <React.Fragment>
			<Container className={this.props.className} toggled={this.props.expanded} hovering={this.state.hovering}
				onClick={this.toggleExpandedView} onMouseEnter={this.isHovering} onMouseLeave={this.isNotHovering}>
				{columns}
			</Container>
			{
				(this.props.expandedView && this.props.expanded) &&
				<Container expanded={true} toggled={true} hovering={this.state.hovering} onClick={this.toggleExpandedView} 
					onMouseEnter={this.isHovering} onMouseLeave={this.isNotHovering}>
					<ExpandedColumn colSpan={columns.length}>
						{this.props.expandedView}
					</ExpandedColumn>
				</Container>
			}
		</React.Fragment>;
	}
}

export default DataTableRow;