import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FieldError = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			margin-top: ${theme.margin.half};
			margin-bottom: ${theme.margin.half};
			color: ${theme.colors.negative};

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
			}
		`;
	}}

`;

const FieldErrorIcon = styled(FontAwesomeIcon)`

	${props => {
		const theme = props.theme;
		return css`
			color: ${theme.colors.negative};
			font-size: 1.15em;
			margin: ${theme.padding.quarter} ${theme.margin.quarter} -${theme.padding.quarter} 0;
		`;
	}}
`;

/**
 * In most cases, you should rely on the `Field` component's built-in error
 * rendering. However, if you don't have a `Field` component to apply the errors
 * to, then this will provide you with a substitute. Apply validations to it the
 * same way you would apply them to a `Field` component.
 */
export default class ValidationMessage extends React.Component {

	render() {

		let errorMessages = null;
		if (this.props.validation) {
			const validations = Array.isArray(this.props.validation)
				? this.props.validation
				: [this.props.validation];
				
			// Merge server side error messages in
			validations.forEach((v, i) => {
				if (v.message && v.validation) {
					validations[i] = {
						...v.validation,
						message: v.message
					};
				}
			});

			const errors = validations.filter(v => v.isNotValid);
			errorMessages = errors.map(v => <li key={v.key}>
				<FieldErrorIcon icon="exclamation-circle"></FieldErrorIcon> 
				{v.message}
			</li>);
		}

		if (!errorMessages) return <React.Fragment></React.Fragment>;

		return <FieldError>
			<ul>{errorMessages}</ul>
		</FieldError>;
	}
}