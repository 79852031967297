import React from 'react';
import { styled, Paper, Button, MobileStepper, Box } from '@material-ui/core';

const StepperStyle = styled(MobileStepper)({
	background: "none",
	width: "400px",
});

const Center = styled(Paper)({
	display: "flex",
	justifyContent: "center",
	width: "100%",
	position: "fixed",
	left: "0",
	bottom: "0",
	zIndex: "2",
    boxShadow: "0px 0px 7px 0px rgba(0,0,0,.4)"
});


class FixedBottomStepper extends React.Component {
	constructor(props) {
		super(props);

		this.handleNextClick = this.handleNextClick.bind(this);
		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleConfirmClick = this.handleConfirmClick.bind(this);
	}

	handleNextClick() {
		if (this.props.onNextClick) {
			this.props.onNextClick();
		}
	}

	handleBackClick() {
		if (this.props.onBackClick) {
			this.props.onBackClick();
		}
	}

	handleConfirmClick() {
		if (this.props.onConfirmClick) {
			this.props.onConfirmClick();
		}
	}

	render() {
		let steps = this.props.steps ? Number(this.props.steps) : 1;
		let activeStep = this.props.activeStep ? this.props.activeStep : 1;
		let showCustomBackButtonTextOnLastStepOnly = this.props.showCustomBackButtonTextOnLastStepOnly ? this.props.showCustomBackButtonTextOnLastStepOnly : false;
		let backButtonText = this.props.backButtonText && (!showCustomBackButtonTextOnLastStepOnly || (showCustomBackButtonTextOnLastStepOnly && activeStep === steps)) ? this.props.backButtonText : "Back";
		let nextButtonText = this.props.nextButtonText ? this.props.nextButtonText : "Next";
		let confirmButtonText = this.props.confirmButtonText ? this.props.confirmButtonText : "Confirm";

		return <Center square >
				<StepperStyle
					steps={steps}
					variant="dots"
					activeStep={activeStep - 1}
					position="static"					
					nextButton={
						activeStep !== steps ?
							<Button size="small" variant="contained" color="primary" onClick={this.handleNextClick} disabled={this.props.nextButtonDisabled} >{nextButtonText}</Button> :
							<Button size="small" variant="contained" color="primary" onClick={this.handleConfirmClick} >{confirmButtonText}</Button>
					}
					backButton={
						<Button size="small" variant="contained" color="primary" onClick={this.handleBackClick} disabled={this.props.backButtonDisabled}>{backButtonText}</Button>
					}
				/>
			</Center>;
	}
}

export default FixedBottomStepper;