import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import {DarkTheme} from '../core/Themes.js';
import DialingCampaignList from './DialingCampaignList';
import AgentList from './AgentList.jsx';

const Container = styled.div`
	${props => {
		const theme = props.theme;
		return css`

			width: calc(100vw - ${theme.margin.quad});
			height: calc(100vh - ${theme.margin.double});
			overflow-y: scroll;
			margin: 0;
			padding: ${theme.margin.double};

			background: ${theme.colors.background};
			color: ${theme.colors.text};

			display: grid;
			grid-template-columns: 1fr;
			grid-template-areas: "campaigns"
				"statistics"
				"agents";
				
			@media screen and (min-width: ${minWidthOfCards * 2}px){
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto 1fr;
				grid-template-areas: "statistics agents"
					"campaigns agents";
			}

			h1, h2 {
				color: ${theme.colors.headingText};
				text-transform: uppercase;
				letter-spacing: ${theme.padding.full};
				font-weight: 300;
			}
		
		`;
	}}	
`;

const minWidthOfCards = 800; //px

const Section = styled.section`
	${props => {
		const theme = props.theme;
		return css`

			margin-right: -${theme.margin.double};
			@media screen and (min-width: ${minWidthOfCards * 2}px){
				margin-right: ${theme.margin.double};
			}
			margin-bottom: ${theme.margin.double};
		
			grid-area: ${props.gridAreaKey};
			
		`;
	}}	
`;

class CommandCenter extends React.Component {

	generateUUID() { // Public Domain/MIT
		var d = new Date().getTime();//Timestamp
		var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16;//random number between 0 and 16
			if (d > 0) {//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

	render() {
		var tabKey = this.generateUUID();
		return <ThemeProvider theme={DarkTheme}>
			<Container className={this.props.className}>
				<Section gridAreaKey="campaigns">
					<h2>Dialing Campaigns</h2>
					<DialingCampaignList></DialingCampaignList>
				</Section>
				<Section gridAreaKey="agents">
					<h2>Agents</h2>
					<AgentList tabKey={tabKey} />
				</Section>
			</Container >
		</ThemeProvider>;
	}
}

export default CommandCenter;
