import React from 'react';
import styled, { css } from "styled-components";

import TabDefinitions from '../home/TabDefinitions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Tabs';

import Card from '../core/Card/Card.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingIndicator from '../core/LoadingIndicator/LoadingIndicator.jsx';

const NewTabCard = styled(Card)`

	${props => {
		const theme = props.theme;
		return css`
			width: 400px;
			margin: 0 ${theme.margin.half} ${theme.margin.full};
			display: inline-grid;			
			grid-template-areas: "label button";
			grid-template-columns: 1fr auto;
			align-items: center;
		`;
	}}

`;

const NewTabLabel = styled.div`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: label;
			text-align: left;
			padding-left: ${theme.padding.full};
			p {
				margin: 0;
			}
		`;
	}}
`;

const NewTabSet = styled.section`

	text-align: center;
	overflow: hidden;

`;

const NewTabIcon = styled.div`
	${props => {
		const theme = props.theme;
		return css`
			background: ${props.background || theme.button.background};
			color: ${props.color || theme.button.text};
			border-radius: 999px;
			margin-right: -${theme.padding.double};
			padding: ${theme.padding.double};
			font-size: 2.5em;
			width: calc(1em + (${theme.padding.double} * 2));
		`;
	}}
`;

const Loader = styled(LoadingIndicator)`
	height: 100%;
	color: ${props => props.theme.colors.border};
`;

class NewTabItems extends React.Component {

	constructor(props) {
		super(props);
		this.launchTab = this.launchTab.bind(this);
	}

	launchTab(evt, type) {
		// Check to see if this is a single instance tab type.  Bring focus to existing tab if one is already open
		var singleInstanceTabTypes = this.props.tabs.filter(t => t.singleInstance && t.type == type);

		if (singleInstanceTabTypes.length > 0) {
			this.props.tabSelectionChanged({ tabId: singleInstanceTabTypes[0].key });
			return;
		}

		// Replace this tab state with new state
		var newTabState = TabDefinitions.InitializeTab(type, this.props.tabKey);

		this.props.tabStateChanged(this.props.tabKey, {
			...newTabState,
			selected: true
		});
	}

	render() {
		//if (!this.props.hasLoadedAgent) {
		//	return <Loader />;
		//}
		var tabs = TabDefinitions.types;
		return <NewTabSet>
			{this.props.online && <NewTabCard onClick={e => this.launchTab(e, tabs.dialerSession)}>
				<NewTabLabel>
					<h3>Log In</h3>
					<p>Log into the Encore voice system</p>
				</NewTabLabel>			
				<NewTabIcon background="#ccd7c6" color="#463">
					<FontAwesomeIcon fixedWidth icon="phone" />
				</NewTabIcon>
			</NewTabCard>}

			{
				(this.props.online && this.props.dialPadAccessPermission >= 1) &&
				<NewTabCard onClick={e => this.launchTab(e, tabs.dialPad)}>
					<NewTabLabel>
						<h3>Dial Pad</h3>
						<p>Make manual or agent-to-agent calls</p>
					</NewTabLabel>
					<NewTabIcon background="#27a2dd" color="#2e3192">
						<FontAwesomeIcon fixedWidth icon="phone-office" />
					</NewTabIcon>
				</NewTabCard>
			}

			{this.props.online && this.props.voicemailAccessPermission >= 1 && <NewTabCard onClick={e => this.launchTab(e, tabs.voicemail)}>
				<NewTabLabel>
					<h3>Voicemail</h3>
					<p>Display a list of Voicemails</p>
				</NewTabLabel>
				<NewTabIcon>
					<FontAwesomeIcon fixedWidth icon="voicemail" />
				</NewTabIcon>
			</NewTabCard>}

			<NewTabCard onClick={e => this.launchTab(e, tabs.settings)}>	
				<NewTabLabel>
					<h3>Settings</h3>
					<p>Select headset &amp; microphone</p>
				</NewTabLabel>			
				<NewTabIcon background="#ccced3" color="#444444">
					<FontAwesomeIcon fixedWidth icon="tools" />
				</NewTabIcon>
			</NewTabCard>
			{
				(this.props.online && this.props.monitoringPermission >= 1) &&
				<NewTabCard onClick={e => this.launchTab(e, tabs.monitoring)}>	
					<NewTabLabel>
						<h3>Monitoring</h3>
						<p>Live overview of active agents</p>
					</NewTabLabel>			
					<NewTabIcon background="#ccd6ee" color="#003399">
						<FontAwesomeIcon fixedWidth icon="users" />
					</NewTabIcon>
				</NewTabCard>
			}

		</NewTabSet>;
	}

}

export default connect(state => ({ ...state.agent, ...state.tabs }),
	dispatch => bindActionCreators(actionCreators, dispatch)
)(NewTabItems)
				