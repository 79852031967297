import React from 'react';
import Notification from '../core/Notification/Notification';
import styled from 'styled-components';

const Container = styled(Notification)`
`;

// Styling the <p> tag in the container CSS does not work for some reason
const Para = styled.p`
	margin-top: 0;
	margin-bottom: ${props => props.theme.margin.half};
`;

export default class NotLicensedNotification extends React.Component {
	
	render() {
		return <Container title="Access Restricted" negative icon={['fa', 'exclamation-triangle']}>
			<Para>
				Cannot load Encore.
			</Para>
			<Para>
				{this.props.data}
			</Para>
		</Container>;
	}
}