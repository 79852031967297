export function getDistinctGroupListFromRuleList(ruleList) {
	if (!ruleList) {
		return [];
	}

	return ruleList.map(item => item.group)
		.filter((value, index, self) => self.indexOf(value) === index)
}

export function getDistinctGroupListFromTestResultList(resultList) {
	if (!resultList) {
		return [];
	}

	return resultList.map(item => item.ruleGroup)
		.filter((value, index, self) => self.indexOf(value) === index)
}