import React from 'react';
import styled, { css, withTheme } from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Indicator = styled.div`
	${props => {
		const theme = props.theme;
		var alphaLevel = props.light ? 0.6 : 0.2;
		return css`
			background: ${props.color.alpha(alphaLevel).hsl().string()};
			color: ${props.light ? props.color.darken(0.5).hex() : props.color.hex()};
			font-family: ${theme.fonts.heading};
			font-weight: 500;
			font-size: .9em;
			/* text-transform: uppercase; */

			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			height: 100%;
			border-radius: 99px;		
		`;
	}}
`;

/**
 * A special kind of badge that takes in a workTaskType, and displays a
 * user-friendly representation with a standard color code.
 *  
 * @property {number}			workTaskType			-	Number from the API
 * 	representing the current type of work task
 */
class TypeIndicator extends React.Component {

	render() {
		var colors = this.props.theme.palettes.workType;
		var options = {};

		switch (this.props.workTaskType) {
			// Undefined = 0,
			// RightClickDial = 1,
			// PredictiveDial = 2,
			// MessageDial =3,
			// Inbound = 4,
			// Transfer = 5
			// AgentToAgent = 9
			// ManualDial = 10
			case 1:
				options.color = colors.rightClick;
				options.text = "Right Click";
				break;
			case 2:
				options.color = colors.predictive;
				options.text = "Outbound";
				break;
			case 3:
				options.color = colors.message;
				options.text = "Message";
				break;
			case 4:
				options.color = colors.inbound;
				options.text = "Inbound";
				break;
			case 5:
				options.color = colors.transfer;
				options.text = "Transfer";
				break;
			case 9:
				options.color = colors.transfer;
				options.text = "Agent To Agent";
				break;
			case 10:
				options.color = colors.predictive;
				options.text = "Manual Dial";
				break;
		}

		if (!options.color && !options.text) {
			return <div></div>;
		}

		var indicator = this.props.className
			? <div className={this.props.className} color={options.color}>{options.text}</div>
			: <Indicator color={options.color}>{options.text}</Indicator>;

		return <React.Fragment>
			{indicator}
		</React.Fragment>;
	}

}

export default withTheme(TypeIndicator);