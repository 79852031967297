import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './Sidebar.jsx';

const Heading = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			margin-top: ${theme.margin.half};
			padding: ${theme.margin.full} ${theme.margin.full} ${theme.margin.half};
			font-size: 1em;
			font-family: ${theme.fonts.heading};
			font-weight: 500;
			text-transform: uppercase;
			color: ${theme.colors.secondary};
		`;
	}} 

`;

const NavList = styled.nav`

	${props => {
		const theme = props.theme;

		return css`

			margin-top: ${theme.margin.full};

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}
		`;
	}}

`;

const NavListLink = styled(NavLink)`

	${props => {
		const theme = props.theme;

		return css`
			display: block;	
			cursor: default;				
			padding: ${theme.margin.half} ${theme.margin.full};
			margin-right: ${theme.margin.full};
			margin-bottom: ${theme.margin.half};
			text-decoration: none;
			font-family: ${theme.fonts.heading};
			font-weight: 300;
			font-size: 1.05em;
			color: ${theme.colors.secondary};
			border-top-right-radius: 999px;
			border-bottom-right-radius: 999px;
			box-shadow: inset 0 0 4px 0 transparent; /* makes transition smoother */

			transition: background-color ease-in-out .05s, 
				color ease-in-out .05s, 
				box-shadow ease-in-out .05s,
				font-weight ease-in-out .05s;

			&.isSelected {				
				background: ${theme.selection.background};
				box-shadow: ${theme.button.shadow};
				font-weight: 500;
				color: ${theme.colors.text};
			}

			&:hover {
				box-shadow: ${theme.button.shadow};
				background: ${theme.button.primary.hover.background};
				color: ${theme.button.primary.hover.text || theme.button.primary.text};
			}		
		`;
	}}

`;

const NavListItemIcon = styled(FontAwesomeIcon)`
	margin-right: ${props => props.theme.margin.half};
`;

/**
 * Sidebar element containing navigation to a set of destinations.
 * 
 * @property {string}			title					-	Title of view, displayed at the
 * 	top of the sidebar.
 * @property {object}			[links]					-	List of sidebar links to display
 * @property {object}			links.icon				-	FontAwesome icon to display to illustrate link
 * @property {string}			links.key				-	React requires a unique key for
 * 	mapped items
 * @property {object}			links.name				-	Label to display for link
 * @property {object}			links.to					-	React-Router destination route
 */
class SidebarNavigation extends React.Component {

	render() {
		var hasLinks = this.props.links && this.props.links.length > 0;

		var links;
		if (hasLinks) {
			links = this.props.links.map(l => {
				if (l.to == null) { /* Route required */
					return;
				}
				return <li key={l.key}>
					<NavListLink activeClassName="isSelected" to={l.to}>
						{l.icon && <NavListItemIcon fixedWidth icon={l.icon}></NavListItemIcon>}
						{l.name}
					</NavListLink>
				</li>;
			});
		}

		return <Sidebar>
			<Heading>{this.props.title}</Heading>
			<NavList>
				<ul>
					{links}
				</ul>
			</NavList>
		</Sidebar>;
	}
}

export default withRouter(SidebarNavigation);
