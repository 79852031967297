import React from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { withStyles } from '@material-ui/core/styles';
import TabDefinitions from './home/TabDefinitions';
import { actionCreators } from './../store/Tabs';
import { bindActionCreators } from 'redux';
import { IconButton } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
const styles = theme => {
	return {
		root: {
			marginTop: '10px',
			padding: "4px"
		},
		badgeBackground: {
			//borderRadius: '50%',
			//padding: '5px',
			backgroundColor: '#FAFBFD',
		}
	}
};


class VoicemailNotification extends React.Component {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {

	}

	launchTab(evt) {
		var vmTab = this.props.tabs.find(x => x.type === 'voicemail');
		if (vmTab) {
			this.props.tabSelectionChanged({ tabId: vmTab.key });
		}
		else {
			var newTabState = TabDefinitions.InitializeTab(TabDefinitions.types.voicemail);
			this.props.tabCreated(newTabState);
		}
	}

	render() {
		const { classes } = this.props;
		var myVoicemails = this.props.filteredVoicemails.filter(x => !x.lastReviewedAgentId).length;
		return <div className={classes.root} >
			<Fade in={myVoicemails > 0}>
				<IconButton size="small" className={classes.badgeBackground} onClick={e => this.launchTab(e)} >
					<Badge badgeContent={myVoicemails} color="secondary">
						<VoicemailIcon />
					</Badge>
				</IconButton>
			</Fade>
		</div>

	}
}


export default withStyles(styles)(connect(state => {
	return {
		...state.voicemails,
		...state.tabs
	};
}, dispatch => bindActionCreators(actionCreators, dispatch)
)(VoicemailNotification));
