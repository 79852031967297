import React from 'react';
import Notification from '../core/Notification/Notification';
import styled from "styled-components";
import RoundButton from '../core/RoundButton/RoundButton';

const Container = styled(Notification)`

`;

// Styling the <p> tag in the container CSS does not work for some reason
const Para = styled.p`

	margin-top: 0;
	margin-bottom: ${props => props.theme.margin.half};

`;

const Actions = styled.div`

	text-align: right;
	margin-top: ${props => props.theme.margin.half};

`;

export default class ConfirmChoiceNotification extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {		
		this.props.onConfirm(e);
		this.props.onDismiss(e);
	}

	render() {
		return <Container title={this.props.title} negative icon={this.props.icon}>
			<Para>
				{this.props.body}
			</Para>
			<Actions>
				<RoundButton icon={["fa", "check"]} onClick={this.handleClick}>
					Ok
				</RoundButton>
				<RoundButton icon={["far", "times"]} onClick={e => this.props.onDismiss(e)}>
					Cancel
				</RoundButton>
			</Actions>
		</Container>
	}

}