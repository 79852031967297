import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = styled(FontAwesomeIcon)`

	font-size: 1.4em;
	margin-bottom: -.1em;
	margin-right: ${props => props.theme.margin.quarter};

`;

const Container = styled.a`

	${props => {
		const theme = props.theme;
		var toggledColor = theme.baseColors.brandHighlight;
		if (props.toggledColor) {
			if(typeof props.toggledColor.alpha === 'function') {
				toggledColor = props.toggledColor;
			} else {
				console.error("Must use a baseColor with alpha and hsl support");
			}
		}
		var color = props.toggled ? toggledColor : theme.baseColors.text;
		var opacity = props.toggled ? 1 : 0.4;
		var shadowColor = color.alpha(0.2).hsl().string();
		return css`

			font-weight: 500;
			color: ${color.alpha(opacity).hsl().string()};
			text-shadow: 0 1px 6px ${shadowColor};

			padding: 6px 8px;
			border-radius: 99px;

			transition: color ease-in-out .05s, 
				background-color ease-in-out .05s, 
				text-shadow ease-in-out .05s;

			&:hover {
				color: ${color.alpha(0.75).hsl().string()};
				background: ${theme.button.background};
			}

			${Icon} {
				filter: drop-shadow(0 1px 6px ${shadowColor});
			}

		`;
	}}	

`;

/**
 * Like a checkbox or the `Toggle` component, but instead it's a label that
 * lights up or is dimmed when toggled/untoggled. Used so far for the state
 * well in the phone control.
 * 
 * @property {boolean}	toggled					- True if checked/toggled
 * @property {Color}		[toggledColor]			- Color of toggle label when lit. Must be a 
 * 	Color object, not a string.
 * @property {function}	onChange					- Callback when the toggle state is changed
 * @property {object}	[icon]					-	FontAwesome icon to add to left of label
 */
export default class ToggleLabel extends React.Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(evt) {
		if (this.props.onChange) {
			this.props.onChange(evt);
		}
	}

	render() {
		var sharedProps = {
			toggled: this.props.checked,
			toggledColor: this.props.toggledColor
		}

		return <Container {...sharedProps} className={this.props.className}
			onClick={this.handleChange} canInteract={this.props.onChange != undefined}>	
			{this.props.icon &&
				<Icon icon={this.props.icon} />
			}
			{this.props.children}
		</Container>;
	}
}