import React from 'react';
import styled, { css } from "styled-components";
import RoundButton from '../core/RoundButton/RoundButton';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import SplitButton from '../core/SplitButton/SplitButton';

const styles = theme => ({
	root: {
	},
	consentIconContainer: {
		display: "inline-block",
		textAlign: "center",
		marginRight: 10,
	},
	icon: {
		marginRight: 10,
		fontSize: 14,
		"&:hover": {

		}
	},
});

const StandardViewHeader = styled.div`
		
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas: "heading actions";
	margin-bottom: ${props => props.theme.margin.full};

	h1 {
		grid-area: heading;
	}

`;

const StandardViewHeaderActions = styled.div`

	grid-area: actions;

`;

class ViewHeader extends React.Component {
	constructor(props) {
		super(props);

		this.handleJumpTo = this.handleJumpTo.bind(this);
	}

	getActiveTask() {
		return this.props.currentWorkTasks && this.props.currentWorkTasks.length > 0
			? this.props.currentWorkTasks.find(x => x.blocking)
			: null;
	}

	handleJumpTo(actionIndex) {
		if (actionIndex == 0) {
			this.loadDebtorScreen();
		}
		if (actionIndex == 1) {
			this.loadContactTrackerEditPhoneNumber();
		}
		if (actionIndex == 2) {
			this.loadContactTrackerConsumerConsent();
		}
	}

	loadDebtorScreen() {
		if (window.bloodhound && window.bloodhound.loadConsumerScreen) {
			console.log('Invoking loadConsumerScreen back to bloodhound', this.props.tabKey);
			window.bloodhound.loadConsumerScreen(this.props.tabKey);
		}
	}

	loadContactTrackerEditPhoneNumber() {
		var activeTask = this.getActiveTask();
		if (window.bloodhound && window.bloodhound.loadContactTrackerEditPhoneNumber) {
			console.log('Invoking loadContactTrackerEditPhoneNumber back to bloodhound', this.props.tabKey);
			window.bloodhound.loadContactTrackerEditPhoneNumber(this.props.tabKey, activeTask ? activeTask.phoneNumber : '');
		}
	}

	loadContactTrackerConsumerConsent() {
		if (window.bloodhound && window.bloodhound.loadContactTrackerConsumerConsent) {
			console.log('Invoking loadContactTrackerConsumerConsent back to bloodhound', this.props.tabKey);
			window.bloodhound.loadContactTrackerConsumerConsent(this.props.tabKey);
		}
	}

	render() {
		var activeTask = this.getActiveTask();
		var showJumpToButton = (activeTask ? activeTask.workTaskType !== 9 && activeTask.workTaskType !== 10 ? true : false : true);

		var ctEnabled = activeTask && activeTask.consumerId;
		var jumpToOptions = [{ label: 'Show Consumer', enabled: true }, { label: 'Edit Phone Number', enabled: ctEnabled }, { label: 'Consumer Consent', enabled: ctEnabled }];

		return <StandardViewHeader>
			<h1>{this.props.label}</h1>

			{this.props.debtorLabel && <h4>{this.props.debtorLabel}</h4>}
			<StandardViewHeaderActions>
				{
					this.props.showActionButtons && showJumpToButton && <div style={{ display: "inline-block", marginRight: 10 }}><SplitButton onClick={this.handleJumpTo} options={jumpToOptions}/></div>
				}
				{
					this.props.onLogoutRequest &&
					<RoundButton disabled={this.props.preventLogout} icon="sign-out" 
						onClick={this.props.onLogoutRequest}>
						{this.props.logoutLabel || "Request Logout"}
					</RoundButton> 
				}
			</StandardViewHeaderActions>
		</StandardViewHeader>;
	}
}

export default withStyles(styles)(connect(state => ({ ...state.agent }))(ViewHeader));
