import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Agent';

class AgentStatus extends React.Component {
	constructor(props) {
		super(props);
		this.agentStatusChanged = this.agentStatusChanged.bind(this);
	}

	async componentDidMount() {
	}

	async componentWillUnmount() {
	}

	async agentStatusChanged(e) {
		//do not use target value directly past await.  causes issues with synthetic eventing.
		var newValue = e.target.value;
		this.props.updateMyState(newValue);
	}

	render() {
		return <div className={this.props.className}>
			<select value={this.props.agentState} onChange={ this.agentStatusChanged }>
				<option value="0">Available</option>
				<option value="1">Standby</option>
				<option value="2">Away</option>
			</select>
		</div>;
	}
}

export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(AgentStatus);
