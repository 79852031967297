export const types = {
	phoneConnected: 'PHONE_CONNECTED',
	transferUpdated: 'TRANSFER_UPDATED',
	transferReset: 'TRANSFER_RESET',
	setTransfer: 'SET_TRANSFER',
	setTransferResponse: 'SET_TRANSFER_RESPONSE'
};

const initialState = {
	transfers: [],
	transferring: false,
	transferRejected: false,
	transferResponse: null,
	agentId: null
};

export const actionCreators = {
	resetTransfer: state => ({ type: types.transferReset, data: state }),
	setTransferring: state => ({ type: types.setTransfer, data: state }),
	setTransferResponse: state => ({ type: types.setTransferResponse, data: state })
};

export const reducer = (state, action) => {
	state = state || initialState;
	var newState;
	var clonedTransfers = state.transfers.slice(0);

	if (action.type === types.phoneConnected) {
		return { ...state, agentId: action.data.id, transferRejected: false };
	}
	else if (action.type === types.transferUpdated) {
		//console.log('DATA: ', action.data, state)

		if (window.bloodhound && window.bloodhound.taskStarted && state.agentId && action.data.transferredFromAgentId === state.agentId && action.data.rejected) {
			newState = { ...state, transferRejected: true, transferring: false };
			var existingTransferIndex = clonedTransfers.findIndex(ele => ele.id === action.data.id);
			clonedTransfers.splice(existingTransferIndex, 1);
			return newState;
		}
	}
	else if (action.type === types.transferReset) {
		var transfer = { ...state, transferRejected: action.data };
		return transfer;
	}
	else if (action.type === types.setTransfer) {
		var transfer = { ...state, transferring: action.data };
		return transfer;
	}
	else if (action.type === types.setTransferResponse) {
		var transfer = { ...state, transferResponse: action.data };
		return transfer;
	}
	return state;
};
