import React from 'react';
import HttpAgent from '../../../httpagent.js';
import RuleList from "./RuleList";
import { Box, TextField, Snackbar, Grid } from '@material-ui/core';
import SelectField from '../../_shared-components/core/SelectField';
import MuiAlert from '@material-ui/lab/Alert';
import { getDistinctGroupListFromRuleList } from '../_utilities/RuleHelpers';
import LoadingDataSpinner from '../../_shared-components/core/LoadingDataSpinner';

class ComplianceCurrentRules extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchText: '',
			filterBy: '',
			sortSearchBy: '',
			currentRuleList: [],
			filterOptions: [
				{ value: 'all', label: 'All' },
				{ value: 'enabled', label: 'Enabled' },
				{ value: 'disabled', label: 'Disabled' },
			],
			sortOptions: [
				{ value: 'Latest', label: 'Latest' },
				{ value: 'Oldest', label: 'Oldest' },
				{ value: 'A-Z', label: 'A-Z' },
			],
			showSnackbar: false,
			snackbarSeverity: 'error',
			snackbarMessage: '',
			loading: true
		}

		this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
		this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
		this.handleSortFieldChange = this.handleSortFieldChange.bind(this);
		this.onRuleChanged = this.onRuleChanged.bind(this);
		this.closeSnackbar = this.closeSnackbar.bind(this);
	}

	closeSnackbar() {
		this.setState({
			showSnackbar: false
		});
	}

	async componentDidMount() {
		await this.getCurrentRules();
	}

	handleSearchFieldChange(e) {
		this.setState({
			searchText: e.target.value
		});
	}

	handleSortFieldChange(e) {
		this.setState({
			sortSearchBy: e.target.value
		});
	}

	handleFilterFieldChange(e) {
		this.setState({
			filterBy: e.target.value
		});
	}

	onRuleChanged(rule) {
		if (rule && this.state.currentRuleList) {
			var ruleList = [...this.state.currentRuleList];
			var index = ruleList.findIndex(v => v.rootId === rule.rootId);
			if (index > -1) {
				ruleList[index] = rule;
				this.setState({
					currentRuleList: ruleList,
				});
			}
		}
	}

	async getCurrentRules() {
		try {
			var response = await HttpAgent.getCurrentComplianceRules();
			var ruleList = response.data;
			var ruleGroups = getDistinctGroupListFromRuleList(ruleList);

			this.setState({
				currentRuleList: ruleList,
				loading: false
			});

			if (this.props.onRulesLoaded) {
				this.props.onRulesLoaded(ruleList, ruleGroups);
			}
		}
		catch (ex) {
			this.setState({
				showSnackbar: true,
				snackbarMessage: "There was an error trying to retrieve the compliance rules from the system.",
				loading: false
			});
		}
	}

	render() {
		return <Box>
			<Grid spacing={1} container>
				<Grid sm={12} md={8} item>
					<TextField
						style={{maxWidth:"600px"}}
						fullWidth
						id="search"
						label="Search Rules"
						value={this.state.searchText}
						onChange={this.handleSearchFieldChange}
						onKeyDown={this.handleSearchKeyDown} />
				</Grid>
				<Grid sm={12} md={2} item container justifyContent="flex-end">
					<SelectField formProps={{
						fullWidth: true,
						variant: "outlined"
					}} label="Filter" value={this.state.filterBy} onChange={this.handleFilterFieldChange} options={this.state.filterOptions} />
				</Grid>
				<Grid sm={12} md={2} item container justifyContent="flex-end">
					<SelectField formProps={{
						fullWidth: true,
						variant: "outlined"
					}} label="Sort Order" value={this.state.sortSearchBy} onChange={this.handleSortFieldChange} options={this.state.sortOptions} />
				</Grid>
			</Grid>

			{
				this.state.loading ? <LoadingDataSpinner message="Loading Compliance Rules..." gridProps={{ style: { marginTop: '20%', fontStyle: 'italic' } }} /> :
					<RuleList rules={this.state.currentRuleList}
						searchText={this.state.searchText}
						sortBy={this.state.sortSearchBy}
						filterBy={this.state.filterBy}
						onRuleChanged={this.onRuleChanged} />
			}

			<Snackbar open={this.state.showSnackbar} autoHideDuration={6000} onClose={this.closeSnackbar}>
				<MuiAlert elevation={6} variant="filled" onClose={this.closeSnackbar} severity={this.state.snackbarSeverity}>
					{this.state.snackbarMessage}
				</MuiAlert>
			</Snackbar>

		</Box >;
	}
}

export default ComplianceCurrentRules;