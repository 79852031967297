import React from 'react';
import DeviceSelector from '../settings/DeviceSelector';
import styled from "styled-components";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Tabs';

const Selector = styled(DeviceSelector)`

	margin-top: ${props => props.theme.margin.half};
	margin-bottom: ${props => props.theme.margin.full};

`;

class SettingsView extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <React.Fragment>
			<h3>Audio Input</h3>
			<Selector input />
			<h3>Audio Output</h3>
			<Selector output />
		</React.Fragment>;
	}

}

export default connect(null,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(SettingsView)