import React from 'react';
import { Box, Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export default class MultiSelectField extends React.Component {
	getMatchingItems(options, values) {
        var items = options.filter(x => values === x.value || (Array.isArray(values) && values.some(z => z.value === x.value || z === x.value)));
		return items;
	}

	render() {
		let { textFieldProps, ...props } = this.props;
        if (props.defaultValue) {
			props.defaultValue = this.getMatchingItems(props.options, props.defaultValue);
		}

		if (props.value) {
            props.value = this.getMatchingItems(props.options, props.value);
        }

		if (props.onChange) {
			props.onChange = (e, values, reason) => {
                this.props.onChange(e, values, reason, props.name);
            }
		}

		return <Box mr={ 0.5 }>
			<Autocomplete
			{...props}
			multiple
			disableCloseOnSelect
            getOptionLabel={(option) => option.label}
			renderOption={(option, { selected }) => (
				<React.Fragment>
					<Checkbox style={{ marginRight: 8 }} checked={selected} />
					{option.label}
				</React.Fragment>
			)}
			renderInput={(params) => (
				<TextField name={props.name} {...params} {...textFieldProps} label={props.label} />
			)}
			/>
		</Box>;
	}
};