import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css, ThemeProvider } from 'styled-components';
import { UpperLayer } from '../../core/Themes.js';
import ConfigurePage from '../ConfigurePage.jsx';
import DataTable from '../../core/DataTable/DataTable.jsx';
import DataTableRow from '../../core/DataTable/DataTableRow.jsx';
import DataTableColumn from '../../core/DataTable/DataTableColumn.jsx';
import StatusIndicator from '../../core/StatusIndicator/StatusIndicator.jsx';
import RoundButton from '../../core/RoundButton/RoundButton.jsx';
import LoadingIndicator from '../../core/LoadingIndicator/LoadingIndicator.jsx';
import Tag from '../../core/Tag/Tag.jsx';
import Fuse from 'fuse.js';
import { actionCreators } from '../../../store/Agents';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HttpAgent from '../../../httpagent.js';
import { actionCreators as notificationActionCreators } from '../../../store/Notifications';
import NotificationDefinitions from '../../notifications/NotificationDefinitions';

const PersonNameArea = styled.div`

	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas:
		"status name"
		"status details";
	align-items: center;

`;

const PersonNameText = styled.span`

	font-size: 1.2em;
	grid-area: name;

`;

const PersonStatus = styled(StatusIndicator)`

	grid-area: status;
	margin-right: ${props => props.theme.margin.half};

`;

const Loader = styled(LoadingIndicator)`
	height: 100%;
	color: ${props => props.theme.colors.border};
`;

const DataView = styled.div`
	height: 100%;
	background: ${props => props.theme.colors.background};

	${props => {
		if (props.loading) return css`
		
			border-top: 2px solid ${props.theme.colors.border};
		
		`;
	}}
`;

const LicenseIcon = styled.span`
	
	margin-left: ${props => props.theme.margin.half};

	svg {
		margin: 0 .1rem;
	}
`;

class AgentList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			query: ''
		};
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	sortAgents(agents) {
		// Do not sort if searching
		if (this.state.query || this.state.query !== '') {
			return agents;
		}
		// Remove null agents (only happens in dev, theoretically)
		const unsorted = agents.filter(d => d.personCode != null);
		// Sort alphabetically
		return unsorted.sort((a, b) => {
			const aName = a.name || a.personCode;
			const bName = b.name || b.personCode;
			return aName.localeCompare(bName);
		});
	}

	filterAgents() {
		if (!this.state.query || this.state.query === '') {
			return this.props.agents;
		}

		// If starts with #, match beginning of attributes names only
		if (this.state.query.startsWith('#')) {
			return this.props.agents.filter(a => {
				if (a.attributes == null || a.attributes.length === 0) return false;

				return a.attributes.some(t => {
					const toMatch = this.state.query.substring(1).toLowerCase();
					const attributeName = t.name.replace(/\W/g, '').toLowerCase();
					return attributeName.startsWith(toMatch);
				});
			});
		}


		//Pre-filtering for system-defined keywords
		const searchTokens = this.state.query.split(/ +/g);

		let filteredAgents = JSON.parse(JSON.stringify(this.props.agents));
		const licensePos = searchTokens.indexOf('license');

		if (licensePos >= 0) {
			filteredAgents = filteredAgents.filter(x => {
				return x.encoreEnabled && !x.isAdminUser;
			});

			searchTokens.splice(licensePos, 1); //removes 'license' from tokenized terms
		}

		const supervisorPos = searchTokens.indexOf('supervisor');

		if (supervisorPos >= 0) {
			filteredAgents = filteredAgents.filter(x => {
				return x.encoreEnabled && x.monitoringPermission === 2 && !x.isAdminUser;
			});

			searchTokens.splice(supervisorPos, 1); //removes 'supervisor' from tokenized terms
		}

		const query = searchTokens.join(' ');

		if (!query) {
			return filteredAgents;
		}
		
		// Else do fuzzy match of all properties
		const options = {
			keys: [{
				name: 'personCode',
				weight: 0.5
			}, {
				name: 'attributes.name',
				weight: 0.2
			}, {
				name: 'name',
				weight: 0.7
			}],
			threshold: 0.2,
			location: 0
		};
		const fuse = new Fuse(filteredAgents, options);
		
		return fuse.search(query);
	}

	onSearchChange(event) {
		const newValue = event.target.value;
		this.setState({
			query: newValue
		});
	}

	handleLogOutAgentSession(agent) {
		if (agent) {			
			//// TODO: FIGURE OUT WHY NOTIFICATIONS DO NOT WORK OUTSIDE OF THE ENCORE HUB
			//this.props.createNotification(
			//	{
			//		hideDismissButton: true,
			//		type: NotificationDefinitions.types.confirmChoice,
			//		body: `Continue logging out agent: ${agent.personCode}?`,
			//		title: 'Confirm Unlock Session?',
			//		icon: ["fas", "sign-out"],
			//		onConfirm: async (evt) => {
			//			await HttpAgent.logOutAgentSession(agent);
			//		}
			//	});

			//if (window.confirm(`Continue logging out agent: ${agent.personCode}?`) == true) {
			agent.monitoringAgentId = 0;
			agent.monitoringAgentBloodhoundUserId = 0;
			agent.monitoringAgentPersonCode = "**";
			HttpAgent.logOutAgentSession(agent);
			//}
		}
	}

	render() {		
		const match = this.props.match;

		let pageContent;
		let hasSearchResults = null;
		const loading = !this.props.agents || this.props.agents.length === 0;		

		if (loading) {
			pageContent = <Loader />;
		} 
		else {
			let displayAgents = this.filterAgents();
			displayAgents = this.sortAgents(displayAgents);
			hasSearchResults = `${displayAgents.length} agents`;

			const agents = displayAgents.map(a => {
				if (a.personCode == null) return;

				let attributes;
				if (a.attributes != null) {
					attributes = a.attributes.map(t => <Tag key={t.id}>{t.name}</Tag>);
				}

				let logoutEnabled = a.currentCampaigns.length != 0 || a.currentWorkTasks.length != 0 || a.online || a.readyForTask;

				return <DataTableRow key={a.personCode}>
					<DataTableColumn key="personName">
						<PersonNameArea>						
							<PersonStatus agent={a} showCode fixedWidth />
							<PersonNameText>
								{a.name || 'Unnamed Agent'}
								<LicenseIcon>
									{a.encoreEnabled && <FontAwesomeIcon icon={['fa', 'satellite-dish']} />}
									{a.monitoringPermission === 2 && <FontAwesomeIcon icon={['fas', 'user-tie']} />}
								</LicenseIcon>
							</PersonNameText>
						</PersonNameArea>
					</DataTableColumn>
					<DataTableColumn key="attributes">
						{attributes}
					</DataTableColumn>
					<DataTableColumn key="editButton">
						<RoundButton icon={['far', 'pencil']} to={`${match.url}${a.id}`}>Edit</RoundButton>
					</DataTableColumn>
					<DataTableColumn key="logoutButton">
						<RoundButton icon={['fas', 'sign-out']} onClick={() => this.handleLogOutAgentSession(a)} disabled={!logoutEnabled}>Logout</RoundButton>
					</DataTableColumn>
				</DataTableRow>;
			});
			pageContent = <DataTable columns={[
				{
					key: 'personName',
					title: 'Agent Name & Code',
					width: [
						{
							width: '60%'
						},
						{
							minWidth: '790px',
							width: '300px'
						}
					]
				},
				{
					key: 'attributes',
					title: 'Attributes',
					width: [
						{
							width: '40%'
						},
						{
							minWidth: '790px',
							width: '100%'
						}
					]
				},
				{
					key: 'editButton',
					headerVisible: false,
					width: '100px',
					align: 'right'
				},
				{
					key: 'logoutButton',
					headerVisible: false,
					width: '100px',
					align: 'right'
				}
			]}>
				{agents}
			</DataTable>;
		}

		return <ConfigurePage 
			className={this.props.className} 
			title="Agents" hideHeaderBorder
			search={
				{
					name: 'agentSearch',
					placeholder: 'Search for agent or attribute',
					width: '300px',
					disabled: loading,
					onChange: this.onSearchChange,
					hasSearchResults
				}
			}>
			<ThemeProvider theme={UpperLayer}>				
				<DataView loading={loading}>
					{pageContent}
				</DataView>
			</ThemeProvider>
		</ConfigurePage>;
	}
}

export default withRouter(connect(state => {
	return {
		...state.agents,
		...state.notifications
	};
}
	, dispatch => bindActionCreators({
		actionCreators,
		...notificationActionCreators
	}, dispatch)
)(AgentList));
