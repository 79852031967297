import React from 'react';
import TestRuleItem from './TestRuleItem';
import { List, Card, CardContent, Typography } from '@material-ui/core';

class TestRuleList extends React.Component {
	constructor(props) {
		super(props)
	}

	sortResultListByOutcome(resultList) {
		if (resultList && resultList.length) {
			for (var i = 0; i < resultList.length; i++) {
				if (resultList[i].isApplicable && resultList[i].restricted) {
					// BLOCKED
					resultList[i].sortOrder = 0;
				}

				if (resultList[i].isApplicable && !resultList[i].restricted) {
					// ALLOWED
					resultList[i].sortOrder = 1;
				}

				if (!resultList[i].isApplicable) {
					// NOT APPLICABLE
					resultList[i].sortOrder = 2;
				}
			}

			resultList = resultList.sort((a, b) => a.sortOrder - b.sortOrder || a.ruleName.localeCompare(b.ruleName));
		}

		return resultList;
	}

	render() {
		let { results, showDisabledRules, ...props } = this.props;
		var resultList = undefined;

		if (results && results.ruleResults) {
			let ruleResults = results.ruleResults;

			if (!showDisabledRules) {
				ruleResults = ruleResults.filter(x => x.isEnabled)
			}

			ruleResults = this.sortResultListByOutcome(ruleResults);

			resultList = ruleResults.map((item, i) => {
				return (<TestRuleItem key={i} rule={item} />);
			});
		}

		return (
			resultList && resultList.length > 0 ?
			<List {...props}>
				{resultList}
			</List> :
			<Card style={{marginTop: "20px"}}>
				<CardContent>
					<Typography variant="body2">
						No test result data to display.
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

export default TestRuleList;