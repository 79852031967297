import React from 'react';
import Layout from './components/Layout';
import Dashboard from './components/dashboard/Dashboard';
import Configure from './components/configure/Configure';
import CommandCenter from './components/command-center/CommandCenter';
import { ThemeProvider } from 'styled-components';
import { LightTheme, DarkTheme } from './components/core/Themes.js';
import { Route, withRouter } from "react-router-dom";
import { GlobalStyle, PopulateFontAwesomeIcons } from './components/core/GlobalStyle';

PopulateFontAwesomeIcons();

/**
 * Root level component for Encore.
 */
class EncoreRouter extends React.Component {
	render() {
		// Change this flag if you want the page to be scrollable for a particular component.
		const scrollable = this.props.location && this.props.location.pathname.includes("dashboard");

		// Temporary flag to enable dark theme. Set in Local Settings database in Dev Tools.
		let theme = LightTheme;
		if (localStorage.getItem("darkModeOverrideEnabled") === "true") {
			theme = DarkTheme;
		}

		return	<ThemeProvider theme={theme}>
				<React.Fragment>
					<GlobalStyle scrollable={scrollable}></GlobalStyle>
						{/* The old dashboard. Only accessible through Dev Tools. */}
						<Route path="/command" component={CommandCenter} />

						{/* The current dialer dashboard for Encore. */}
						<Route path="/dashboard" component={Dashboard} />

						{/* The Encore Configuration view. */}
						<Route path="/configure" component={Configure} />

						{/* The Encore Hub view. */}
						<Route path="/encore" component={Layout} />
				</React.Fragment>
			</ThemeProvider>;
	}
}

export default withRouter(EncoreRouter);
