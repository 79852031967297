import React from 'react';
import { Select, InputLabel, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import { Help } from '@material-ui/icons';

class SelectField extends React.Component {
	render() {		
		let { formProps, ...props} = this.props;
		let options = props.options ? props.options : [];
		let { helperText, ...newFormProps } = formProps;

		return <FormControl {...newFormProps}>
			<InputLabel>{props.label}</InputLabel>
			<Select {...props}>
				{options.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)}
			</Select>

			{(props.id && formProps.error && helperText) ?
				<FormHelperText htmlFor={props.id} error>{helperText}</FormHelperText> : ""
			}
		</FormControl>;
	}
};
export default SelectField
