import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Agent';
import styled from 'styled-components';
import { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatusWrapper = styled.div`
	${props => {
		const theme = props.theme;
		return css`		
			color: ${props.online ? theme.colors.positive : props.loading ? theme.colors.text : theme.colors.negative};	
		`;
	}}	
`;

class OnlineIndicator extends React.Component {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
	}

	async componentWillUnmount() {
	}

	render() {
		const loading = this.props.online === null || this.props.phoneConnected === null || this.props.phoneAudioConnected === null;
		const online = this.props.online && this.props.phoneConnected && this.props.phoneAudioConnected;
		return <StatusWrapper online={online} loading={loading} className={this.props.className}>
			{
				!online &&
				<FontAwesomeIcon fixedWidth spin={loading} icon={ loading ? "sync-alt" : "circle"}/>
			}
		</StatusWrapper >;
	}
}

export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(OnlineIndicator);
