import TabDefinitions from '../components/home/TabDefinitions';


export const types = {
	tabsInitialized: 'TABS_INITIALIZED',
	tabCreated: 'TAB_CREATED',
	tabSelectionChanged: 'TAB_SELECTION_CHANGED',
	tabStateChanged : 'TAB_STATE_CHANGED',
	tabDeleted: 'TAB_DELETED',
	taskAccepted: 'TASK_UPDATED',
	taskEnded: 'TASK_ENDED',
	phoneConnected: 'PHONE_CONNECTED'
};

const initialState = {
	tabs: [],
	agentId: null
};

export const actionCreators = {
	tabsInitialized: tabs => ({ type: types.tabsInitialized, data: tabs }),
	tabCreated: tab => ({ type: types.tabCreated, data: tab }),
	tabSelectionChanged: selectedTabKey => ({ type: types.tabSelectionChanged, data: selectedTabKey }),
	tabStateChanged: (id, state) => ({ type: types.tabStateChanged, data: {id : id, state : state}}),
	tabDeleted: deletedTabKey => ({ type: types.tabDeleted, data: deletedTabKey })
};

export const reducer = (state, action) => {
	state = state || initialState;
	var existingTabs;
	var existingTabIndex;
	if (action.type === types.phoneConnected) {
		return { ...state, agentId: action.data.id };
	}
	else if (action.type === types.tabsInitialized) {
		return {
			...state,
			tabs: action.data
		};
	}
	else {
		var existingTab;
		if ((action.type === types.taskAccepted || action.type === types.taskEnded) && state.agentId && action.data.agentId === state.agentId) {
			existingTabs = state.tabs.slice(0);
			existingTabIndex = existingTabs.findIndex(t =>
				action.data.originId && t.key === action.data.originId ||
				action.data.sessionId && t.sessionId === action.data.sessionId);
			existingTab = existingTabs[existingTabIndex];
			console.debug('Processing agent targetted task start/end in tabs state', existingTabIndex, existingTab, action.data);
			if (existingTab && existingTab.selected) {
				existingTabs[existingTabIndex] = { 
					...existingTabs[existingTabIndex], 
					consumer: action.type === types.taskEnded ? null : action.data.consumer
				};

				return {
					...state,
					tabs: existingTabs
				};
			}
			else if (existingTab) {
				existingTabs.forEach(t => {
					t.selected = t.key === existingTab.key;
				});
			}
			else {
				existingTabs.forEach(tab => {
					tab.selected = false;
				});
				var newTab = TabDefinitions.InitializeTab(TabDefinitions.types.consumerSession, action.data.originId);
				existingTabs.push({
					...newTab,
					selected: true, 
					consumer: action.type === types.taskEnded ? null : action.data.consumer
				});
			}

			return {
				...state,
				tabs: existingTabs
			};
		}

		else if (action.type === types.tabCreated) {
			existingTabs = state.tabs.slice(0);
			// If this tab key already exists, don't add it
			if (existingTabs.find(t => t.key == action.data.key)) {
				console.debug('creating new tab, existing tab found', action.data);
				return state;
			}
			// Otherwise, add it - and select it
			existingTabs.forEach(tab => {
				tab.selected = false;
			});
			console.debug('creating new tab, new tab created', action.data);
			existingTabs.push({
				...action.data,
				selected: true
			});
			return {
				...state,
				tabs: existingTabs
			};
		}

		else if (action.type === types.tabSelectionChanged) {
			const existingTabs = state.tabs.slice(0);
			existingTab = existingTabs.find(t =>
				action.data.originId && t.key === action.data.originId ||
				action.data.sessionId && t.sessionId === action.data.sessionId ||
				action.data.tabId && t.key === action.data.tabId);
			console.debug('tab selection changed', existingTab, action.data);
			if (existingTab) {
				existingTabs.forEach(tab => {
					tab.selected = action.data.tabId && tab.key === action.data.tabId ||
						action.data.sessionId && tab.sessionId === action.data.sessionId ||
						action.data.originId && tab.key === action.data.originId;
				});
				return {
					...state,
					tabs: existingTabs
				};
			}
			return state;

		}

		else if (action.type === types.tabStateChanged) {
			existingTabs = state.tabs.slice(0);
			existingTabIndex = existingTabs.findIndex(tab => tab.key == action.data.id);
			existingTabs[existingTabIndex] = { ...existingTabs[existingTabIndex], ...action.data.state };
			console.debug('tab state changed', existingTabs[existingTabIndex], action.data);
			return {
				...state,
				tabs: existingTabs
			};
		}

		else if (action.type === types.tabDeleted) {
			existingTabs = state.tabs.slice(0);
			existingTabIndex = existingTabs.findIndex(tab => tab.key == action.data);
			// If this tab key doesn't exist, or if only one tab left, don't do anything
			if (existingTabIndex == -1 || existingTabs.length <= 1) {
				return state;
			}
			// Otherwise, delete it, and select an adjacent tab
			existingTabs.splice(existingTabIndex, 1);
			var newIndex = existingTabIndex > existingTabs.length - 1 ? existingTabs.length - 1 : existingTabIndex;
			existingTabs[newIndex].selected = true;
			var newState = {
				...state,
				tabs: existingTabs
			};
			return newState;
		}
	}

	return state;
};
