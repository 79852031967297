import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

class ConfirmDialog extends React.Component {
	constructor(props) {
		super(props);

		this.handleCancel = this.handleCancel.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
	}

	handleCancel() {
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	handleConfirm() {
		if (this.props.onConfirm) {
			this.props.onConfirm();
		}
	}

	render() {
		return <Dialog
			open={this.props.open}
			onClose={this.handleCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{this.props.dialogTitle}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{this.props.dialogMessage}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleCancel} color="primary">
					Cancel
				</Button>
				<Button onClick={this.handleConfirm} color="primary" autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>;
	}
}

export default ConfirmDialog;