
export const types = {
	taskQueued: 'TASK_QUEUED',
	taskEnded: 'TASK_ENDED',
	taskUpdated: 'TASK_UPDATED',
	tasksInitialized: 'TASKS_INITIALIZED',
	phoneConnected: 'PHONE_CONNECTED'
};

const initialState = {
	tasks: [],
	agentId: null
};

export const actionCreators = {
	finishTask: id => ({ type: types.taskCompleted, data: { id: id } })
};

export const reducer = (state, action) => {
	state = state || initialState;
	var newState;
	var clonedTasks = state.tasks.slice(0);
	if (action.type === types.phoneConnected) {
		return { ...state, agentId: action.data.id };
	}
	else if (action.type === types.tasksInitialized) {
		console.debug('task initialized', action.data);
		newState = { ...state, tasks: action.data };
		return newState;
	}
	else if (action.type === types.taskQueued) {
		console.debug('task started', action.data);
		newState = { ...state, tasks: clonedTasks };
		clonedTasks.push(action.data);
		return newState;
	}
	else if (action.type === types.taskUpdated) {
		console.debug('task updated', action.data);
		if (window.bloodhound && window.bloodhound.taskStarted && state.agentId && action.data.agentId === state.agentId) {
			console.log('Invoking task started back to bloodhound.', action.data, state);
			window.bloodhound.taskStarted(action.data.originId, JSON.stringify(action.data));
		}
		newState = { ...state, tasks: clonedTasks };
		var existingTaskIndex = clonedTasks.findIndex(ele => ele.id === action.data.id);
		clonedTasks[existingTaskIndex] = action.data;
		return newState;
	}
	else if (action.type === types.taskEnded) {
		if (window.bloodhound && window.bloodhound.taskFinished && state.agentId && action.data.agentId === state.agentId) {
			console.log('Invoking task finished back to bloodhound.', action.data, state);
			window.bloodhound.taskFinished(action.data.originId, JSON.stringify(action.data));
		}
		newState = { ...state, tasks: clonedTasks };
		var existingTaskIndex = clonedTasks.findIndex(ele => ele.id === action.data.id);
		clonedTasks.splice(existingTaskIndex, 1);
		return newState;
	}

	return state;
};