import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Phone';
import { MillisecondsToDuration } from './utils/DateHelpers';

class PhoneTimer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			duration: 0
		};
	}

	async componentDidMount() {
		this.intervalHandle = setInterval(() => this.intervalTick(), 1000);
	}

	async componentWillUnmount() {
		clearInterval(this.intervalHandle);
	}

	intervalTick() {
		this.setState({
			duration: this.state.duration + 1
		});
	}

	render() {
		return <span>{MillisecondsToDuration(this.state.duration * 1000)}</span>;
	}
}

export default connect(state => state.phone,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(PhoneTimer);
