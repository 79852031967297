import React from 'react';
import RuleGroup from './RuleGroup';
import { List, Card, CardContent, Typography } from '@material-ui/core';
import { getDistinctGroupListFromRuleList } from '../_utilities/RuleHelpers';

class RuleList extends React.Component {
	constructor(props) {
		super(props)

		this.onRuleChanged = this.onRuleChanged.bind(this);
	}

	filterAndSort() {
		var ruleList = this.props.rules;
		var searchText = this.props.searchText ? this.props.searchText.toLowerCase() : '';

		if (ruleList.length > 0) {
			if (this.props.filterBy !== '' && this.props.filterBy !== 'all') {
				ruleList = ruleList.filter(r => (r.enabled && this.props.filterBy === 'enabled') || (!r.enabled && this.props.filterBy === 'disabled'));				
			}

			if (searchText !== '') {
				ruleList = ruleList.filter(r => r.name.toLowerCase().indexOf(searchText) > -1 || r.group.toLowerCase().indexOf(searchText) > -1)
			}			
		}

		var sort = this.props.sortBy ? this.props.sortBy.toLowerCase() : '';		
		switch (sort) {
			case "oldest":
				return ruleList.sort((a, b) => new Date(a.lastUpdatedTimestamp) - new Date(b.lastUpdatedTimestamp));
			case "latest":
				return ruleList.sort((a, b) => new Date(b.lastUpdatedTimestamp) - new Date(a.lastUpdatedTimestamp));
			case "a-z":
			default:
				return ruleList.sort((a, b) => a.group.localeCompare(b.group) || a.name.localeCompare(b.name));
		}
	}

	onRuleChanged(rule) {
		if (this.props.onRuleChanged) {
			this.props.onRuleChanged(rule);
		}
	}

	render() {		
		var ruleList = [], groupList = [], menuItems = [];
		if (this.props.rules) {			
			ruleList = this.filterAndSort();
			groupList = getDistinctGroupListFromRuleList(ruleList);

			menuItems = groupList && groupList.map((item, i) => {				
				var groupRules = ruleList.filter(r => r.group === item);
				return (<RuleGroup key={item} rules={groupRules} groups={groupList} onRuleChanged={this.onRuleChanged} />);
			});
		}

		return (
			ruleList.length > 0 ?
			<List>
				{menuItems}
			</List> :
			<Card style={{marginTop: "20px"}}>
				<CardContent>
					<Typography variant="body2">
						No rule data to display.
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

export default RuleList;