import React from 'react';
import Autocomplete from '../Autocomplete/Autocomplete';
import InputAddContainer from './InputAddContainer';
import styled, {css} from 'styled-components';
import Token from '../Token/Token';
import posed, { PoseGroup } from 'react-pose';

const LineList = styled.ul`	
	${props => {
		const theme = props.theme;
		let lineStyle = css`		
			display: inline-block;
			margin: 0;

			&:not(:last-child) {
				margin-right: ${theme.margin.half};
			}		
		`;
		if (props.onePerLine) {
			lineStyle = css`				
			
			`;
		}
		return css`
			list-style: none;
			margin: 0;
			${props.containsItems ? css`margin-top: ${theme.margin.half};` : null}
			padding: 0;

			li {
				${lineStyle}
			}
		`;
	}} 
`;

const LineWrapper = posed.li({	
	enter: {
		y: 0,
		opacity: 1,
		delay: 0,
		transition: {
			y: { type: 'spring', stiffness: 1000, damping: 25 },
			default: { duration: 150 }
		}
	},
	exit: {
		y: 50,
		opacity: 0,
		transition: { duration: 150 }
	}
});

/**
 * Creates an input-add component using the Autocomplete component to render the dropdown.
 * 
 * @property {function}		[lineRender]			-	Custom rendering function for list & dropdown items
 * @property {function}		[onUpdate]				-	Callback for list updates
 * @property {function}		[onAdd]					-	Callback for list additions
 *	@property {function}		[onRemove]				-	Callback for list removals
 */
export default class InputAdd extends React.Component {

	// TODO: implement allowCustom property for allowing custom entries into list

	constructor(props) {
		super(props);
		this.state = {
			items: this.props.value
		};

		this.addToList = this.addToList.bind(this);
		this.pullFromList = this.pullFromList.bind(this);

		// You can hook into one of these three functions through this.props
		// If you just need to have the latest array state, use onUpdate
		this.onUpdate = this.onUpdate.bind(this);
		this.onAdd = this.onAdd.bind(this);
		this.onRemove = this.onRemove.bind(this);
	}

	componentDidMount() {
		this.setState({
			items: this.props.value
		});
	}

	inputRender() {		
		return <Autocomplete {...this.props} />;
	}

	onAdd(item) {
		if (this.props.onAdd) {
			this.props.onAdd(item);
		}
	}

	onRemove(itemKey, i) {
		if (this.props.onRemove) {
			this.props.onRemove(itemKey, i);
		}
	}

	onUpdate() {
		if (this.props.onUpdate) {
			this.props.onUpdate(this.state.items);
		}
	}

	async addToList(item) {
		this.setState(ps => {
			const items = ps.items;
			const existingIndex = items.findIndex(pi => item.key === pi.key);
			if (this.props.allowDuplicates || existingIndex < 0) {
				items.push(item);
			}
			return {
				...ps,
				items
			};
		}, () => {
			this.onUpdate();
			this.onAdd(item);
		});
	}

	async pullFromList(itemKey, i) {
		this.setState(ps => {
			const items = ps.items;
			let index = i;
			if (!index) {
				index = items.findIndex(item => item.key === itemKey);
			}	
			if (index > -1) {
				items.splice(index, 1);
			}
			return {
				...ps,
				items
			};
		}, () => {
			this.onUpdate();
			this.onRemove(itemKey, i);
		});
	}

	render() {
		
		const pullFromList = this.pullFromList;
		let itemRender = (item) => <span>{item.value}</span>;
		if (this.props.itemRender) {
			itemRender = (item) => this.props.itemRender(item);
		}
		let lineRender = (item, i) => {
			return <LineWrapper key={item.key || i}>
				<Token key={item.key || i} onRemove={() => pullFromList(item.key, i)}>
					{itemRender(item)}
				</Token>
			</LineWrapper>;
		};

		// NOTE: If using lineRender, be sure to implement pullFromList yourself
		if (this.props.lineRender) {
			lineRender = (item, i) => {
				return <LineWrapper key={item.key || i}>
					{this.props.lineRender(item, i, pullFromList)}
				</LineWrapper>;
			};
		}

		return <InputAddContainer input={() => <Autocomplete {...this.props} inputAdd onChange={this.addToList} clearOnSelect />}>
			<LineList containsItems={(this.state.items && this.state.items.length > 0)} 
				onePerLine={this.props.onePerLine}>
				<PoseGroup>
					{this.state.items.map(lineRender)}
				</PoseGroup>
			</LineList>
		</InputAddContainer>;
	}
}