import React from 'react';
import { DateDiffInDays } from '../utils/DateHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AgentTaskTimer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			duration: 0
		};
	}

	async componentDidMount() {
		this.intervalHandle = setInterval(() => this.intervalTick(), 1000);
	}

	async componentWillUnmount() {
		clearInterval(this.intervalHandle);
	}

	intervalTick() {
		this.setState({
			duration: this.state.duration + 1
		});
	}	

	render() {
		const timeToDisplay = (this.props.activeTask !== undefined && this.props.activeTask !== null ? this.props.activeTask.lastPhoneCallTimeEnd : this.props.lastWorkTaskTime);
		const timerLabel = (this.props.label !== undefined && this.props.label !== null ? this.props.label : "");
		const timerIcon = (this.props.icon !== undefined && this.props.icon !== null ? this.props.icon : null);

		return <div><FontAwesomeIcon icon={timerIcon} />{timerLabel} {DateDiffInDays(new Date(timeToDisplay), new Date(Date.now()))}</div>;
	}
}

export default AgentTaskTimer;
