import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ConfigurePage from '../ConfigurePage.jsx';
import FormInput from '../../core/FormInput/FormInput.jsx';
import FormToggle from '../../core/FormToggle/FormToggle.jsx';
import RoundButton from '../../core/RoundButton/RoundButton.jsx';
import TagSelector from '../../core/TagSelector/TagSelector.jsx';
import HttpAgent from '../../../httpagent.js';
import PhoneNumber from '../../core/PhoneNumber/PhoneNumber';
import Field from '../../core/Field/Field.jsx';
import ApplyValidation from '../../utils/Validation.js';
import LoadingIndicator from '../../core/LoadingIndicator/LoadingIndicator.jsx';
import InputAdd from '../../core/InputAdd/InputAdd';
import ValidationMessage from '../../core/Validation/ValidationMessage';

const FormSection = styled.section`

	${props => {
		const theme = props.theme;
		return css`
			margin: ${theme.margin.full};
		`;
	}}

`;

const Loader = styled(LoadingIndicator)`
	font-size: .5em;	
	color: ${props => props.theme.colors.border};
`;

const PhoneNumberName = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			margin-bottom: ${theme.margin.half};
		`;
	}}
`;

const PhoneNumberText = styled(PhoneNumber)`

	display: block;
	font-family: ${props => props.theme.fonts.numeric};
	font-size: 0.95em;
	letter-spacing: -0.05em;
`;

const Cell = styled.div`

	grid-area: ${props => props.gridArea};

`;

const PhoneNumberContainer = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			display: grid;
			width: 100%;
			max-width: 400px;
			grid-template-areas: "number remove";
			grid-template-columns: 1fr auto;
			grid-column-gap: ${theme.margin.half};
			align-items: center;
			margin: ${theme.margin.full} 0;
			padding-left: ${theme.margin.half};
			border-left: 5px solid ${theme.colors.border};
		`;
	}}

`;

class AgentEdit extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			agent: {
				name: '',
				personCode: '',
				extension: '',
				id: null,
				callerIdPhoneNumber: ''
			},
			agentLoaded: false,
			allAttributes: [],
			allAttributesLoaded: false,
			availablePhoneNumbers: [],
			availablePhoneNumbersLoaded: false,
			requestFailed: false,
			serverErrors: {}
		};
		this.handleSave = this.handleSave.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onExtensionChange = this.onExtensionChange.bind(this);
		this.onAttributeChange = this.onAttributeChange.bind(this);
		this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
		this.handleToggleCanPauseRecordings = this.handleToggleCanPauseRecordings.bind(this);
		this.handleToggleCanDeleteVoicemail = this.handleToggleCanDeleteVoicemail.bind(this);
		this.handleToggleCanArchiveVoicemail = this.handleToggleCanArchiveVoicemail.bind(this);
		this.handleToggleEncoreEnabled = this.handleToggleEncoreEnabled.bind(this);
		this.handleSupervisorChange = this.handleSupervisorChange.bind(this);
		this.handleVoicemailPermissionChange = this.handleVoicemailPermissionChange.bind(this);
		this.phoneNumberLineRender = this.phoneNumberLineRender.bind(this);
		this.handleDialPadAccessPermissionChange = this.handleDialPadAccessPermissionChange.bind(this);
		this.onCallerIdPhoneNumberChange = this.onCallerIdPhoneNumberChange.bind(this);
		this.handleCallAcceptModeChange = this.handleCallAcceptModeChange.bind(this);

		ApplyValidation(this, {
			extensionTooLong: {
				validIf: state => !state.agent.extension || state.agent.extension.length < 7,
				message: 'Extension cannot be more than 6 digits'
			},
			extensionTooShort: {
				validIf: state => !state.agent.extension || state.agent.extension.length > 1,
				message: 'Extension cannot be less than 2 digits'
			},
			extensionIsANumber: {
				validIf: state => !state.agent.extension || !isNaN(state.agent.extension),
				trackDirtyState: state => state.agent.extension,
				message: 'Extension must be a number'
			},
			callerIdNumberNotLength: {
				validIf: state => !state.agent.callerIdPhoneNumber || state.agent.callerIdPhoneNumber.length === 10,
				message: 'Outgoing Caller Id must be 10 numeric digits'
			},
			callerIdNumberIsANumber: {
				validIf: state => !state.agent.callerIdPhoneNumber || !isNaN(state.agent.callerIdPhoneNumber),
				trackDirtyState: state => state.agent.callerIdPhoneNumber,
				message: 'Outgoing Caller Id must be a number'
			},
			noServerErrors: {
				validIf: state => {
					if (state.requestFailed) {
						return false;
					}
					if (!state.serverErrors || state.serverErrors.length === 0) {
						return true;
					}
					let serverErrors = Object.keys(state.serverErrors);
					const ignoredKeys = ['$type', 'extension', 'encoreEnabled', 'monitoringPermission'];
					serverErrors = serverErrors.filter(e => !ignoredKeys.includes(e));
					return serverErrors.length === 0;
				},
				allowSubmit: true,
				message: 'Unknown issue saving agent edits'
			},
			invalidExtension: {
				validIf: state => !state.serverErrors.extension,
				allowSubmit: true
			},
			licenseMissing: {
				validIf: state => !state.serverErrors.encoreEnabled,
				allowSubmit: true
			},
			superviseLicenseMissing: {
				validIf: state => !state.serverErrors.monitoringPermission,
				allowSubmit: true
			}
		});
	}

	async componentDidMount() {

		HttpAgent.getAgent(this.props.match.params.id)
			.then(res => {
				const agent = res.data;
				this.setStateWithDirtyTracking(() => ({
					agent: agent,
					agentLoaded: true
				}));
			});

		HttpAgent.getTags()
			.then(attributes => {
				this.setState({
					allAttributes: attributes.data,
					allAttributesLoaded: true
				});
			});

		HttpAgent.getAgentPhoneNumbers(this.props.match.params.id)
			.then(phoneNumbers => {
				this.setState({
					availablePhoneNumbers: phoneNumbers.data,
					availablePhoneNumbersLoaded: true
				});
			});
	}

	async handleSave() {
		const self = this;
		const isValid = this.stateIsValid();

		if (isValid) {			
			HttpAgent.updateAgent(this.state.agent)
				.then(function (response) {
					if (response.data.success) {
						self.validateAndSetState(prevState => ({
							...prevState,
							requestFailed: false,
							serverErrors: {}
						}));
						self.props.history.push('./');
					} else {
						self.validateAndSetState(prevState => ({
							...prevState,
							requestFailed: false,
							serverErrors: response.data.validationErrors
						}));
					}
				})
				.catch(function (error) {
					self.validateAndSetState(prevState => ({
						...prevState,
						requestFailed: true,
						serverErrors: {}
					}));
				});
		}
	}

	async handleSupervisorChange(val) {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				monitoringPermission: val
			}
		}));
	}

	async handleVoicemailPermissionChange(val) {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				voicemailAccessPermission: val
			}
		}));
	}

	handleToggleCanDeleteVoicemail() {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				canDeleteVoicemail: !prev.agent.canDeleteVoicemail
			}
		}));
	}

	handleToggleCanArchiveVoicemail() {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				canArchiveVoicemail: !prev.agent.canArchiveVoicemail
			}
		}));
	}

	handleToggleCanPauseRecordings() {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				canPauseRecording: !prev.agent.canPauseRecording
			}
		}));
	}

	handleToggleEncoreEnabled() {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				encoreEnabled: !prev.agent.encoreEnabled,
				monitoringPermission: prev.agent.encoreEnabled ? 0 : prev.agent.monitoringPermission,
				voicemailPermission: prev.agent.encoreEnabled ? 0 : prev.agent.voicemailPermission,
				dialPadAccessPermission: prev.agent.encoreEnabled ? 0 : prev.agent.dialPadAccessPermission
			}
		}));
	}

	async handleDialPadAccessPermissionChange(val) {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				dialPadAccessPermission: val
			}
		}));
	}

	async handleCallAcceptModeChange(val) {
		this.setState(prev => ({
			agent: {
				...prev.agent,
				callAcceptMode: val
			}
		}));
	}

	onExtensionChange(event) {
		const newValue = event.target.value;
		this.validateAndSetState(prevState => ({
			agent: {
				...prevState.agent,
				extension: newValue
			}
		}));
	}

	onCallerIdPhoneNumberChange(event) {
		const newValue = event.target.value;
		this.validateAndSetState(prevState => ({
			agent: {
				...prevState.agent,
				callerIdPhoneNumber: newValue
			}
		}));
	}

	onNameChange(event) {
		const newValue = event.target.value;
		this.setState(prevState => ({
			agent: {
				...prevState.agent,
				name: newValue
			}
		}));
	}

	onAttributeChange(attributeId) {
		const newAttributes = this.toggleAttribute(attributeId);
		this.setState(prevState => ({
			agent: {
				...prevState.agent,
				attributes: newAttributes
			}
		}));
	}

	onPhoneNumberChange(items) {
		this.setState(prevState => ({
			agent: {
				...prevState.agent,
				phoneNumbers: items
			}
		}));
	}

	toggleAttribute(attributeId) {

		const existing = this.state.agent.attributes || [];

		// If selected, remove
		const match = existing.findIndex(e => e.id === attributeId);
		if (match !== -1) {
			existing.splice(match, 1);
			return existing;
		}

		// Otherwise, add to end of current agent attributes
		const newAttribute = this.state.allAttributes.find(a => a.id === attributeId);
		existing.push(newAttribute);
		return existing;
	}

	phoneNumberRender(p) {
		return <div>
			<PhoneNumberName>{p.description}</PhoneNumberName>
			<PhoneNumberText>{p.digits}</PhoneNumberText>
		</div>;
	}

	phoneNumberLineRender(item, i, onRemove) {
		return <PhoneNumberContainer>
			<Cell gridArea="number">
				{this.phoneNumberRender(item)}
			</Cell>
			<Cell gridArea="remove">
				<RoundButton icon={['far', 'times']} onClick={() => onRemove(item.key, i)}>Remove</RoundButton>
			</Cell>
		</PhoneNumberContainer>;
	}

	render() {

		const agent = this.state.agent;
		const validation = this.state.validation;
		const canSubmit = !this.state.validationFailed;

		const loaded = agent != null && agent.id != null;
		let title = '';
		let name = '';
		let personCode = '';
		let extension = '';
		let encoreEnabled = false;
		let canPauseRecording = false;
		let canDeleteVoicemail = false;
		let canArchiveVoicemail = false;
		let monitoringPermission = 0;
		let voicemailAccessPermission = 0;
		let dialPadAccessPermission = 0;
		let phoneNumbers = [];
		let callerIdPhoneNumber = '';
		let callAcceptMode = 0;

		if (loaded) {
			title = agent.name || agent.personCode || '';
			name = agent.name || '';
			personCode = agent.personCode || '';
			canPauseRecording = agent.canPauseRecording;
			canDeleteVoicemail = agent.canDeleteVoicemail;
			canArchiveVoicemail = agent.canArchiveVoicemail;
			encoreEnabled = agent.encoreEnabled;
			monitoringPermission = agent.monitoringPermission;
			voicemailAccessPermission = agent.voicemailAccessPermission;
			dialPadAccessPermission = agent.dialPadAccessPermission;
			extension = agent.extension || '';
			phoneNumbers = agent.phoneNumbers;
			callerIdPhoneNumber = agent.callerIdPhoneNumber;
			callAcceptMode = agent.callAcceptMode;
		}		

		let phoneNumberControl = <Loader />;
		if (this.state.availablePhoneNumbersLoaded && this.state.agentLoaded) {
			let availablePhoneNumbers = [];
			availablePhoneNumbers = this.state.availablePhoneNumbers.map((p, i) => {
				const key = `pn_${p.providerId}`;
				return {
					...p,
					key: key,
					searchKey: `${p.description}${p.digits}`
				};
			});

			// Don't let the list show the same number twice
			if (phoneNumbers && phoneNumbers.length > 0) {
				availablePhoneNumbers = availablePhoneNumbers.filter(p => {
					return phoneNumbers.findIndex(a => a.providerId === p.providerId) === -1;
				});
			}

			phoneNumberControl = <InputAdd name="phoneNumbers" label="Direct Phone Numbers (DID)" width="300px"
				value={phoneNumbers} allowCustom={false} onePerLine
				itemRender={this.phoneNumberRender} lineRender={this.phoneNumberLineRender}
				disabled={!this.state.availablePhoneNumbersLoaded || !loaded} onUpdate={this.onPhoneNumberChange}
				allowDuplicates={false} items={availablePhoneNumbers} placeholder="Enter a phone number" />;
		}

		return <ConfigurePage
			className={this.props.className}
			title="Edit Agent"
			breadcrumbs={[
				{ name: 'Agent List', to: './', key: 'agentList' },
				{ name: title, key: 'personName' }
			]}>
			<FormSection>
				<h3>Profile</h3>

				<FormInput name="agentName" label="Name" disabled={!loaded} width="400px"
					placeholder="Name" value={name} onChange={this.onNameChange} />

				<FormInput name="personCode" label="Person Code" disabled codeField value={personCode} />

				<FormInput name="callerIdPhoneNumber" label="Outgoing Caller Id" disabled={!loaded}
					numericField width="300px" value={callerIdPhoneNumber} onChange={this.onCallerIdPhoneNumberChange} placeholder="Enter a phone number"
					validation={[
						validation.callerIdNumberIsANumber,
						validation.callerIdNumberNotLength,
						{
							validation: validation.invalidExtension,
							message: this.state.serverErrors.callerIdPhoneNumber
						}
					]} />

				<h3>Routing</h3>

				<TagSelector selectedTags={agent.attributes} availableTags={this.state.allAttributes}
					onTagClick={this.onAttributeChange} loading={!this.state.allAttributesLoaded || !loaded} label="Attributes" />

				{phoneNumberControl}

				<FormInput name="extension" label="Extension" disabled={!loaded}
					numericField width="100px" value={extension} onChange={this.onExtensionChange}
					validation={[
						validation.extensionIsANumber,
						validation.extensionTooLong,
						validation.extensionTooShort,
						{
							validation: validation.invalidExtension,
							message: this.state.serverErrors.extension
						}
					]} />

				{!agent.isAdminUser &&
					<div>
						<h3>Permissions</h3>
						<FormToggle checked={encoreEnabled} name="toggleEncoreEnabled" label="Encore Enabled"
							onChange={this.handleToggleEncoreEnabled}
							validation={[
								{
									validation: validation.licenseMissing,
									message: this.state.serverErrors.encoreEnabled
								}
							]}>
							{encoreEnabled ? 'On' : 'Off'}
						</FormToggle>
						{encoreEnabled && <FormToggle checked={canPauseRecording} name="toggleCanPauseRecording" label="Can Pause Call Recordings"
							onChange={this.handleToggleCanPauseRecordings}>
							{canPauseRecording ? 'On' : 'Off'}
						</FormToggle>}
						{encoreEnabled && <FormToggle checked={canDeleteVoicemail} name="toggleCanDeleteVoicemail" label="Can Delete Voicemail"
							onChange={this.handleToggleCanDeleteVoicemail}>
							{canDeleteVoicemail ? 'On' : 'Off'}
						</FormToggle>}
						{encoreEnabled && <FormToggle checked={canArchiveVoicemail} name="toggleCanArchiveVoicemail" label="Can Archive Voicemail"
							onChange={this.handleToggleCanArchiveVoicemail}>
							{canArchiveVoicemail ? 'On' : 'Off'}
						</FormToggle>}
						{encoreEnabled && <Field label="Supervisor Functions Allowed"
							validation={[
								{
									validation: validation.superviseLicenseMissing,
									message: this.state.serverErrors.monitoringPermission
								}
							]}>
							<select disabled={!loaded} value={monitoringPermission} onChange={e => this.handleSupervisorChange(e.target.value)}>
								<option value="0">Not Allowed</option>
								<option value="1">Can Monitor Agents</option>
								<option value="2">Can Drop In on &amp; Monitor Agents</option>
							</select>

						</Field>}
						{encoreEnabled && <Field label="Voicemail Access">
						<select disabled={!loaded} value={voicemailAccessPermission} onChange={e => this.handleVoicemailPermissionChange(e.target.value)}>
								<option value="0">None</option>
								<option value="1">Agent's DID, Extension, and Attributes</option>
								<option value="2">All</option>
							</select>

						</Field>}
						{encoreEnabled && <Field label="Dial Pad Access Allowed">
						<select disabled={!loaded} value={dialPadAccessPermission} onChange={e => this.handleDialPadAccessPermissionChange(e.target.value)}>
							<option value="0">None</option>
							<option value="1">Agent-to-Agent Only</option>
							<option value="2">External Only</option>
							<option value="3">Full Access</option>
						</select>
						</Field>}

						{encoreEnabled && <Field label="Inbound Accept Mode">
							<select disabled={!loaded} value={callAcceptMode} onChange={e => this.handleCallAcceptModeChange(e.target.value)}>
								<option value="0">Auto Accept</option>
								<option value="1">Manual Accept</option>
								<option value="2">Agent Preference</option>
							</select>
						</Field>}
					</div>
				}

				<ValidationMessage validation={validation.noServerErrors} />

				<RoundButton disabled={!loaded || !canSubmit} primary onClick={this.handleSave}>Save Changes</RoundButton>
				<RoundButton to="./">Back</RoundButton>
			</FormSection>
		</ConfigurePage>;
	}
}

export default withRouter(AgentEdit);
