import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoadingDataSpinner extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const message = this.props.message ? this.props.message : "";
		let props = { ...this.props };
		let gridProps = {};

		if (props.gridProps) {
			gridProps = props.gridProps;
			delete props.gridProps;
		}

		return <Grid {...gridProps} container direction="row" alignItems="center" justifyContent="center" spacing={1}>
			<Grid item>
				<CircularProgress {...props} style={props.progressStyle} variant="indeterminate" disableShrink />
			</Grid>
			<Grid item>
				<Typography variant="h3" component="h1">
					{message}
				</Typography>
			</Grid>
		</Grid>;
	}
}

export default LoadingDataSpinner;