// Enables tracking of state properties.
// Do not call setState at same time as this, due to possible race conditions.
function setStateWithDirtyTracking(prevStateFunction) {
	this.setState(prevState => {
		const ps = prevStateFunction(prevState);
		const newState = {
			...prevState,
			...ps
		};
		const dirtyStates = {};
		Object.keys(this.validations).forEach(vKey => {
			const v = this.validations[vKey];
			if (!v.trackDirtyState) return;
			dirtyStates[vKey] = {
				initialState: v.trackDirtyState(newState),
				dirtied: false
			};
		});
		return {
			...newState,
			validationDirtyStates: dirtyStates
		};
	});
}

// Returns whether the state passes all validations.
// Calls setState on *invalid validations only* - preserves previous state.
// Do not call setState if this returns false, due to possible race conditions.
function stateIsValid() {
	let isValid = true;
	const newValidationStates = this.state.validation;
	Object.keys(this.validations).forEach(vKey => {
		// Note that we do not look at trackDirtyState for this
		const v = this.validations[vKey];
		const thisIsValid = v.validIf == null
			? true
			: v.validIf(this.state, this.state);
		if (!v.allowSubmit) {
			isValid = isValid && thisIsValid;
		}
		newValidationStates[vKey] = {
			isNotValid: !thisIsValid,
			required: v.required,
			message: v.message,
			key: vKey
		};
	});
	if (!isValid) {
		this.setState({
			validation: newValidationStates,
			validationFailed: !isValid
		});
	}
	return isValid;
}

// Updates state with new state, and results of all validations.
// Do not call setState at same time as this, due to possible race conditions.
function validateAndSetState(prevStateFunction) {

	this.setState(prevState => {
		const ps = prevStateFunction(prevState);
		const newState = {
			...prevState,
			...ps
		};

		const validationStates = {};
		let validationFailed = false;

		Object.keys(this.validations).forEach(vKey => {
			const v = this.validations[vKey];
			let shortCircuit = false;
			if (v.trackDirtyState) {
				const initialPropertyState = newState.validationDirtyStates[vKey].initialState;
				if (!newState.validationDirtyStates[vKey].dirtied) {
					const newPropertyState = v.trackDirtyState(newState);
					if (initialPropertyState === newPropertyState) {
						shortCircuit = true;
					} else {
						newState.validationDirtyStates[vKey].dirtied = true;
					}
				}			
			}
			let isNotValid = false;
			if (!shortCircuit && v.validIf != null) {							
				isNotValid = !v.validIf(newState, prevState);
				if (!v.allowSubmit) {
					validationFailed = validationFailed || isNotValid;
				}
			}
			var msg = v.message;
			if (v.message instanceof Function) {
				msg = v.message(newState);
			}

			validationStates[vKey] = {
				isNotValid: isNotValid,
				required: v.required,
				message: msg,
				key: vKey
			};			
		});

		const finalState = {
			...newState,
			validation: validationStates,
			validationFailed: validationFailed
		};		
		return finalState;
	});
}

export default function ApplyValidation(component, validations) {
	// Apply with keys for easy use
	component.validations = {};
	const validationStates = {};
	Object.keys(validations).forEach(v => {
		const validation = validations[v];
		component.validations[v] = {
			message: validation.message,
			validIf: validation.validIf,
			allowSubmit: validation.allowSubmit,
			required: validation.required,
			trackDirtyState: validation.trackDirtyState
		};
		validationStates[v] = {
			isNotValid: false,
			required: validation.required,
			message: validation.message,
			key: v
		};
	});
	
	component.setStateWithDirtyTracking = setStateWithDirtyTracking.bind(component);
	component.stateIsValid = stateIsValid.bind(component);
	component.validateAndSetState = validateAndSetState.bind(component);

	component.state = {
		...component.state,
		validation: validationStates,
		validationFailed: false
	};
}
