import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Grid, Typography, Collapse, Chip, Tooltip } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

class TestRuleItem extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		var rule = this.props.rule;
		var restrictionMessages, classificationMessages = [];
		var messageStyle = {
			borderBottom: "1px solid #fff"
		}

		classificationMessages = rule.classificationMessages && rule.classificationMessages.map((item, i) => {
			return (
				<ListItem key={i}>
					<ListItemIcon>
						<ArrowRightAltIcon style={{ color: "#fff" }} />
					</ListItemIcon>
					<Typography key={item} variant="body1" color="secondary">{item}</Typography>
				</ListItem>
			);
		});

		restrictionMessages = rule.restrictionMessages && rule.restrictionMessages.map((item, i) => {
			return (
				<ListItem key={i}>
					<ListItemIcon>
						<ArrowRightAltIcon style={{ color: "#fff" }} />
					</ListItemIcon>
					<Typography key={item} variant="body1" color="primary">{item}</Typography>					
				</ListItem>
			);
		});

		var ruleInfo = (
			<Typography variant='body2'>				
				<span style={{ fontSize: '14pt', fontWeight: 'bold' }}>{rule.ruleName && (rule.ruleName + ' (' + rule.ruleGroup + ')')}</span>
				<Chip component="span" size="small" label={rule.isEnabled ? 'Enabled' : 'Disabled'} style={{ marginLeft: '10px', backgroundColor: rule.isEnabled ? 'green' : 'red' }} />
			</Typography>
		);

		var ruleDetails = '';

		return <Grid container justifyContent="center" alignItems="center" >
			<Grid container item xs={1} alignItems="flex-start" justifyContent="center">
				{(rule.isApplicable && rule.restricted) ?
					<Tooltip title="Failed"><BlockIcon style={{ color: "red" }} fontSize="large" /></Tooltip> : ''
				}

				{(rule.isApplicable && !rule.restricted) ?
					<Tooltip title="Passed"><CheckIcon style={{ color: "green" }} fontSize="large" /></Tooltip> : ''
				}

				{!rule.isApplicable ?
					<Tooltip title="Not Applicable"><Typography variant="h2">NA</Typography></Tooltip> : ''
				}
			</Grid>
			<Grid item xs={11}>
				<ListItem component="span">
					<ListItemText component="span"
						primary={ruleInfo}
						secondary={ruleDetails} />
				</ListItem>

				<Collapse in={true} timeout="auto" unmountOnExit>
					<List component="div" disablePadding style={restrictionMessages && restrictionMessages.length === 0 ? messageStyle : {}}>
						{classificationMessages}
					</List>
				</Collapse>

				<Collapse in={true} timeout="auto" unmountOnExit>
					<List component="div" disablePadding style={messageStyle}>
						{restrictionMessages}
					</List>
				</Collapse>				
			</Grid>
		</Grid>;
	}
}

export default withRouter(TestRuleItem);