import styled, { css } from 'styled-components';
import DataTableColumn from '../core/DataTable/DataTableColumn.jsx';

export const CompactColumn = styled(DataTableColumn)`

	${props => {
		const theme = props.theme;
		return css`

			padding: ${theme.margin.quarter} ${theme.padding.half};

			&:first-child {
				padding-left: ${theme.margin.full};
			}

			&:last-child {
				padding-right: ${theme.margin.full};
			}

		`;
	}}

`;
