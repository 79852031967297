import React from 'react';
import HttpAgent from '../../httpagent.js';
import styled, { css } from 'styled-components';
import { LightTheme } from '../core/Themes.js';
import FormInput from '../core/FormInput/FormInput.jsx';
import FormToggle from '../core/FormToggle/FormToggle.jsx';
import RoundButton from '../core/RoundButton/RoundButton.jsx';
import ValidationMessage from '../core/Validation/ValidationMessage';
import ApplyValidation from '../utils/Validation.js';

const Header = styled.h3`

	${props => {
		const theme = props.theme;
		return css`
			margin-bottom: ${theme.margin.half};
		`;
	}}
`;

const Container = styled.section`

	${props => {
		const theme = props.theme;
		return css`
			margin: ${theme.margin.full};
			min-width: 350px;
		`;
	}}
`;

export default class AttachVoicemail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phoneNumber: '',
			consumerNumber: '',
			showConsumerName: false,
			serverError: {}
		};

		this.handleAttach = this.handleAttach.bind(this);
		this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
		this.onConsumerNumberChange = this.onConsumerNumberChange.bind(this);

		ApplyValidation(this, {
			phoneTooLong: {
				validIf: state => state.phoneNumber === '' || state.phoneNumber.length <= 10,
				message: 'phone number cannot be more than 10 digits'
			},
			consumerRequired: {
				validIf: state => state.consumerNumber !== '',
				trackDirtyState: state => state.consumerNumber,
				message: 'Consumer Number required'
			}
		});
	}

	async handleAttach(evt) {
		const self = this;
		const isValid = this.stateIsValid();

		if (isValid) {
			var response = await HttpAgent.attachVoicemail({ id: this.props.voicemail.id, phoneNumber: this.state.phoneNumber, consumerNumber: this.state.consumerNumber, callId: this.props.voicemail.callId });
			if (response.data.success) {
				self.validateAndSetState(prevState => ({
					...prevState,
					serverError: {
						isNotValid: false,
						message: ''
					}
				}));
				if (this.props.onAttached) {
					this.props.onAttached();
				}
			} else {
				self.validateAndSetState(prevState => ({
					...prevState,
					serverError: {
						isNotValid: true,
						message: response.data.failureMessage
					}
				}));
			}
		}
	}

	onConsumerNumberChange(evt) {
		const newValue = evt.target.value;
		this.validateAndSetState(prev => ({
			...prev,
			consumerNumber: newValue,
			showConsumerName : false
		}));
	}

	onPhoneNumberChange(evt) {
		const newValue = evt.target.value;
		this.validateAndSetState(prev => ({
			...prev,
			phoneNumber: newValue
		}));
	}

	async componentDidMount() {
		this.setStateWithDirtyTracking(() => ({
			phoneNumber: this.props.voicemail.fromPhoneNumber,
			consumerNumber: this.props.consumerInfo && this.props.consumerInfo.consumerNumber ? this.props.consumerInfo.consumerNumber : '',
			showConsumerName: this.props.consumerInfo ? true : false
		}));
	}



	render() {
		const validation = this.state.validation;
		var header = this.state.showConsumerName ? `Attach to ${this.props.consumerInfo.consumerName}` : 'Attach Consumer'
		return <Container>
			<Header>{header}</Header>
			<FormInput validation={[validation.phoneTooLong]} value={this.state.phoneNumber} name="phoneNumber" label="Phone Number" placeholder="Phone Number" onChange={this.onPhoneNumberChange} />
			<FormInput validation={[validation.consumerRequired]} value={this.state.consumerNumber} name="debtorNumber" label="Consumer Number" placeholder="Consumer Number" onChange={this.onConsumerNumberChange} />
			<ValidationMessage validation={this.state.serverError} />

			<RoundButton primary icon="plus" onClick={this.handleAttach}>
				Attach
			</RoundButton>
			<RoundButton icon={["far", "times"]} onClick={this.props.onAttached}>
				Close
			</RoundButton>
		</Container>;
	}
}
