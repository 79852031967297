import React from 'react';
import styled, {css} from "styled-components";
import TabDefinitions from './TabDefinitions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Tabs';

const StandardView = styled.div`

	${props => {
		const theme = props.theme;
		var padding = theme.margin.full;
		var height = null;

		// On the TabDefinitions component, set containerStyle to true if you need a 
		// tab that fills the available view without scrolling
		if (props.containerStyle) {
			padding = 0;
			height = css`
				height: 100%;
			`;
		}
		return css`
		
			padding: ${padding};

			h3 {
				font-family: ${theme.fonts.heading};
				font-size: 1.45em;
			}		

			${height}
		
		`;
	}}

`;


class TabContainer extends React.Component {

	render() {
		const tabs = this.props.tabs;
		var tabComponent = null;
		var tab = tabs.find(tab => tab.selected);
		if (!tab && tabs.length > 0) {
			console.error("No tab is currently selected.  Selecting first tab");
			tab = tabs[0];
		}
		if (tab) {
			tabComponent = TabDefinitions.RenderTab(tab);
		}
		return <StandardView containerStyle={tab.containerStyle}>
			{tabComponent}
		</StandardView>;
	}
}

export default connect(state => state.tabs,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(TabContainer)