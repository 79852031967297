import React from 'react';
import HttpAgent from '../../../httpagent.js';
import { ListItem, ListItemText, ListItemSecondaryAction, Checkbox, Snackbar, Grid, Switch } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ConfirmDialog from '../../_shared-components/core/ConfirmDialog';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';

class RuleItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            dialogOpen: false,
			showSnackbar: false,
			snackbarSeverity: 'error',
			snackbarMessage: '',
		}

		this.editRule = this.editRule.bind(this);
		this.toggleRule = this.toggleRule.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleDialogConfirm = this.handleDialogConfirm.bind(this);
	}

	editRule(e) {
		this.props.history.push({
			pathname: `/edit/${this.props.rule.id}`,
			groups: this.props.groups
		});
	}

	toggleRule(e) {
		this.setState({
			dialogTitle: e.target.checked ? "Enable Rule" : 'Disable Rule',
			dialogMessage: e.target.checked ? "Are you sure that you want to enable this rule?" : "Are you sure you want to disable this rule?",
            dialogOpen: true
		});
	}

	async updateRule(complianceRule) {
        try {
			let response = await HttpAgent.updateComplianceRules(complianceRule);

			if (this.props.onRuleChanged) {
				this.props.onRuleChanged(response.data);
			}
		} catch (error) {
			if (error.response.status === 400) {
				this.setState({
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage:
						`There are outstanding validation issues that must be resolved before the rule can be saved.`,
				});
			} else {
				this.setState({
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage: `There was an unexpected error that occurred while trying to save the rule.`,
				});
			}
        }
    }

	handleDialogClose() {
		this.setState({
            dialogOpen: false
		});
	}

	async handleDialogConfirm() {
		var rule = { ...this.props.rule };
		rule.enabled = !this.props.rule.enabled;
        await this.updateRule(rule);
		this.setState({
			dialogOpen: false,
		});
	}

	render() {
		var ruleInfo = this.props.rule && `Last updated ${new Date(this.props.rule.lastUpdatedTimestamp).toLocaleString()} by ${this.props.rule.personCode}`;
        return <Grid container justifyContent="center" alignItems="center" >
			<Grid item xs={1} >
				<Switch
					checked={this.props.rule.enabled}
					onClick={this.toggleRule}
					color="primary"
				/>
			</Grid>
			<Grid item xs={11}>
				<ListItem button divider onClick={this.editRule}>
					<ListItemText
						primary={this.props.rule.name && this.props.rule.name}
						secondary={ruleInfo} />
				</ListItem>
			</Grid>
			<ConfirmDialog
				open={this.state.dialogOpen}				
				dialogTitle={this.state.dialogTitle}
				dialogMessage={this.state.dialogMessage}
				onCancel={this.handleDialogClose}
				onConfirm={this.handleDialogConfirm}
			/>

			<Snackbar open={this.state.showSnackbar} autoHideDuration={6000} onClose={this.closeSnackbar}>
				<MuiAlert elevation={6} variant="filled" onClose={this.closeSnackbar} severity={this.state.snackbarSeverity}>
					{this.state.snackbarMessage}
				</MuiAlert>
			</Snackbar>
		</Grid>;
	}
}

export default withRouter(RuleItem);