import React from 'react';
import styled, { css, withTheme } from 'styled-components';

import Card from '../core/Card/Card.jsx';
import StatusIndicator from '../core/StatusIndicator/StatusIndicator.jsx';
import Tag from '../core/Tag/Tag.jsx';
import RoundButton from '../core/RoundButton/RoundButton.jsx';

const AgentCardBase = styled(Card)`

	padding: 0;
	display: grid;
	grid-template-areas: 
		"status name name indicators listen duration"
		"status debtor-call attributes attributes listen duration";
	grid-template-columns: auto auto auto 1fr 64px 80px;
	grid-template-rows: auto 32px;

	h3 {
		font-size: 1.4em;

		code {
			font-size: .8em;
		}
	}

	h5, h3 code {
		color: ${props => props.theme.colors.secondary};
	}

`;

const NameCell = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			padding: ${theme.margin.quarter} ${theme.margin.full} 0 0;
			grid-area: name;
		`;
	}}

`;

const StatusIndicatorCell = styled(StatusIndicator)`

	${props => {
		const theme = props.theme;
		return css`
			grid-area: status;
			padding: 0 0 0 ${theme.margin.full};
			display: flex;
			align-items: center;
			font-size: 1.5em;
			margin-right: ${theme.margin.half};
		`;
	}}

`;

const DurationCell = styled.div`

	${props => {
		const theme = props.theme;
		var backgroundColor = props.positive ? theme.baseColors.positiveDark : theme.baseColors.brandDark;
		var backgroundHighlight = props.positive ? theme.baseColors.positive : theme.baseColors.brandHighlight;
		var color = "inherit";
		if (props.wrapUp) {
			backgroundColor = theme.baseColors.brandHighlight.darken(0.15);
			backgroundHighlight = theme.baseColors.brandHighlight.lighten(0.15);
			color = theme.baseColors.brandDark.hex();
		}
		return css`
			background: linear-gradient(180deg, ${backgroundColor.hex()} 0%,
				${backgroundHighlight.hex()} 600%);
			box-shadow: inset 0px 2px 0px ${backgroundHighlight.alpha(0.1).rgb().string()},
				-8px 0 5px -5px rgba(0,0,0,.125);	
			transition: background-color ease-in-out .05s, 
				box-shadow ease-in-out .05s;
			grid-area: duration;
			font-family: ${theme.fonts.numeric};
			font-size: 1.3em;
			border-radius: ${theme.margin.double};
			border-top-left-radius: ${theme.margin.half};
			border-bottom-left-radius: ${theme.margin.half};
			display: flex;
			justify-content: center;
			align-items: center;
			color: ${color};
		`;
	}}

`;

const AttributeCell = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			grid-area: attributes;
			padding: 0 ${theme.margin.full} ${theme.margin.half} 0;
			margin-right: ${theme.margin.half};
		`;
	}}

`;

const SmallRoundButton = styled(RoundButton)`

	${props => {
		const theme = props.theme;
		return css`
			font-size: .8em;
			padding: ${theme.margin.quarter} ${theme.margin.half};
			margin-right: ${theme.padding.double};
			margin-top: 3px;
		`;
	}}

`;

const ActiveCallText = styled.h5`

	grid-area: debtor-call;
	padding: ${props => props.theme.margin.half};
	padding-right: ${props => props.theme.margin.full};
	padding-left: 0;

`;

const AdditionalAttributeText = styled.h5`

	display: inline-block;
	padding: ${props => props.theme.margin.half};

`;

class AgentCard extends React.Component {

	handleViewDebtor(consumerId) {
		if (window.bloodhound.loadConsumer) {
			console.log('Invoking loadConsumer back to bloodhound', this.props.tabKey, consumerId);
			window.bloodhound.loadConsumer(this.props.tabKey, consumerId);
		}
	}

	render() {
		var a = this.props.agent;
		var currentTask = this.props.agent.currentWorkTasks && this.props.agent.currentWorkTasks.length > 0 ? this.props.agent.currentWorkTasks[0] : null;
		var attributes = null;
		const maxAttributeCount = 3;
		var additionalText = null;
		if (currentTask != null && currentTask.attributes != null) {
			attributes = currentTask.attributes.map(t => 
				<Tag background={this.props.theme.baseColors.background.hex()} key={t.key}>{t.name || t.key}</Tag>
			);
			attributes = attributes.slice(0, maxAttributeCount);
			if (currentTask.attributes.length > maxAttributeCount) { 
				additionalText = <AdditionalAttributeText>+ {currentTask.attributes.length - maxAttributeCount} More</AdditionalAttributeText>;
			}
		} 

		return <AgentCardBase>
			<StatusIndicatorCell agent={a} />
			<NameCell>
				<h3>
					{a.name || "Unnamed Agent"} <code>{a.personCode}</code>
				</h3>
			</NameCell>
			<DurationCell positive={currentTask != null} wrapUp={currentTask && currentTask.lastPhoneCallTimeEnd}>{a.duration}</DurationCell>
			{!currentTask && <ActiveCallText>No Active Call</ActiveCallText>}
			{(currentTask && !currentTask.lastPhoneCallTimeEnd) && <ActiveCallText>On Call</ActiveCallText>} 
			{(currentTask && currentTask.lastPhoneCallTimeEnd) && <ActiveCallText>Wrapping Up</ActiveCallText>} 
			<AttributeCell>
				{currentTask && currentTask.consumerId && 
					<SmallRoundButton icon={["fas", "user-circle"]} 
						onClick={() => this.handleViewDebtor(currentTask.consumerId)}>View Debtor
					</SmallRoundButton>
				} 												
				{attributes}
				{additionalText}			
			</AttributeCell>
		</AgentCardBase>;
	}	
}

export default withTheme(AgentCard);