import React from 'react';
import styled, { css, ThemeProvider } from "styled-components";
import { UpperLayer, LowerLayer } from "../core/Themes.js";
import DirectionalIndicator from '../core/DirectionalIndicator/DirectionalIndicator.jsx';
import TypeIndicator from '../core/TypeIndicator/TypeIndicator.jsx';
import StatusIndicator from '../core/StatusIndicator/StatusIndicator.jsx';
import Tag from '../core/Tag/Tag.jsx';
import RoundButton from '../core/RoundButton/RoundButton.jsx';
import HttpAgent from '../../httpagent.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Agent';
import { actionCreators as notificationActionCreators } from '../../store/Notifications';
import NotificationDefinitions from '../notifications/NotificationDefinitions';

const Cell = styled.div`
	grid-area: ${props => props.area};

	${props => {
		if (props.center) {
			return css`
			
				align-items: center;
				justify-content: center;
				display: flex;
			`;
		}
	}}

`;

const NumericCell = styled(Cell)`

	code {
		font-family: ${props => props.theme.fonts.numeric};
	}
	text-align: right;

`;

const NameCell = styled(Cell)`

	@media screen and (max-width: 700px) {
		display: none;
	}

	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

`;

const Header = styled.header`

	${props => {
		const theme = props.theme;
		const logOutColWidth = props.monitoringPermission >= 1 ? "65px" : "0px";

		return css`
			border-bottom: 2px solid ${theme.colors.border};

			font-family: ${theme.fonts.heading};
			text-transform: uppercase;
			font-size: .9em;
			
			display: grid;
			grid-template-areas: 
				"logout status type person-code name io queue-code duration";
			grid-template-columns: ${logOutColWidth} 65px 65px 1fr 0 60px 60px 75px;

			@media screen and (min-width: 701px) {
				grid-template-columns: ${logOutColWidth} 65px 65px 100px 1fr 60px 60px 75px;
			}

			${Cell} {
				border-right: 2px solid ${theme.colors.border};
				padding: ${theme.margin.half} ${theme.margin.quarter};
				text-align: center;

				&:last-child {
					border-right: none;
				}
			}
			
		`;
	}}

`;

const List = styled.ul`

	border-bottom: 2px solid ${props => props.theme.colors.border};
	margin: 0;
	padding: 0;

`;

const Item = styled.li`

	${props => {
		const theme = props.theme;
		const logOutColWidth = props.monitoringPermission >= 1 ? "65px" : "0px";

		return css`
			background: ${props.selected ? theme.colors.divider : theme.colors.background};
			border-bottom: 2px solid ${theme.colors.divider};
			list-style: none;
			transition: background-color ease-in-out .05s, max-height ease-in-out 0.75s;	
			
			display: grid;
			grid-template-areas: 
				"logout status type person-code name io queue-code duration"
				"details details details details details details details details"
				"actions actions actions actions actions actions actions actions";
			grid-template-columns: ${logOutColWidth} 30px 100px 1fr 0 60px 60px 75px;

			@media screen and (min-width: 700px) {
				grid-template-columns: ${logOutColWidth} 30px 100px 100px 1fr 60px 60px 75px;
			}

			&:hover {
				background: ${props.selected ? "none" : theme.colors.divider};
			}

			&:active {
				background: none;
			}

			&:last-child {
				border-bottom: none;
			}

			${Cell} {
				padding: ${theme.margin.half} ${theme.margin.quarter};
			}
			
		`;
	}} 

`;

const ItemDetails = styled.div`

	${props => {
		const theme = props.theme;
		return css`
		
			grid-area: details;
			margin-top: ${theme.margin.half};
			margin-bottom: ${theme.margin.quarter};
			padding: 0 ${theme.margin.half};
		
		`;
	}}


`;

const ItemActions = styled.div`

	grid-area: actions;
	margin-bottom: ${props => props.theme.margin.half};
	padding: 0 ${props => props.theme.margin.half};
	text-align: right;

`;

const SmallRoundButton = styled(RoundButton)`

	${props => {
		const theme = props.theme;
		return css`
			font-size: .8em;
			margin-top: 3px;
			cursor: pointer;
		`;
	}}

`;

class ItemList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedItemKey: null,
			dropInButtonEnabled: true
		};
		this.onItemClick = this.onItemClick.bind(this);
	}

	async onItemClick(key) {
		this.setState(ps => {
			if (ps.selectedItemKey == null || ps.selectedItemKey != key) {
				return {
					selectedItemKey: key
				};
			}
			return {
				selectedItemKey: null
			}
		});
	}

	handleViewDebtor(consumerId) {
		if (window.bloodhound.loadConsumer) {
			console.log('Invoking loadConsumerScreen back to bloodhound', this.props.tabKey, consumerId);
			window.bloodhound.loadConsumer(this.props.tabKey, consumerId);
		}
	}

	async handleDrop(taskId) {
		var req = { taskId: taskId };
		await HttpAgent.dropIn(req);

		this.setState({
			dropInButtonEnabled: true
		});
	}

	handleDropIn(taskId) {
		if (!this.state.dropInButtonEnabled) {
			return;
		}

		this.setState({
			dropInButtonEnabled: false
		}, async () => await this.handleDrop(taskId));
	}

	getActiveTask() {
		if (this.props.currentWorkTasks) {
			return this.props.currentWorkTasks.find(x => x.blocking && x.activePhoneCallId != null);
		}
		return null;
	}

	handleLogOutAgentSession(event, agent) {
		event.stopPropagation();

		if (agent) {
			agent.monitoringAgentId = this.props.agentId;
			agent.monitoringAgentBloodhoundUserId = this.props.bloodhoundUserId;
			agent.monitoringAgentPersonCode = this.props.personCode;

			this.props.createNotification(
				{
					hideDismissButton: true,
					type: NotificationDefinitions.types.confirmChoice,
					body: `Continue logging out agent: ${agent.personCode}?`,
					title: 'Confirm logout Session?',
					icon: ["fas", "sign-out"],
					onConfirm: async (evt) => {
						await HttpAgent.logOutAgentSession(agent);
					}
				});
		}
	}

	render() {
		var agentTask = this.getActiveTask();
		var itemsWithSelection = this.props.items.map(i => {
			return {
				...i,
				selected: i.key == this.state.selectedItemKey
			}
		});

		var items = itemsWithSelection.map(i => {
			var attributes = i.agent.attributes.map(t =>
				<Tag key={t.key}>{t.name || t.key}</Tag>
			);
			var type = i.task != null ? i.task.workTaskType : null;
			return <Item key={i.key} onClick={(e) => this.onItemClick(i.key)} selected={i.selected} monitoringPermission={this.props.monitoringPermission}>
				{this.props.monitoringPermission >= 1 ? 
					<Cell center area="logout">
						<SmallRoundButton icon={["fas", "sign-out"]}
							onClick={(event) => this.handleLogOutAgentSession(event, i.agent)}>
						</SmallRoundButton>
					</Cell> : ""
				}

				<Cell center area="status">
					<StatusIndicator agent={i.agent} />
				</Cell>
				<Cell center area="type">
					<TypeIndicator workTaskType={type} />
				</Cell>
				<Cell area="person-code"><code>{i.agent.personCode}</code></Cell>
				<NameCell area="name">{i.agent.name}</NameCell>
				<Cell center area="io">
					<DirectionalIndicator up={i.outbound} down={i.inbound} />
				</Cell>
				<Cell area="queue-code"><code>{i.queueCode}</code></Cell>
				<NumericCell area="duration"><code>{i.duration}</code></NumericCell>
				{
					i.selected &&
					<ThemeProvider theme={LowerLayer}>
						<React.Fragment>
							<ItemDetails>
								<h4>Agent Attributes</h4>
								{
									attributes.length > 0 &&
									<React.Fragment>
										{attributes}
									</React.Fragment>
								}
								{
									attributes == 0 &&
									<span>No Attributes Applied</span>
								}
							</ItemDetails>
							{i.task && 
								<ItemActions>
								{i.task.consumerId && <RoundButton icon={["fas", "user-circle"]}
									onClick={() => this.handleViewDebtor(i.task.consumerId)}>View Debtor
									</RoundButton>}
								{!agentTask && i.task.activePhoneCallId && this.props.monitoringPermission && (this.props.monitoringPermission >= 2) && <RoundButton icon={["fas", "headset"]}
									onClick={() => this.handleDropIn(i.task.id)} disabled={!this.state.dropInButtonEnabled}>Drop In
									</RoundButton>}
								</ItemActions>
							}
						</React.Fragment>
					</ThemeProvider>
				}
			</Item>
		});

		return <React.Fragment>
			<Header monitoringPermission={this.props.monitoringPermission}>
				{this.props.monitoringPermission >= 1 ?
					<Cell area="logout">Logout</Cell> :
				""}
				<Cell area="status">Status</Cell>
				<Cell area="type">Type</Cell>
				<Cell area="person-code">Agent Code</Cell>
				<NameCell area="name">Agent Name</NameCell>
				<Cell area="io">In/Out</Cell>
				<Cell area="queue-code">Queue</Cell>
				<Cell area="duration">Duration</Cell>
			</Header>
			<ThemeProvider theme={UpperLayer}>
				<List>
					{items}
				</List>
			</ThemeProvider>
		</React.Fragment>;
	}

}

export default connect(state => {
	return {
		...state.agent,
		...state.notifications
	};
}
	, dispatch => bindActionCreators({
		actionCreators,
		...notificationActionCreators
	}, dispatch)
)(ItemList);
