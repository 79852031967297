import React from 'react';
import styled, { css } from 'styled-components';

export const EntryInput = styled.input`

	${props => {
		const theme = props.theme;
		return css`

			background: ${theme.input.background};
			color: ${theme.input.text};
			display: block;
			border-radius: 999px;
			padding: ${theme.padding.double} ${theme.padding.quad};
			box-shadow: inset 0 0 0 2px ${theme.colors.border}, inset 0 0 0 2px transparent;
			border-style: initial;
			outline: none;			
			transition: box-shadow ease-in-out .05s;
			font-family: ${theme.fonts.primary};
			width: calc(${props.width || "200px"} - (${theme.padding.quad} * 2));

			&:focus {
				box-shadow: inset 0 0 0 2px ${theme.colors.brand}, ${theme.well.shadow};
			}

			&:disabled {
				background: ${theme.colors.background};
			}

			${props.codeField && css`
				font-family: ${theme.fonts.code};
				font-size: 1.25em;
				padding: calc(${theme.padding.full} + ${theme.padding.half})  ${theme.padding.quad} ${theme.padding.full};
			`}

			${props.numericField && css`
				font-family: ${theme.fonts.numeric};
				font-size: 1.25em;
				letter-spacing: -0.05em;
				padding: calc(${theme.padding.full} + ${theme.padding.half})  ${theme.padding.quad} ${theme.padding.full};
			`}

		`;
	}}
`;

/**
 * An `input` element for text with Encore styling applied. All properties are 
 * passed through to the core `input` element. 
 * 
 * @property {boolean}	[codeField]				- Set to true to use code-friendly font 
 * 	& disable spell check
 * @property {boolean}	[numericField]			- Set to true to use number-friendly font
 */
export default class TextInput extends React.Component {

	render() {

		var inputProps = Object.assign({}, this.props);
		var unsafeProps = ['children', 'onClickOutside'];
		unsafeProps.forEach(p => {
			delete inputProps[p];
		});

		var spellcheck = this.props.codeField ? "false" : "true";

		return <EntryInput {...inputProps} spellCheck={spellcheck} ref={this.props.innerRef} />;
	}
}