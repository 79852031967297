import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
	grid-area: sidebar;

	${props => {
		const theme = props.theme;
		return css`
			background: ${theme.colors.background};
			border-right: 2px solid ${theme.colors.border};
		`;
	}} 
`;

/**
 * Structural container for sidebar items
 */
class Sidebar extends React.Component {

	render() {
		return <Container className={this.props.className}>
			{this.props.children}
		</Container >;
	}
}

export default Sidebar;
