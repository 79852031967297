import React from 'react';
import RuleItem from './RuleItem';
import { Box, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

class RuleGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true
		};
		this.expandGroup = this.expandGroup.bind(this);
		this.onRuleChanged = this.onRuleChanged.bind(this);
	}

	expandGroup() {
		this.setState({
			expanded: !this.state.expanded
		});
	}

	onRuleChanged(rule) {
		if (this.props.onRuleChanged) {
			this.props.onRuleChanged(rule);
		}
	}

	render() {
		var rules = this.props.rules;
		var groupName = (rules && rules.length > 0 ? rules[0].group : '');
		var ruleList = '';

		if (rules) {
			ruleList = rules && rules.map((item, i) => {
				return (<RuleItem key={item.id} rule={item} groups={this.props.groups} onRuleChanged={this.onRuleChanged} />);
			});
		}

		return <React.Fragment>
			<ListItem button divider onClick={this.expandGroup}>
				<ListItemText primary={groupName} primaryTypographyProps={{ variant: "h3" }} />
				{this.state.expanded ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
				<Box>
					{ruleList}
				</Box>
			</Collapse>
		</React.Fragment>;
	}
}

export default RuleGroup;