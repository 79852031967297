import React from 'react';
import styled, { css } from 'styled-components';

import AgentCard from '../command-center/AgentCard.jsx';

import AgentTaskDuration from '../utils/AgentTaskDuration.js';

import posed, { PoseGroup } from 'react-pose';

import { connect } from 'react-redux';
import { withRouter }  from "react-router-dom";

const CardContainer = posed.li({});

const Container = styled.ul`

	${props => {
		const theme = props.theme;
		return css`
			width: 100%;
			list-style: none;
			padding: 0;
			margin-left: -${theme.margin.full};
			margin-right: -${theme.margin.full};
		`;
	}}

`;

class AgentList extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			agents: []
		};

		this.calculateAgentDurations = this.calculateAgentDurations.bind(this);
	}

	componentDidMount() {

		setInterval(() => {
			this.calculateAgentDurations();
		}, 1000);

	}

	calculateAgentDurations() {
			
		this.setState(prevState => {
			var newAgents = this.props.agents;

			newAgents.forEach(agent => {			
				agent.duration = AgentTaskDuration(agent);
			})
			return {
				agents: newAgents
			};
		});
	}

	render() {
		var sorted = this.state.agents.sort(function (a, b) {
			var aName = a.name || a.personCode;
			var bName = b.name || b.personCode;
			var nameSort = aName.localeCompare(bName);
			return nameSort;
		});

		var cards = sorted.map(a => {
			return <CardContainer key={a.personCode}>
				{(a.online && a.personCode != null) &&
					<AgentCard key={a.personCode} agent={a} tabKey={this.props.tabKey} />
				}			
			</CardContainer>;
		});		

		return <Container>			
			<PoseGroup animateOnMount={true}>{cards}</PoseGroup>
		</Container>;
	}
}

export default withRouter(connect(state => state.agents)(AgentList));