export default class ValidationService {
	constructor(config) {
        this.config = config;
    }

	validate(name, value) {
        let result = {
            isInvalid: false
        }
		var selectedValidation = this.config[name];
		if (!selectedValidation) {
            return result;
		}

		const messages = selectedValidation.filter(x => x.isInvalid(value)).map(x => x.label);
        result.isInvalid = messages.length > 0;
        result.messages = messages;
        return result;
    }
}