import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`

	${props => {
		const theme = props.theme;
		return css`

			display: grid;
			grid-template-columns: auto 1fr;
			grid-column-gap: ${theme.margin.half};
			grid-template-rows: auto 1fr;
			grid-row-gap: ${theme.margin.half};
			grid-template-areas:
				"icon header"
				"spacer content";

		`;
	}}

`;

const IconBubble = styled.div`

	${props => {
		const theme = props.theme;
		var color = props.color;
		var iconColor = theme.colors.white;
		return css`

			grid-area: icon;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: ${theme.padding.full};
			font-size: 1.2em;
			height: 1.5em;
			width: 1.5em;

			border-radius: 9999px;
			background: linear-gradient(180deg, ${color.highlightColor} 0%, ${color.baseColorTransparent} 100%), ${color.baseColor};
			box-shadow: inset 0px 1px 6px ${color.baseColor};
			color: ${iconColor};

			.fa-exclamation-triangle
			{
				margin: -4px 0 0 1px;
			}
		`;
	}}

`;

const Header = styled.header`

	grid-area: header;
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 1.25em;
	font-family: ${props => props.theme.fonts.heading};
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: ${props => props.color.baseColor};
	font-weight: 300;

`;

const Content = styled.div`

	grid-area: content;

	${props => {
		if (props.renderPlainText) {
			return css`white-space: pre-wrap;`;
		}
	}}

`;

/**
 * Default notification structure, with icon and header text.
 * 
 * @property {boolean}			[renderPlainText]		-	Applies `white-space: pre-wrap;` to 
 * 	content area. Useful for Bloodhound messages.
 * @property {string}			title						-	Notification Title
 * @property {boolean}			[negative]				-	Apply colors for negative content
 * @property {boolean}			[positive]				-	Apply colors for positive content
 * @property {boolean}			[warning]				-	Apply colors for warning content
 */
class Notification extends React.Component {

	render() {
		var theme = this.props.theme;
		var baseColor = theme.colors.darkGray;
		var baseColorTransparent = theme.baseColors.darkGray.alpha(0).hsl().string();
		var highlightColor = theme.colors.gray;	

		if (this.props.negative) {
			baseColor = theme.colors.negativeDark;
			baseColorTransparent = theme.baseColors.negativeDark.alpha(0).hsl().string();
			highlightColor = theme.colors.negative;	
		}

		if (this.props.warning) {
			baseColor = theme.colors.warning;
			baseColorTransparent = theme.baseColors.warning.alpha(0).hsl().string();
			highlightColor = theme.colors.warningLight;	
		}

		if (this.props.positive) {
			baseColor = theme.colors.positiveDark;
			baseColorTransparent = theme.baseColors.positiveDark.alpha(0).hsl().string();
			highlightColor = theme.colors.positive;	
		}

		let color = {
			baseColor,
			baseColorTransparent,
			highlightColor
		}

		return <Container>
			<IconBubble color={color}>
				<FontAwesomeIcon fixedWidth icon={this.props.icon}></FontAwesomeIcon>
			</IconBubble>
			<Header color={color}>{this.props.title}</Header>
			<Content renderPlainText={this.props.renderPlainText}>{this.props.children}</Content>
		</Container>;
	}
}

export default withTheme(Notification);