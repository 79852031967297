import React from 'react';
import HttpAgent from '../../../httpagent.js';
import { withRouter } from "react-router-dom";
import MultiSelectField from '../../_shared-components/core/MultiSelectField';
import AutoCompleteTextField from '../../_shared-components/core/AutoCompleteTextField';
import ConfirmDialog from '../../_shared-components/core/ConfirmDialog';
import SelectField from '../../_shared-components/core/SelectField';
import Stepper from '../../_shared-components/core/FixedBottomStepper';
import ValidationService from '../../_utilities/ValidationService';
import { Grid, Typography, TextField, Accordion, AccordionSummary, AccordionDetails, Box, Tooltip, Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReviewComplianceRule from './ReviewComplianceRule';
import LoadingDataSpinner from '../../_shared-components/core/LoadingDataSpinner';
import { Options as SelectOptions } from '../_utilities/SelectOptionHelpers';
import NumericTextField from '../../_shared-components/core/NumericTextField';

class EditComplianceRule extends React.Component {
	constructor(props) {
		super(props);

		this.validationService = new ValidationService({
			ruleName: [
				{ label: "Rule name is required.", isInvalid: (value) => { return !value || value.trim().length <= 0 } },
				{ label: "Rule must be less than 100 character long.", isInvalid: (value) => { return Boolean(value && value.length >= 100) } }
			],
			ruleGroup: [
				{ label: "Rule group is required", isInvalid: (value) => { return !value || value.trim().length <= 0 } },
				{ label: "Rule group must be less than 100 character long.", isInvalid: (value) => { return Boolean(value && value.length >= 100) } }
			],
			ruleOperations: [
				{ label: "Rule must have at least one operation selected.", isInvalid: (values) => { return !values || values.length <= 0 } }
			],
			ruleScope: [
				{ label: "Rule must have a scope selected.", isInvalid: (values) => { return !Number.isInteger(+values) } }
			],
			ruleAttempts: [
				{ label: "Rule attempts must be >= 1.", isInvalid: (values) => { return values && (!Number.isInteger(+values) || parseInt(values) < 1) } }
			],
			rulePeriod: [
				{ label: "Rule period must be >= 1.", isInvalid: (values) => { return values && (!Number.isInteger(+values) || parseInt(values) < 1) } }
			],
			ruleRightPartyContact: [
				{ label: "Rule right party contact must be >= 1.", isInvalid: (values) => { return values && (!Number.isInteger(+values) || parseInt(values) < 1) } }
			]
		});

		this.state = {
			activeStep: 1,
			ruleName: '',
			ruleOperations: [],
			ruleGroup: '',
			ruleEntity: '',
			ruleClient: '',
			ruleTerritory: '',
			rulePhoneTechnologies: [],
			ruleOperationalConsents: [],
			ruleIgnoreOneTimeConsent: false,
			rulePrevent: false,
			ruleRunActionOnPrevent: '',
			ruleScope: 0,
			ruleAttempts: undefined,
			rulePeriod: undefined,
			ruleRightPartyContact: undefined,
			ruleTimeOfDay: undefined,
			ruleRootId: 0,
			showSnackbar: false,
			snackbarSeverity: 'error',
			snackbarMessage: '',
			isLoaded: this.props.match.params.id === undefined,
			dialogOpen: false,
			dialogType: '',
			dialogTitle: '',
			dialogMessage: '',
			isDirty: false,
			clientErrors: [],
			operationExpanded: true,
			classificationExpanded: false,
			restrictionExpanded: false,
		};

		this.handleBackClick = this.handleBackClick.bind(this);
		this.handleNextClick = this.handleNextClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleConfirmClick = this.handleConfirmClick.bind(this);
		this.handleRuleFieldChange = this.handleRuleFieldChange.bind(this);
		this.handleRuleFieldOnBlur = this.handleRuleFieldOnBlur.bind(this);
		this.handleRuleAutoCompleteChange = this.handleRuleAutoCompleteChange.bind(this);
		this.handleRuleAutoCompleteInputChange = this.handleRuleAutoCompleteInputChange.bind(this);
		this.handleRuleMultiSelectOnBlur = this.handleRuleMultiSelectOnBlur.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleDialogConfirm = this.handleDialogConfirm.bind(this);
		this.handleOperationsExpandCollapse = this.handleOperationsExpandCollapse.bind(this);
		this.handleClassificationsExpandCollapse = this.handleClassificationsExpandCollapse.bind(this);
		this.handleRestrictionsExpandCollapse = this.handleRestrictionsExpandCollapse.bind(this);
		this.closeSnackbar = this.closeSnackbar.bind(this);
	}

	async componentDidMount() {
		if (this.props.match.params.id) {
			try {
				var response = await HttpAgent.getCurrentComplianceRule(this.props.match.params.id);
				var resData = response.data;
				this.setState({
					ruleEnabled: resData.enabled,
					ruleName: resData.name,
					ruleGroup: resData.group,
					ruleOperations: resData.classifications.targetEvents,
					ruleRunActionOnPrevent: resData.restrictions.preventActionCode ? resData.restrictions.preventActionCode : '',
					ruleEntity: resData.classifications.entityCode,
					ruleClient: resData.classifications.clientCodeGroup,
					rulePhoneTechnologies: resData.classifications.phoneTechnologies,
					ruleTerritory: resData.classifications.territory,
					ruleOperationalConsents: resData.classifications.consentTypes,
					ruleIgnoreOneTimeConsent: resData.restrictions.ignoreOneTimeConsent,
					rulePrevent: resData.restrictions.prevent,
					ruleScope: resData.restrictions.scope,
					ruleAttempts: resData.restrictions.attempts,
					rulePeriod: resData.restrictions.perDays,
					ruleRightPartyContact: resData.restrictions.rightPartyContacts,
					ruleRootId: resData.rootId,
					isLoaded: true
				});

			}
			catch (ex) {
				this.setState({
					isLoaded: true,
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage: 'Failed to load data for the rule that you are attempting to edit.  Please try to refresh your application to re-establish connection.  If errors persist please contact support for assistance.',
				});
			}

		}
	}

	closeSnackbar() {
		this.setState({
			showSnackbar: false
		});
	}

	handleNextClick() {
		this.setState({
			activeStep: this.state.activeStep + 1
		});
	}

	handleBackClick() {
		if (this.state.activeStep !== 1) {
			if (this.state.activeStep === 2) {
				if (this.state.serverErrors) {
					this.handleServerErrors(this.state.serverErrors);
				}

				this.validateRuleConfiguration();
			}

			this.setState({
				activeStep: this.state.activeStep - 1,
				serverErrors: undefined
			});
		}

		if (this.state.activeStep === 1) {
			this.goBackHome();
		}
	}

	handleServerErrors(errors) {	
		if (errors && errors.length) {
			var mappedFields = this.buildReviewList().filter(x => x.postFieldName && x.stateFieldName);

			Object.entries(mappedFields).forEach(([key, value]) => {
				var matchingItem = errors.filter(x => x.MemberNames.some(z => z === mappedFields[key].postFieldName));
				
				if (matchingItem.length > 0) {
					this.setState({
						[`${mappedFields[key].stateFieldName}Validation`]: { isInvalid: true, messages: [matchingItem[0].ErrorMessage] },
						[`${mappedFields[key].type}Expanded`]: true,
					});
				}
			});
		}
	}

	handleCancelClick() {
		this.goBackHome();
	}

	goBackHome() {
		if (this.state.isDirty) {
			this.setState({
				dialogOpen: true,
				dialogType: "cancel",
				dialogTitle: "Continue exiting without saving changes?",
				dialogMessage: "Would you like to continue exiting without saving your changes?"
			});
		} else {
			this.props.history.push('/');
		}
	}

	async handleConfirmClick() {
		if (this.validateRuleConfiguration()) {
			this.setState({
				dialogOpen: true,
				dialogType: "update",
				dialogTitle: "Confirm rule update",
				dialogMessage: "Continue updating rule configuration?"
			});
		} else {
			this.setState({
				showSnackbar: true,
				snackbarSeverity: 'error',
				snackbarMessage:
					`There are outstanding validation issues that must be resolved before the rule can be saved.`,
			});			
		}
	}

	handleRuleFieldChange(e) {
		const { name, value, type, checked } = e.target;
		const combinedValue = type === "checkbox" ? checked : value;
		const validation = this.validationService.validate(name, combinedValue);
		this.setState({ [name]: combinedValue, [`${name}Validation`]: validation, isDirty: true });
	}

	handleRuleFieldOnBlur(e) {
		const { name, value, type, checked } = e.target;
		const combinedValue = type === "checkbox" ? checked : value;
		const validation = this.validationService.validate(name, combinedValue);
		this.setState({ [`${name}Validation`]: validation });
		this.handleSpecialCaseFields();
	}

	handleRuleAutoCompleteChange(e, value, fieldName) {
		const validation = this.validationService.validate(fieldName, value);
		var rawValues = Array.isArray(value) ? value.map(x => x.value) : value;
		this.setState({ [fieldName]: rawValues, [`${fieldName}Validation`]: validation, isDirty: true });
	}

	handleRuleAutoCompleteInputChange(e, values, reason, fieldName) {
		const validation = this.validationService.validate(fieldName, values);
		var rawValues = Array.isArray(values) ? values.map(x => x.value) : values;
		this.setState({ [fieldName]: rawValues, [`${fieldName}Validation`]: validation, isDirty: (reason !== 'reset') });
	}

	handleRuleMultiSelectOnBlur(e) {
		var name = e.target.name;
		const validation = this.validationService.validate(name, this.state[name]);
		this.setState({ [`${name}Validation`]: validation });
	}

	handleDialogClose() {
		this.setState({
			dialogOpen: false
		});
	}

	async handleDialogConfirm() {
		this.setState({
			dialogOpen: false,
		});

		if (this.state.dialogType == 'cancel') {
			this.props.history.push('/');
		}

		if (this.state.dialogType == 'update' && this.state.activeStep === 2) {
			var complianceRule = this.setComplianceRuleObject();
			await this.upsertComplianceRule(complianceRule);
		}
	}

	handleOperationsExpandCollapse(e, expanded) {
		this.setState({ operationExpanded: expanded });
	}

	handleClassificationsExpandCollapse(e, expanded) {
		this.setState({ classificationExpanded: expanded });
	}

	handleRestrictionsExpandCollapse(e, expanded) {
		this.setState({ restrictionExpanded: expanded });
	}

	handleSpecialCaseFields() {
		if (this.state.ruleEntity === '') {
			if (this.state.ruleClient !== '') {
				this.setState({ ruleClient: '' });
			}

			if (this.state.ruleTerritory !== '') {
				this.setState({ ruleTerritory: '' });
			}
		}
	}

	isNumeric(str) {
		if (typeof str != "string") {
			return false;
		}

		return !isNaN(str) &&
			!isNaN(parseFloat(str));
	}

	replaceAll(str, find, replace) {
		return str.toString().split(find).join(replace);
	}

	setComplianceRuleObject() {
		var complianceRule = {
			classifications: {
				entityCode: this.state.ruleEntity.toLocaleUpperCase(),
				clientCodeGroup: this.state.ruleClient.toLocaleUpperCase(),
				consentTypes: this.state.ruleOperationalConsents,
				phoneTechnologies: this.state.rulePhoneTechnologies,
				targetEvents: this.state.ruleOperations,
				territory: this.state.ruleTerritory.toLocaleUpperCase()
			},
			restrictions: {
				preventActionCode: this.state.ruleRunActionOnPrevent.toLocaleUpperCase(),
				attempts: this.state.ruleAttempts,
				prevent: this.state.rulePrevent,
				perDays: this.state.rulePeriod,
				rightPartyContacts: this.state.ruleRightPartyContact,
				scope: this.state.ruleScope,
                ignoreOneTimeConsent: this.state.ruleIgnoreOneTimeConsent
			},
			enabled: this.state.ruleEnabled,
			name: this.state.ruleName,
			group: this.state.ruleGroup,
			rootId: this.state.ruleRootId
		}

		return complianceRule;
	}

	async upsertComplianceRule(complianceRule) {
		try {
			await HttpAgent.updateComplianceRules(complianceRule);
			this.props.history.push('/');
		} catch (error) {
			if (error.response.status === 400) {
				this.setState({
					serverErrors: error.response.data,
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage:
						`There are outstanding validation issues that must be resolved before the rule can be saved.`,
				});

				console.log('Server validation error(s): ', error.response.data.map(x => x.ErrorMessage));
			} else {
				this.setState({
					showSnackbar: true,
					snackbarSeverity: 'error',
					snackbarMessage: `There was an unexpected error that occurred while trying to save the rule.`,
				});
			}
		}
	}

	async getEntityList() {
		try {
			let response = await HttpAgent.getEntityList();
			let data = response.data;

			this.setState({ ruleEntities: data });
		} catch (error) {
			console.log("ERROR RETRIEVING ENTITY LIST: ", error.response);
		}
	}

	getSelectedItemsTextString(options, values) {
		var items = options.filter(x => values === x.value || (Array.isArray(values) && values.find(z => z.value === x.value || z === x.value)));
		let strings = items.map(x => x.label);
		return strings;
	}

	validateRuleConfiguration() {
		var errors = [];
		var reviewList = this.buildReviewList().filter(x => x.stateFieldName);

		// Check for validation service errors
		Object.entries(this.validationService.config).forEach(([key, value]) => {
			const validation = this.validationService.validate(key, this.state[key]);

			if (validation.isInvalid && validation.messages.length > 0) {
				for (var i = 0; i < validation.messages.length; i++) {
					errors.push(validation);

					this.setState({
						[`${key}Validation`]: validation,
					});
				}

				var typeIndex = reviewList.findIndex(x => x.stateFieldName === key);
				if (typeIndex !== -1 && !this.state[`${reviewList[typeIndex].type}Expanded`]) {
					this.setState({
						[`${reviewList[typeIndex].type}Expanded`]: true,
					});
				}
			}
		});

		// CHECK CONDITIONAL VALIDATION
		if (!this.state.rulePrevent) {
			this.setState({				
				ruleAttemptsValidation: undefined,
				ruleRightPartyContactValidation: undefined
			});

			if (!this.ruleStateHasValue("ruleAttempts") && !this.ruleStateHasValue("ruleRightPartyContact")) {
				let validationError = { isInvalid: true, messages: ['If the restriction does not prevent, attempts or right party contacts must be provided'] };

				this.setState({
					restrictionExpanded: true,
					ruleAttemptsValidation: validationError,
					ruleRightPartyContactValidation: validationError,
				});

				errors.push(validationError);
			}			
		}

		console.log('Rule Validation error(s): ', errors.map(x => x.messages));
		this.setState({ clientErrors: errors });
		return (errors.length === 0);
	}

	ruleStateHasValue(stateField) {
		return !!this.state[stateField];
	}

	buildReviewList() {
		return [
			// Compile operation review data
			{ type: "operation", stateFieldName: "ruleName", label: "Rule name", alwaysShow: true, content: this.state.ruleName, validation: this.validationService.validate('ruleName', this.state.ruleName) },
			{ type: "operation", stateFieldName: "ruleGroup", label: "Rule group", alwaysShow: true, content: this.state.ruleGroup, validation: this.validationService.validate('ruleGroup', this.state.ruleGroup) },
			{ type: "operation", stateFieldName: "ruleOperations", label: "Operation", alwaysShow: true, content: this.getSelectedItemsTextString(SelectOptions.operations, this.state.ruleOperations), validation: this.validationService.validate('ruleOperations', this.state.ruleOperations) },

			// Compile classification review data
			{ type: "classification", label: "Phone Technology", content: this.getSelectedItemsTextString(SelectOptions.phoneTechnology, this.state.rulePhoneTechnologies) },
			{ type: "classification", label: "Operational Consent", content: this.getSelectedItemsTextString(SelectOptions.operationalConsent, this.state.ruleOperationalConsents) },
			{ type: "classification", stateFieldName: "ruleClient", postFieldName: 'ClientCodeGroup', label: "Client or Client Group", content: this.state.ruleClient.toUpperCase() },
			{ type: "classification", stateFieldName: "ruleEntity", postFieldName: 'EntityCode', label: "Entity", content: this.state.ruleEntity.toUpperCase() },
			{ type: "classification", stateFieldName: "ruleTerritory", postFieldName: 'Territory', label: "Territory", content: this.state.ruleTerritory.toUpperCase() },

			// Compile restrictions review data
			{ type: "restriction", label: "Scope", content: this.getSelectedItemsTextString(SelectOptions.scope, this.state.ruleScope, false) },
			{ type: "restriction", stateFieldName: "ruleAttempts", label: "Attempts", postFieldName: 'Attempts', content: this.state.ruleAttempts, validation: this.validationService.validate('ruleAttempts', this.state.ruleAttempts) },
			{ type: "restriction", label: "Ignore One Time Consent", content: this.getSelectedItemsTextString(SelectOptions.binaryOption, this.state.ruleIgnoreOneTimeConsent, false) },
			{ type: "restriction", label: "Prevent", showPrevented: true, isPrevented: this.state.rulePrevent, content: this.getSelectedItemsTextString(SelectOptions.binaryOption, this.state.rulePrevent, false) },
			{ type: "restriction", stateFieldName: "ruleRunActionOnPrevent", postFieldName: 'PreventActionCode', label: "Prevent Action Code", showPrevented: true, content: this.state.ruleRunActionOnPrevent },
			{ type: "restriction", stateFieldName: "rulePeriod", label: "Period (Days)", postFieldName: 'PerDays', content: this.state.rulePeriod, validation: this.validationService.validate('rulePeriod', this.state.rulePeriod) },
			{ type: "restriction", stateFieldName: "ruleRightPartyContact", label: "Right Party Contact", postFieldName: 'RightPartyContacts',content: this.state.ruleRightPartyContact, validation: this.validationService.validate('ruleRightPartyContact', this.state.ruleRightPartyContact) },
		];
	}

	render() {
		const totalSteps = 2;
		var isEditMode = this.props.match.params.id !== undefined;

		return <Box pt={1}>
			<Box mb={1} display="flex">
				<Box mr={1}><Button variant="contained" color="primary" onClick={this.handleCancelClick}>Cancel</Button></Box>
				<Box flexGrow={1}>
					<Typography variant="h1">{!isEditMode ? "Create a New Rule" : "Edit Rule"}</Typography>
				</Box>
			</Box>
			{!this.state.isLoaded && <LoadingDataSpinner message="Loading Compliance Rule..." gridProps={{style: { marginTop: '20%', fontStyle: 'italic' }}} />}
			{this.state.isLoaded && <Box>
				<Box mb={4}>
					<Grid container>
						{this.state.activeStep === 1 &&
							<React.Fragment>
								<Accordion expanded={this.state.operationExpanded} style={{ width: '100%' }} onChange={this.handleOperationsExpandCollapse}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Box display="block">
											<Typography variant="h2">Bloodhound Operations</Typography>
											<Typography variant="caption">Set the rule name and group and pick the operations associated with the rule.</Typography>
										</Box>
									</AccordionSummary>
									<AccordionDetails >
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
												<TextField
													fullWidth
													error={this.state.ruleNameValidation ? this.state.ruleNameValidation.isInvalid : false}
													helperText={this.state.ruleNameValidation ? this.state.ruleNameValidation.messages[0] : ''}
													name="ruleName"
													label="Rule Name"
													value={this.state.ruleName}
													onBlur={this.handleRuleFieldOnBlur}
													onChange={this.handleRuleFieldChange} />
											</Grid>
											<Grid item xs={12} md={6}>
												<AutoCompleteTextField
													options={this.props.location.groups ? this.props.location.groups : []}
													fullWidth
													name="ruleGroup"
													label="Rule Group"
													textFieldProps={{
														variant: "outlined",
														onBlur: this.handleRuleFieldOnBlur,
														error: this.state.ruleGroupValidation ? this.state.ruleGroupValidation.isInvalid : false,
														helperText: this.state.ruleGroupValidation ? this.state.ruleGroupValidation.messages[0] : ''
													}}
													value={this.state.ruleGroup}
													onInputChange={this.handleRuleAutoCompleteInputChange}
													onChange={this.handleRuleAutoCompleteChange}
												/>
											</Grid>
											<Grid item xs={12} md={12}>
												<MultiSelectField
													fullWidth
													name="ruleOperations"
													label="Operations"
													textFieldProps={{
														onBlur: this.handleRuleMultiSelectOnBlur,
														helperText: this.state.ruleOperationsValidation ? this.state.ruleOperationsValidation.messages[0] : '',
														error: this.state.ruleOperationsValidation ? this.state.ruleOperationsValidation.isInvalid : false 
													}}
													value={this.state.ruleOperations}
													options={SelectOptions.operations}
													onChange={this.handleRuleAutoCompleteInputChange} />
											</Grid>
										</Grid>
									</AccordionDetails >
								</Accordion>
								<Accordion expanded={this.state.classificationExpanded} style={{ width: '100%' }} onChange={this.handleClassificationsExpandCollapse}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Box display="block">
											<Typography variant="h2">Classifications</Typography>
											<Typography variant="caption">Set filters (classifications) used to determine if a specific operation is associated with the rule.</Typography>
										</Box>
									</AccordionSummary>
									<AccordionDetails >
										<Grid container spacing={2}>
											<Grid item xs={12} md={6}>
											<TextField
												fullWidth label="Entity"
												name="ruleEntity"
												value={this.state.ruleEntity}
												onChange={this.handleRuleFieldChange}
												onBlur={this.handleRuleFieldOnBlur}
												inputProps={{ style: { textTransform: "uppercase" } }}
												error={this.state.ruleEntityValidation ? this.state.ruleEntityValidation.isInvalid : false}
												helperText={this.state.ruleEntityValidation && this.state.ruleEntityValidation.messages ? this.state.ruleEntityValidation.messages[0] : ''}
											/>
											</Grid>
											<Grid item xs={12} md={6}>
												<Tooltip title={this.state.ruleEntity ? "" : "An entity must be specified in order to enter a client or client group."}>
												<TextField
													fullWidth
													label="Client"
													name="ruleClient"
													value={this.state.ruleClient}
													onChange={this.handleRuleFieldChange}
													disabled={!this.state.ruleEntity}
													inputProps={{ style: { textTransform: "uppercase" } }}
													error={this.state.ruleClientValidation ? this.state.ruleClientValidation.isInvalid : false}
													helperText={this.state.ruleClientValidation && this.state.ruleClientValidation.messages ? this.state.ruleClientValidation.messages[0] : ''}
												/>
												</Tooltip>
											</Grid>
											<Grid item xs={12}>
												<MultiSelectField
													fullWidth
													name="ruleOperationalConsents"
													label="Operational Consents"
													value={this.state.ruleOperationalConsents}
													options={SelectOptions.operationalConsent}
													onChange={this.handleRuleAutoCompleteInputChange} />
											</Grid>
											<Grid item xs={12} md={6}>
												<MultiSelectField
													fullWidth
													name="rulePhoneTechnologies"
													label="Phone Technologies"
													value={this.state.rulePhoneTechnologies}
													options={SelectOptions.phoneTechnology}
													onChange={this.handleRuleAutoCompleteInputChange} />
											</Grid>
											<Grid item xs={12} md={6}>
												<Tooltip title={this.state.ruleEntity ? "" : "An entity must be specified in order to enter a territory."}>
													<TextField
														fullWidth
														label="Territory"
														name="ruleTerritory"
														value={this.state.ruleTerritory}
														onChange={this.handleRuleFieldChange}
														disabled={!this.state.ruleEntity}
														inputProps={{ style: { textTransform: "uppercase" } }}
														error={this.state.ruleTerritoryValidation ? this.state.ruleTerritoryValidation.isInvalid : false}
													    helperText={this.state.ruleTerritoryValidation && this.state.ruleTerritoryValidation.messages ? this.state.ruleTerritoryValidation.messages[0] : ''}
													/>
												</Tooltip>
											</Grid>
										</Grid>
									</AccordionDetails >
								</Accordion>
								<Accordion expanded={this.state.restrictionExpanded} style={{ width: '100%' }} onChange={this.handleRestrictionsExpandCollapse}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Box display="block">
											<Typography variant="h2">Restrictions</Typography>
											<Typography variant="caption">Set restrictions used to trigger a rule and prevent an operation from occurring.</Typography>
										</Box>
									</AccordionSummary>
									<AccordionDetails >
										<Grid container spacing={2}>
											<Grid item xs={12} md={4}>
												<Tooltip title={this.state.rulePrevent ? "If prevent is set to 'Yes' any operation that is associated with the rule will be blocked regardless of any other restrictions specified" : ""}>
													<SelectField
														name="rulePrevent"
														label="Prevent"
														formProps={{
															fullWidth: true,
															variant: "outlined"
														}}
														value={this.state.rulePrevent}
														options={SelectOptions.binaryOption}
														onChange={this.handleRuleFieldChange} />
												</Tooltip>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													name="ruleRunActionOnPrevent"
													label="Run Action on Prevent"
													value={this.state.ruleRunActionOnPrevent}
													onChange={this.handleRuleFieldChange}
													inputProps={{ style: { textTransform: "uppercase" } }}
													error={this.state.ruleRunActionOnPreventValidation ? this.state.ruleRunActionOnPreventValidation.isInvalid : false}
													helperText={this.state.ruleRunActionOnPreventValidation && this.state.ruleRunActionOnPreventValidation.messages ? this.state.ruleRunActionOnPreventValidation.messages[0] : ''}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<SelectField
													name="ruleScope"
													label="Scope"
													formProps={{
														fullWidth: true,
														variant: "outlined",
														disabled: this.state.rulePrevent
													}}
													value={this.state.ruleScope}
													options={SelectOptions.scope}
													onChange={this.handleRuleFieldChange}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<SelectField
													name="ruleIgnoreOneTimeConsent"
													label="Ignore One Time Consent"
													formProps={{
														fullWidth: true,
														variant: "outlined",
													}}
													value={this.state.ruleIgnoreOneTimeConsent}
													options={SelectOptions.binaryOption}
													onChange={this.handleRuleFieldChange}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<NumericTextField
													fullWidth
													name="ruleAttempts"
													label="Attempts"
													helperText={this.state.ruleAttemptsValidation ? this.state.ruleAttemptsValidation.messages[0] : ''}
													error={this.state.ruleAttemptsValidation ? this.state.ruleAttemptsValidation.isInvalid : false}
													value={this.state.ruleAttempts}
													onChange={this.handleRuleFieldChange}
													disabled={this.state.rulePrevent}
													textFieldProps={{
														format: "#####",
														isNumericString: true,
														allowNegative: false,
														allowLeadingZeros: false,
													}}
													InputLabelProps={{ shrink: true }}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<Tooltip title="The consumer can be contacted indefinitely if period is not specified.">
													<NumericTextField
														fullWidth
														name="rulePeriod"
														label="Period (Days)"
														helperText={this.state.rulePeriodValidation ? this.state.rulePeriodValidation.messages[0] : ''}
														error={this.state.rulePeriodValidation ? this.state.rulePeriodValidation.isInvalid : false}
														value={this.state.rulePeriod}
														onChange={this.handleRuleFieldChange}
														disabled={this.state.rulePrevent}
														textFieldProps={{
															format: "#####",
															isNumericString: true,
															allowNegative: false,
															allowLeadingZeros: false,
														}}
														InputLabelProps={{ shrink: true }}
													/>
												</Tooltip>
											</Grid>
											<Grid item xs={12} md={4}>
												<NumericTextField
													fullWidth
													name="ruleRightPartyContact"
													label="Right Party Contacts (RPC)"
													helperText={this.state.ruleRightPartyContactValidation ? this.state.ruleRightPartyContactValidation.messages[0] : ''}
													error={this.state.ruleRightPartyContactValidation ? this.state.ruleRightPartyContactValidation.isInvalid : false}
													value={this.state.ruleRightPartyContact}
													onChange={this.handleRuleFieldChange}
													disabled={this.state.rulePrevent}
													textFieldProps={{
														format: "#####",
														isNumericString: true,
														allowNegative: false,
														allowLeadingZeros: false,
													}}
													InputLabelProps={{ shrink: true }}
												/>
											</Grid>
										</Grid>
									</AccordionDetails >
								</Accordion>
							</React.Fragment>
						}
						{this.state.activeStep === 2 &&
							<ReviewComplianceRule serverErrors={this.state.serverErrors} reviewList={this.buildReviewList()} />
						}

						<ConfirmDialog
							open={this.state.dialogOpen}
							dialogTitle={this.state.dialogTitle}
							dialogMessage={this.state.dialogMessage}
							onCancel={this.handleDialogClose}
							onConfirm={this.handleDialogConfirm}
						/>
					</Grid>
				</Box>
				<Box height={4}>
					<Stepper
						steps={totalSteps}
						onBackClick={this.handleBackClick}
						onNextClick={this.handleNextClick}
						onConfirmClick={this.handleConfirmClick}
						activeStep={this.state.activeStep}
						nextButtonDisabled={this.state.activeStep === totalSteps} />
				</Box>
			</Box>}

			<Snackbar open={this.state.showSnackbar} autoHideDuration={6000} onClose={this.closeSnackbar}>
				<MuiAlert elevation={6} variant="filled" onClose={this.closeSnackbar} severity={this.state.snackbarSeverity}>
					{this.state.snackbarMessage}
				</MuiAlert>
			</Snackbar>
		</Box>;
	}
}

export default withRouter(EditComplianceRule);