import React from 'react';
import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`	
	${props => {
		const theme = props.theme;
		return css`
			color: ${theme.colors.brandHighlight};
			display: flex;
			justify-content: center;
			align-items: center;
		`;
	}} 
`;

const Icon = styled(FontAwesomeIcon)`
	font-size: 5em;
`;

/**
 * Spinner to use to indicate loading content.
 */
export default class LoadingIndicator extends React.Component {

	render() {

		return <Container className={this.props.className}>
			<Icon icon={["fal", "cog"]} spin />
		</Container>;
	}

}