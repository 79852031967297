import React from 'react';
import { connect } from 'react-redux';
import AgentTaskDuration from "../utils/AgentTaskDuration.js";

class AgentAdapter extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			agents: [],
			intId: null
		};

		this.calculateAgentDurations = this.calculateAgentDurations.bind(this);
	}

	async componentDidMount() {

		const intId = window.setInterval(this.calculateAgentDurations, 1000);
		this.setState({
			intId: intId
		});

	}

	async componentWillUnmount() {
		window.clearInterval(this.state.intId);
	}

	calculateAgentDurations() {
			
		this.setState(prevState => {
			const newAgents = this.props.agents;

			newAgents.forEach(agent => {			
				agent.duration = AgentTaskDuration(agent);
			})
			return {
				agents: newAgents
			};
		});

	}

	render() {
		const sorted = this.state.agents.sort(function (a, b) {
			const aName = a.name || a.personCode;
			const bName = b.name || b.personCode;
			const nameSort = aName.localeCompare(bName);
			return nameSort;
		});

		const filtered = sorted.filter(a => a.online);

		const items = filtered.map(a => {
			
			return {
				// TODO: confirm that the person code is an adequately unique identifier. perhaps agentId.
				key: a.personCode,
				agent: {
					...a,
					name: a.name || "Unnamed Agent"
				},
				task: a.currentWorkTasks.length > 0 ? a.currentWorkTasks[0] : null,
				queueCode: a.currentCampaigns.length === 0 ? null
					: a.currentCampaigns[0].queueCode,
				inbound: a.currentCampaigns.length === 0 
					? false
					: a.currentCampaigns[0].inbound,
				outbound: a.currentCampaigns.length === 0 
					? false
					: a.currentCampaigns[0].outbound,
				duration: a.duration
			};
		});

		const childrenWithProps = React.Children.map(this.props.children, child =>
			React.cloneElement(child, { items: items })
		);

		return <React.Fragment>
			{childrenWithProps}
		</React.Fragment>;
	}

}

export default connect(state => state.agents)(AgentAdapter)