import React from 'react'

const TagEnd = props => (
	/* <svg width="1em" height="1em" viewBox="0 0 44 70" fill="none" {...props}> */
	<svg viewBox="5 0 38 70" fill="none" {...props}>
		<g clipPath="url(#prefix__clip0)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.9 45.532c6.667-4.68 6.667-16.383 0-21.064L6.4 1.648C4.492.308 2.447-.17.5.052V0H-104c-9.941 0-18 8.059-18 18v34c0 9.941 8.059 18 18 18H.5v-.052c1.947.221 3.992-.256 5.9-1.595l32.5-22.82zM27.8 43.4a8.4 8.4 0 1 0 0-16.8 8.4 8.4 0 0 0 0 16.8z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="prefix__clip0">
				<path fill="#fff" d="M0 0h44v70H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default TagEnd;
