import NotificationDefinitions from '../components/notifications/NotificationDefinitions';

export const types = {
	notificationCreated: 'NOTIFICATION_CREATED',
	notificationDismissed: 'NOTIFICATION_DISMISSED',
	encoreHubDisconnected: 'ENCOREHUB_DISCONNECTED',
	phoneAudioDisconnected:'PHONE_AUDIO_DISCONNECTED',
	phoneDisconnected : 'PHONE_DISCONNECTED',
	unlicensedUser: 'NOTIFICATION_UNLICENSED'
};

const initialState = {
	notifications: []
};

export const actionCreators = {
	createNotification: data => ({ type: types.notificationCreated, data: data }),
	dismissNotification: key => ({ type: types.notificationDismissed, data: { key: key } })
};

export const reducer = (state, action) => {
	state = state || initialState;
	var newState;
	var clonedNotifications = state.notifications.slice(0);
	if (action.type === types.notificationCreated) {
		return createNotification(state, clonedNotifications, action.data);
	}
	else if (action.type === types.notificationDismissed) {
		newState = { ...state, notifications: clonedNotifications };
		var existingNotificationIndex = clonedNotifications.findIndex(ele => ele.key === action.data.key);
		clonedNotifications.splice(existingNotificationIndex, 1);
		return newState;
	}
	else if (action.type === types.encoreHubDisconnected) {
		// Create disconnection notification
		return createNotification(state, clonedNotifications, {
			data: action.data.message,
			type: NotificationDefinitions.types.disconnect
		});
	}
	else if (action.type === types.phoneDisconnected) {
		// Create disconnection notification
		return createNotification(state, clonedNotifications, {
			data: action.data,
			type: NotificationDefinitions.types.phoneDisconnect
		});
	}
	else if (action.type === types.phoneAudioDisconnected) {
		// Create disconnection notification
		return createNotification(state, clonedNotifications, {
			data: action.data,
			type: NotificationDefinitions.types.phoneAudioDisconnect
		});
	}
	else if (action.type === types.unlicensedUser) {
		return createNotification(state, clonedNotifications, {
			type: 'unlicensed',
			data: action.data.failureMessage
		});
	}
	return state;
};

function createNotification(state, clonedNotifications, data) {	
	var newState = { ...state, notifications: clonedNotifications };
	clonedNotifications.push(data);
	return newState;
}
