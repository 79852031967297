import React from 'react';
import HttpAgent from '../httpagent.js';
import styled, { css, ThemeProvider } from 'styled-components';
import { LightTheme } from './core/Themes.js';
import InputBar from './core/InputBar/InputBar';
import TransferList from './TransferList.jsx';
import TransferSteps from './transfer/TransferSteps';
import connection from '../signalragent.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Transfers';

const TransferToLabel = styled.h3`
	${props => {
	    const theme = props.theme;
	    const margin_left = theme.padding.full;
		const color = props.color;

		return css`	
		    margin-left: ${margin_left};
            color: ${color};
		`;
	}}
`;

class PhoneTransfer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			transferQuery: '',
			transferUserId: '',
			showTransferWizard: false,
			transferInProgress: false,
			showError: false,
			transferAgentUnAvailable: false
		};

		this.transferChanged = this.transferChanged.bind(this);
		this.transferKeyPressed = this.transferKeyPressed.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.handleActiveCancel = this.handleActiveCancel.bind(this);
		this.handleActiveClose = this.handleActiveClose.bind(this);

		this.onLineClick = this.onLineClick.bind(this);
	}

	async componentDidMount() {

	}

	async initTransfer(transferValue) {
		if (this.state.transferInProgress) {
			return;
		}

		const req = {
			to: transferValue,
			callId: this.props.task.activePhoneCallId,
			consumerPhoneNumber: this.props.task.phoneNumber
		};

		this.setState({
			transferInProgress : true
		});

		this.props.resetTransfer(false);
		this.props.setTransferring(true);

		try {
			const response = await HttpAgent.transferCall(req);
			this.props.setTransferResponse(response.data);

			if (response.status === 200 && response.data && response.data.success) {
				this.setState({
					showTransferWizard: true,
					transferResponse: response.data,
					transferResponseMessage: ''
				});
			} else {
				this.setState({
					transferInProgress : false,
					transferResponse: response.data,
					showTransferWizard: false,
					transferAgentUnAvailable: true,
					transferResponseMessage: response.data && response.data.errors ? `Unable to transfer to agent: ${response.data.errors[0]}` : "The agent you're attempting to transfer to is unavailable at this time."
				});

				if (response.data && response.data.errors) {
					console.log(`Unable to transfer to agent: ${response.data.errors[0]}`);
				}
			}			
		} catch (error) {
			this.props.setTransferResponse(error);

			this.setState({
				transferQuery: '',
				transferUserId: '',
				transferResponse: error,
				transferInProgress: false,
				showError: true,
				transferAgentUnAvailable: true,
				transferResponseMessage: error.response.data && error.response.data.errors ? `Unable to transfer to agent: ${error.response.data.errors[0]}` : 'Unknown transfer error'
			});				
		}		
	}

	async transferKeyPressed(event) {
		let transferTo = this.state.transferQuery;

		if (event.key === 'Enter' && transferTo != '') {			
			let agents = this.props.agents;
			let agent = agents ? agents.find(a => a.personCode.toLowerCase() == transferTo.toLowerCase()) : null;		

			if (agent) {
				let state = agent.state;
				let online = agent.online && agent.phoneConnected && agent.phoneAudioConnected;
				let currentTask = agent.currentWorkTasks && agent.currentWorkTasks.length > 0 ? agent.currentWorkTasks[0] : null;
				let onCall = (currentTask && !currentTask.completedTimestamp);
				let canTransferToAgent = (state !== 2 && online && !onCall);

				if (!canTransferToAgent) {
					console.log('Unable to transfer to unavailable agent.')
					this.setState({
						transferAgentUnAvailable: true,
						transferResponseMessage: "The agent you're attempting to transfer to is unavailable at this time."
					});
					return;
				}

				transferTo = agent.bloodhoundUserId;
			}

			//Send it.
			await this.initTransfer(transferTo);			
		}
	}

	async handleOutsideClick(event) {
		if (!this.state.transferInProgress && this.props.dismissRequested) {
			this.props.dismissRequested(event);
		}
	}

	async handleActiveCancel(args) {
		HttpAgent.cancelTransfer(args);
		this.handleActiveClose();
	}

	async handleActiveClose(event) {
		this.props.dismissRequested(event);
	}

	handleKeyDown(event) {
		//clear the error on typing.
		this.setState({
			showError: false,
			transferAgentUnAvailable: false
		});

		// Escape key does not trigger keyPressed.
		if (event.key === 'Escape' && !this.state.transferInProgress && this.props.dismissRequested) {
			this.props.dismissRequested(event);
		}
	}

	async onLineClick(userId) {
		if (userId) {
			this.setState({ transferAgentUnAvailable: false });
			await this.initTransfer(userId);
		} else {
			console.log('Unable to transfer to unavailable agent');
			this.setState({ transferAgentUnAvailable: true });			
		}
	}

	transferChanged(event) {
		this.setState({
			transferQuery: event.target.value
		});
	}

	render() {
		const icon = {
			color: null,
			style: null,
			name: null
		};
		if (this.state.showError) {
			icon.name = "times";
			icon.style = "far";
			icon.color = "destructive";
		}

		return <ThemeProvider theme={LightTheme}>
			<InputBar placeholder="Name of agent" value={this.state.transferQuery} onKeyDown={this.handleKeyDown}
				onKeyPress={this.transferKeyPressed} onChange={this.transferChanged} icon={icon}
				onClickOutside={this.handleOutsideClick} type='text'
				disabled={this.state.transferInProgress}>
				{
					(this.state.showTransferWizard || this.props.transferring) && 
					<TransferSteps details={this.props.transferResponse} onClose={this.handleActiveClose}
						onCancel={this.handleActiveCancel}/>
				}
				{
					this.state.transferAgentUnAvailable &&
					<React.Fragment>
						<TransferToLabel color="#FF0000">{this.state.transferResponseMessage}</TransferToLabel>
						<hr/>
					</React.Fragment>
				}
				{
					(!this.state.showTransferWizard && !this.props.transferring) &&
					<React.Fragment>
						<TransferToLabel>Transfer To</TransferToLabel>
						<TransferList onClick={this.onLineClick} query={this.state.transferQuery} />
					</React.Fragment>
				}
			</InputBar>
		</ThemeProvider>;
	}
}

export default connect(state => {
	return {
		...state.transfers,
		...state.agents
	};
}
	, dispatch => bindActionCreators(Object.assign({}, actionCreators), dispatch)
)(PhoneTransfer);