import React from 'react';
import styled, {css} from 'styled-components';
import TagEnd from './TagEnd.jsx';

const TagTextColumn = styled.div`

	${props => {
		const theme = props.theme;

		return css`
			grid-area: tag;
			padding: ${theme.margin.quarter} ${theme.margin.half};
			padding-right: ${theme.margin.quarter};
			border-top-left-radius: ${theme.margin.quarter};
			border-bottom-left-radius: ${theme.margin.quarter};
			line-height: 1em;
			white-space: nowrap;
			text-overflow: ellipsis; 
			overflow: hidden; 
			transition: background-color ease-in-out .05s, 
				color ease-in-out .05s, 
				box-shadow ease-in-out .05s;
		`;
	}}

`;

const TagEndColumn = styled(TagEnd)`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: tag-end;
			height: calc(1em + ${theme.margin.half});
			transition:  color ease-in-out .05s, 
				box-shadow ease-in-out .05s;
		`;
	}}

`;

const Container = styled.div`
	${props => {
		const theme = props.theme;
		
		var background = props.background || (props.selected
			? theme.button.primary.background
			: theme.button.background);
		var color = props.color || (props.selected
			? theme.button.primary.text
			: theme.button.text);

		var selectableStyles = null;
		if (props.onClick != null) {
		
			var hoverBackground = props.hoverBackground || props.selected
				? theme.button.primary.hover.background
				: theme.button.hover.background;		
			var hoverColor = props.hoverColor || props.selected
				? theme.button.primary.hover.text
				: theme.button.hover.text;

			var activeShadow = props.selected && theme.tag.primary
				&& theme.tag.primary.active.shadow
				? theme.tag.primary.active.shadow
				: theme.tag.active.shadow;

			selectableStyles = css`

				&:hover ${TagEndColumn} {
					color: ${hoverBackground};
				}

				&:active {
					box-shadow: none;
					${TagTextColumn} {
						box-shadow: ${activeShadow};
					}
				}

				&:hover ${TagTextColumn} {
					background: ${hoverBackground};
					color: ${hoverColor};
				}			
			`;
		}

		return css`
			display: inline-grid;
			grid-template-columns: 1fr auto;
			grid-template-areas: "tag tag-end";
			grid-column-gap: 0;
			font-size: .9em;

			margin: ${theme.margin.quarter} 0;

			&:not(:last-child) {
				margin-right: ${theme.margin.half};
			}

			${TagTextColumn} {
				background: ${background};
				color: ${color};
			}

			${TagEndColumn} {
				color: ${background};
			}

			${selectableStyles}
		`;
	}}
	
`;

/**
 * Decorative wrapper around text used to indicate an attribute.
 * When selected, sets to primary color scheme
 * @property {boolean}	[selected]			- Flag to indicate selected state
 * @property {object}	[onClick]			- Callback for user click action
 * @property {object}	[background]		- Override default background color
 * @property {object}	[hoverBackground]	- Override default hover background color
 * @property {object}	[hoverColor]		- Override default text color
 */
export default class Tag extends React.Component {

	render() {

		var onClick = null;
		if (this.props.onClick) {
			onClick = (e) => this.props.onClick(e);
		}

		return <Container {...this.props} onClick={onClick}>
			<TagTextColumn>{this.props.children}</TagTextColumn>
			<TagEndColumn />
		</Container>;
	}
	
}