import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`

	${props => {
		const theme = props.theme;
		return css`

			display: grid;
			grid-template-columns: auto 1fr;
			grid-column-gap: ${theme.margin.half};
			grid-template-rows: auto 1fr;
			grid-template-areas:
				"input input"
				"items items";
			margin-bottom: ${theme.margin.full};

		`;
	}}

`;

const Cell = styled.div`

	grid-area: ${props => props.area};

`;

/**
 * Structural component dividing the dropdown and the list.
 */
export default class InputAddContainer extends React.Component {

	render() {

		return <Container>
			<Cell area="input">
				{this.props.input(this.state)}
			</Cell>
			<Cell area="items">{this.props.children}</Cell>
		</Container>;
	}
}