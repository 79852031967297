import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { UpperLayer } from '../Themes.js';
import TextInput from "../TextInput/TextInput.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Overlay = styled.div`	

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	display: flex;

	z-index: 1000;

	background-color: rgba(0,0,0,.3);

`;

const Bar = styled.div`

	${props => {
		const theme = props.theme;
		var templateAreas = () => {
			if (props.hasIcon) {
				return css`
					grid-template-areas: 
						"field field icon"
						"results results results";
				`;
			}
			else {
				return css`
					grid-template-areas: 
						"field field"
						"results results";
				`;
			}
		}
		var columns = props.hasIcon 
			? "auto 1fr auto" 
			: "auto 1fr";
		return css`

			background: ${theme.colors.background};
			color: ${theme.colors.text};
			box-shadow: ${theme.modal.shadow};

			padding: ${theme.padding.x6};
			width: calc(100% - (${theme.margin.x6} * 2));
			height: calc(100% - (${theme.margin.x6} * 2));
			
			border-radius: ${theme.margin.triple};
			
			display: grid;
			grid-template-columns: ${columns};
			grid-column-gap: ${theme.margin.full};
			grid-row-gap: ${theme.margin.full};
			grid-template-rows: auto 1fr;
			${templateAreas}
			align-items: top;

			z-index: 1001;
		`;
	}}
`;

const Field = styled(TextInput)`

	grid-area: field;
	font-size: 1.15em;

`;

const Results = styled.div`

	grid-area: results;
	overflow-y: scroll;

`;

const IconContainer = styled.div`

	grid-area: icon;
	font-size: 1.75em;

	${props => {
		const theme = props.theme;
		var color = () => {
			if (!props.color) {
				return theme.button.primary.hover.background;
			}
			switch (props.color) {
				case "positive":
					return theme.button.positive.background
				case "destructive":
					return theme.button.destructive.background;
				default:
					return props.color;
			}
		};
		return css`color: ${color};`;
	}};

`;

class InputBarBlock extends React.Component {

	render() {
		var hasIcon = this.props.icon != null && this.props.icon.name != null;

		return <Bar hasIcon={hasIcon}>
			<Field {...this.props} width={"100%"} autoFocus ></Field>
			{hasIcon && <IconContainer color={this.props.icon.color}>
				<FontAwesomeIcon fixedWidth icon={[this.props.icon.style, this.props.icon.name]} />
			</IconContainer>}
			<Results>{this.props.children}</Results>
		</Bar>;
	}
}

/**
 * Modal text input dialog.
 * 
 * @property {object}		children				-	The React component rendered below the text field.
 * @property {object}		icon					-	The FontAwesome icon rendered next to the text field.
 * 	Mostly used to indicate error states.
 * @property {string}		icon.color			-	Color of the icon. Options are "positive", "destructive", 
 * 	or a custom color (rendered direct to CSS).
 * @property {string}		icon.name			-	FontAwesome key for the icon.
 * @property {string}		icon.style			-	FontAwesome icon set to use.
 * 
 * **All properties passed to the `TextInput` component.** See `TextInput` component for more details.
 */
export default class InputBar extends React.Component {

	onClickOverlay(evt) {
		evt.preventDefault();
		if (this.props.onClickOutside && evt.target === evt.currentTarget) {
			this.props.onClickOutside(evt);
		}
	}

	render() {

		return <Overlay onClick={e => this.onClickOverlay(e)}>
			<ThemeProvider theme={UpperLayer}>
				<InputBarBlock {...this.props}></InputBarBlock>
			</ThemeProvider>
		</Overlay>;
	}
}