export const types = {
	agentsInitialized : 'AGENT_INITIALIZE',
	agentUpdated : 'AGENT_UPDATED'
};

const initialState = {
	agents: []
};

export const actionCreators = {
	updateAgents: data => ({ type: types.agentsInitialized, data: data })
};

export const reducer = (state, action) => {
	state = state || initialState;
	if (action.type === types.agentsInitialized) {
		return { agents: action.data };
	}

	if (action.type === types.agentUpdated) {
		const clonedAgents = state.agents.slice(0);
		const existingAgentIndex = clonedAgents.findIndex(ele => ele.id === action.data.id);
		clonedAgents[existingAgentIndex] = action.data;

		return { agents: clonedAgents };
	}

	return state;
};
