import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { LowerLayer } from '../Themes.js';
import DataTableColumn from './DataTableColumn.jsx';

const Container = styled.table`
	width: 100%;
	border-collapse: collapse;
	table-layout:fixed;
`;

const Header = styled.thead`

	${props => {
		const theme = props.theme;
		return css`
			background: ${theme.colors.background};
			border-top: 2px solid ${theme.colors.border};
			border-bottom: 2px solid ${theme.colors.border};
			font-family: ${theme.fonts.heading};

			th {
				font-weight: 500;
				text-transform: uppercase;
				font-size: .9em;
			}
		`;
	}}

`;

/**
 * Data grid for showing tabular data. Supports showing and hiding columns based
 * on window width.
 * @property {React.Element}	children			-	Set of DataTableRows
 * @property {Array(object)}	columns			-	Array of definitions for table columns.
 * 
 * **Properties for `column` array elements. Make sure there is a `DataTableColumn`
 * with a matching key for each row.**
 * @property {string}			key				-	Column key
 * @property {boolean}			[numeric]		-	Style and format as a number
 * @property {boolean}			[code]			-	Style and format as code
 * @property {boolean}			[bold]			-	Use a heavier font weight
 * @property {object}			[itemWidth]		-	Specify the width of the column. If
 * 	a string, will write directly to CSS (ex. "300px" becomes `width: 300px;`).
 * 	If an array of objects with `maxWidth` and/or `minWidth` properties, then will 
 * 	wrap the widths in media queries based on `maxWidth` and `minWidth`.
 * @property {string}			[itemAlign]		-	Sets `textAlign` CSS property
 */
class DataTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			expandedRowKey: null
		};
		this.handleRowClick = this.handleRowClick.bind(this);
	}

	handleRowClick(key) {
		this.setState(ps => {
			return {
				expandedRowKey: key === ps.expandedRowKey ? null : key
			};
		});
	}

	render() {

		// Passing columns to row
		const rows = this.props.children.map(row => {
			if (!row) return;
			return React.cloneElement(row, {
				...row.props,
				rowKey: row.key,
				expanded: this.state.expandedRowKey === row.key,
				onRowClick: this.handleRowClick,
				columns: this.props.columns
			});
		});

		const headerColumns = this.props.columns.map(c => {
			if (!c) return;
			return <DataTableColumn header  
				className={c.className} 
				width={c.width}
				align={c.align}
				key={c.key}>
				{c.title}
			</DataTableColumn>;
		});

		return <Container className={this.props.className}>
			<ThemeProvider theme={LowerLayer}>
				<Header>
					<tr>
						{headerColumns}
					</tr>
				</Header>
			</ThemeProvider>
			<tbody>{rows}</tbody>
		</Container >;
	}
}

export default DataTable;