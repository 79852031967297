import React from 'react';
import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import TextInput from '../core/TextInput/TextInput.jsx';

// A problem with CSS grid is that there's no way for grid content
// to be 100% of the cell's height. So we manually specify the height of
// breadcrumbs here, so your content area's height can be 
// calc(100vh - ${BreadcrumbHeight}) and fill the entire window aside from the crumbs.
const BreadcrumbHeight = "3.5em";
const HeaderHeight = "4.5em"; // includes top margin, removed when breadcrumbs added
const ContentHeight = css`
	height: calc(100vh - ${BreadcrumbHeight} + ${props => props.theme.margin.full} - ${HeaderHeight});
`;
const ContentHeightWithoutBreadcrumbs = css`
	height: calc(100vh - ${HeaderHeight});
`;

const PrimaryContainer = styled.div`

	${props => {
		const theme = props.theme;

		let gridStyle = css`
			grid-template-rows: ${HeaderHeight} 1fr;
			grid-template-areas:
				"header"
				"content";
		`;
		if (props.hasBreadcrumbs) {
			gridStyle = css`
				grid-template-rows: ${BreadcrumbHeight} calc(${HeaderHeight} - ${theme.margin.full}) 1fr;
				grid-template-areas:
					"breadcrumbs"
					"header"
					"content";
			`;
		}
		if (props.hasSearchResults) {
			gridStyle = css`
				grid-template-rows: ${BreadcrumbHeight} calc(${HeaderHeight} - ${theme.margin.full}) 1fr;
				grid-template-areas:
					"header"
					"breadcrumbs"
					"content";
			`;
		}

		return css`
			height: 100vh;
			margin: 0;

			grid-area: main; /* grid-area for Configure.jsx */
			
			display: grid;
			${gridStyle}
		`;
	}}
`;

const BreadcrumbArea = styled.nav`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: breadcrumbs;
			padding: ${theme.margin.full} ${theme.margin.full} ${theme.margin.half};
			font-size: .9em;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				padding-bottom: ${theme.padding.full};
				border-bottom: 2px solid ${theme.colors.border};
				display: inline-block;

				li {
					display: inline-block;
					font-family: ${theme.fonts.heading};
					text-transform: uppercase;
					font-weight: 500;
					padding-right: ${theme.margin.half};

					&:last-child {
						padding-right: 0;
					}

					a {
						text-decoration: none;
						color: ${theme.colors.secondary};
						padding-bottom: ${theme.padding.full};
						transition: border-bottom ease-in-out .1s;
						border-bottom: 3px solid transparent; /* ensures transition works */

						:hover {
							border-bottom: 3px solid ${theme.colors.brand};
						}
					}
				}
			}
		`;
	}}	
`;

const BreadcrumbDivider = styled(FontAwesomeIcon)`
	${props => {
		const theme = props.theme;
		return css`
			margin-left: ${theme.margin.half};
			color: ${theme.colors.brand};
		`;
	}}
`;

const HeaderArea = styled.div`
	${props => {
		const theme = props.theme;
		return css`
			grid-area: header;
			padding: ${props.hasBreadcrumbs ? "0" : theme.margin.full} ${theme.margin.full} ${props.hasSearchResults ? "0" : theme.margin.full};
			display: grid;
			grid-template-columns: auto 1fr ${props.actions ? "auto" : ""};
			grid-template-areas: "title search-box ${props.actions ? "actions" : ""}";
		`;
	}}
`;

const TitleText = styled.h2`

	grid-area: title;

`;

const SearchBox = styled.div`

	grid-area: search-box;
	justify-self: end;
	align-self: center;

`;

const ActionsArea = styled.div`

	grid-area: actions;
	justify-self: end;
	align-self: center;

`;

const ContentArea = styled.section`
	${props => {
		const theme = props.theme;
		const height = props.hasBreadcrumbs || props.hasSearchResults
			? ContentHeight
			: ContentHeightWithoutBreadcrumbs;
		
		let headerBorder = css`border-top: 2px solid ${theme.colors.border};`;
		if (props.hideHeaderBorder) {
			headerBorder = null;
		}

		return css`
			grid-area: content;
			overflow-y: scroll;
			${headerBorder};
			${height}

			h3 {
				margin-bottom: ${theme.margin.half};
				font-weight: 500;
				font-family: ${theme.fonts.heading};
				font-size: 1em;
				text-transform: uppercase;
				color: ${theme.colors.secondary};

				&:not(:first-child) {
					margin-top: ${theme.margin.double};
				}
			}
		`;
	}}
`;


// Expecting this 
export default class ConfigurePage extends React.Component {

	render() {
		const hasBreadcrumbs = this.props.breadcrumbs && this.props.breadcrumbs.length > 0;
		const hasSearchResults = this.props.search && this.props.search.hasSearchResults;

		let breadcrumbs;
		if (hasBreadcrumbs) {
			breadcrumbs = this.props.breadcrumbs.map((b, i) => {
				const lastOne = i == this.props.breadcrumbs.length - 1;
				if (b.to == null) {
					return <li key={b.key}>{b.name}</li>
				}
				return <li key={b.key}>
					<Link to={b.to}>{b.name}</Link>
					{!lastOne &&
						<BreadcrumbDivider icon="angle-right"></BreadcrumbDivider>
					}
				</li>;
			});
		}

		return <PrimaryContainer 
			className={this.props.className} 
			hasBreadcrumbs={hasBreadcrumbs}
			hasSearchResults={hasSearchResults}>
			{hasBreadcrumbs && 
				<BreadcrumbArea>
					<ul>
						{breadcrumbs}
					</ul>					
				</BreadcrumbArea>
			}
			<HeaderArea 
				hasBreadcrumbs={hasBreadcrumbs} 
				hasSearchResults={hasSearchResults}
				actions={this.props.actions}>
				<TitleText>{this.props.title}</TitleText>
				{
					this.props.search &&
					<SearchBox>
						<TextInput {...this.props.search}></TextInput>
					</SearchBox>
				}			
				{
					this.props.actions &&
					<ActionsArea>
						{this.props.actions}
					</ActionsArea>
				}	
			</HeaderArea>
			{hasSearchResults && 
				<BreadcrumbArea>
					{hasSearchResults}				
				</BreadcrumbArea>
			}
			<ContentArea hasBreadcrumbs={hasBreadcrumbs}
				hasSearchResults={hasSearchResults}
				hideHeaderBorder={this.props.hideHeaderBorder}>
				{this.props.children}
			</ContentArea>
		</PrimaryContainer>;
	}
	
}