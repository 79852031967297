import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import styled, { css } from 'styled-components';
import ConfigurePage from '../ConfigurePage.jsx';
import FormInput from '../../core/FormInput/FormInput.jsx';
import RoundButton from '../../core/RoundButton/RoundButton.jsx';
import HttpAgent from '../../../httpagent.js';
import ApplyValidation from '../../utils/Validation.js';

const FormSection = styled.section`

	${props => {
		const theme = props.theme;
		return css`
			margin: ${theme.margin.full};
		`;
	}}

`;

class TagEdit extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tag: {
				key: "",
				name: "",
				description: "",
				priority: "",
				id: null
			},
			createMode: false,
			userChangedName: false
		};
		this.handleSave = this.handleSave.bind(this);
		this.onTagChange = this.onTagChange.bind(this);
		this.onNameChange = this.onNameChange.bind(this);
		this.onDescChange = this.onDescChange.bind(this);
		this.onPriorityChange = this.onPriorityChange.bind(this);

		ApplyValidation(this, {
			keyRequired: {
				validIf: state => state.tag.key.length != 0,
				trackDirtyState: state => state.tag.key,
				required: true,
				message: "Key is required"
			},
			keyNotClean: {
				validIf: state => {
					var regexp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
					return regexp.test(state.tag.key);
				},
				trackDirtyState: state => state.tag.key,
				message: "Key can only consist of lower-case letters and numbers, separated with single hyphens"
			},
			keyNotUnique: {
				validIf: (state, prevState) => {
					if (!this.state.createMode) return true;
					var isUnique = !prevState.existingNames.some(n => {
						return state.tag.key.toLowerCase() == n.toLowerCase();
					});
					return isUnique;
				},
				message: "Key is already in use"
			},
			keyTooLong: {
				validIf: state => state.tag.key.length < 100,
				message: "Key must be less than 100 characters"
			},
			nameRequired: {
				validIf: state => state.tag.name.length != 0,
				trackDirtyState: state => state.tag.name,
				required: true,
				message: "Name is required"
			},
			priorityIsANumber: {
				validIf: state => !state.tag.priority || !isNaN(state.tag.priority),
				trackDirtyState: state => state.tag.priority,
				message: "Priority must be a number"
			},
		});
	}

	async componentDidMount() {
		const tags = await HttpAgent.getTags();
		var existingNames = tags.data.map(t => t.key);
		if (this.props.match.params.id == "create") {
			this.setStateWithDirtyTracking((prevState) => ({
				createMode: true,
				existingNames: existingNames
			}));
		} else {
			var tag = tags.data.find(a => {
				return a.id == this.props.match.params.id
			});
			this.setStateWithDirtyTracking((prevState) => ({
				tag: tag,
				tagNames: []
			}));
		}
	}

	async handleSave() {
		var isValid = this.stateIsValid();
		if (isValid) {
			if (this.state.createMode) {
				await HttpAgent.createTag(this.state.tag);
			}
			else {
				await HttpAgent.updateTag(this.state.tag);
			}
			this.props.history.push("./");
		}
	}

	onDescChange(event) {
		var newValue = event.target.value;
		this.setState(prevState => ({
			tag: {
				...prevState.tag,
				description: newValue
			}
		}));
	}

	onPriorityChange(event) {
		var newValue = event.target.value;
		this.validateAndSetState(prevState => ({
			tag: {
				...prevState.tag,
				priority: newValue
			}
		}));
	}

	onNameChange(event) {
		var newValue = event.target.value;
		this.validateAndSetState(prevState => ({
			tag: {
				...prevState.tag,
				name: newValue
			},
			userChangedName: true
		}));
	}

	onTagChange(event) {
		var newValue = event.target.value;
		var newName = {};
		if (!this.state.userChangedName) {
			newName = {
				name: this.prettifyString(newValue)
			}
		}
		this.validateAndSetState(prevState => ({
			tag: {
				...prevState.tag,
				key: newValue,
				...newName
			}
		}));
	}

	prettifyString(str) {
		return str.split('-').map(function capitalize(part) {
			return part.charAt(0).toUpperCase() + part.slice(1);
		}).join(' ');
	}

	render() {

		var tag = this.state.tag;
		var createMode = this.state.createMode;
		var validation = this.state.validation;
		var canSubmit = !this.state.validationFailed;

		var loaded = createMode || (tag != null && tag.id != null);

		var title = "", name = "", key = "", description = "", priority = "";
		if (loaded || createMode) {
			title = tag.name || tag.key || "";
			name = tag.name || "";
			key = tag.key || "";
			description = tag.description || "";
			priority = tag.priority || ""
		}
		if (createMode) {
			title = "Add Attribute"
		}

		return <ConfigurePage
			className={this.props.className}
			title={this.state.createMode ? "Add Attribute" : "Edit Attribute"}
			breadcrumbs={[
				{ name: "Attribute List", to: "./", key: "tagList" },
				{ name: title, key: "tagEdit" }
			]}>
			<FormSection>
				<FormInput name="key" label="Key" width="350px" onChange={this.onTagChange}
					disabled={!createMode} codeField placeholder="key" value={key} validation={[
						validation.keyRequired,
						validation.keyNotClean,
						validation.keyNotUnique,
						validation.keyTooLong
					]} />

				<FormInput name="name" label="Name" placeholder="Name" disabled={!loaded}
					onChange={this.onNameChange} value={name} validation={validation.nameRequired} />

				<FormInput name="desc" label="Description" width="100%" disabled={!loaded}
					placeholder="Internal description for this attribute" value={description}
					onChange={this.onDescChange} />

				<FormInput name="priority" label="Priority" width="300px" disabled={!loaded}
					placeholder="Attribute priority when processing calls" value={priority}
					validation={validation.priorityIsANumber}
					onChange={this.onPriorityChange} />

				<RoundButton disabled={!loaded || !canSubmit} primary onClick={this.handleSave}>Save Changes</RoundButton>
				<RoundButton to="./">Back</RoundButton>
			</FormSection>
		</ConfigurePage>;
	}
}

export default withRouter(TagEdit);