import React from 'react';
import styled, {css} from 'styled-components';
import HexToRgb from '../../utils/HexToRgb.js';

const LineItem = styled.div`
	${props => {
		const theme = props.theme;
		return css`	
			padding: ${theme.padding.full};
			margin: ${theme.padding.full} 0;
			margin-right: ${theme.margin.full};

			&:hover {
				background: ${HexToRgb(theme.colors.text, .05)};
			}			
		`;
	}}

	border-radius: 999px;	
`;

/**
 * Decorative wrapper for list items used in an InputBar.
 */
export default class InputLine extends React.Component {

	render() {
		return <LineItem className={this.props.className} onClick={this.props.onClick}>
			{this.props.children}
		</LineItem>
	}

}