import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Agent';

class PermissionWrapper extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props[this.props.permission]) {
			return <React.Fragment>{this.props.children}</React.Fragment>;
		}

		return null;
	}
}

export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(PermissionWrapper);
