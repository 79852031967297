import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

function buttonStyle(props, iconOnly) {
	var buttonStyle = props.theme.button;
	if (props.primary) {
		buttonStyle = {
			...buttonStyle,
			...props.theme.button.primary
		};
	}
	if (props.positive) {
		buttonStyle = {
			...buttonStyle,
			...props.theme.button.positive
		};
	}
	if (props.destructive) {
		buttonStyle = {
			...buttonStyle,
			...props.theme.button.destructive
		};
	}

	var background = buttonStyle.background;
	var color = buttonStyle.text;
	var shadow = buttonStyle.shadow;

	var hoverBackground = buttonStyle.hover.background || buttonStyle.background;
	var hoverColor = buttonStyle.hover.text || buttonStyle.text;
	var hoverShadow = buttonStyle.hover.shadow || buttonStyle.shadow;

	var activeBackground = buttonStyle.active.background || buttonStyle.background;
	var activeColor = buttonStyle.active.text || buttonStyle.text;
	var activeShadow = buttonStyle.active.shadow || buttonStyle.shadow;
	
	if (props.disabled) {
		if (buttonStyle.disabled != null) {
			background = buttonStyle.disabled.background || background;
			color = buttonStyle.disabled.text || color;
			shadow = buttonStyle.disabled.shadow || shadow;
		}
	}

	var iconOnlyCss = null;
	if (iconOnly) {
		iconOnlyCss = css`
			padding: ${props.theme.padding.double};
			padding-left: ${props.theme.padding.triple};
			text-align: center;	
		`;
	}

	var giantSizeCss = null;
	if (props.giantSize) {
		giantSizeCss = css`
			font-size: 29px;
			line-height: 1.25em;	
			padding: 13px;
			padding-bottom: 11px;	
			
			&:not(:last-child) {
				margin-right: ${props.theme.margin.half};
			}
		`;
	}

	const theme = props.theme;

	return css`

		display: inline-block;	
		border-radius: 999px;
		cursor: default;
		user-select: none;
		font-family: ${theme.fonts.button}, ${theme.fonts.primary}, sans-serif;
		font-weight: 300;
		text-transform: uppercase;
		text-decoration: none;
		font-size: .9em;
		line-height: 1em;
		padding: ${theme.padding.double} ${theme.padding.triple} ${theme.padding.double} ${theme.padding.double};
		text-align: center;
	
		transition: background-color ease-in-out .05s, 
			color ease-in-out .05s, 
			box-shadow ease-in-out .05s;

		background: ${background};
		color: ${color};
		box-shadow: ${shadow};

		/* 
			TODO: If a vertical list, don't implement these margins. 
				  Also, determine alignment before adding margin	
		*/
		&:not(:last-child) {
			margin-right: ${theme.margin.full};
		}

		&:not([disabled]):hover {
			background: ${hoverBackground};
			color: ${hoverColor};
			box-shadow: ${hoverShadow};
		}

		&:not([disabled]):active {
			transition: background-color ease-in-out .05s, 
				color ease-in-out .05s, 
				box-shadow ease-in-out .05s;
			background: ${activeBackground};
			box-shadow: ${activeShadow};
			color: ${activeColor};
		}

		${iconOnlyCss}
		${giantSizeCss}
	`;
}

const Container = styled.a`
	${props => buttonStyle(props)}
`;

const LinkContainer = styled(Link)`
	${props => buttonStyle(props)}
`;

const IconOnlyContainer = styled.a`
	${props => buttonStyle(props, true)}
`;

const IconOnlyLinkContainer = styled(Link)`
	${props => buttonStyle(props, true)}
`;

const IconContainer = styled.span`
	${props => {
		if (!props.iconOnly && !props.giantSize) {
			return css`
				padding-right: ${props => props.theme.padding.full};
				padding-left: 0;
			`;
		}
	}}	
`;

/**
 * Standard button. Depending on props results in an `<a>`, `<button>` or `<LinkButton>`.
 * 
 * @property {object}			[to]					-	React-Router route to destination
 * @property {string}			[href]				-	URL string to redirect user to
 * @property {object}			[onClick]			-	Callback action when user clicks button
 * @property {object}			[icon]				-	FontAwesome icon to add to left of label
 */
export default class RoundButton extends React.Component {

	render() {

		var buttonProps = Object.assign({}, this.props);
		if (buttonProps.disabled) {
			delete buttonProps["onClick"];
			delete buttonProps["to"];
		}

		// React throws a fit if you try to pass "iconOnly" as a prop and
		// styled-components can't see props.children, so this is needed to
		// tell the button styling that there's no icon without either method.

		var iconOnly = this.props.children == undefined;

		var CustomLinkContainer = LinkContainer;
		var CustomContainer = Container;
		if (iconOnly) {
			CustomLinkContainer = IconOnlyLinkContainer;
			CustomContainer = IconOnlyContainer;
		}

		var iconProps = Object.assign({}, buttonProps);
		delete iconProps["className"];

		var innerContent = <React.Fragment>			
			<IconContainer {...iconProps} onClick={() => { }}>
				{buttonProps.icon &&
					<FontAwesomeIcon fixedWidth spin={iconProps.spin} icon={iconProps.icon} />
				}
			</IconContainer>
			{buttonProps.children}
		</React.Fragment>;

		if (buttonProps.to) {
			return <CustomLinkContainer {...buttonProps}>
				{innerContent}
			</CustomLinkContainer>;
		}

		return <CustomContainer {...buttonProps}>
			{innerContent}
		</CustomContainer>;
	}
}