import React from 'react';
import styled, { css } from 'styled-components';

const ButtonContainer = styled.button`

	${props => {
		const theme = props.theme;
		return css`

			border-radius: 9999px;
			background: ${props.pressed ? theme.colors.border : "transparent"};
			border: 2px solid ${theme.colors.border};
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			cursor: default;
			user-select: none;
			outline: none;

			&:hover {
				border: 2px solid ${theme.colors.primary};
			}

			transition: background-color ease-in-out .15s,
				border-color ease-in-out .05s;

			h2 {				
				font-family: ${theme.fonts.heading};
				font-size: 4vh;
				line-height: 4vh;
			}
		
			h5 {
				font-weight: normal;
				font-size: 2vh;
				line-height: 2vh;
				font-family: ${theme.fonts.code};
				letter-spacing: 1px;
			}

		`;
	}}

`;

export default class RoundButton extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			pressed: 0
		};
		this.showPressed = this.showPressed.bind(this);
		this.clearPressed = this.clearPressed.bind(this);
	}

	showPressed() {		
		window.setTimeout(this.clearPressed, 1000);
		this.setState(ps => {
			return {
				pressed: ps.pressed + 1
			};
		});
	}

	clearPressed() {
		this.setState(ps => {
			return {
				pressed: ps.pressed - 1
			};
		});
	}

	render() {
		return <ButtonContainer pressed={this.state.pressed > 0}
			onClick={this.props.onClick}>
			<h2>{this.props.children}</h2>
			<h5>
				{this.props.letters && <span>{this.props.letters}</span>}
				{!this.props.letters && <span>&nbsp;</span>}
			</h5>
		</ButtonContainer>;
	}

}