import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

export default class AutoCompleteTextField extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const filter = createFilterOptions();
		var acOptions = this.props.options ? this.props.options : [];	
		let props = { ...this.props };

		if (props.onChange) {
			props.onChange = (e, value) => {
				this.props.onChange(e, value, props.name);
			}
		}

		if (props.onInputChange) {
			props.onInputChange = (e, values, reason) => {
				this.props.onInputChange(e, values, reason, props.name);
			}
		}

		let textFieldProps = {};

		if (props.textFieldProps) {
			textFieldProps = props.textFieldProps;
			delete props.textFieldProps;
		}

		return <Autocomplete
			{...props}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				if (props.value) {
					return filtered.filter(x => x.toLowerCase().indexOf(props.value.toLowerCase()) !== -1);
				}

				return filtered;
			}}
			selectOnFocus			
			handleHomeEndKeys
			options={acOptions}
			freeSolo
			renderInput={(params) => (
				<TextField {...params} name={props.name} label={props.label} {...textFieldProps}  />
			)}
		/>;
	}
};