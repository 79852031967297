import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const mainButtonStyle = {
	color: "#000",
	backgroundColor: "#acc3e7",
	cursor: "default",
	userSelect: "none",
	fontFamily: "sans-serif",
	fontWeight: 300,
	textTransform: "uppercase",
	textDecoration: "none",
	fontSize: ".9rem",
	lineHeight: ".5rem",
	borderTopLeftRadius: 999,
	borderBottomLeftRadius: 999,
}

const dropButtonStyle = {
	color: "#000",
	backgroundColor: "#acc3e7",
	display: "inline-block",
	cursor: "default",
	borderTopRightRadius: 999,
	borderBottomRightRadius: 999,
}

const buttonGroupStyle = {
	height: 30,
}

export default class SplitButton extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.anchorRef = React.createRef();

		this.state = {
			open: false,
			selectedIndex: 0,
		}
	}


	handleClick() {
		if (this.props.options[this.state.selectedIndex].enabled) {
			this.props.onClick(this.state.selectedIndex);
		}
	};

	handleMenuItemClick(event, index){
		this.setSelectedIndex(index);
		this.setOpen(false);
		this.props.onClick(index);
	};

	handleToggle(){
		this.setOpen((prevOpen) => !prevOpen);
	};

	handleClose(event){
		if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
			return;
		}
		this.setOpen(false);
	};

	setOpen(val) {
		this.setState({
			open: val
		});
	}

	setSelectedIndex(val) {
		this.setState({
			selectedIndex: val
		});
	}

	render() {
		var buttonProps = Object.assign({}, this.props);
		if (buttonProps.disabled) {
			delete buttonProps["onClick"];
			delete buttonProps["to"];
		}

		var selectedDisabled = !this.props.options[this.state.selectedIndex].enabled
		var selectedText = this.props.options[this.state.selectedIndex].label;

		return (
			<Grid container direction="column" alignItems="center">
				<Grid item xs={12}>
					<ButtonGroup style={buttonGroupStyle} variant="contained" color="primary" ref={this.anchorRef} aria-label="split button" disableElevation>
						<Button disabled={selectedDisabled} style={mainButtonStyle} onClick={this.handleClick}>{selectedText}</Button>
						<Button style={dropButtonStyle} 
							color="primary"
							size="small"
							aria-controls={this.state.open ? 'split-button-menu' : undefined}
							aria-expanded={this.state.open ? 'true' : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							onClick={this.handleToggle}
						>
							<ArrowDropDownIcon />
						</Button>
					</ButtonGroup>
					<Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={this.handleClose}>
										<MenuList id="split-button-menu">
											{this.props.options.map((option, index) => (
												<MenuItem
													key={option.label}
													selected={index === this.state.selectedIndex}
													onClick={(event) => this.handleMenuItemClick(event, index)}
													disabled={!option.enabled}
												>
													{option.label}
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Grid>
			</Grid>
		);
	}

}