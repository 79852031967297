import * as signalR from '@aspnet/signalr';

const connection = new signalR.HubConnectionBuilder()
	.withUrl("/CommunicationTaskHub", {
		accessTokenFactory: () => {
			var roydanToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/, '$1');
			return roydanToken;
		}
	})
	.build();
window.signalrConnection = connection;


export default connection;
