export default function AgentTaskDuration(agent) {
	// -   Duration: Running timer since you went online, reset each time you pick up a phone, 
	// or hang up the phone, or change the status (while **not** on the phone). Need last hung up time, 
	// last call started time (only used when on a call), time of last status change, and (if none of 
	// the above apply) time agent went online - all needed for agents online prior to last page refresh

	var durationDate = new Date(agent.lastOnlineTimestamp);
	var stateSince = new Date(agent.lastStateChangeTimestamp);
	if (agent.lastStateChangeTimestamp && stateSince > durationDate) {
		durationDate = stateSince;
	}
	var lastCallEnded = new Date(agent.lastWorkTaskTime);
	if (agent.lastWorkTaskTime && lastCallEnded > durationDate) {
		durationDate = lastCallEnded;
	}
	var currentTask = agent.currentWorkTasks && agent.currentWorkTasks.length > 0 ? agent.currentWorkTasks[0] : null;
	if (currentTask) {
		durationDate = new Date(currentTask.acceptedTimestamp);
		if (currentTask.lastPhoneCallTimeEnd) {
			durationDate = new Date(currentTask.lastPhoneCallTimeEnd);
		}
	}

	// a and b are javascript Date objects
	function dateDiffInDays(a, b) {

		const minutesFromMs = 1000 * 60;
		const secondsFromMs = 1000;
		
		var duration = (b - a);
		var minutes = Math.floor(duration / minutesFromMs);
		var seconds = Math.floor((duration % minutesFromMs) / secondsFromMs);

		return {
			minutes: (minutes < 0) ? 0 : minutes,
			seconds: (seconds < 0) ? 0 : seconds
		};
	}

	const difference = dateDiffInDays(durationDate, new Date(Date.now()));

	return difference.minutes >= 60
		? "> 1h"
		: `${difference.minutes}:${("0" + difference.seconds).slice(-2)}`;
}
