import React from 'react';

import DataTable from '../core/DataTable/DataTable.jsx';
import DataTableRow from '../core/DataTable/DataTableRow.jsx';
import { CompactColumn } from './CompactColumn.jsx';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import styled from 'styled-components';

import { MillisecondsToDuration } from '../utils/DateHelpers';

import Tag from '../core/Tag/Tag.jsx';
const CompactTag = styled(Tag)`

	margin: 0;

`;

class InboundGrid extends React.Component {


	calculateRow(key) {
		var totalRow = key.isTotal;
		var attribute = this.props.tags.find(x => x.id === key.attributeId);
		var rowKey = attribute ? attribute.name : key.isTotal ? 'ZZZZZZZZ' : 'YYYYYYYY';
		var daily = this.props.stats.find(x => (x.attributeId === key.attributeId && x.isTotal === key.isTotal));
		var agents = this.props.agents.agents.filter(x => x.online && x.currentCampaigns.find(y => y.inbound) && (key.attributeId == null || x.attributes.find(y => y.id === key.attributeId)));
		var waitingTasks = this.props.tasks.tasks.filter(x => x.inbound && x.acceptedTimestamp == null && (key.isTotal || x.attributes.length === 0 && key.attributeId === null || x.attributes.find(y => y.id === key.attributeId)));
		var workingTasks = this.props.tasks.tasks.filter(x => x.inbound && x.acceptedTimestamp != null && (key.isTotal || x.attributes.length === 0 && key.attributeId === null || x.attributes.find(y => y.id === key.attributeId)));
		var avgHold = daily != null && daily.numberOfQueuedCalls != 0 ? Math.round(daily.queuedTime / daily.numberOfQueuedCalls) : 0;
		var avgDuration = daily != null && daily.numberOfConnectedCalls != 0 ? Math.round(daily.talkingTime / daily.numberOfConnectedCalls) : 0;
		var promptAns = daily != null && daily.totalCalls != 0 ? (daily.numberOfPromptAnsweredCalls / daily.totalCalls).toFixed(2) * 100 : 0;
		var dailyTotals = daily != null ? daily.totalCalls : 0;

		return <DataTableRow key={rowKey}>
			<CompactColumn bold={totalRow} key="attribute-name">
				{
					totalRow && <span>Total</span>
				}
				{
					(!totalRow && attribute) && <CompactTag>{attribute.name}</CompactTag>
				}
				{
					(!totalRow && !attribute) && <span>No Attribute</span>
				}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="agents">
				{agents.length}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="holds">
				{waitingTasks.length}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="in-progress">
				{workingTasks.length}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="calls">
				{dailyTotals}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="abandoned">
				{daily != null ? daily.numberOfAbandonedCalls : 0}
			</CompactColumn>
			{/* <CompactColumn numeric key="wrap-up">
					{a.wrapUp}
				</CompactColumn> */}
			<CompactColumn bold={totalRow} numeric key="avg-hold">
				{
					<span>{MillisecondsToDuration(avgHold * 1000)}</span>
				}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="avg-duration">
				{
					<span>{MillisecondsToDuration(avgDuration * 1000)}</span>
				}
			</CompactColumn>
			<CompactColumn bold={totalRow} numeric key="ans-sec">
				{
					<span>{Number.isInteger(promptAns) ? promptAns : '0'}%</span>
				}
			</CompactColumn>
		</DataTableRow>;
	}

	unionUniqueRows(agentsAttributes, stats, equalityFunc) {
		var union = agentsAttributes.concat(stats);
		for (var i = 0; i < union.length; i++) {
			for (var j = i + 1; j < union.length; j++) {
				if (equalityFunc(union[i], union[j])) {
					union.splice(j, 1);
					j--;
				}
			}
		}
		return union;
	}

	getUniqueRows() {
		var agents = this.props.agents.agents.filter(x => x.online && x.currentCampaigns.find(y => y.inbound));
		var agentAttributes = agents.map(p => p.attributes);
		if (agentAttributes && agentAttributes.length > 0) {
			agentAttributes = agentAttributes.reduce((a, b) => a.concat(b)).map(z => {
				return {
					attributeId: z.id,
					isTotal: false
				}
			});
		}
		var stats = this.props.stats.map(x => {
			return { attributeId: x.attributeId, isTotal: x.isTotal };
		});
		var keys = this.unionUniqueRows(agentAttributes,stats,(x, y) => {return x.attributeId === y.attributeId && x.isTotal === y.isTotal;});
		return keys;
	}

	render() {
		var rows = [];
		if (this.props.stats && this.props.tags) {
			rows = this.getUniqueRows().map(x => this.calculateRow(x));
			rows = rows.sort(function (a, b) {
				var nameSort = a.key.localeCompare(b.key);
				return nameSort;
			});
		}

		return <DataTable columns={[
			{
				key: "attribute-name",
				title: "Attribute Name",
				width: [
					{
						width: "19%"
					}
				]
			},
			{
				key: "agents",
				title: "Agents", // Only agents currently taking inbound calls
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "holds",
				title: "In Queue",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "in-progress",
				title: "Connected",
				align: "right",
				width: [
					{
						width: "9%"
					}
				]
			},
			{
				key: "calls",
				title: "Daily",
				align: "right",
				width: [
					{
						width: "6%"
					}
				]
			},

			//{
			//	key: "calls-per-second",
			//	title: "Calls / Sec.",
			//	align: "right",
			//	width: [
			//		{
			//			width: "11%"
			//		}
			//	]
			//},
			{
				key: "abandoned",
				title: "Abandoned",
				align: "right",
				width: [
					{
						width: "10%"
					}
				]
			},
			{
				key: "avg-hold",
				title: "Avg. Hold",
				align: "right",
				width: [
					{
						width: "9%"
					}
				]
			},
			{
				key: "avg-duration",
				title: "Avg. Duration",
				align: "right",
				width: [
					{
						width: "12%"
					}
				]
			},
			{
				key: "ans-sec",
				title: "Prompt",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
		]}>
			{rows}
		</DataTable>;
	}
}
export default withRouter(connect(state => {
	return {
		tasks: state.tasks,
		agents: state.agents
	};
})(InboundGrid));