import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Tasks from './Tasks';
import * as Phone from './Phone';
import * as Agent from './Agent';
import * as Agents from './Agents';
import * as Voicemails from './Voicemails';
import * as DialingCampaigns from './DialingCampaigns';
import * as Notifications from './Notifications';
import * as Tabs from './Tabs';
import TabDefinitions from '../components/home/TabDefinitions';
import HttpAgent from '../httpagent';
import connection from '../signalragent';
import * as Transfers from './Transfers';

function signalRInvokeMiddleware() {
	return (next) => async (action) => {
		if (action.data) {
			switch (action.type) {
				case Agent.types.meStateChanged:
					connection.invoke('UpdateAgentState', { agentState: action.data });
					break;
				case Agent.types.meCallAcceptStateChanged:
					connection.invoke('UpdateAgentCallAcceptState', { callAcceptState: action.data });
					break;
			}
		}
		return next(action);
	};
}

async function loadData(store) {

	store.dispatch({
		type: Tabs.types.tabCreated,
		data: TabDefinitions.InitializeTab(TabDefinitions.types.newTab)
	});
	var agents = await HttpAgent.getAgents();
	store.dispatch({
		data: agents.data,
		type: Agents.types.agentsInitialized
	});

	var tasks = await HttpAgent.getTasks();
	store.dispatch({
		data: tasks.data,
		type: Tasks.types.tasksInitialized
	});

	var voicemails = await HttpAgent.getVoicemails();
	store.dispatch({
		data: voicemails.data,
		type : Voicemails.types.voicemailInitialized
	});
}

async function signalRRegisterCommands(store) {

	function startConnectionWithRetry(hubConnection) {
		console.log('signalr start retry connection', hubConnection);
		hubConnection.start()
			.then(function () {
				onRetryConnected(hubConnection);
			})
			.catch(onConnectionError);
	}

	function onConnectionError(error) {
		console.log('signalr connection error', error);
		setTimeout(function () {
			startConnectionWithRetry(connection);
		}, 1000);
	}

	function bindConnectionMessage(connection) {
		connection.onclose(onConnectionError);
	}

	function onRetryConnected(hubConnection) {
		var state = store.getState();
		if (state.agent.phoneAudioConnected || state.agent.phoneConnected) {
			hubConnection.invoke('EncoreHubConnected');
			hubConnection.invoke('PhoneConnected');
			hubConnection.invoke('PhoneAudioConnected');
		}
	}

	bindConnectionMessage(connection);

	connection.on('DialerCampaignStarted',
		data => {
			store.dispatch({
				data: data,
				type: DialingCampaigns.types.dialingCampaignStarted
			});
			console.debug('signaled campaign session started', data);
		});

	connection.on('DialerCampaignStopped',
		data => {
			store.dispatch({
				data: data,
				type: DialingCampaigns.types.dialingCampaignStopped
			});
			console.debug('signaled campaign session stopped', data);
		});

	connection.on('DialerCampaignUpdated',
		data => {
			store.dispatch({
				data: data,
				type: DialingCampaigns.types.dialingCampaignUpdated
			});
			console.debug('signaled campaign session udpated', data);
		});

	connection.on('WorkTaskQueued',
		data => {
			store.dispatch({
				data: data,
				type: Tasks.types.taskQueued
			});
			console.debug('signaled work task started', data);
		});
	connection.on('WorkTaskUpdated',
		data => {
			store.dispatch({
				data: data,
				type: Tasks.types.taskUpdated
			});
			console.debug('signaled work task updated', data);
		});
	connection.on('WorkTaskEnded',
		data => {
			store.dispatch({
				data: data,
				type: Transfers.types.transferUpdated
			})

			store.dispatch({
				data: data,
				type: Tasks.types.taskEnded
			});

			console.debug('signaled work task ended', data);
		});

	connection.on('AgentUpdated',
		data => {
			store.dispatch({
				data: data,
				type: Agents.types.agentUpdated
			});
			console.debug('signaled agent udpated', data);
		});

	connection.on('NotificationCreated',
		data => {
			store.dispatch({
				data: data,
				type: Notifications.types.notificationCreated
			});
		});

	connection.on('VoicemailUpdated',
		data => {
			store.dispatch({
				data: data,
				type: Voicemails.types.voicemailUpdated
			});
			console.debug('signaled work task updated', data);
		});

	connection.on('VoicemailDeleted',
		data => {
			store.dispatch({
				data: data,
				type: Voicemails.types.voicemailDeleted
			});
			console.debug('signaled work task updated', data);
		});

	connection.on('PhoneCallStatusUpdated',
		data => {			
			store.dispatch({
				data: data,
				type: Phone.types.phoneCallStatusUpdated
			});
			console.debug('signaled phone call status updated', data);
		});

	connection.onclose(async () => {
		store.dispatch({
			data: {apiDisconnected : true},
			type: Agent.types.encoreHubDisconnected
		});
		console.info('signalr connection closed.  dispatched encoreHubDisconnected.');
	});

	connection.on('PhoneConnected',
		data => {
			if (data.success) {
				store.dispatch({
					data: data.agent,
					type: Agent.types.phoneConnected
				});

				store.dispatch({
					data: data.agent,
					type: Transfers.types.phoneConnected
				});

				console.info('signaled phone connected.  dispatched phoneConnected.', data.agent);
			}
		});

	connection.on('EncoreHubConnected',
		data => {
			if (data.success) {
				store.dispatch({
					data: data.agent,
					type: Agent.types.encoreHubConnected
				});
				console.info('signaled agent connected from Encore.  dispatched encoreHubConnected.', data.agent);
			} else {
				console.log(`Error connecting to Encore: ${data.failureMessage}`, data);
				store.dispatch({
					data: data,
					type: Notifications.types.unlicensedUser
				});
			}
		});
	connection.on('Disconnected',
		data => {
			if (data.apiDisconnected) {
				store.dispatch({
					data: data,
					type: Agent.types.encoreHubDisconnected
				});
			}
			else if (data.phoneDisconnected) {
				store.dispatch({
					data: data,
					type: Agent.types.phoneDisconnected
				});
			}
			else if (data.phoneAudioDisconnected){
				store.dispatch({
					data: data,
					type: Agent.types.phoneAudioDisconnected
				});
			}

			console.info('signaled phone disconnected from Encore.  dispatched phoneDisconnected.', data);
		});
}

export default function configureStore(history, initialState) {
	const reducers = {
		tasks: Tasks.reducer,
		phone: Phone.reducer,
		agent: Agent.reducer,
		agents: Agents.reducer,
		dialingCampaigns: DialingCampaigns.reducer,
		notifications: Notifications.reducer,
		tabs: Tabs.reducer,
		voicemails: Voicemails.reducer,
		transfers: Transfers.reducer
	};

	const middleware = [
		thunk,
		routerMiddleware(history),
		signalRInvokeMiddleware
	];

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
		enhancers.push(window.devToolsExtension());
	}

	const rootReducer = combineReducers({
		...reducers,
		routing: routerReducer
	});

	const store = createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);

	signalRRegisterCommands(store);

	loadData(store);

	window.encore = {
		focusTab: function (originId) {
			if (originId) {
				store.dispatch({
					type: Tabs.types.tabSelectionChanged,
					data: { originId: originId }
				});
			}
		}
	}

	return store;
}
