import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import Badge from '../core/Badge/Badge.jsx';
import HttpAgent from '../../httpagent.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from '../core/Card/Card.jsx';
import RoundButton from '../core/RoundButton/RoundButton.jsx';

const CampaignCard = styled(Card)`
	${props => {
		const theme = props.theme;
		return css`

			padding: ${theme.margin.half};
			padding-left: ${theme.margin.full};
			display: grid;
			grid-template-areas: 
				"queue-code status total-call-count active-incall-count active-outcall-count active-task-count agent-count dial-ratio abandon-ratio queue-done controls";
			grid-template-columns: auto auto auto auto auto auto auto auto auto auto 1fr;
			grid-column-gap: ${theme.margin.full};

			h3 {
				font-size: 1.4em;

				code {
					font-size: .8em;
				}
			}

			h5, h3 code {
				color: ${theme.colors.secondary};
			}

		`;
	}}	

`;

const CampaignCardQueueCode = styled.h3`
	grid-area: queue-code;
`;

const CampaignCardCell = styled.div`
	grid-area: ${props => props.gridArea};
	display: inline-flex;
	align-items: center;
`;

const CampaignCardControls = styled.div`

	grid-area: controls;
	text-align: right;

`;

class DialingCampaign extends React.Component {

	constructor(props) {
		super(props);
		this.onStatusChange = this.onStatusChange.bind(this);
	}

	async onStatusChange(status) {
		switch (status) {
			case 'run':
				break;
			case 'stop':
				await HttpAgent.stopDialingCampaign(this.props.dialer);
				break;
			case 'pause':
				await HttpAgent.pauseDialingCampaign(this.props.dialer);
				break;
			case 'resume':
				await HttpAgent.resumeDialingCampaign(this.props.dialer);
				break;
		}
	}

	render() {

		var paused = this.props.dialer.paused;
		var running = this.props.dialer.running;
		var stopping = this.props.dialer.stopping;
		var inbound = !this.props.dialer.queue;
		var dialerState = 'Stopped';

		if (paused) {
			dialerState = 'Paused';
		} else if (stopping) {
			dialerState = 'Stopping';
		} else if (running) {
			dialerState = 'Running';
		}

		var background, color, theme = this.props.theme;

		if (paused || stopping) {
			color = theme.colors.warning;
		}
		if (running && !paused && !stopping) {
			background = theme.button.positive.background;
			color = theme.button.positive.text;
		}

		var showStop = !this.props.predictive;
		var showAgent = this.props.predictive;

		return <CampaignCard className={this.props.className}>
			<CampaignCardQueueCode>
				{this.props.dialer.queue ? this.props.dialer.queue : '*IN*'} <code>{this.props.dialer.entity}</code>
			</CampaignCardQueueCode>
			<CampaignCardCell gridArea="status">
				<Badge block background={background} color={color}>{dialerState}</Badge>
			</CampaignCardCell>
			<CampaignCardCell gridArea="total-call-count">
				<FontAwesomeIcon icon="phone" />
				&nbsp; {this.props.dialer.totalNumberOfCalls}
			</CampaignCardCell>
			<CampaignCardCell gridArea="active-incall-count">
				<React.Fragment>
					<FontAwesomeIcon icon="arrow-alt-to-bottom" />
					&nbsp; {this.props.dialer.currentInboundCalls}
				</React.Fragment>
			</CampaignCardCell>
			<CampaignCardCell gridArea="active-outcall-count">
				<React.Fragment>
					<FontAwesomeIcon icon="arrow-alt-from-bottom" />
					&nbsp; {this.props.dialer.currentOutboundCalls}
				</React.Fragment>
			</CampaignCardCell>
			<CampaignCardCell gridArea="active-task-count">
				<React.Fragment>
					<FontAwesomeIcon icon="clipboard-list-check" />
					&nbsp; {this.props.dialer.currentActiveTasks}
				</React.Fragment>
			</CampaignCardCell>
			<CampaignCardCell gridArea="agent-count">
				{
					showAgent && <React.Fragment>
						<FontAwesomeIcon icon="user-headset" />
						&nbsp; {this.props.dialer.loggedInAgents}
					</React.Fragment>
				}
			</CampaignCardCell>
			<CampaignCardCell gridArea="dial-ratio">
				{
					this.props.predictive && this.props.dialer.callsPerAgent && !inbound && <React.Fragment>
						<strong>CPC</strong>
						&nbsp; {this.props.dialer.callsPerAgent.toFixed(3)}
					</React.Fragment>
				}
			</CampaignCardCell>
			<CampaignCardCell gridArea="abandon-ratio">
				{
					this.props.predictive && !inbound && <React.Fragment>
						<strong>AR</strong>
						&nbsp; {(this.props.dialer.abandonRate * 100).toFixed(3)}%
					</React.Fragment>
				}
			</CampaignCardCell>
			<CampaignCardControls>
				{!stopping && !inbound &&
					<React.Fragment>
						{!running && !paused && <RoundButton onClick={() => { this.onStatusChange('run') }} icon="play">Run</RoundButton>}
						{
							running &&
							<React.Fragment>
								{showStop && <RoundButton onClick={() => { this.onStatusChange('stop') }} icon="stop">Stop</RoundButton>}
								{paused && <RoundButton onClick={() => { this.onStatusChange('resume') }} icon="play">Resume</RoundButton>}
								{!paused && <RoundButton onClick={() => { this.onStatusChange('pause') }} icon="pause">Pause</RoundButton>}
							</React.Fragment>
						}
					</React.Fragment>
				}
			</CampaignCardControls>
		</CampaignCard >;
	}
}

export default withTheme(DialingCampaign);