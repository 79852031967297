import { css, createGlobalStyle } from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faAngleLeft,
	faAngleRight,
	faArchive,
	faArrowAltDown,
	faArrowAltFromBottom, 
	faArrowAltToBottom,
	faArrowAltUp,
	faAsterisk,	
	faChartLine,
	faCheck,
	faCheckCircle,
	faCircle,
	faClipboardCheck,
	faClipboardListCheck,
	faComment,
	faCommentAlt,
	faCommentSlash,
	faEllipsisH,
	faExchangeAlt,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faExternalLinkSquare,
	faFilter,
	faFlagCheckered,
	faHeadset,
	faHeadSideCough,
	faHeadSideCoughSlash,
	faHourglassStart,
	faMicrophoneAlt,
	faMicrophoneAltSlash,
	faPause,
	faPencilAlt,
	faPhone,	
	faPhoneOffice,
	faPhoneSlash,
	faPhoneVolume,
	faPlay,
	faPlus,
	faSatelliteDish,
	faSearch,
	faSignOut,
	faSpinner,
	faStop,
	faSyncAlt,
	faTh,
	faTools,
	faTrash,
	faUnlink,
	faUnlock,
	faUser,
	faUserCircle,
	faUserHeadset,
	faUserPlus,
	faUsers,
	faUserTie,
	faVoicemail,
	faVolumeMute,
	faVolumeSlash,
	faVolumeUp,
	faWhistle,
	faWifi,
	faWifi1,
	faWifi2,
	faWifiSlash
} from '@fortawesome/pro-solid-svg-icons';
import {
	faCircle as farCircle,
	faCommentAlt as farCommentAlt,
	faPencil as farPencil,
	faPhoneAlt as farPhoneAlt,
	faPlusCircle as farPlusCircle, 
	faSatelliteDish as farSatelliteDish,
	faTags as farTags, 
	faTimes,
	faTrash as farTrash,
	faUserTie as farUserTie,
	faWindowAlt as farWindowAlt, 
} from '@fortawesome/pro-regular-svg-icons';
import {
	faCog as falCog
} from '@fortawesome/pro-light-svg-icons';

/**
 * Global styling for Encore.
 */
export const GlobalStyle = createGlobalStyle`

	${props => {
		const theme = props.theme;
		let scrollable = css`		
			height: 100vh;
			overflow:hidden;
		`;
		if (props.scrollable) {
			scrollable = css`			
				overflow-y: auto;			
			`;
		}
		return css`

			body {
				font-family: ${theme.fonts.primary};
				font-size: 0.9rem;
				
				margin: 0;
				padding: 0;

				background: ${theme.colors.background};
				color: ${theme.colors.text};
				
				${scrollable}

				h1, h2, h3, h4, h5, h6 {
					font-weight: 300;
					margin: 0;
				}

				h1, h2 {
					font-size: 1.75rem;
					font-family: ${theme.fonts.heading};
					color: ${theme.colors.headingText};
				}

				h4, h5, h6 {
					text-transform: uppercase;
					font-family: ${theme.fonts.heading};
					font-weight: 500;
				}

				code {
					font-size: 1.2em;
					line-height: 1em;
					font-family: ${theme.fonts.code};
				}
			}

			label {
				display: inline-block;
				margin-bottom: 1em;
				margin-right: .5em;
			}

			select {
				border: 2px solid ${theme.colors.border};
				border-radius: 999px;
				padding: 0.25em 0.25em;
				font-family: ${theme.fonts.primary};
				outline: none;		

				&:disabled {
					background: ${theme.colors.background};
				}	

				&:focus {
					border: 2px solid ${theme.colors.brand};
					box-shadow: ${theme.well.shadow};
				}
			}

			*:not(input) {
				user-select: none;
			}
		
		`;
	}}

`;
/**
 * Adds icons to the global FontAwesome library.
 */
export function PopulateFontAwesomeIcons() {
	library.add({
		faAngleLeft,
		faAngleRight,
		faArchive,
		faArrowAltDown,
		faArrowAltFromBottom,
		faArrowAltToBottom,
		faArrowAltUp,
		faAsterisk,		
		faChartLine,
		faCheck,
		faCheckCircle,
		faCircle,
		faClipboardCheck,
		faClipboardListCheck,
		faComment,		
		faCommentAlt,		
		faCommentSlash,
		faEllipsisH,
		faExchangeAlt,
		faExclamation,
		faExclamationCircle,
		faExclamationTriangle, 
		faExternalLinkSquare,
		faFilter,
		faFlagCheckered,		
		faHeadset,		
		faHeadSideCough,
		faHeadSideCoughSlash,
		faHourglassStart,
		falCog,
		faMicrophoneAlt,
		faMicrophoneAltSlash,
		faPause,
		faPencilAlt,
		faPhone,
		farPhoneAlt,
		faPhoneOffice,
		faPhoneSlash,
		faPhoneVolume,
		faPlay,
		faPlus,
		faSatelliteDish,
		faSearch,
		faSignOut,
		faSpinner,
		faStop,
		faSyncAlt,
		faTh, 
		faTimes,
		faTools,
		faTrash,
		faUnlink,
		faUnlock,
		faUser,
		faUserCircle,
		faUserHeadset,
		faUserPlus,
		faUsers,
		faUserTie, 
		faVoicemail,
		faVolumeMute,
		faVolumeSlash, 
		faVolumeUp,
		faWhistle,
		faWifi,
		faWifi1,
		faWifi2,
		faWifiSlash,
		farCircle,
		farCommentAlt,
		farPencil,
		farPlusCircle,
		farSatelliteDish,
		farTags,
		farTrash,
		farUserTie,
		farWindowAlt
	});
}