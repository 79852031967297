import React from 'react';
import { BrowserRouter as Router, withRouter, Switch, Route } from "react-router-dom";
import OneModule from './one/OneModule';
import EncoreRouter from './EncoreRouter';
/**
 * Root level component for Encore.
 */
class App extends React.Component {
	render() {
		return <Router>
			<Switch>
				<Route path="/compliance" component={OneModule} />
				<EncoreRouter />
            </Switch>

		</Router>;
	}
}

export default withRouter(App);