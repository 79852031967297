
export const types = {
	dialingCampaignLoaded: 'DIALING_CAMPAIGN_LOADED',
	dialingCampaignStarted: 'DIALING_CAMPAIGN_STARTED',
	dialingCampaignStopped: 'DIALING_CAMPAIGN_STOPPED',
	dialingCampaignUpdated: 'DIALING_CAMPAIGN_UPDATED'
};

const initialState = {
	campaigns : []
};

export const actionCreators = {
	loadDialerCampaigns: dialers => ({ type: types.dialingCampaignLoaded, data: dialers }),
	dialingCampaignStarted: dialer => ({ type: types.dialingCampaignStarted, data: dialer }),
	dialingCampaignStopped: dialer => ({ type: types.dialingCampaignStopped, data: dialer }),
	dialingCampaignUpdated: dialer => ({ type: types.dialingCampaignUpdated, data: dialer })
};

export const reducer = (state, action) => {
	state = state || initialState;
	if (action.type === types.dialingCampaignLoaded) {
		return { campaigns: action.data };
	}
	if (action.type === types.dialingCampaignStarted) {
		const clonedDialingCampaigns = state.campaigns.slice(0);
		clonedDialingCampaigns.push(action.data);
		return { campaigns: clonedDialingCampaigns };
	}
	if (action.type === types.dialingCampaignStopped) {
		const clonedDialingCampaigns = state.campaigns.slice(0);
		const dialingCampaignIndex = clonedDialingCampaigns.findIndex(ele => ele.id === action.data.id);
		clonedDialingCampaigns.splice(dialingCampaignIndex, 1);
		return { campaigns: clonedDialingCampaigns };
	}
	if (action.type === types.dialingCampaignUpdated) {
		const clonedDialingCampaigns = state.campaigns.slice(0);
		const dialingCampaignIndex = clonedDialingCampaigns.findIndex(ele => ele.id === action.data.id);
		clonedDialingCampaigns[dialingCampaignIndex] = action.data;
		return { campaigns: clonedDialingCampaigns };
	}

	return state;
};
