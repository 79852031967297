import { createTheme } from '@material-ui/core/styles'
import { Box, styled } from '@material-ui/core';

var baseTheme = {
	palette: {
		type: 'dark',
		primary: {
			main: "#DCEB3C",
			light: "#DCEB3C",
			dark: "#9BA713",
		},
		secondary: {
			main: "#B9D5FF",
			light: "#E8F1FF",
			dark: "#BFD9FF",
		},
		action: {
		},
		background: {
			default: "#031F4F",
			paper: "#22375C"
		},
		divider: "#52678B",
	},
	typography: {
		h1: {
			fontSize: "32px",
			fontWeight: 700,
		},
		h2: {
			fontSize: "20px",
			fontWeight: 600
		},
		h3: {
			fontSize: "18px",
			fontWeight: 600,
		},

		fontFamily: "'Inter','Roboto',sans-serif",
	},
	spacing: 16,
}
const theme = createTheme(baseTheme);

theme.overrides = {
	MuiButton: {
		root: {
			borderRadius: "32px",
			boxShadow: "0px 2px 5px rgba(0,0,0,0.25)",
		},

		// Name of the rule
		text: {
			fontSize: "15px",
			fontWeight: 500,
			textTransform: "none",
		},
	}
}

theme.props = {
	MuiSelect: {
		variant: 'outlined'
	},
	MuiTextField: {
		variant: 'outlined'
	}
};

theme.wrapper = styled(Box)(({ theme }) => ({
	height: '100vh',
	'&::-webkit-scrollbar': {
		width: '16px'
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: theme.palette.background.a,
		borderRadius: '8px'
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundImage: "linear-gradient(" + theme.palette.secondary.light + "," + theme.palette.secondary.dark + ")",
		borderRadius: '8px'
	}
}));

export default theme;