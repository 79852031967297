import React from 'react';
import { styled, Grid, Typography, Paper, TableContainer, Table, TableCell, TableRow, TableBody, TableHead, Chip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const InvalidChipStyle = styled(Chip)(({ theme }) => ({
	backgroundColor: theme.palette.error.main,
}));

const ValidChipStyle = styled(Chip)(({ theme }) => ({
	marginRight: "8px",
	textAlign: "center",
	minWidth: "48px"
}));

const BorderTableCell = styled(TableCell)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.divider}`,
	borderBottom: `none`
}));

class ReviewComplianceRule extends React.Component {
	createChip(label, isValid) {
		if (!label) return null;

		return isValid ?
			<ValidChipStyle key={label} color="secondary" size="small" label={label} /> :
			<InvalidChipStyle key={label} icon={<WarningIcon />} label={label} size="small" />;
	}

	createChips(content, isValid) {
		if (Array.isArray(content)) {
			return content.map(label => { return this.createChip(label, isValid) });
		}

		return this.createChip(content, isValid);
	}

	parseServerErrors(item) {
		if (!this.props.serverErrors) {
			return null;
		}

		var matchingItem = this.props.serverErrors.filter(x => x.MemberNames.some(z => z === item.postFieldName));
		if (!matchingItem) {
			return null;
		}

        return matchingItem.map(y => y.ErrorMessage);
	}

	createReviewItem(item) {
		var validation = item && item.validation;
		var isInvalid = validation && validation.isInvalid;
		var serverSideInvalid = this.parseServerErrors(item);
		let invalidTags = [];

		if (isInvalid) {
			invalidTags = invalidTags.concat(this.createChips(item.validation.messages, false));
		}

		if (serverSideInvalid) {
			invalidTags = invalidTags.concat(this.createChips(serverSideInvalid, false));
		}

		var chips = this.createChips(item.content, true);
        if ((!chips || chips.length === 0) &&
            (!invalidTags ||invalidTags.length === 0)) {
            return null;
		}

        return <TableRow key={item.label}>
			<BorderTableCell style={{ width: "25%" }} scope="row">
				{item.label}
			</BorderTableCell>
			<BorderTableCell scope="row">
				{chips}{invalidTags}
			</BorderTableCell>
		</TableRow>;
	}

	createReviewTable(header, desc, emptyDesc, items, first) {
        var mappedItems = items.map(x => {
            return this.createReviewItem(x);
		}).filter(x => x !== null);
        var descStyle = mappedItems.length === 0 ? { fontWeight: "bolder", fontSize: "12pt" } : {};
		var mappedDesc = mappedItems.length === 0 ? emptyDesc : desc;

        return <Paper style={{ marginTop: first ? "0px" : "16px" }}>
			<TableContainer>
				<Table size="small">
					<TableHead >
						<TableRow key="header">
							<TableCell style={{ paddingBottom: "16px", paddingTop: "16px", borderBottom: "none" }}><Typography variant="h3">{header}</Typography></TableCell>
							<TableCell style={{ paddingBottom: "16px", paddingTop: "16px", borderBottom: "none" }}><Typography variant="caption" style={descStyle}>{mappedDesc}</Typography></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{mappedItems}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>;
	}

	render() {
		var operations = this.props.reviewList.filter(x => x.type === 'operation');
		var classifications = this.props.reviewList.filter(x => x.type === 'classification');
		var isPrevented = this.props.reviewList.some(x => x.isPrevented);
		var restrictions = this.props.reviewList.filter(x => ((isPrevented && x.showPrevented) || (!isPrevented && x.type === 'restriction')));

        return <React.Fragment>
			<Grid item xs={12}>
				{this.createReviewTable("Operations", "The rule is defined as...", "", operations, true)}
				{this.createReviewTable("Classifications", "The rule applies if...", "With no classifications selected any associated operation will check restrictions", classifications)}
				{this.createReviewTable("Restrictions", "Restrictions of rule... ", "", restrictions)}
			</Grid>
		</React.Fragment >;
	}
}

export default ReviewComplianceRule;