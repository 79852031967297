import React from 'react';
import Notification from '../core/Notification/Notification';
import styled from "styled-components";
import RoundButton from '../core/RoundButton/RoundButton';

const Container = styled(Notification)`

`;

// Styling the <p> tag in the container CSS does not work for some reason
const Para = styled.p`

	margin-top: 0;
	margin-bottom: ${props => props.theme.margin.half};

`;

const Actions = styled.div`

	text-align: right;
	margin-top: ${props => props.theme.margin.half};

`;

export default class PhoneDisconnectedNotification extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			reloading: false
		};

		this.refresh = this.refresh.bind(this);
	}

	async refresh() {
		window.location.reload();
		this.setState({
			reloading: true
		});
	}

	render() {
		return <Container title="Phone Disconnected" negative icon="phone-slash">
			<Para>
				The Encore Phone has lost its connection.
			</Para>
			<Para>
				Encore will attempt to reconnect automatically. If the Encore Hub cannot reconnect and the diconnected indicator remains red, check your internet connection and ensure a persistent connection. Click refresh to reload the Encore Hub.
			</Para>
			<Actions>
				<RoundButton disabled={this.state.reloading} primary={!this.state.reloading}
					spin={this.state.reloading} icon="sync-alt" onClick={e => this.refresh()}>
					Refresh
				</RoundButton>
				<RoundButton icon={["far", "times"]} onClick={e => this.props.onDismiss(e)}>
					Close
				</RoundButton>
			</Actions>
		</Container>;
	}

}