import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { CardLayer } from '../Themes.js';

const Container = styled.div`

	${props => {
		const theme = props.theme;

		return css`
			background: ${theme.card.background};
			box-shadow: ${theme.card.shadow};
			padding: ${theme.margin.half} ${theme.margin.full};
			border-radius: ${theme.margin.triple};
			margin-bottom: ${theme.margin.half};
		`;
	}}

`;
const ActiveContainer = styled.a`

	${props => {
		const theme = props.theme;

		return css`
			background: ${theme.card.background};
			box-shadow: ${theme.card.shadow};
			padding: ${theme.margin.half} ${theme.margin.full};
			border-radius: ${theme.margin.triple};
			margin-bottom: ${theme.margin.half};

			transition: box-shadow ease-in-out .05s;

			&:hover, &:active {
				box-shadow: ${theme.card.hover.shadow};
			}

			&:active {
				background: ${theme.card.active.background};
			}
		`;
	}}

`;

/**
 * Decorative component that wraps elements in an elevated bubble. Can be used as a giant button.
 */
class Card extends React.Component {

	render() {

		let CardContainer = Container;
		if (this.props.onClick) {
			CardContainer = ActiveContainer;
		}

		return <ThemeProvider theme={CardLayer}>
			<CardContainer className={this.props.className} onClick={this.props.onClick}>
				{this.props.children}
			</CardContainer >
		</ThemeProvider>;
	}
}

export default Card;