import React from 'react';
import Notification from '../core/Notification/Notification';
import styled from "styled-components";
import RoundButton from '../core/RoundButton/RoundButton';

const Container = styled(Notification)`

`;

// Styling the <p> tag in the container CSS does not work for some reason
const Para = styled.p`

	margin-top: 0;
	margin-bottom: ${props => props.theme.margin.half};

`;

const Actions = styled.div`

	text-align: right;
	margin-top: ${props => props.theme.margin.half};

`;

export default class PhoneAudioDisconnectedNotification extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			reloading: false
		};

		this.refresh = this.refresh.bind(this);
	}

	async refresh() {
		window.location.reload();
		this.setState({
			reloading: true
		});
	}

	render() {
		var slitMsg = this.props.data.message.split('\n').map(i => {
			return <Para>
				{i}
			</Para>
		});
		return <Container title="Audio Disconnected" negative icon="volume-slash">
			{slitMsg}
			<Para>
				Please check your computer's audio settings to ensure proper audio device connectivity.  Once verified, configure your audio device selection in the "Settings" section of the Encore Hub. You can confirm reconnection once the red indicator disappears.
			</Para>
			<Actions>
				<RoundButton disabled={this.state.reloading} primary={!this.state.reloading}
					spin={this.state.reloading} icon="sync-alt" onClick={e => this.refresh()}>
					Refresh
				</RoundButton>
				<RoundButton icon={["far", "times"]} onClick={e => this.props.onDismiss(e)}>
					Close
				</RoundButton>
			</Actions>
		</Container>;
	}

}