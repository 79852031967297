import React from 'react';
import styled, { css } from "styled-components";
import RoundButton from '../core/RoundButton/RoundButton';
import TabHeader from './TabHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as tabActionCreators } from '../../store/Tabs';
import { actionCreators as notificationActionCreators } from '../../store/Notifications';
import TabDefinitions from '../home/TabDefinitions';
import NotificationDefinitions from '../notifications/NotificationDefinitions';

const TabSet = styled.ul`
	grid-area: tab-set;

	list-style: none;
	margin: 0;
	padding: 0;
	
	display: grid;

	grid-template-columns: repeat(${props => props.tabCount + 1}, auto) 1fr;

	height: calc(100% + 2px);
	margin-bottom: ${props => props.tabCount == 1 ? "-1px" : "-2px"};
`;

const NotificationText = styled.div`

	p {
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

`;

const SmallText = styled.p`

	font-size: .85em;
	color: ${props => props.theme.colors.secondary};

`;

const NewTabButton = styled(RoundButton)`

	${props => {
		const theme = props.theme;
		return css`
		
			font-size: .8em;
			padding: ${theme.padding.full};
			padding-left: ${theme.padding.double};
			margin: ${theme.padding.full} 0 calc(${theme.padding.full} + ${theme.padding.half}) calc(${theme.padding.full} + ${theme.padding.half});
			display: inline-flex;
			align-items: center;
		
		`;
	}}	

`;

class TabController extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			consumers: []
		};
		this.onNewTabClick = this.onNewTabClick.bind(this);
		this.onExistingTabClick = this.onExistingTabClick.bind(this);
		this.onExistingTabClose = this.onExistingTabClose.bind(this);
	}

	onNewTabClick() {
		var newTabState = TabDefinitions.InitializeTab(TabDefinitions.types.newTab);
		this.props.tabCreated(newTabState);
	}

	onExistingTabClick(evt, tabDetails) {
		this.props.tabSelectionChanged({ tabId: tabDetails.key });
	}

	onExistingTabClose(evt, tabDetails) {
		evt.stopPropagation();
		if (!tabDetails.task && !tabDetails.campaign) {
			var tab = this.props.tabs.find(t => t.key == tabDetails.key);
			this.props.tabDeleted(tab.key);
		} else {
			if (tabDetails.campaign) {			
				this.props.createNotification(
					{ 
						type: NotificationDefinitions.types.basic,
						body: <NotificationText>
							<p>You must log out of this campaign before closing the tab.</p>
							<SmallText>To log out, you must not have an active task. You can stop the flow of incoming calls by setting your status to Away.</SmallText>
						</NotificationText>, 
						title:'Cannot Close Tab',
						icon: ["far", "window-alt"],
						warning: true
					});
			} else if (tabDetails.task) {			
				this.props.createNotification(
					{ 
						type: NotificationDefinitions.types.basic,
						body: "You must finish this task before closing the tab.", 
						title:'Cannot Close Tab',
						icon: ["far", "window-alt"],
						warning: true
					});
			}
		}
	}

	render() {
		var tabHeaders = this.props.tabs.map(t => {
			let consumerName = "";
			let tabLabel = t.label;

			// If there is an active task for this tab, do not allow closing
			let tabTask = null;
			if (this.props.agent.currentWorkTasks) {
				tabTask = this.props.agent.currentWorkTasks.find(k => k.originId === t.key);
				if (tabTask && tabTask.consumer) {
					consumerName = `${tabTask.consumer.firstName} ${tabTask.consumer.lastName}`;
				}
			}

			let tabCampaign = null;
			if (this.props.agent.currentCampaigns) {
				tabCampaign = this.props.agent.currentCampaigns.find(c => c.campaignSessionId === t.sessionId);
				if (this.props.agent.currentWorkTasks) {
					let campaignTask = this.props.agent.currentWorkTasks.find(k => k.sessionId === t.sessionId);
					if (campaignTask && campaignTask.consumer) {						
						consumerName = `${campaignTask.consumer.firstName} ${campaignTask.consumer.lastName}`;
					}
				}
			}

			// If no consumer name exists, check to see if this is a manual dial
			if (t.type.toLowerCase() === 'dialpad' || t.type.toLowerCase() === 'consumersession') {
				let manualDialTask = null;

				if (consumerName === '' && this.props.agent.currentWorkTasks) {
					manualDialTask = this.props.agent.currentWorkTasks.find(t => t.workTaskType === 10);					

					if (manualDialTask) {
						consumerName = manualDialTask.phoneNumber;
						tabLabel = 'Manual Dial';
					} else {
						let agentToAgentTask = this.props.agent.currentWorkTasks.find(t => t.workTaskType === 9);

						if (agentToAgentTask) {
							consumerName = agentToAgentTask.phoneNumber;
							tabLabel = 'Agent';
						} else {
							let supervisorTask = this.props.agent.currentWorkTasks.find(t => t.workTaskType === 6 && t.phoneNumber !== '' && t.consumer === null);

							if (supervisorTask) {
								consumerName = supervisorTask.phoneNumber;
								tabLabel = 'Manual Dial';
							}
						}
					}
				}
			}

			const tabDetails = {
				campaign: tabCampaign,
				task: tabTask,
				key: t.key
			};

			return <TabHeader key={t.key} selected={t.selected}
				disableClose={(this.props.tabs.length == 1)}
				onClick={e => this.onExistingTabClick(e, tabDetails)}
				onClose={e => this.onExistingTabClose(e, tabDetails)}>
				<strong>{tabLabel}</strong>
				{
					consumerName &&
					<span>&nbsp;&#8212; {consumerName}</span>
				}		
			</TabHeader>;
		});
		return <TabSet tabCount={this.props.tabs.length}>
			{tabHeaders}
			<NewTabButton onClick={this.onNewTabClick} icon="plus"></NewTabButton>
		</TabSet>;
	}
}

export default connect(state => {
	return {
		...state.tabs,
		agent: state.agent,
		...state.notifications
	}
},
	dispatch => bindActionCreators({
		...tabActionCreators, 
		...notificationActionCreators
	}, dispatch)
)(TabController)