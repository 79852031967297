import React from 'react';
import styled from 'styled-components';
import { withRouter, Route } from "react-router-dom";
import AgentEdit from "./agents/AgentEdit.jsx";
import AgentList from "./agents/AgentList.jsx";
import TagList from "./tags/TagList.jsx";
import TagEdit from "./tags/TagEdit.jsx";
import SidebarNavigation from "../core/Sidebar/SidebarNavigation.jsx";

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	margin: 0;

	display: grid;
	grid-template-columns: 160px 1fr;
	grid-template-areas: "sidebar main";
`;

class Configure extends React.Component {

	render() {

		var agentListRoute = `${this.props.match.url}/agent/`;
		var tagListRoute = `${this.props.match.url}/attributes/`;

		return <Container className={this.props.className}>
			<SidebarNavigation 
				title="Configuration"
				links={[
					{ key: "agents", name: "Agents", to: agentListRoute, icon: "user-headset"},
					{ key: "tags", name: "Attributes", to: tagListRoute, icon: ["far", "tags"]}
				]}>
			</SidebarNavigation>
			<Route exact path={agentListRoute} component={AgentList}/>
			<Route path={`${this.props.match.url}/agent/:id`} component={AgentEdit}/>
			<Route exact path={tagListRoute} component={TagList}/>
			<Route path={`${this.props.match.url}/attributes/:id`} component={TagEdit}/>
		</Container >;
	}
}

export default withRouter(Configure);
