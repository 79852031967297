import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			margin-bottom: ${theme.margin.full};
		`;
	}}
`;

const FieldLabel = styled.label`

	${props => {
		const theme = props.theme;
		return css`
			margin-bottom: ${theme.margin.half};
		`;
	}}

`;

const RequiredText = styled.span`

	${props => {
		const theme = props.theme;
		return css`
			margin-top: ${theme.margin.quarter};
			font-family: ${theme.fonts.heading};
			display: block;
			color: ${theme.colors.secondaryLight};
			font-size: .75em;
			text-transform: uppercase;
		`;
	}}

`;

const FieldError = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			margin-top: ${theme.margin.half};
			margin-bottom: ${theme.margin.half};
			color: ${theme.colors.negative};

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
			}
		`;
	}}

`;

const FieldContent = styled.div`

	position: relative;

`;

const FieldErrorIcon = styled(FontAwesomeIcon)`

	${props => {
		const theme = props.theme;
		return css`
			position: absolute;
			left: -${theme.padding.double};
			top: -${theme.margin.quarter};
			color: ${theme.colors.negative};
			font-size: 1.35em;
		`;
	}}
`;

const FieldErrorIconBackground = styled(FieldErrorIcon)`
	color: ${props => props.theme.colors.white};
`;

/**
 * Wrapper for form elements to provide labeling and validation support.
 * 
 * @property {object}		children				-	The form field element to display inside
 * 	this component.
 * @property {string}		[className]			-	HTML class name for the field container
 * @property {string}		label					-	User-facing name for the field
 * @property {object}		labelProps			-	Object containing additional properties to
 * 	apply to the `<label>` element specifically
 * @property {string}		name					-	HTML name for the form field. Used for 
 * 	the `for` attribute on the label as well.
 * @property {object}		[validation]		-	Validations to apply to field. Either
 * 	a validation object, or an array of validation objects, from the component's
 * 	validation state.
 */
export default class Field extends React.Component {

	render() {

		let showError = false;
		let required = false;
		let errorMessages = null;
		if (this.props.validation) {
			const validations = Array.isArray(this.props.validation)
				? this.props.validation
				: [this.props.validation];
				
			// Merge server side error messages in
			validations.forEach((v, i) => {
				if (v.message && v.validation) {
					validations[i] = {
						...v.validation,
						message: v.message
					};
				}
			});

			const errors = validations.filter(v => v.isNotValid);
			showError = errors.length > 0;
			required = validations.some(v => v.required);
			errorMessages = errors.map(v => <li key={v.key}>{v.message}</li>);
		}

		return <Container className={this.props.className} ref={this.props.innerRef}>
			<FieldLabel htmlFor={this.props.name} {...this.props.labelProps}>
				{this.props.label}
				{required && <RequiredText>Required</RequiredText>}
			</FieldLabel>
			<FieldContent>
				{this.props.children}
				{
					showError &&
					<React.Fragment>
						<FieldErrorIconBackground icon="circle"></FieldErrorIconBackground> 
						<FieldErrorIcon icon="exclamation-circle"></FieldErrorIcon> 
					</React.Fragment>
				}
			</FieldContent>	
			{
				errorMessages &&
				<FieldError>
					<ul>{errorMessages}</ul>
				</FieldError>
			}		
		</Container>;
	}
}