export const types = {
	voicemailUpdated: 'VOICEMAIL_UPDATED',
	voicemailDeleted: 'VOICEMAIL_DELETED',
	voicemailInitialized: 'VOICEMAIL_INITIALIZED',
	phoneConnected: 'PHONE_CONNECTED',
	agentUpdated: 'AGENT_UPDATED'
};

const initialState = {
	filteredVoicemails:[],
	voicemails: [],
	agentId: null,
	agentDIDs: [],
	agentExtension: null,
	agentAttributes: [],
	agentVoicemailPermission:null //if null dont show anything until this loads.
};

export const actionCreators = {

};

function filterVoicemails(state) {
	var filteredVoicemails = [];

	if (state.voicemails) {
		switch (state.agentVoicemailPermission) {
			case 0:
				break;
			case 1:
				filteredVoicemails = state.voicemails.filter(vm => {
					//extension match.
					if (vm.toExtension !== null) {
						return vm.toExtension === state.agentExtension;
					}
					if (vm.toNumber !== null && state.agentDIDs.filter(x => x.digits == vm.toPhoneNumber).length > 0) {
						return true;
					}
					if (vm.attributes.length > 0 && vm.attributes.filter(x => {
						var res = state.agentAttributes.filter(y => x.key == y.key).length > 0;
						return res;
					}).length > 0) {
						return true;
					}
					return false;
				});
				break;
			case 2:
				filteredVoicemails = state.voicemails;
				break;
			default:
				break;
		}
	}

	state.filteredVoicemails = filteredVoicemails;
	return state;
}

export const reducer = (state, action) => {
	state = state || initialState;
	if (action.type === types.phoneConnected || (action.type === types.agentUpdated && state.agentId && action.data.id === state.agentId)) {
		var data = action.data;
		var newState = { ...state, agentId: action.data.id, agentAttributes: data.attributes, agentExtension: data.extension, agentVoicemailPermission: data.voicemailAccessPermission, agentDIDs: data.phoneNumbers };
		return filterVoicemails(newState);
	}
	else if (action.type === types.voicemailInitialized) {
		var newState = { ...state, voicemails: action.data.voicemails };
		return filterVoicemails(newState);
	}
	else if (action.type === types.voicemailUpdated) {
		var vm = action.data;
		var clonedVms = state.voicemails.slice(0);
		var index = clonedVms.findIndex(x => x.id == vm.id);
		if ((vm.processed) && index >= 0) {
			clonedVms.splice(index, 1);
		}
		else if (index >= 0) {
			clonedVms[index] = vm;
		}
		else {
			clonedVms.push(vm);
		}

		var newState = { ...state, voicemails: clonedVms };
		return filterVoicemails(newState);
	}
	else if (action.type === types.voicemailDeleted) {
		var vm = action.data;
		var clonedVms = state.voicemails.slice(0);
		var index = clonedVms.findIndex(x => x.id == vm.id);

		if (index >= 0) {
			clonedVms.splice(index, 1);
		}

		var newState = { ...state, voicemails: clonedVms };
		return filterVoicemails(newState);
	}
	return state;
};




