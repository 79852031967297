import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DialerLogin from '../dialer/DialerLogin'
import DialerSession from '../dialer/DialerSession'
import ViewHeader from './ViewHeader';
import { actionCreators } from '../../store/Tabs';

class DialerView extends React.Component {
	constructor(props) {
		super(props);
		this.onLoggedIn = this.onLoggedIn.bind(this);
		this.onLogoutRequested = this.onLogoutRequested.bind(this);
		this.loadDebtorScreen = this.loadDebtorScreen.bind(this);
	}

	async loadDebtorScreen() {
		if (window.bloodhound && window.bloodhound.loadConsumerScreen) {
			console.log('Invoking loadConsumerScreen back to bloodhound.', this.props.tabKey);
			window.bloodhound.loadConsumerScreen(this.props.tabKey);
		}
	}

	onLoggedIn(dialerSession) {
		var newTabState = {
			session: dialerSession,
			sessionId : dialerSession.id
		}
		this.props.tabStateChanged(this.props.tabKey, newTabState);
	}

	onLogoutRequested() {

	}

	render() {
		var session = undefined;
		if (this.props.session && this.props.currentCampaigns) {
			session = this.props.currentCampaigns.find(campaign => campaign.campaignSessionId === this.props.session.id);
		}
		return <div>
			{!session && 
				<React.Fragment>
					<ViewHeader label="Log In" />
				    <DialerLogin tabKey={this.props.tabKey} originId={this.props.originId} loggedIn={this.onLoggedIn} enabled={this.props.online} />
				</React.Fragment>
			}
			{session && 
				<React.Fragment>
					<DialerSession sessionId={session.Id} tabKey={this.props.tabKey} originId={this.props.originId} 
						dialerSession={session} logoutRequested={this.onLogoutRequested}></DialerSession>
				</React.Fragment>
			}
		</div>;
	}
}


export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DialerView);
