import React from 'react';
import styled, { css } from "styled-components";
import SidebarCurtain from '../core/Sidebar/SidebarCurtain';
import AgentAdapter from '../monitoring/AgentAdapter';
import ItemList from '../monitoring/ItemList';

const Container = styled.div`
	height: 100%;
	margin: 0;

	display: grid;
	grid-template-columns: 160px 1fr;
	grid-template-areas: "sidebar main";
`;

const MainContent = styled.div`

	grid-area: main;
	overflow-y: auto;

`;

const Curtain = styled(SidebarCurtain)`

	grid-area: sidebar;

`;
export default class MonitoringView extends React.Component {

	render() {

		return <Container>
			<Curtain title="Monitoring">
			</Curtain>
			<MainContent>
				<AgentAdapter>
					<ItemList tabKey={this.props.tabKey}></ItemList>
				</AgentAdapter>
			</MainContent>
		</Container>;
	}

}