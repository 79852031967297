import React from 'react';
import styled, {css} from 'styled-components';

const Container = styled.div`
	${props => {
		const theme = props.theme;
		return css`
			background: ${props.background || theme.button.background};
			color: ${props.color || theme.button.text};
			display: ${props.block ? "block" : "inline-block"};
			font-size: 1em;
			line-height: 1em;
			border-radius: 80px;
			padding: 8px 12px 8px;
			text-align: center;
		`;
	}}
	
`;

/**
 * Decorative label to show the state of something to the user.
 */
export default class Badge extends React.Component {

	render() {

		return <Container className={this.props.className} inline={this.props.inline}
			background={this.props.background} color={this.props.color}>
			{this.props.children}
		</Container>;
	}
	
}