import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Agent';

import NewTabItems from "../new-tab/NewTabItems";


class NewTabView extends React.Component {

	render() {
		return <NewTabItems tabKey={this.props.tabKey} />;
	}

}

export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(NewTabView)
