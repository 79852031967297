import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Agent';
import HttpAgent from '../../httpagent';
import TaskDetail from '../TaskDetail';
import styled, {css} from 'styled-components';
import ViewHeader from '../views/ViewHeader';

const CenterContainer = styled.div`

	${props => {
		var margin = props.activeTask ? 200 : 160;
		return css`margin: calc(50vh - ${margin}px) auto 0;`;
	}}

	padding: 0 ${props => props.theme.margin.double};	
	text-align: center;
	position: relative;

`;

class DialerSession extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			logoutButtonEnabled: true
		}

		this.logoutDialerClicked = this.logoutDialerClicked.bind(this);
	}

	async logoutDialer() {
		var session = this.props.dialerSession;
		//Tell the roydan API we are logging into a dialer session. after we get confirmation that we are logged in (signalr), tell a bh debtor window
		await HttpAgent.logoutDialer({ queue: session.queue, entity: session.entity, sessionId: session.campaignSessionId, originId: this.props.tabKey });
		if (window.bloodhound && window.bloodhound.logoutDialer) {
			console.log('Invoking logoutDialer back to bloodhound', this.props.tabKey, session);
			window.bloodhound.logoutDialer(this.props.tabKey, JSON.stringify(session));

			if (this.props.previousCallAcceptState !== this.props.callAcceptState) {
				this.props.updateMyCallAcceptState(this.props.previousCallAcceptState);
			}			
		}

		this.setState({
			logoutButtonEnabled: true
		});
	}

	async logoutDialerClicked() {
		if (!this.state.logoutButtonEnabled) {
			return;
		}

		this.setState({
			logoutButtonEnabled: false
		}, async () => await this.logoutDialer());
	}

	getCurrentTask() {
		return this.props.currentWorkTasks.find(
			task => task.sessionId && this.props.dialerSession && task.sessionId === this.props.dialerSession.campaignSessionId);
	}

	render() {
		var sessionTask = this.getCurrentTask();

		return <React.Fragment>			
			<ViewHeader tabKey={this.props.tabKey} showActionButtons={true} label="Encore Voice" onLogoutRequest={this.logoutDialerClicked} logoutLabel="Log out" preventLogout={((sessionTask !== undefined) || !this.state.logoutButtonEnabled)} />
			<CenterContainer activeTask={sessionTask}>
				<TaskDetail tabKey={this.props.tabKey} activeTask={sessionTask} queueCode={this.props.dialerSession.queue} />				
			</CenterContainer>	
		</React.Fragment>;
	}
}


export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DialerSession);
