export const Options = {
	operations: [
		{ value: 1, label: 'Predictive Dial' },
		{ value: 2, label: 'Message Dial' },
		{ value: 3, label: 'Right Click Dial' },
		{ value: 4, label: 'Automated Message' }
	],
	binaryOption: [
		{ value: false, label: 'No' },
		{ value: true, label: 'Yes' }
	],
	phoneTechnology: [
		{ value: 0, label: "Unknown" },
		{ value: 1, label: "Landline" },
		{ value: 2, label: "Cell Phone" },
		{ value: 3, label: "VoIP" },
		{ value: 4, label: "Other" }
	],
	operationalConsent: [
		{ value: 0, label: 'Unspecified' },
		{ value: 1, label: 'Verbal Consent' },
		{ value: 2, label: 'Written Consent' },
		{ value: 3, label: 'Denied Consent' },
		{ value: 4, label: 'Indirect Consent' }
	],
	scope: [
		{ value: 0, label: "Consumer" },
		{ value: 1, label: "Target" },
		{ value: 2, label: "Global" }
	],
};