import React from 'react';
import styled, { css } from 'styled-components';
import RoundButton from '../RoundButton/RoundButton';
import posed from 'react-pose';

const Overlay = styled.div`	

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	display: flex;

	z-index: 1000;

	background-color: rgba(0,0,0,.3);

`;

const PoseContainer = posed.div({
	closed: {
		y: 0,
		opacity: 1,
		delay: 0,
		transition: {
			y: { type: 'spring', stiffness: 1000, damping: 25 },
			default: { duration: 150 }
		}
	},
	open: {
		y: 50,
		opacity: 0,
		transition: { duration: 150 }
	}
});

const Box = styled.div`

	${props => {
		const theme = props.theme;
		return css`
			background: ${theme.colors.background};
			color: ${theme.colors.text};
			box-shadow: ${theme.modal.shadow};
			max-width: 75vw;

			padding: ${theme.margin.full};
			
			border-radius: ${theme.margin.double};

			z-index: 1001;
		`;
	}}
`;

const Actions = styled.div`

	${props => {
		const theme = props.theme;

		return css`
			text-align: right;
			margin-top: ${props => props.theme.margin.full};
		`;
	}}

`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

/**
 * Pop-up dialog for important alerts.
 * 
 * @property {function}		[onDismiss]				-	Required function to be passed in if the dialog is dismissible
 * @property {boolean}		[notDismissible]		-	Use when the user should not be able to dismiss the dialog at all
 * @property {boolean}		[hideDismissButton]	-	Use when you have an alternative dismiss button implemented
 */
export default class Dialog extends React.Component {

	constructor(props) {
		super(props);
		this.onDismiss = this.onDismiss.bind(this);
		this.onClickOverlay = this.onClickOverlay.bind(this);
	}

	async onDismiss(evt) {
		if (this.props.onDismiss) {
			this.props.onDismiss(evt);
		} else {
			// eslint-disable-next-line no-console
			console.error("Dialog cannot close itself. Add onDismiss property with function to remove this component.");
		}
	}

	async onAccept(evt) {
		if (this.props.onAccept) {
			this.props.onAccept(evt);
		} else {
			// eslint-disable-next-line no-console
			console.error("Dialog cannot close itself. Add onAccept property with function to remove this component.");
		}
	}

	async handleBoxClick(e) {
		e.stopPropagation();
	}

	async onClickOverlay(evt) {
		console.log(evt.target);
		if (this.props.notDismissible) return;
		evt.preventDefault();
		if (evt.target === evt.currentTarget) {
			this.onDismiss(evt);
		}
	}

	render() {

		return <Overlay onMouseDown={e => this.onClickOverlay(e)}>
			<PoseContainer initialPose="open" pose="closed">
				<Box onMouseDown={e => this.handleBoxClick(e)}>
					{this.props.children}

					<ButtonContainer>
					{(this.props.showAcceptButton) &&
						<Actions>
							<RoundButton primary icon={this.props.acceptIcon} onClick={e => this.onAccept(e)} disabled={this.props.acceptDisabled}>
								{this.props.acceptText ? this.props.acceptText : "Ok" }
							</RoundButton>
						</Actions>
					}

					{(!this.props.hideDismissButton && !this.props.notDismissible) &&
						<Actions>
							<RoundButton primary icon={["far", "times"]} onClick={e => this.onDismiss(e)}>
								Close
							</RoundButton>
						</Actions>
					}
					</ButtonContainer>
				</Box>
			</PoseContainer>
		</Overlay>;
	}
}