import React from 'react';
import { withRouter }  from "react-router-dom";
import HttpAgent from '../../../httpagent.js';
import styled, { css, ThemeProvider } from 'styled-components';
import { UpperLayer } from '../../core/Themes.js';
import ConfigurePage from '../ConfigurePage.jsx';
import DataTable from '../../core/DataTable/DataTable.jsx';
import DataTableRow from '../../core/DataTable/DataTableRow.jsx';
import DataTableColumn from '../../core/DataTable/DataTableColumn.jsx';
import RoundButton from '../../core/RoundButton/RoundButton.jsx';
import LoadingIndicator from '../../core/LoadingIndicator/LoadingIndicator.jsx';
import Fuse from 'fuse.js';


const DataView = styled.div`
	height: 100%;
	background: ${props => props.theme.colors.background};

	${props => {
		if (props.loading) return css`
		
			border-top: 2px solid ${props.theme.colors.border};
		
		`;
	}}
`;

const Loader = styled(LoadingIndicator)`
	height: 100%;
	color: ${props => props.theme.colors.border};
`;

const AddButton = styled(RoundButton)`
	width: 50px;
	margin-left: 10px;
	text-align: center;
	span {
		margin-left: 0;
	}
`;

class TagList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: [],
			filteredTags: [],
			query: "",
			loading: true
		};
		this.filterTags = this.filterTags.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.sortAttributes = this.sortAttributes.bind(this);
	}

	async componentDidMount() {
		var response = await HttpAgent.getTags();
		// Sort alphabetically
		const tags = response.data.sort((a, b) => {
			var aName = a.name || a.key;
			var bName = b.name || b.key;			
			return aName ? aName.localeCompare(bName) : "";
		});
		this.setState({
			tags: tags,
			loading: false
		});
	}

	filterTags(query) {
		// Do fuzzy match of all properties
		var options = {
			keys: [{
				name: 'key',
				weight: 0.5
			}, {
				name: 'name',
				weight: 0.8
			}, {
				name: 'description',
				weight: 0.2
			}],
			threshold: 0.2,
			location: 0,
		};
		var fuse = new Fuse(this.state.tags, options);
		
		return fuse.search(query);
	}

	onSearchChange(event) {
		var newValue = event.target.value;
		var filteredTags = this.filterTags(newValue);
		this.setState({
			filteredTags: filteredTags,
			query: newValue
		});
	}

	sortAttributes(attrs) {
		// Do not sort if searching
		if (this.state.query || this.state.query !== "") {
			return attrs;
		}		
		// Sort alphabetically
		return attrs.sort((a, b) => {
			var aName = a.name || a.key;
			var bName = b.name || b.key;			
			return aName ? aName.localeCompare(bName) : "";
		});
	}

	render() {
		var match = this.props.match;

		var pageContent;

		var actions = <AddButton icon={["far", "plus-circle"]} to={`${match.url}create`}>New</AddButton>

		var filteredTagData = this.state.filteredTags.length > 0 || this.state.query
			? this.state.filteredTags 
			: this.state.tags;
		
		filteredTagData = this.sortAttributes(filteredTagData);

		var loading = this.state.loading;
		if (loading) {
			pageContent = <Loader />;
		} 
		else {
			var tagLines = filteredTagData.map(t => {
				if (t.id == null) return;
				return <DataTableRow key={t.id}>
					<DataTableColumn key="name">
						{t.name}
					</DataTableColumn>
					<DataTableColumn key="key">
						<code>{t.key}</code>
					</DataTableColumn>
					<DataTableColumn key="priority">
						{t.priority}
					</DataTableColumn>
					<DataTableColumn key="desc">
						{t.description}
					</DataTableColumn>
					<DataTableColumn key="editButton">
						<RoundButton icon={["far", "pencil"]} to={`${match.url}${t.id}`}>Edit</RoundButton>
					</DataTableColumn>
				</DataTableRow>;
			});
			pageContent = <DataTable columns={[
				{
					key: "name",
					title: "Name",
					width: [
						{
							width: "30%"
						},
						{
							minWidth: "900px",
							width: "200px"
						}
					]
				},
				{
					key: "key",
					title: "Key",
					width: [
						{
							width: "25%"
						},
						{
							minWidth: "900px",
							width: "200px"
						}
					]
				},
				{
					key: "priority",
					title: "Priority",
					width: [
						{
							width: "15%"
						},
						{
							minWidth: "900px",
							width: "70px"
						}
					]
				},
				{
					key: "desc",
					title: "Description",
					width: [
						{
							width: "30%"
						},
						{
							minWidth: "900px",
							width: "100%"
						}
					]
				},
				{
					key: "editButton",
					headerVisible: false,
					width: "100px",
					align: "right"
				}
			]}>
				{tagLines}
			</DataTable>;
		}

		return <ConfigurePage 
			className={this.props.className} 
			title="Attributes" hideHeaderBorder
			search={
				{
					name: "tagSearch",
					placeholder: "Search for attribute",
					width: "220px",
					disabled: loading,
					onChange: this.onSearchChange				
				}
			}
			actions={actions}>
			<ThemeProvider theme={UpperLayer}>
				<DataView loading={loading}>
					{pageContent}
				</DataView>
			</ThemeProvider>
		</ConfigurePage>;
	}
}

export default withRouter(TagList);