import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Agent';
import RoundButton from '../core/RoundButton/RoundButton';
import Toggle from '../core/Toggle/Toggle';
import HttpAgent from '../../httpagent';
import FormInput from '../core/FormInput/FormInput.jsx';
import styled, { css } from 'styled-components';
import ApplyValidation from '../utils/Validation.js';
import ValidationMessage from '../core/Validation/ValidationMessage';

const LoginGrid = styled.div`

	display: grid;
	max-width: 560px;
	grid-template-areas:
		"toggle toggle"
		"validation validation"
		"queue nothing"
		"actions actions";
	grid-template-columns: auto 1fr;
	grid-column-gap: ${props => props.theme.margin.full};
`;

const QueueContainer = styled.div`

	grid-area: queue;
	min-width: 300px;

`;

const ToggleContainer = styled.div`

	grid-area: toggle;
	display: flex;
	align-content: center;

`;

const DialerToggle = styled(Toggle)`
	margin-right: ${props => props.theme.margin.half};
`

const ActionContainer = styled.div`

	grid-area: actions;
	display: inline-flex;
   align-items: center;

`;

const ValidationContainer = styled.div`

	grid-area: validation;

`;

const CallAcceptInfo = styled.span`
	${props => {
		const theme = props.theme;
		return css`
			color: ${theme.colors.negative};
			margin-left: 2px;
			display: flex;
		`;
	}}
`;

class DialerLogin extends React.Component {
	constructor(props) {
		super(props);
		const inbound = window.localStorage.getItem('inboundLoginFlag');
		const outbound = window.localStorage.getItem('outboundLoginFlag');
		const queue = window.localStorage.getItem('queueLogin');
		this.state = {
			queue: queue == null ? '' : queue,
			inbound: inbound == null ? false : inbound == 'true',
			outbound: outbound == null ? true : outbound == 'true',
			requestFailed: null,
			failedMessage: null,
			loginButtonEnabled: true
		}
		this.loginDialerClicked = this.loginDialerClicked.bind(this);
		this.onQueueChange = this.onQueueChange.bind(this);
		this.handleToggleInbound = this.handleToggleInbound.bind(this);
		this.handleToggleOutbound = this.handleToggleOutbound.bind(this);
		this.onQueueKeyPress = this.onQueueKeyPress.bind(this);

		ApplyValidation(this, {
			selectAnOption: {
				validIf: state => state.inbound || state.outbound,
				message: "Must select Inbound or Outbound"
			},
			queueRequired: {
				validIf: state => !state.outbound || state.queue.length !== 0,
				trackDirtyState: state => state.queue,
				message: "Queue is required for Outbound dialer"
			},
			requestFailed: {
				validIf: state => !state.requestFailed,
				allowSubmit: true,
				message: state => state.failedMessage
			},
		});
	}

	async componentDidMount() {
		this.setStateWithDirtyTracking((prevState) => prevState);
	}

	async handleToggleInbound() {
		const toggled = !this.state.inbound;
		window.localStorage.setItem('inboundLoginFlag', toggled);
		this.validateAndSetState(() => ({
			inbound: toggled,
			requestFailed: false,
			failedMessage: ''
		}));
	}

	async handleToggleOutbound() {
		const toggled = !this.state.outbound;
		window.localStorage.setItem('outboundLoginFlag', toggled);
		this.validateAndSetState(() => ({
			outbound: toggled,
			requestFailed: false,
			failedMessage: ''
		}));
	}

	async onQueueKeyPress(e) {
		if (!e) e = window.event;
		const keyCode = e.keyCode || e.which;
		if (keyCode === '13') {
			// Enter pressed
			this.loginDialerClicked();
		}
	}

	async loginDialer() {
		const isValid = this.stateIsValid();

		if (isValid) {
			const entity = await window.bloodhound.getEntity();
			//Tell the roydan API we are logging into a dialer session. after we get confirmation that we are logged in (signalr), tell a bh debtor window
			const res = await HttpAgent.loginDialer({
				queue: !this.state.outbound ? null : this.state.queue.trim().toUpperCase(),
				entity: entity,
				inbound: this.state.inbound,
				outbound: this.state.outbound
			});

			if (!res.data || res.error) {
				this.validateAndSetState(() => ({
					requestFailed: true,
					failedMessage: "Unable to log in. An unknown error occurred."
				}));
				return;
			}
			else if (!res.data.success) {
				this.validateAndSetState(() => ({
					requestFailed: true,
					failedMessage: res.data.failureMessage
				}));
				return;
			}
			if (this.props.loggedIn) {
				this.props.loggedIn(res.data.campaignSession);
			}
			if (window.bloodhound && window.bloodhound.loginDialer) {
				console.log('Invoking loginDialer back to bloodhound', this.props.tabKey, res.data.campaignSession);
				window.bloodhound.loginDialer(this.props.tabKey, JSON.stringify(res.data.campaignSession));

				if (this.state.outbound) {
					this.props.updatePreviousCallAcceptState(this.props.callAcceptState);
					this.props.updateMyCallAcceptState(0);
				}
			}
		}

		this.setState({
			loginButtonEnabled: true
		});
	}

	async loginDialerClicked() {	
		if (!this.state.loginButtonEnabled) {
			return;
		}

		this.setState({
			loginButtonEnabled: false
		}, () => this.loginDialer());
	}

	onQueueChange(event) {
		const newValue = event.target.value.trim().toUpperCase();
		window.localStorage.setItem('queueLogin', newValue);
		this.validateAndSetState(() => ({
			queue: newValue,
			requestFailed: false,
			failedMessage : ''
		}));
	}

	render() {	
		const validation = this.state.validation;
		const canSubmit = !this.state.validationFailed && this.props.online && this.props.phoneConnected && this.props.phoneAudioConnected && this.state.loginButtonEnabled;
		const callAcceptNotification = (this.state.outbound && this.props.callAcceptState === 1 ?
			<CallAcceptInfo>Dialer calls are auto accepted while logged in to Outbound.</CallAcceptInfo> : "");

		return <LoginGrid>
			<ToggleContainer>
				<DialerToggle checked={this.state.inbound} onChange={this.handleToggleInbound} >
					Inbound
				</DialerToggle>
				<DialerToggle checked={this.state.outbound} onChange={this.handleToggleOutbound} >
					Outbound
				</DialerToggle>				
			</ToggleContainer>
			<ValidationContainer>
				<ValidationMessage validation={validation.selectAnOption} />
			</ValidationContainer>
			<QueueContainer>
				<FormInput autoFocus codeField name="queue" label="Queue" width="100%"  
					validation={[
						validation.queueRequired,
						validation.requestFailed
					]} disabled={!this.state.outbound}
					onKeyPress={this.onQueueKeyPress}
					value={this.state.queue} onChange={this.onQueueChange} />
			</QueueContainer>
			<ActionContainer>
				<RoundButton disabled={!canSubmit} primary onClick={this.loginDialerClicked} icon="play">
					Log In
				</RoundButton>
				{callAcceptNotification}
			</ActionContainer>			
		</LoginGrid>;
	}
}


export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DialerLogin);
