import React from 'react';
import styled, { css } from 'styled-components';

const ContentCell = styled.td`

	${props => cellStyling(props)}
	
	${props => {	

		const font = props.numeric 
			? css`
				font-family: ${props.theme.fonts.numeric};
				font-size: 1.15em;
			`
			: props.code 
				? css`
					font-family: ${props.theme.fonts.code};
					font-size: 1.15em;
				`
				: css``;

		return font;
	}}

	font-weight: ${props => props.bold ? "700" : "300"};

`;

const HeaderCell = styled.th`

	${props => cellStyling(props)}	
	white-space: nowrap;

`;

function cellStyling(props) {
	const theme = props.theme;
	var width = "inherit";
	var mediaQueries = [];
	if ((typeof props.itemWidth) == "string") {
		width = props.itemWidth;
	}
	else if (Array.isArray(props.itemWidth)) {
		// Assume width is media query set
		props.itemWidth.forEach(w => {
			if (w.maxWidth == undefined && w.minWidth == undefined) {
				width = w.width;
			}
			else {
				if (w.maxWidth != undefined) {
					mediaQueries.push(css`
					
						@media(max-width: ${w.maxWidth}) {
							width: ${w.width};
						}
					
					`);
				}
				if (w.minWidth != undefined) {
					mediaQueries.push(css`
					
						@media(min-width: ${w.minWidth}) {
							width: ${w.width};
						}
					
					`);
				}
			}
		})
	}

	return css`
		padding: ${theme.margin.half} ${theme.padding.full};
		width: ${width};
		text-align: ${props.itemAlign || "left"};	
		text-overflow: ellipsis; 
		overflow: hidden; 

		${mediaQueries}

		&:first-child {
			padding-left: ${theme.margin.full};
		}

		&:last-child {
			padding-right: ${theme.margin.full};
		}
	`;
}

/**
 * Column for a DataTable.
 * @property {Array(object)}	children			-	Content to write into the table cell
 * @property {string}			key				-	Column key
 * 
 * Additional properties are mapped to the column in the parent `DataTable` component.
 * Be sure that the `key` property is specified on both.
 */
class DataTableColumn extends React.Component {

	render() {
		if (this.props.header) {
			return <HeaderCell className={this.props.className} 
				itemWidth={this.props.width}
				itemAlign={this.props.align}>
				{this.props.children}
			</HeaderCell >;
		}
		return <ContentCell className={this.props.className}  
			numeric={this.props.numeric} 
			code={this.props.code}
			bold={this.props.bold}
			itemWidth={this.props.width}
			itemAlign={this.props.align}>
			{this.props.children}
		</ContentCell >;
	}
}

export default DataTableColumn;