import React from 'react';
import styled, { css } from 'styled-components';
import Downshift from 'downshift';
import Field from '../Field/Field';

const SelectField = styled(Field)`

	${props => props.inputAdd ? css`margin-bottom: 0;` : null}

`;

const SelectInput = styled.input`

	${props => {
		const theme = props.theme;
		return css`
			border: 0;
			background: none;
			display: block;
			padding: ${props => props.theme.padding.double};
			outline: none;			
			font-family: ${theme.fonts.primary};	
			width: calc(${props.width || "200px"} - (${theme.padding.quad} * 2));
		`;
	}}
			
`;

const Container = styled.div`

	margin: 0;
	padding: 0;

	${props => {
		const theme = props.theme;
		let focus = null;
		if (props.focused) {
			focus = css`
				box-shadow: inset 0 0 0 2px ${theme.colors.brand}, ${theme.well.shadow};
			`;
		}
		return css`		
			background: ${props.disabled ? theme.colors.background : theme.input.background};
			color: ${theme.input.text};
			padding: 0 ${theme.padding.double};
			display: block;
			border-radius: calc(1em + ${theme.padding.full} - 1px);
			box-shadow: inset 0 0 0 2px ${theme.colors.border}, inset 0 0 0 2px transparent;
			transition: box-shadow ease-in-out .05s;
			font-family: ${theme.fonts.primary};
			width: calc(${props.width || "200px"} - (${theme.padding.double} * 2));	

			${focus}
		`;
	}}
`;

const ItemList = styled.ul`

	${props => {
		if (!props.children) {
			return css`
				display: none;
			`;
		}
		const theme = props.theme;
		return css`	
			max-height: 20vh;
			overflow-y: auto;
			margin: 0;
			padding: 0;
			margin-bottom: ${theme.padding.full};
			display: inline-block;
			width: 100%;

		`;
	}}

`;

const Item = styled.li`

	${props => {
		const theme = props.theme;
		let highlighted = null;
		if (props.highlighted) {
			highlighted = css`

				background: ${theme.colors.border};

			`;
		}
		return css`	

			font-size: .9em;

			margin: ${theme.padding.half};
			padding: ${theme.padding.half};
			border-radius: ${theme.padding.full};

			${highlighted}

		`;
	}}

`;

/**
 * Creates an dropdown selector for a list of options.
 * Provide an itemRender property to control how each item is rendered in the dropdown.
 * Uses the Downshift library. https://github.com/downshift-js/downshift
 */
export default class Autocomplete extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			focused: false
		};

		this.input = React.createRef();

		this.clear = this.clear.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	async clear() {
		this.clearSelection();
	}

	async onBlur() {
		this.setState({
			focused: false
		});
	}

	async onFocus() {
		this.setState({
			focused: true
		});
	}

	render() {

		let onSelect = (selection) => {
			// eslint-disable-next-line no-console
			console.error(`No action taken for item ${selection}`);
		};
		if (this.props.onChange) {
			const clear = this.clear;
			onSelect = item => {
				if (item != null || !this.props.clearOnSelect) {
					this.props.onChange(item);
					if (this.props.clearOnSelect) {
						clear();
					}
				}
			};
		}

		return <Downshift
			onSelect={onSelect}
			itemToString={item => ((item && item.value) ? item.value : '')}>
			{({
				getInputProps,
				getItemProps,
				getLabelProps,
				getMenuProps,
				getRootProps,
				isOpen,
				inputValue,
				highlightedIndex,
				selectedItem,
				clearSelection
			}) => {

				this.clearSelection = clearSelection;

				let itemContent = (item) => {
					return item.value;
				};
				if (this.props.itemRender) {
					itemContent = (item) => {
						return this.props.itemRender(item);
					};
				}

				const itemRender = (item, index) => (
					<Item {...getItemProps({
						key: item.key,
						index,
						item,
						selected: selectedItem === item,
						highlighted: highlightedIndex === index
					})}> {itemContent(item)} </Item>
				);

				return <SelectField {...getRootProps({refKey: 'innerRef'})} label={this.props.label} 
					labelProps={{...getLabelProps()}} inputAdd={this.props.inputAdd}>
					<Container width={this.props.width} focused={this.state.focused} disabled={this.props.disabled}>
						<SelectInput {...getInputProps({
							width: this.props.width,
							placeholder: this.props.placeholder,
							disabled: this.props.disabled,
							onFocus: this.onFocus,
							onBlur: this.onBlur,
							ref: this.input
						})} />
						<ItemList {...getMenuProps()}>
							{isOpen ? this.props.items
								.filter(item => {
									if (!inputValue) {
										return true;
									}
									const lowInputValue = inputValue.toLowerCase();
									const lowValue = item.value ? item.value.toLowerCase() : null;
									if (item.value && lowValue.includes(lowInputValue)) {
										return true;
									}
									const lowSearchKey = item.searchKey ? item.searchKey.toLowerCase() : null;
									return (item.searchKey && lowSearchKey.includes(lowInputValue));
								})
								.map((item, index) => itemRender(item, index))
								: null}
						</ItemList>
					</Container>
				</SelectField>;
			}}
		</Downshift>;
	}
}