import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import {UpperLayer, LowerLayer} from '../core/Themes.js';

import InboundGrid from './InboundGrid';
import OutboundGrid from './OutboundGrid';
import TotalsGrid from './TotalsGrid';
import DialogDisplay from '../DialogDisplay';

import HttpAgent from '../../httpagent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionCreators as notificationActionCreators } from '../../store/Notifications';
import NotificationDefinitions from '../notifications/NotificationDefinitions';
import { bindActionCreators } from 'redux';

const Container = styled.div`
	${props => {
		const theme = props.theme;
		var totalsHeight = 124;
		return css`
			background: ${theme.colors.background};
			color: ${theme.colors.text};
			display: grid;
			grid-template-rows:
				minmax(30vh, auto)
				minmax(30vh, auto)
				${totalsHeight}px;
			grid-template-areas:
				"attributes"
				"out-queues"
				"out-totals";
			height: 100vh;
		`;
	}}
`;

const Cell = styled.div`

	grid-area: ${props => props.area};

	${props => {
		if (props.center) {
			return css`
			
				align-items: center;
				justify-content: center;
				display: flex;
			
			`;
		}
	}}

	${props => {
		if (props.scrollable) {
			return css`
			
				overflow-y: scroll;
				min-height: 30vh;
				resize: vertical;
			
			`;
		}
	}}

`;

const HeaderCell = styled.div`

	${props => {
		const theme = props.theme;
		return css`

			background: ${theme.colors.border};
			font-family: ${theme.fonts.heading};
			letter-spacing: 3px;
			font-weight: 300;
			font-size: 1.3em;
			text-transform: uppercase;
			padding: ${theme.margin.quarter} ${theme.margin.full};
			display: flex;
			align-items: center;

		`;
	}}

`;


class Dashboard extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			inboundStatistics: [],
			outboundStatistics: []
		};
		this.updateData = this.updateData.bind(this);

	}

	async componentDidMount() {
		const intId = window.setInterval(this.updateData, 30000);
		this.setState({
			intId: intId
		});
		this.updateData();
	}

	async componentWillUnmount() {
		window.clearInterval(this.state.intId);
	}

	async updateData() {
		const inboundStats = await HttpAgent.getInboundStatistics();
		const outboundStats = await HttpAgent.getOutboundStatistics();
		const tags = await HttpAgent.getTags();
		this.setState({
			tags : tags.data,
			inboundStatistics: inboundStats.data,
			outboundStatistics: outboundStats.data
		});
	}

	render() {

		return <React.Fragment>
			<ThemeProvider theme={UpperLayer}>
				<Container>
					<Cell scrollable area="attributes">
						<ThemeProvider theme={LowerLayer}>
							<HeaderCell> Inbound </HeaderCell>
						</ThemeProvider>
						<InboundGrid tags={this.state.tags} stats={this.state.inboundStatistics}/>
					</Cell>
					<Cell scrollable area="out-queues">
						<ThemeProvider theme={LowerLayer}>
							<HeaderCell> Outbound </HeaderCell>
						</ThemeProvider>
						<OutboundGrid stats={this.state.outboundStatistics}/>
					</Cell>
					<Cell area="out-totals">
						<TotalsGrid stats={this.state.outboundStatistics}/>
					</Cell>
				</Container>
			</ThemeProvider>
			<DialogDisplay/>
		</React.Fragment>;
	}
}

export default withRouter(connect(state => state.tasks, dispatch => bindActionCreators({
	...notificationActionCreators
}, dispatch))(Dashboard));
