export const types = {
	encoreHubConnected: 'ENCOREHUB_CONNECTED',
	encoreHubReconnected:'ENCOREHUB_RECONNECTED',
	encoreHubDisconnected: 'ENCOREHUB_DISCONNECTED',
	phoneConnected: 'PHONE_CONNECTED',
	phoneDisconnected: 'PHONE_DISCONNECTED',
	phoneAudioConnected: 'PHONE_AUDIO_CONNECTED',
	phoneAudioDisconnected: 'PHONE_AUDIO_DISCONNECTED',
	meStateChanged: 'ME_STATE_CHANGE',
	meCallAcceptStateChanged: 'ME_CALL_ACCEPT_STATE_CHANGE',
	agentUpdated: 'AGENT_UPDATED',
	previousCallAcceptStateChanged: 'PREVIOUS_CALL_ACCEPT_STATE_CHANGE'
};

const initialState = {
	agentId : null,
	online: null,
	phoneAudioConnected:null,
	phoneConnected:null,
	agentState: 0,
	canPauseRecording: false,
	monitoringPermission: 0,
	dialPadAccessPermission: 0,
	callerIdPhoneNumber: null,
	previousCallAcceptState: 0
};

export const actionCreators = {
	updateMyState: state => ({ type: types.meStateChanged, data: state }),
	updateMyCallAcceptState: state => ({ type: types.meCallAcceptStateChanged, data: state }),
	updatePreviousCallAcceptState: state => ({ type: types.previousCallAcceptStateChanged, data: state })
};

export const reducer = (state, action) => {
	var agentUpdateToBloodhound = (agent) => {
		if (window.bloodhound && window.bloodhound.agentUpdated) {
			console.log('Invoking Agent Updated back to bloodhound.', action.data);
			window.bloodhound.agentUpdated(JSON.stringify(agent));
		}
	}

	var setAccessibilityState = (data, agent) => {
		if (data.phoneDisconnected) {
			agent.phoneConnected = false;
		}
		if (data.apiDisconnected) {
			agent.online = false;
		}
		if (data.phoneAudioDisconnected) {
			agent.phoneAudioConnected = false;
		}
	};

	state = state || initialState;

	if (action.type === types.encoreHubConnected) {
		var agent = {
			...state,
			...action.data,
			agentId: action.data.id,
			agentState: action.data ? action.data.state : 0,
			canPauseRecording: action.data.canPauseRecording,
			monitoringPermission: action.data.monitoringPermission,
			dialPadAccessPermission: action.data.dialPadAccessPermission,
			callerIdPhoneNumber: action.data.callerIdPhoneNumber,
			callAcceptState: action.data ? action.data.callAcceptState : 0,
			previousCallAcceptState: action.data ? action.data.previousCallAcceptState : 0
		};

		agentUpdateToBloodhound(agent);
		return agent;
	}
	if (action.type === types.encoreHubDisconnected) {
		var agent = { ...state };
		setAccessibilityState(action.data, agent);
		agentUpdateToBloodhound(agent);
		return agent
	}
	if (action.type === types.phoneConnected) {
		var agent = { ...state, phoneConnected: true };
		agentUpdateToBloodhound(agent);
		return agent
	}
	if (action.type === types.phoneDisconnected) {
		var agent = { ...state };
		setAccessibilityState(action.data, agent);
		agentUpdateToBloodhound(agent);
		return agent
	}
	if (action.type === types.phoneAudioConnected) {
		var agent = { ...state, phoneAudioConnected: true };
		agentUpdateToBloodhound(agent);
		return agent
	}
	if (action.type === types.phoneAudioDisconnected) {
		var agent = { ...state };
		setAccessibilityState(action.data, agent);
		agentUpdateToBloodhound(agent);
		return agent
	}
	if (action.type === types.encoreHubReconnected) {
		var agent = { ...state, online: true };
		agentUpdateToBloodhound(agent);
		return agent
	}
	if (action.type === types.meStateChanged) {
		var agent = { ...state, agentState: action.data };
		agentUpdateToBloodhound(agent);
		return agent;
	}
	if (action.type === types.agentUpdated && state.agentId && action.data.id === state.agentId) {
		var agent = { ...state, ...action.data };
		agentUpdateToBloodhound(agent);

		if (window.bloodhound && window.bloodhound.closeEncoreHub && !agent.online) {
			console.log('Invoking Agent Encore Hub close back to bloodhound.', action.data);
			window.bloodhound.closeEncoreHub();
		} else if (!agent.online) {
			console.log('Unable to close Encore Hub for agent: ', agent);
		}

		return agent;
	}
	if (action.type === types.meCallAcceptStateChanged) {
		var agent = { ...state, callAcceptState: action.data };
		agentUpdateToBloodhound(agent);
		return agent;
	}
	if (action.type === types.previousCallAcceptStateChanged) {
		var agent = { ...state, previousCallAcceptState: action.data };
		return agent;
	}

	return state;
};
