import React from 'react';
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Indicator = styled.span`

	${props => {
		const theme = props.theme;
		return css`
			color: ${props.indicatorActive ? theme.colors.secondary : theme.colors.border};
			&:not(:last-child) {
				margin-right: ${theme.margin.quarter};
			}
		`;
	}}

`;

/**
 * Indicator for upstream/downstream activity.
 * 
 * @property {boolean}		[up]			-	Set to true to light up upward-facing arrow
 * @property {boolean}		[down]		-	Set to true to light up downward-facing arrow
 */
export default class DirectionalIndicator extends React.Component {

	render() {

		return <React.Fragment>
			<Indicator indicatorActive={this.props.down}><FontAwesomeIcon icon={"arrow-alt-to-bottom"} /></Indicator>
			<Indicator indicatorActive={this.props.up}><FontAwesomeIcon icon={"arrow-alt-from-bottom"} /></Indicator>
		</React.Fragment>;
	}

}