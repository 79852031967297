import React from 'react';
import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TextColumn = styled.div`

	${props => {
		const theme = props.theme;

		return css`
			grid-area: tag;
			padding: ${theme.margin.quarter} ${theme.margin.half} ${theme.margin.quarter} ${theme.margin.full};
			white-space: nowrap;
			text-overflow: ellipsis; 
			overflow: hidden; 
		`;
	}}

`;

const EndColumn = styled.a`
	${props => {
		const theme = props.theme;
		const color = theme.button.primary.background;
		const background = theme.colors.secondaryLight;
		return css`
			grid-area: tag-end;
			height: calc(1em + ${theme.margin.half});
			transition: background-color ease-in-out .05s,
				color ease-in-out .05s;
			background: ${background};
			color: ${color};
			display: flex;
			align-items: center;
			justify-content: center;
    		font-size: 1.2em;
			height: calc(100% -  ${theme.padding.double});
			margin: ${theme.padding.full};
			margin-right: ${theme.margin.half};			
			padding: 0 ${theme.margin.half};
			border-radius: 999px;

			&:hover {
				background: ${theme.colors.negative};
				color: ${theme.colors.brandDarkText};
			}
		`;
	}}

`;

const Container = styled.div`
	${props => {
		const theme = props.theme;
		
		const background = theme.button.primary.background;
		const color = theme.button.primary.text;

		let selectableStyles = null;
		if (props.onClick != null) {
		
			const hoverBackground = theme.button.primary.hover.background;
			const hoverColor = theme.button.primary.hover.text;

			selectableStyles = css`

				&:hover {
					background: ${hoverBackground};
					color: ${hoverColor};
				}	
			`;
		}

		return css`
			display: inline-grid;
			grid-template-columns: 1fr auto;
			grid-template-areas: "tag tag-end";
			grid-column-gap: 0;
			font-size: .9em;
			line-height: 1em;
			background: ${background};
			color: ${color};
			border-radius: 999px;
			transition: background-color ease-in-out .05s, 
				color ease-in-out .05s, 
				box-shadow ease-in-out .05s;
			justify-content: center;
			align-items: center;

			margin: ${theme.margin.quarter} 0;

			&:not(:last-child) {
				margin-right: ${theme.margin.half};
			}

			${selectableStyles}
		`;
	}}
	
`;

/**
 * Badge with a delete button. Like a Tag component, but with a
 * button inside to trigger a delete method.
 * 
 * Needs to be tested more - not in production use yet.
 */
export default class Token extends React.Component {

	render() {

		let onClick = null;
		if (this.props.onClick) {
			onClick = (e) => this.props.onClick(e);
		}

		return <Container onClick={onClick}>
			<TextColumn>{this.props.children}</TextColumn>
			<EndColumn onClick={this.props.onRemove}>
				<FontAwesomeIcon icon={["far", "times"]} />
			</EndColumn>
		</Container>;
	}
	
}