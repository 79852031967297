import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Notifications';
import {ThemeProvider} from "styled-components";
import {UpperLayer} from './core/Themes.js';
import Dialog from './core/Dialog/Dialog';
import NotificationDefinitions from './notifications/NotificationDefinitions';

class DialogDisplay extends React.Component {
	constructor(props) {
		super(props);
		this.dismissDialog = this.dismissDialog.bind(this);
	}

	dismissDialog(evt, key) {
		if (this.props.dismissNotification) {
			this.props.dismissNotification(key);
		}
	}

	render() {
		var dialog = <React.Fragment></React.Fragment>;
		if (this.props.notifications && this.props.notifications.length > 0) {
			var baseNotify = this.props.notifications[0];
			var notifyWithHook = {
				...baseNotify,
				onDismiss: this.dismissDialog
			};
			var notify = NotificationDefinitions.InitializeNotification(notifyWithHook);
			var rendered = NotificationDefinitions.RenderNotification(notify);
			dialog = <Dialog key={notify.key} onDismiss={evt => this.dismissDialog(evt, notify.key)}
				hideDismissButton={notify.hideDismissButton} notDismissible={notify.notDismissible}>
				{rendered}
			</Dialog>
		}

		return <ThemeProvider theme={UpperLayer}>
			{dialog}
		</ThemeProvider>
	}
}

export default connect(state => state.notifications,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DialogDisplay);