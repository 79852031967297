import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Tasks';
import styled, { css, ThemeProvider } from "styled-components";
import { UpperLayer, LowerLayer } from "../core/Themes.js";
import Dialog from '../core/Dialog/Dialog';
import DataTable from '../core/DataTable/DataTable.jsx';
import DataTableRow from '../core/DataTable/DataTableRow.jsx';
import DataTableColumn from '../core/DataTable/DataTableColumn.jsx';

import PhoneNumber from "../core/PhoneNumber/PhoneNumber";

import Tag from '../core/Tag/Tag.jsx';
import RoundButton from '../core/RoundButton/RoundButton.jsx';
import HttpAgent from '../../httpagent.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MillisecondsToDuration } from '../utils/DateHelpers';
import AttachVoicemail from '../voicemail/AttachVoicemail';
import ConsumerView from './ConsumerView';

import { Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';

import { actionCreators as notificationActionCreators } from '../../store/Notifications';
import NotificationDefinitions from '../notifications/NotificationDefinitions';
import { fal } from '@fortawesome/pro-light-svg-icons';

const MainContent = styled.div`
	grid-area: main;
	overflow-y: auto;
`;

const DataView = styled.div`
	border-bottom: 2px solid ${props => props.theme.colors.border};
`;

const ExpandedView = styled.div`

	padding: ${props => props.theme.margin.full};

`;

const TaskWrapper = styled.div`

	padding: ${props => props.theme.margin.full};

`;

const AttributeSection = styled.div`

	padding-bottom: ${props => props.theme.margin.full};

`;

const AudioContainer = styled.div`

	audio {
		width: 100%;
		height: 2em;
	}
	padding-bottom: ${props => props.theme.margin.full};

`;

const VoicemailActions = styled.div`

	text-align: right;

`;

const NotificationText = styled.div`

	p {
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

`;

const SmallText = styled.p`

	font-size: .85em;
	color: ${props => props.theme.colors.secondary};

`;

const styles = theme => {
	return {
		consumerIcon: {
			verticalAlign: 'middle',
		},
		tooltip: {
			fontSize: '0.9rem'
		}
	}
};


class VoicemailView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedItemKey: null,
			attachWindowOpen: false,
			selectedVoicemail: null,
			consumerNumber: ''
		};
		this.handleAttachDismissal = this.handleAttachDismissal.bind(this);
	}

	render() {
		const activeTask = this.props.currentWorkTasks && this.props.currentWorkTasks.length > 0
			? this.props.currentWorkTasks.find(task => task.originId && task.originId === this.props.tabKey)
			: null;

		const content = activeTask ? <TaskWrapper><ConsumerView tabKey={this.props.tabKey} /></TaskWrapper> : this.voicemailList();
		return <MainContent>
			{content}
		</MainContent>;
	}

	handleAttachDismissal() {
		this.setState({
			attachWindowOpen: false,
			selectedVoicemail: null
		});
	}

	async attachVoicemail(evt, id) {
		evt.stopPropagation();
		var consumerInfo = {};
		var selectedVm = this.props.voicemails.find(x => x.id == id);
		if (selectedVm && selectedVm.consumerId) {

			consumerInfo.consumerNumber = selectedVm.consumerNumber;
			consumerInfo.consumerName = selectedVm.consumerName; 
		}
		else if (window.bloodhound && window.bloodhound.getCurrentConsumerInfo) {
			var bldhoundConsumerInfo = await window.bloodhound.getCurrentConsumerInfo(this.props.tabKey);
			if (bldhoundConsumerInfo) {	
				consumerInfo.consumerNumber = bldhoundConsumerInfo.debtorNumber;
				consumerInfo.consumerName = `${bldhoundConsumerInfo.debtorFirstName} ${bldhoundConsumerInfo.debtorLastName}`; 
			}
		}
		
		this.setState({
			attachWindowOpen: true,
			selectedVoicemail: selectedVm,
			consumerInfo : consumerInfo
		});
	}

	async archiveVoicemail(evt, id) {
		evt.stopPropagation();

		this.props.createNotification(
			{
				hideDismissButton: true,
				type: NotificationDefinitions.types.confirmChoice,
				body: 'Continue archiving this voicemail?',
				title: 'Confirm Archive Voicemail?',
				icon: ["fa", "archive"],
				onConfirm: async (evt) => {
					await HttpAgent.archiveVoicemail(id);		
				}
			});
	}

	async deleteVoicemail(evt, id) {
		evt.stopPropagation();

		this.props.createNotification(
			{
				hideDismissButton: true,
				type: NotificationDefinitions.types.confirmChoice,
				body: 'Continue deleting this voicemail?',
				title: 'Confirm Delete Voicemail?',
				icon: ["far", "trash"],
				onConfirm: async (evt) => {
					await HttpAgent.deleteVoicemail(id);
				}
			});
	}

	async playedVoicemail(id) {
		await HttpAgent.voicemailPlayed(id);
	}

	showConsumerScreen(evt, phoneNumber, consumerId) {
		evt.stopPropagation();
		if (consumerId && window.bloodhound && window.bloodhound.loadConsumer) {
			console.log('Invoking loadConsumerScreen back to bloodhound', this.props.tabKey);
			window.bloodhound.loadConsumer(this.props.tabKey, consumerId);
		}
		else if (window.bloodhound && window.bloodhound.loadConsumerScreenFromPhoneNumber) {
			console.log('Invoking loadConsumerScreen back to bloodhound', this.props.tabKey);
			window.bloodhound.loadConsumerScreenFromPhoneNumber(this.props.tabKey, phoneNumber);
		}
	}

	voicemailList() {
		var propVoicemails = this.props.filteredVoicemails ? this.props.filteredVoicemails : [];
		var itemsWithSelection = propVoicemails.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(i => {
			return {
				...i,
				selected: i.id == this.state.selectedItemKey
			}
		});

		const voicemails = itemsWithSelection.map(i => {
			const attributes = i.attributes.map(t =>
				<Tag key={t.key}>{t.name || t.key}</Tag>
			);
			const date = new Date(i.timestamp);
			const streamUrl = `api/voicemail/${i.id}/stream`;

			const expandedView = <ExpandedView>
				{
					attributes.length > 0 &&
					<AttributeSection>
						<h5>Attributes</h5>
						<ThemeProvider theme={LowerLayer}>
							<React.Fragment>
								{attributes}
							</React.Fragment>
						</ThemeProvider>
					</AttributeSection>
				}
				{
					!(attributes.length > 0) &&
					<AttributeSection>
						<h5>No Attributes</h5>
					</AttributeSection>
				}
				<AudioContainer>
					<audio controls src={streamUrl} onPlay={() => this.playedVoicemail(i.id)} controlsList="nodownload">
						Your browser does not support the <code>audio</code> element.
					</audio>
				</AudioContainer>
				<VoicemailActions>
					{this.props.canDeleteVoicemail && <RoundButton icon={["far", "trash"]} onClick={evt => this.deleteVoicemail(evt, i.id)}>Delete</RoundButton>}
					{this.props.canArchiveVoicemail && <RoundButton icon={["fa", "archive"]} onClick={evt => this.archiveVoicemail(evt, i.id)}>Archive</RoundButton>}
					<RoundButton icon="plus" onClick={evt => this.attachVoicemail(evt, i.id)}>Attach</RoundButton>
					<RoundButton icon="search" onClick={evt => this.showConsumerScreen(evt, i.fromPhoneNumber, i.consumerId)}>{i.consumerId ? "Load" : "Search"}</RoundButton>
				</VoicemailActions>
			</ExpandedView>;

			return <DataTableRow key={i.id} expandedView={expandedView}>
				<DataTableColumn key="unplayed">
					<span>{!i.lastReviewedAgentId && <FontAwesomeIcon icon="exclamation" />}</span>
				</DataTableColumn>
				<DataTableColumn key="dateTime">
					<span>{(date.toLocaleString())}</span>
				</DataTableColumn>
				<DataTableColumn key="fromPhoneNumber">
					<span><PhoneNumber>{i.fromPhoneNumber}</PhoneNumber> {
						i.consumerName && <Tooltip title={<span className={this.props.classes.tooltip}>{i.consumerName}</span>} className={this.props.classes.consumerIcon}>
							<PersonIcon />
						</Tooltip>
					}
					</span>
				</DataTableColumn>
				<DataTableColumn key="toPhoneNumber">
					<PhoneNumber>{i.toPhoneNumber}</PhoneNumber>
				</DataTableColumn>
				<DataTableColumn key="durationInSeconds">
					<code>{MillisecondsToDuration(i.durationInSeconds * 1000)}</code>
				</DataTableColumn>
			</DataTableRow>;
		});

		return <ThemeProvider theme={UpperLayer}>
			<React.Fragment>
				<DataView>
					<DataTable columns={[
						{
							key: "unplayed",
							title: "",
							width: [
								{
									width: "5%"
								},
								{
									minWidth: "10px",
									width: "10px"
								}
							]
						},
						{
							key: "dateTime",
							title: "Date & Time",
							width: [
								{
									width: "30%"
								},
								{
									minWidth: "790px",
									width: "250px"
								}
							]
						},
						{
							key: 'fromPhoneNumber',
							title: 'From',
							width: [
								{
									width: '25%'
								},
								{
									minWidth: '790px',
									width: '200px'
								}
							]
						},
						{
							key: 'toPhoneNumber',
							title: 'To',
							width: [
								{
									width: '25%'
								},
								{
									minWidth: '790px',
									width: '200px'
								}
							]
						},
						{
							key: 'durationInSeconds',
							title: 'Duration',
							width: [
								{
									width: '15%'
								},
								{
									minWidth: '790px',
									width: '100%'
								}
							]
						},
					]}>
						{voicemails}
					</DataTable>
				</DataView>
				{this.state.attachWindowOpen &&
					<Dialog onDismiss={this.handleAttachDismissal} hideDismissButton>
					<AttachVoicemail tabKey={this.props.tabKey} onAttached={this.handleAttachDismissal} consumerInfo={this.state.consumerInfo} voicemail={this.state.selectedVoicemail}></AttachVoicemail>
					</Dialog>
				}
			</React.Fragment>

		</ThemeProvider>;
	}
}

export default withStyles(styles)(connect(state => {
		return {
			...state.agent,
			...state.voicemails,
			...state.notification
		};
	}
	, dispatch => bindActionCreators({
		actionCreators,
		...notificationActionCreators
	}, dispatch)
)(VoicemailView));