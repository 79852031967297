import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import StatusIndicator from './core/StatusIndicator/StatusIndicator.jsx';
import InputLine from './core/InputBar/InputLine.jsx';
import Fuse from 'fuse.js';

const TransferOption = styled(InputLine)`
	${props => {
		const theme = props.theme;
	    const status = props.status;
	    const color = status === "Online" ? theme.colors.positiveText : theme.colors.brand;
	    const canTransferToAgent = props.canTransferToAgent;
		const cursor = canTransferToAgent ? "pointer" : "not-allowed";
		return css`	
			h3 {
				color: ${color};
			}
            cursor: ${cursor};
		`;
	}}
`;

const StatusIndicatorWithMargin = styled(StatusIndicator)`
	margin-right: ${props => props.theme.margin.half};
`;

class TransferList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			transferTo: ''
		};

		this.currentTransferTo = null;
		this.nextTransferTo = null;
		this.queryTransferList = this.queryTransferList.bind(this);
	}

	queryTransferList() {
		//when the query is empty return an empty list.  This will be an additive filter.
		if (this.props.query === '') {
			return [];
		}

		const startsWithSearch = (a, b) => {
			return a && b && a.trim().toUpperCase().startsWith(b.trim().toUpperCase());
		};

		const agents = this.props.agents.filter(agent => {
			if (!agent) {
				return false;
			}

			if (!agent.encoreEnabled) {
				return false;
			}

			return (startsWithSearch(agent.personCode, this.props.query) ||
					startsWithSearch(agent.name, this.props.query)) &&
				// Exclude self
				agent.personCode.toUpperCase() !== this.props.agent.personCode.toUpperCase();
		}).sort(function (a, b) {
			const aName = a.name || a.personCode;
			const bName = b.name || b.personCode;
			const onlineSort = (a.online === b.online) ? 0 : a.online ? -1 : 1;
			const readyForTaskSort = (a.readyForTask === b.readyForTask) ? 0 : a.readyForTask ? -1 : 1;
			const availableSort = (a.state === b.state) ? 0 : a.state === 0 ? -1 : 1;
			const nameSort = aName.localeCompare(bName);

			return onlineSort || readyForTaskSort || availableSort || nameSort;
		});
		return agents;
	}

	render() {
		const searchResults = this.queryTransferList();
		const items = searchResults.map(agent => {
			let canTransferToAgent = false;

			if (agent) {
				let state = agent.state;
				let online = false;
				let currentTask = null;
				let onCall = false;

				state = agent.state;
				online = agent.online && agent.phoneConnected && agent.phoneAudioConnected;
				currentTask = agent.currentWorkTasks && agent.currentWorkTasks.length > 0 ? agent.currentWorkTasks[0] : null;

				onCall = (currentTask && !currentTask.completedTimestamp);
				canTransferToAgent = (state !== 2 && online && !onCall);
			}

			return <TransferOption key={agent.bloodhoundUserId} status={agent.status} canTransferToAgent={canTransferToAgent}
				onClick={(e) => this.props.onClick((canTransferToAgent ? agent.bloodhoundUserId : null), e)} >
				<h3>
					<StatusIndicatorWithMargin agent={agent} showCode />
					{agent.name && <span>{agent.name}</span>}
				</h3>
			</TransferOption>;
		});

		return <React.Fragment>
			{items}
		</React.Fragment>;
	}
}

export default connect(state => ({ agent: {...state.agent}, ...state.agents }))(TransferList);