import React from 'react';
import theme from './_shared-styles/Theme';
import ComplianceRouter from './compliance/ComplianceRouter';
import { ThemeProvider } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";

class OneModule extends React.Component {
	render() {
		return <ThemeProvider theme={theme}>
			<CssBaseline />
			<Container theme={ theme.wrapper }>
				<ComplianceRouter />
			</Container>
		</ThemeProvider>;
	}
}

export default OneModule;