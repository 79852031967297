import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, inputRef, inputProps, ...other } = props;

	return (
		<NumberFormat
			{...other}
			{...props.inputProps}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
		/>
	);
});

NumberFormatCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default class NumericTextField extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let props = { ...this.props };
		let textFieldProps = {};

		if (props.textFieldProps) {
			textFieldProps = props.textFieldProps;
			delete props.textFieldProps;
		}

		return (
			<TextField
				{...props}
				InputProps={{
					inputComponent: NumberFormatCustom,
					inputProps: textFieldProps
				}}
			/>
		);
	}
}