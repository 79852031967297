import React from 'react';

import DataTable from '../core/DataTable/DataTable.jsx';
import DataTableRow from '../core/DataTable/DataTableRow.jsx';
import { CompactColumn } from './CompactColumn.jsx';

import styled from 'styled-components';

const EmptyHeader = styled.h3`

	margin-left: ${props => props.theme.margin.full};
	color: ${props => props.theme.colors.secondary};

`;

export default class TotalsGrid extends React.Component {

	renderRow(s) {

		return <DataTableRow key={s.type}>
			<CompactColumn key="type">
				{s.type}
			</CompactColumn>
			<CompactColumn numeric key="live-connect">
				{s.liveConnect}
			</CompactColumn>
			<CompactColumn numeric key="live-message">
				{s.liveMessage}
			</CompactColumn>
			<CompactColumn numeric key="live-ans-mach">
				{s.liveAnsweringMachine}
			</CompactColumn>
			<CompactColumn numeric key="busy">
				{s.busy}
			</CompactColumn>
			<CompactColumn numeric key="no-answer">
				{s.noAnswer}
			</CompactColumn>
			<CompactColumn numeric key="ans-mach">
				{s.answeringMachine}
			</CompactColumn>
			<CompactColumn numeric key="fax">
				{s.fax}
			</CompactColumn>
			<CompactColumn numeric key="op-int">
				{s.operatorIntercept}
			</CompactColumn>
			<CompactColumn numeric key="hang-ups">
				{s.hangups}
			</CompactColumn>
		</DataTableRow>;
	}

	groupBy(xs, key) {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	}

	render() {
		if (!this.props.stats || this.props.stats.length <= 0) {
			return <EmptyHeader>No Statistics Available</EmptyHeader>;
		}
		
		var grouping = this.groupBy(this.props.stats, 'campaignType');
		//message dialing
		var aggStats = (acc, val) => {
			acc.liveConnect += val.numberOfConnectedCalls;
			acc.hangups += val.numberOfAbandonedCalls;
			acc.busy += val.numberOfBusyCalls;
			acc.noAnswer += val.numberOfNoAnswerCalls;
			acc.answeringMachine += val.numberOfAnsweringMachineCalls;
			acc.operatorIntercept += val.numberOfFailedCalls;
			acc.fax += val.numberOfFaxCalls;
			acc.liveMessage += val.numberOfLiveMessageCalls;
			acc.liveAnsweringMachine += val.numberOfLiveAnsweringMachineCalls;
			return acc;
		};

		var rcd = null;
		if (grouping[null]) {
			rcd = grouping[null].reduce(aggStats, {
				type: 'Right Click Dial',
				liveConnect: 0,
				hangups: 0,
				busy: 0,
				noAnswer: 0,
				answeringMachine: 0,
				operatorIntercept: 0,
				fax: 0,
				liveMessage: 0,
				liveAnsweringMachine: 0
			});
		}
		var message = null;
		if (grouping[0]) {
			message = grouping[0].reduce(aggStats, {
				type: 'Message',
				liveConnect: 0,
				hangups: 0,
				busy: 0,
				noAnswer: 0,
				answeringMachine: 0,
				operatorIntercept: 0,
				fax: 0,
				liveMessage: 0,
				liveAnsweringMachine: 0
			});
		}
		//predictive dialing.
		var predictive = null;
		if (grouping[1]) {
			predictive = grouping[1].reduce(aggStats, {
				type: 'Outbound',
				liveConnect: 0,
				hangups: 0,
				busy: 0,
				noAnswer: 0,
				answeringMachine: 0,
				operatorIntercept: 0,
				fax: 0,
				liveMessage: 0,
				liveAnsweringMachine: 0
			});
		}
		var aggs = [];
		if (predictive) {
			aggs.push(predictive);
		}
		if (message) {
			aggs.push(message);
		}
		if (rcd) {
			aggs.push(rcd);
		}
		return <DataTable columns={[
			{
				key: "type",
				headerVisible: false,
				width: [
					{
						width: "18%"
					}
				]
			},
			{
				key: "live-connect",
				title: "Live Connect",
				align: "right",
				width: [
					{
						width: "11%"
					}
				]
			},
			{
				key: "live-message",
				title: "Live Message",
				align: "right",
				width: [
					{
						width: "12%"
					}
				]
			},
			{
				key: "live-ans-mach",
				title: "Live A.M.",
				align: "right",
				width: [
					{
						width: "9%"
					}
				]
			},
			{
				key: "busy",
				title: "Busy",
				align: "right",
				width: [
					{
						width: "6%"
					}
				]
			},
			{
				key: "no-answer",
				title: "No Answer",
				align: "right",
				width: [
					{
						width: "10%"
					}
				]
			},
			{
				key: "ans-mach",
				title: "A.M.",
				align: "right",
				width: [
					{
						width: "7%"
					}
				]
			},
			{
				key: "fax",
				title: "Fax",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "op-int",
				title: "Failed",
				align: "right",
				width: [
					{
						width: "8%"
					}
				]
			},
			{
				key: "hang-ups",
				title: "Hang Ups",
				align: "right",
				width: [
					{
						width: "11%"
					}
				]
			},
		]}>
			{aggs.map(x => this.renderRow(x))}
		</DataTable>;

	}
}
