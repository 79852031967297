import Color from 'color';

const baseMargin = 1;
const basePadding = 4;

function flattenColors(colors) {
	var retColors = {};	
	Object.keys(colors).forEach(colorKey => {
		retColors[colorKey] = colors[colorKey].hex();
	});
	return retColors;
}

// Allows you to make a color darker or lighter, inverted if dark theme
// Negative values darken, positive values lighten
function shade(dark, color, level) {
	var darken = level < 0 ? !dark.isDarkTheme : dark.isDarkTheme;
	var adjLevel = level < 0 ? level * -1 : level;
	var finalShade = adjLevel * dark.contrastMultiplier > 1 ? 1 : adjLevel * dark.contrastMultiplier;	
	return darken 
		? color.darken(finalShade) 
		: color.lighten(finalShade);
}

//#region Common Theme

const baseRoydanColors = {
	positive: Color("#88AA00"),
	positiveDark: Color("#677217"),
	positiveLight: Color("#c3ee00"),
	negative: Color("#A32A23"),
	negativeDark: Color("#42110E"),
	negativeLight: Color("#FF473C"),
	warning: Color("#F56D24"), 
	warningLight: Color("#FF9900"), 
	white: Color("#fff"),
	black: Color("#000"),
	darkestGray: Color("#222"),
	darkGray: Color("#444"),
	gray: Color("#888")
};

const commonTheme = {
	colors: {
		...(flattenColors(baseRoydanColors)),
	},
	palettes: {
		workType: {
			rightClick: Color("#C52500"),
			predictive: Color("#01A3CE"),
			message: Color("#616161"),
			inbound: Color("#4C007D"),
			transfer: Color("#FFC000")
		}
	},
	fonts: {
		primary: "'Open Sans', sans-serif",
		heading: "'Rubik', sans-serif",
		numeric: "'Courier Prime Sans', sans-serif",
		code: "'Courier Prime Code', monospace",
		button: "'Rubik', sans-serif"
	},
	margin: {
		quarter: `${baseMargin / 4}rem`,
		half: `${baseMargin / 2}rem`,
		full: `${baseMargin}rem`,
		double: `${baseMargin * 2}rem`,
		triple: `${baseMargin * 3}rem`,
		quad: `${baseMargin * 4}rem`,
		x6: `${baseMargin * 6}rem`,
		x8: `${baseMargin * 8}rem`,
	},
	padding: {
		quarter: `${basePadding / 4}px`,
		half: `${basePadding / 2}px`,
		full: `${basePadding}px`,
		double: `${basePadding * 2}px`,
		triple: `${basePadding * 3}px`,
		quad: `${basePadding * 4}px`,
		x6: `${basePadding * 6}px`,
		x8: `${basePadding * 8}px`,
	},
}

//#endregion

const baseDarkThemeColors = {
	colors: {
		...baseRoydanColors,
		background: Color("#202020"),
		text: Color('#ddc'),
		brand: Color("#326Fa6"),
		brandText: baseRoydanColors.white,
		brandDark: Color("#1B3D5E"),
		brandDarkText: baseRoydanColors.white,
		brandHighlight: Color("#92BEFF"),
		brandHighlightText: baseRoydanColors.black,
		brandDarkMuted: Color("#273a4a"),
		upperLayer: Color("#666666").mix(Color("#202020"), 0.75),
		headingText: Color("#92BEFF"),
		secondary: Color("#999999").mix(Color("#202020"), 0.25),
		secondaryLight: Color("#bbbbbb").mix(Color("#202020"), 0.35),
		border: Color("#666666").mix(Color("#202020"), 0.65),
		inputBackground: Color("#060606")
	},
	dark: {
		isDarkTheme: true,
		contrastMultiplier: 4
	}
}

const baseLightThemeColors = {
	colors: {
		...baseRoydanColors,
		background: Color("#D0DDF1"),
		text: baseRoydanColors.darkestGray,
		brand: Color("#324A6D"),
		brandText: baseRoydanColors.white,
		brandDark: Color("#031E34"),
		brandDarkText: baseRoydanColors.white,
		brandHighlight: Color("#5F8CCF"),
		brandHighlightText: baseRoydanColors.white,
		brandDarkMuted: Color("#273a4a"),
		upperLayer: Color("#D0DDF1").mix(Color(baseRoydanColors.white), 0.75),
		headingText: baseRoydanColors.darkestGray,
		secondary: Color("#324A6D").mix(Color("#D0DDF1"), 0.15),
		secondaryLight: Color("#324A6D").mix(Color("#D0DDF1"), 0.35),
		border: Color("#324A6D").mix(Color("#D0DDF1"), 0.8),
		inputBackground: baseRoydanColors.white
	},
	dark: {
		isDarkTheme: false,
		contrastMultiplier: 1
	}	
}

const CoreTheme = base => {
	var newTheme = ({
		...commonTheme,
		baseColors: {
			...base.colors,
		},
		colors: {
			...flattenColors(base.colors),
		},
		dark: base.dark,
		button: {
			background: shade(base.dark, base.colors.background, -0.1).hex(),
			text: base.colors.text.hex(),
			shadow: `inset 0 0 4px 0 ${shade(base.dark, base.colors.background, -0.15).hex()}`,
			primary: {
				background: base.colors.brand.hex(),
				text: base.colors.brandText.hex(),
				shadow: `inset 0 0 4px 0 ${base.colors.brand.lighten(0.15 * base.dark.contrastMultiplier).hex()}`,
				hover: {
					background: base.colors.brandHighlight.hex(),
					text: base.colors.brandHighlightText.hex(),
					shadow: `inset 0 0 4px 0 ${base.colors.brandHighlight.lighten(0.15 * base.dark.contrastMultiplier).hex()}`
				},
				active: {
					background: base.colors.brandDark.hex(),
					text: base.colors.brandDarkText.hex(),
					shadow: `inset 0 2px 4px 3px ${base.colors.brandDark.darken(0.15 * base.dark.contrastMultiplier).hex()}`
				}
			},
			positive: {
				background: commonTheme.colors.positiveDark,
				text: commonTheme.colors.white,
				shadow: `inset 0 0 4px 0 ${shade(base.dark, base.colors.positiveDark, -0.15).hex()}`,
				hover: {
					background: commonTheme.colors.positive,
					text: commonTheme.colors.darkestGray,					
					shadow: `inset 0 0 4px 0 ${shade(base.dark, base.colors.positive, -0.15).hex()}`
				},
				active: {
					background: commonTheme.colors.positiveDark,
					text: commonTheme.colors.white,
					shadow: `inset 0 2px 4px 3px ${base.colors.positiveDark.darken(0.15 * base.dark.contrastMultiplier).hex()}`
				}
			},
			destructive: {
				background: commonTheme.colors.negative,
				text: commonTheme.colors.white,
				shadow: `inset 0 0 4px 0 ${base.colors.negative.lighten(0.1 * base.dark.contrastMultiplier).hex()}`,
				hover: {
					background: commonTheme.colors.negativeLight,
					text: commonTheme.colors.white,					
					shadow: `inset 0 0 4px 0 ${base.colors.negativeLight.lighten(0.1 * base.dark.contrastMultiplier).hex()}`
				},
				active: {
					background: commonTheme.colors.negative,
					text: commonTheme.colors.white,
					shadow: `inset 0 2px 4px 3px ${base.colors.negative.darken(0.1 * base.dark.contrastMultiplier).hex()}`
				}
			},
			disabled: {
				background: shade(base.dark, base.colors.background, -0.05).hex(),
				text: shade(base.dark, base.colors.background, -0.3).hex(),
				shadow: `inset 0 0 4px 0 ${shade(base.dark, base.colors.background, -0.1).hex()}`
			},
			hover: {
				background: shade(base.dark, base.colors.background, -0.15).hex(),
				shadow: `inset 0 0 4px 0 ${shade(base.dark, base.colors.background, -0.2).hex()}`
			},
			active: {
				background: shade(base.dark, base.colors.background, -0.175).hex(),
				shadow: `inset 0 2px 4px 3px ${base.colors.background.darken(0.225).hex()}`
			}
		},
		input: {
			background: base.colors.inputBackground.hex(),
			text: base.colors.text.hex()
		},
		selection: {
			background: base.colors.background.darken(0.1).hex()
		},
		tag: {
			active: {			
				shadow: "inset 0px 6px 6px -4px rgba(0,0,0,.075)"
			}
		},
		well: {
			background: base.colors.brandDarkMuted.hex(),
			shadow:"inset 0 3px 6px rgba(0,0,0,.1)",
			text: baseRoydanColors.positive.hex()
		}
	});	
	return newTheme;
}

export const DarkTheme = CoreTheme(baseDarkThemeColors);
export const LightTheme = CoreTheme(baseLightThemeColors);

//#region LowerLayer Theme

export const LowerLayer = theme => {
	const contrast = .5 + (theme.dark.contrastMultiplier / 2);
	const newBaseTheme = {
		colors: {
			...theme.baseColors,
			background: shade(theme.dark, theme.baseColors.background, -0.1 * contrast),
			border: shade(theme.dark, theme.baseColors.background, -0.15 * contrast)
		},
		dark: theme.dark
	};
	const newTheme = CoreTheme(newBaseTheme);
	return {
		...newTheme,
		tab: {
			background: newTheme.colors.background,
			selected: {
				background: theme.colors.background
			},
			hover: {
				background: newTheme.baseColors.background.mix(theme.baseColors.background, 0.5).hex()
			}			
		}
	};
};

//#region UpperLayer Theme

export const UpperLayer = theme => {
	var newBaseTheme = {
		colors: {
			...theme.baseColors,
			background: theme.baseColors.upperLayer,
			divider: theme.baseColors.upperLayer.mix(theme.baseColors.background, 0.3)
		},
		dark: theme.dark
	};
	var newTheme = CoreTheme(newBaseTheme);
	return {
		...newTheme,
		modal: {
			shadow:`0px 12px 10px -8px ${theme.baseColors.black.alpha(0.4).hsl().string()},
				inset 0px -2px 1px ${theme.baseColors.background.hex()}`
		},
		tab: {
			background: newTheme.colors.background,
			selected: {
				background: theme.colors.background
			},
			hover: {
				background: newTheme.baseColors.background.mix(theme.baseColors.background, 0.5).hex()
			}			
		}
	}
}

//#endregion

export const CardLayer = theme => {
	var newBaseTheme = {
		colors: {
			...theme.baseColors,
			background: shade(theme.dark, theme.baseColors.background, -0.175),
			border: shade(theme.dark, theme.baseColors.background, -0.15)
		},
		dark: {
			...theme.dark,
			isDarkTheme: true
		}
	};
	var newTheme = CoreTheme(newBaseTheme);
	var baseCardShadow = `0px 4px 4px rgba(0, 0, 0, 0.15)`;
	var baseCardHighlight = `, inset 0px 2px 0px ${shade(newTheme.dark, theme.baseColors.background, -0.17).hex()}`;
	return {
		...newTheme,
		card: {
			background: `linear-gradient(180deg, ${shade(newTheme.dark, theme.baseColors.background, -0.12).hex()} 0%, `
				+ `${shade(newTheme.dark, theme.baseColors.background, -0.17).hex()} 100%)`,
			shadow: baseCardShadow + baseCardHighlight,
			hover: {
				shadow: baseCardShadow + `, inset 0px 0px 0 5px ${shade(newTheme.dark, theme.baseColors.background, -0.08).hex()}` + baseCardHighlight
			},
			active: {
				background: `${shade(newTheme.dark, theme.baseColors.background, -0.08).hex()}`
			}
		}
	}
}