import Twilio from 'twilio-client';

export const types = {
	phoneDeviceReadyType: 'PHONE_DEVICE_READY_UPDATED',
	phoneConnectionUpdatedType: 'PHONE_CONNECTION_UPDATED',
	phoneConnected: 'PHONE_CONNECTED',
	phoneCallStatusUpdated: 'PHONE_CALL_STATUS_UPDATED'
};
window.roydanPhone = new Twilio.Device();

const initialState = {
	device: window.roydanPhone,
	ready: false,
	phoneCallEvent: null
};

export const actionCreators = {
	updateDeviceReady: ready => ({ type: types.phoneDeviceReadyType, data: ready }),
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === types.phoneConnected) {
		return { ...state, agentId : action.data.id };
	}

	if (action.type === types.phoneDeviceReadyType) {
		return { ...state, ready: action.data };
	}

	if (action.type === types.phoneCallStatusUpdated) {
		console.log('phone call status updated', action.data);
		return { ...state, phoneCallEvent: action.data };
	}

	return state;
};
