import React from 'react';
import Notification from '../core/Notification/Notification';
import DisconnectionNotification from './DisconnectionNotification';
import NotLicensedNotification from './NotLicensedNotification';
import PhoneDisconnectedNotification from './PhoneDisconnectedNotification';
import PhoneAudioDisconnectedNotification from './PhoneAudioDisconnectedNotification';
import ConfirmChoiceNotification from './ConfirmChoiceNotification';

const notifyDefs = {
	basic: {
		init: props => {
			return {
				...props
			};
		},
		render: (data) => {
			const allowedProps = getAllowedProps(data);
			return <Notification {...allowedProps}>
				{data.body}
			</Notification>;
		}
	},
	plainText: {
		init: props => {
			return {
				...props
			};
		},
		render: (data) => {
			const allowedProps = getAllowedProps(data);
			return <Notification renderPlainText {...allowedProps}>
				{data.body}
			</Notification>;
		}
	},
	disconnect: {
		init: props => {
			return {
				...props,
				hideDismissButton: true
			};
		},
		render: (data) => {
			return <DisconnectionNotification {...data} />;
		}
	},
	phoneDisconnect : {
		init: props => {
			return {
				...props,
				hideDismissButton: true
			};
		},
		render: (data) => {
			return <PhoneDisconnectedNotification {...data} />;
		}
	},
	phoneAudioDisconnect: {
		init: props => {
			return {
				...props,
				hideDismissButton: true
			};
		},
		render: (data) => {
			return <PhoneAudioDisconnectedNotification {...data} />;
		}
	},
	unlicensed: {
		init: props => {
			return {
				...props,
				notDismissible: true
			};
		},
		render: (data) => {
			return <NotLicensedNotification {...data} />;
		}
	},
	confirmChoice: {
		init: props => {
			return {
				...props
			};
		},
		render: (data) => {
			return <ConfirmChoiceNotification {...data} />;
		}
	}
};

var types = {}
Object.keys(notifyDefs).forEach(key => {
	types[key] = key;
});

function generateUUID() { // Public Domain/MIT
	var d = new Date().getTime();//Timestamp
	var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16;//random number between 0 and 16
		if (d > 0) {//Use timestamp until depleted
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {//Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
}

function getAllowedProps(props) {

	if (props == null) {
		return props;
	}

	var outProps = {};
	var allowedKeys = [
		"title", "icon", "positive", "negative", "warning" 
	];

	for (let i = 0; i < Object.keys(props).length; i++) {
		const key = Object.keys(props)[i];
		if (allowedKeys.includes(key.toLowerCase())) {
			outProps[key] = props[key];
		}
	}

	return outProps;
}

function InitializeNotification(props) {
	var type = props.type ? props.type : 'basic';
	var def = notifyDefs[type];
	var key = props.key || generateUUID();
	var newProps = {
		...props,
		key: key,
		type: type
	};

	return def.init(newProps);
}

function RenderNotification(data) {
	var def = notifyDefs[data.type];
	return def.render(data);	
}

export default {
	types,
	InitializeNotification,
	RenderNotification
};