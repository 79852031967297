import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Tabs';
import TaskDetail from '../TaskDetail'
import ViewHeader from './ViewHeader';
import styled, { css } from 'styled-components';
import HttpAgent from '../../httpagent.js';

const CenterContainer = styled.div`

	${props => {
		const margin = props.activeTask ? 200 : 160;
		return css`margin: calc(50vh - ${margin}px) auto 0;`;
	}}

	padding: 0 ${props => props.theme.margin.double};
	text-align: center;
	position: relative;

`;

class ConsumerView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.updateConsumerConsentDisplay = this.updateConsumerConsentDisplay.bind(this);
	}

	async componentDidMount() {
		await this.updateConsumerConsentDisplay();
	}

	async componentDidUpdate(props) {				
		if (props.consumer !== this.props.consumer) {
			await this.updateConsumerConsentDisplay();
		}		
	}

	async getConsumerConsentList(task) {
		if (task && task.consumerId) {
			try {
				let response = await HttpAgent.getConsumerConsent(task.consumerId)
				let data = response.data;

				this.setState({ consumerConsentList: data.sort((a, b) => b.Timestamp - a.Timestamp) });
			} catch (error) {
				console.log("ERROR RETRIEVING CONSUMER CONSENT: ", error.response);
			}
		}
	}

	async updateConsumerConsentDisplay() {
		const activeTask = this.props.currentWorkTasks && this.props.currentWorkTasks.length > 0
			? this.props.currentWorkTasks.find(task => task.originId && task.originId === this.props.tabKey)
			: null;

		if (activeTask) {
			await this.getConsumerConsentList(activeTask);
		}
	}

	render() {
		const activeTask = this.props.currentWorkTasks && this.props.currentWorkTasks.length > 0
			? this.props.currentWorkTasks.find(task => task.originId && task.originId === this.props.tabKey)
			: null;

		var headerLabel = "Consumer";

		if (activeTask) {
			if (activeTask.workTaskType === 9) {
				headerLabel = "Agent";
			} else if (activeTask.workTaskType === 10) {
				headerLabel = "Manual Dial";
			} else if (activeTask.workTaskType === 6 && activeTask.consumer === null && activeTask.phoneNumber !== '') {
				headerLabel = "Supervising";
			}
		}

		return <React.Fragment>
			<ViewHeader label={headerLabel} showActionButtons={true} tabKey={this.props.tabKey}
				consumerConsentList={this.state.consumerConsentList}
				onConsentUpdated={this.updateConsumerConsentDisplay}
			/>
			<CenterContainer activeTask={activeTask}>
				<TaskDetail tabKey={this.props.tabKey} activeTask={activeTask} />
			</CenterContainer>
		</React.Fragment>;
	}
}


export default connect(state => state.agent,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(ConsumerView);
